

import ActionArea from "components/Table/ActionArea"
import ResultsIdLinkCell from "page-sections/queryBuilder/components/ResultsIdLinkCell"

export const columns = [
    {
        Header: "Asset ID",
        accessor: "assetId",
        Cell: ({ value: data }) => {
            var _id = data.Id
            return(
            <ResultsIdLinkCell id={_id} object={data}/> 
            )
          },
    },
    {
        Header: "Asset Name",
        accessor: "assetName",
    },
    {
        Header: "Asset Trade",
        accessor: "assetTrade",
    },
    {
        Header: "Asset Category",
        accessor: "assetCategory",
    },
    {
        Header: "Asset Description",
        accessor: "assetDescription",
    },
    {
        Header: "Asset System Type",
        accessor: "assetSystemType",
    },
    {
        Header: "Building Name",
        accessor: "buildingName",
    },
    {
        Header: "Space Name",
        accessor: "spaceName",
    },
    {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ value: data }) => {
            const viewPath = "/fb/app/home/view-asset?id=" + data.id
            const editPath = "/fb/app/home/edit-asset?id=" + data.id
            // const deletePath = "/asset/delete?id="+data.id

            return(
                <ActionArea
                    viewPath={viewPath}
                    editPath={editPath}
                    deleteAPIPath="/asset/delete"
                    data={data}
                />
            )
        }
    }
]