import axios from 'axios'
import { globalPublicData } from "../../globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import { 
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions 

} from "../../redux/features/menuOptions/menuOptionsSlice";
// import { useFetchMenuOptionsQuery } from "../../redux/features/api/apiSlice"

import { columns as estateColumns } from './sections/EstateTable/estateColumns';
import { columns as propertyGroupColumns } from './sections/PropertyGroupTable/propertyGroupColumns';
import { columns as buildingColumns } from './sections/BuildingTable/buildingColumns';
import { columns as spaceColumns } from './sections/SpaceTable/spaceColumns';
import { columns as assetColumns } from './sections/AssetTable/assetColumns';

export const queryData = async (params) => {

    var endPoint = globalPublicData.baseEndpoint + "/common/searchbyname"
    const array = ["estate", "propertyGroup", "property", "space"]
    
    array.map( item => {
        
        if (params[item].Id === "All"){
            // params[item + "QueryString"] = item + "Id=" + "*"
            params[item + "QueryString"] = null
        } else {
            params[item + "QueryString"] = item + "Id=" +params[item].Id
        }
    })

    if (params.estateQueryString){endPoint += "?"+params.estateQueryString}
    if (params.propertyGroupQueryString){endPoint += "&"+params.propertyGroupQueryString}
    if (params.propertyQueryString){endPoint += "&"+params.propertyQueryString}
    if (params.spaceQueryString){endPoint += "&"+params.spaceQueryString}

    console.log('[queryData] endpoint is: ', endPoint)

    const response = await axios.get(endPoint)

    const uniqueIds = [...new Set(response.data.map( item => item.Id))]

    var filteredArrayByTimestamp = []

    uniqueIds.map( item => {
        var tempArray = response.data.filter( obj => {
            return obj.Id === item
        }).sort((a,b) => {
            return b["_ts"] - a["_ts"]
        })
        
        if (tempArray[0]["Is_Deleted"] !==true){
            
            filteredArrayByTimestamp.push(tempArray[0])
        }
    })

    

    var queriedEstates = filteredArrayByTimestamp.filter( (item) => {
        return item.Type === "estate"
    })

    var queriedProperties = filteredArrayByTimestamp.filter( (item) => {
        return item.Type === "property_group"
    })

    var queriedBuildings = filteredArrayByTimestamp.filter( (item) => {
        return item.Type === "building"
    })

    var queriedBuildingSpaces = filteredArrayByTimestamp.filter( (item) => {
        return item.Type === "space"
    })

    var queriedAssets = filteredArrayByTimestamp.filter( (item) => {
        return item.Type === "asset"
    })

    return {
        queriedEstates: queriedEstates,
        queriedProperties: queriedProperties,
        queriedBuildings: queriedBuildings,
        queriedBuildingSpaces: queriedBuildingSpaces,
        queriedAssets: queriedAssets
    }
}

export const queryMenuOptions = async () => {
    const endPoint = globalPublicData.baseEndpoint + "/Common/getmenunamelist"
    // const { data = [], isFetching } = useFetchMenuOptionsQuery();

    try {
        const response = await axios.get(endPoint)
        console.log('response is: ', response)
        // console.log('[queryMenuOptions] reduxData is: ', data)

        var tempData = []
        response.data.map( item => {
            var tempOptionToAdd = item
            tempOptionToAdd.label = item.Name + ' (' + item.Id + ')'
            tempOptionToAdd.id = item.Id
            tempData.push(tempOptionToAdd)
        })

        const tempEstates = tempData.filter(item => item.Type === "estate")
        const tempPropertyGroups = tempData.filter(item => item.Type === "property_group")
        const tempBuildings = tempData.filter(item => item.Type === "building")
        const tempSpaces = tempData.filter(item => item.Type === "space")
        const tempAssets = tempData.filter(item => item.Type === "asset")
        
        tempEstates.push({label: "All", id: "All"})
        tempPropertyGroups.push({label: "All", id: "All"})
        tempBuildings.push({label: "All", id: "All"})
        tempSpaces.push({label: "All", id: "All"})

        return {
            tempEstates: tempEstates,
            tempPropertyGroups: tempPropertyGroups,
            tempBuildings: tempBuildings,
            tempSpaces: tempSpaces
        }

    } catch (err) {
        console.log(err)
        return err
    }
}

export const prepareSingleMenuOptions = (data, filterCriteria) => {
    const tempData = data.filter( item => item.Type === filterCriteria)
    tempData.push({Name: "All", Id: "All"})
    return tempData

}

export const prepareAllMenuOptions = (data) => {
    var preparedOptions = {}
    var tempData

    const uniqueIds = [...new Set(data.map( item => item.Id))]

    var filteredArrayByTimestamp = []

    uniqueIds.map( item => {
        var tempArray = data.filter( obj => {
            return obj.Id === item
        }).sort((a,b) => {
            return b["_ts"] - a["_ts"]
        })
        
        if (tempArray[0]["Is_Deleted"] !==true){
            
            filteredArrayByTimestamp.push(tempArray[0])
        }
    })


    const filterArray = [ "estate", "property_group", "building", "space"]
    
    filterArray.map( (filter) => {
        var tempData = filteredArrayByTimestamp.filter( item => item.Type === filter)
        tempData.push({
            Name: "All", Id: "All",
            Estate_Name: "All", Estate_Id: "All",
            Property_Group_Name: "All", Property_Group_Id: "All",
            Property_Name: "All", Property_Id: "All",
            Space_Name: "All", Space_Id: "All"
        
        })
        if (filter !== "estate"){
            tempData.push({
                Name: "NA", Id: "NA",
                // Estate_Name: "NA", Estate_Id: "NA",
                Property_Group_Name: "NA", Property_Group_Id: "NA",
                Property_Name: "NA", Property_Id: "NA",
                Space_Name: "NA", Space_Id: "NA"
            })
    
        }
        preparedOptions[filter] = tempData
        tempData = []
    })

    return preparedOptions

}

export const tableColumnNames = {
    estates: estateColumns,
    propertyGroups: propertyGroupColumns,
    buildings: buildingColumns,
    spaces: spaceColumns,
    assets: assetColumns
}