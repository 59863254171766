/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function IdLinkCell({ id, checked }) {
  const navigate = useNavigate()
  const user = useSelector( state => state.user)

  var path = "/fb/app/home/view-booking?id=" +id


  const handleSubmit = ()=>{
    
    navigate("/fb/app/home/view-booking-details" +"?id=" + id)
  }

  return (
    <MDBox display="flex" alignItems="center">
      <MDBox ml={1}>
        <MDButton 
          variant="text" 
          color="info" 
          onClick={handleSubmit}
          sx={{marginleft: "0", paddingLeft: "0"}}
        >
          #{id}
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

// Setting default value for the props of IdCell
IdLinkCell.defaultProps = {
  checked: false,
};

// Typechecking props for the IdCell
IdLinkCell.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default IdLinkCell;
