const selectBuildingExcelFields = (item) => {
    const {
        Id,
        FMC_Id,
        Department_Id,
        Property_Group_Id,
        Name,
        FM_Internal_Name,
        External_Facing_Name,
        Gross_Floor_Area,
        JTC_Address,
        Postal_Code,
        Longtitude,
        Latitude,
        FEMS_Building_System_Code,
        Building_Short_Name,
        Number_Of_Floors,
        Number_Of_Units,
        Remarks,
        Periodic_Structural_Inspection_Date,
        Green_Mark_Award,
        Green_Mark_Certification_Expiry_Date,
        Building_Type,
        Building_Type_Id,
    } = item
    return {
        Id,
        FMC_Id,
        Department_Id,
        Property_Group_Id,
        Name,
        FM_Internal_Name,
        External_Facing_Name,
        Gross_Floor_Area,
        JTC_Address,
        Postal_Code,
        Longtitude,
        Latitude,
        FEMS_Building_System_Code,
        Building_Short_Name,
        Number_Of_Floors,
        Number_Of_Units,
        Remarks,
        Periodic_Structural_Inspection_Date,
        Green_Mark_Award,
        Green_Mark_Certification_Expiry_Date,
        Building_Type,
        Building_Type_Id,
    }
}

export default selectBuildingExcelFields