/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "./data/dataTableData";
import BasicLayout from "layouts/BasicLayout";
import DefaultPageHeader from "page-sections/DefaultPageHeader";

function BookingList() {
  const [menu, setMenu] = useState(null);

  const [tableData, setTableData] = useState(dataTableData)
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const filterByStatus = (status) => {

    var originalRows = dataTableData.rows
    var filteredRows = dataTableData.rows.filter( item => {return item.status === status})
    console.log('filteredRows are: ', filteredRows)
    setTableData({...tableData, rows: filteredRows})
    closeMenu()
  }

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={()=>{filterByStatus("completed")}}>Status: Completed</MenuItem>
      <MenuItem onClick={()=>{filterByStatus("refunded")}}>Status: Refunded</MenuItem>
      <MenuItem onClick={()=>{filterByStatus("in-progress")}}>Status: In-Progress</MenuItem>
      <MenuItem onClick={()=>{filterByStatus("requested")}}>Status: Requested</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={()=>{setTableData(dataTableData)}}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <BasicLayout user>
      <DefaultPageHeader title="View Bookings" />
      <MDBox my={3} mx={3}>
        <MDBox display="flex" justifyContent="space-between" sx={{width: "100%"}} alignItems="flex-end" mb={2}>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "contained"} color="info" onClick={openMenu}>
              filters&nbsp;
              <ArrowDropDownIcon fontSize="large" />
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton color="info">
                {/* <Icon>description</Icon> */}
                export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={tableData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
    </BasicLayout>
  );
}

export default BookingList;
