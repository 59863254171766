import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

import Collapse from '@mui/material/Collapse';


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import QueryMenu from "../../page-sections/queryBuilder/QueryMenu";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";

const QueryBuilderPage = () => {

    return(
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <QueryMenu 
                    isFetching={false}
                    // setQueriedData={setQueriedData}
                />
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default QueryBuilderPage