/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultPageHeader(props) {

  
  return (
        <MDBox
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="10.75rem"
            sx={{
              backgroundSize: "cover",
              backgroundColor: "#0055B8",
              color: "white",
              overflow: "hidden",
            }}
        >
            <Container maxWidth="xl">
                <MDTypography variant="h2" color="white" sx={{marginLeft: "20px"}}>
                {props.title}
              </MDTypography>
            </Container>
          {/* <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="h2" color="white" sx={{marginLeft: "20px"}}>
                {props.title}
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
  );
}

export default DefaultPageHeader;
