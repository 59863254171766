const defaultFontSize = "small"
const defaultFontWeight = "regular"


export const generateRowsData = (rawData) => {
    var itemArray = []
    rawData.map( (item, key) => {
        var tempItem = {
            estateId: item,
            estateName: item.Name,
            estateNameJMM: item.Estate_Name_JMM || "NA",
            estateFmcId: item.FMC_Id || "NA",
            estateDepartment: item.Department_Id || item.Department ||  "NA",
            actions: item
        }    

        itemArray.push(tempItem)
    })

    return itemArray
}

