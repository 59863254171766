const selectEstateExcelFields = (item) => {
    const {
        Id,
        FMC_Id,
        Department_Id,
        Name,
        Geometry,

    } = item
    return {
        Id,
        FMC_Id,
        Department_Id,
        Name,
        Geometry,
    }
}

export default selectEstateExcelFields