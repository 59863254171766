/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";

// Calendar application components
import Header from "./components/Header";
import NextEvents from "./components/NextEvents";
import ProductivityChart from "./components/ProductivityChart";

// Data
import calendarEventsData from "./data/calendarEventsData";
import BasicLayout from "layouts/BasicLayout";
import DefaultPageHeader from "page-sections/DefaultPageHeader";

function SchedulePage() {
  return (
    <BasicLayout user>
      <DefaultPageHeader title="View Schedule"/>
      <MDBox pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ height: "max-content" }}>
            {useMemo(
              () => (
                <EventCalendar
                  initialView="timeGridWeek"
                  initialDate="2023-06-10"
                  events={calendarEventsData}
                  selectable
                  // editable
                />
              ),
              [calendarEventsData]
            )}
          </Grid>
        </Grid>
      </MDBox>
    </BasicLayout>
  );
}

export default SchedulePage;
