import ActionArea from "components/Table/ActionArea"
import ResultsIdLinkCell from "page-sections/queryBuilder/components/ResultsIdLinkCell"


var viewPath = "/estates/view/single"

export const columns = [
    {
        Header: "Property Group Id",
        accessor: "propertyGroupId",
        Cell: ({ value: data }) => {
            var _id = data.Id
            return(
            <ResultsIdLinkCell id={_id} object={data}/> 
            )
          },
    },
    {
        Header: "Property Group Name",
        accessor: "propertyGroupName",
    },
    {
        Header: "Department",
        accessor: "department",
    },
    {
        Header: "Property Group FMC ID",
        accessor: "propertyGroupFMCId",
    },
    {
        Header: "Property Type",
        accessor: "propertyType",
    },
    {
        Header: "Estate Name",
        accessor: "estateName",
    },
    {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ value: data }) => {
            const viewPath = "/fb/app/home/view-property-group?id=" + data.id
            const editPath = "/fb/app/home/edit-property-group?id=" + data.id
            return(
                <ActionArea
                    viewPath={viewPath}
                    editPath={editPath}
                    deleteAPIPath="/propertygroup/delete"
                    data={data}
                />
            )
        }
    }
]