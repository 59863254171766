import { useState, useEffect, useRef } from "react";
// @mui material components
import Card from "@mui/material/Card";

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";

import PropertyGroupSection from "page-sections/property-groups/PropertyGroupSection";
import PropertyGroupSubmitArea from "page-sections/property-groups/PropertyGroupSubmitArea";
import HierarchyFormInformation from "../../page-sections/HierarchyFormInformation/HierarchyFormInformation";
import { defaultInputState, defaultInputErrorState } from "page-sections/property-groups/utils";
import { useSelector } from "react-redux";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";
import MDTypography from "components/MDTypography";

const CreatePropertyGroupTicketPage = (props) => {

    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const viewMode = false
    const disableIdField = false

    const [inputs, setInputs] = useState(defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        if (!user.roleClaims.includes("amm.bsd")) {
            navigate("/fb/app/403")
        }

        setInputErrorState(defaultInputErrorState)
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                    <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                        Create Property Group
                    </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={true}
                    />
                    <PropertyGroupSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={false}
                        disableIdField={false}
                    />  
                    <MDBox p={2} mt={7}>  
                        <EstatePGBuildingSubmitArea
                            inputs={inputs}
                            inputErrorState={inputErrorState}
                            setInputErrorState={setInputErrorState}
                            submitCreateRequestButton={{
                                show: true,
                                text: "Submit Request",
                                color: "info"
                            }}
                            approveButton={{
                                show: false,
                                text: "Approve Request",
                                color: "info"
                            }}
                            updateTicketButton={defaultNoShowButtonAttributes}
                            updateBatchTicketButton={defaultNoShowButtonAttributes}
                            createEndpoint="/propertygroup/create"
                            updateEndpoint="/propertygroup/update"
                            deleteEndpoint="/propertygroup/delete"
                            editRedirectUrl="/fb/app/home/edit-property-group"
                            defaultInputErrorState={defaultInputErrorState}
                        />
                    </MDBox>
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreatePropertyGroupTicketPage


CreatePropertyGroupTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};