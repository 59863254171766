import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";

import { defaultInputState } from "./utils";
import { updateSpaceDescription } from "redux/features/dropdownOptions/dropdownOptionsSlice";

const SpaceBasicInformationSection = (props) => {

    const navigate = useNavigate()
    const { title, inputs, inputErrorState, handleInputChange, setInputs, submitButton, editButton, viewMode, showIdField, disableIdField } = props

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    const dispatch = useDispatch()
    const user =  useSelector(state => state.user)
    const dropdownOptions = useSelector(state => state.dropdownOptions)
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    
    useEffect(()=>{
        
        const fetchAmmSystemTypeData = async ()=>{
            const url = globalPublicData.baseEndpoint + "/systemtype/getammsystemtypebycategory/Space_Description"
            const res = await axios.get(url, axiosConfig)
            console.log("SpaceBasicInformationSection][fetchAmmSystemTypeData] res.data is: ", res.data)
            var _filteredData = res.data.filter(item => item.Is_Deleted === false)
            console.log("SpaceBasicInformationSection][fetchAmmSystemTypeData] filtered data is: ", _filteredData)
            dispatch(updateSpaceDescription(_filteredData))
        }

        fetchAmmSystemTypeData()
        
    },[])

    const [spaceDescOptions, setSpaceDescOptions] = useState({
        Space_Description: [...new Set(dropdownOptions.Space_Description.map( item => { return item.Display_Name}))],
        Space_Abbreviation: [...new Set(dropdownOptions.Space_Description.map( item => { return item.Display_Abbreviation}))],
    })

    useEffect(()=>{
        setSpaceDescOptions({
            Space_Description: [...new Set(dropdownOptions.Space_Description.map(item => { return item.Display_Name }))],
            Space_Abbreviation: [...new Set(dropdownOptions.Space_Description.map(item => { return item.Display_Abbreviation }))],
        })
    },[dropdownOptions])

    const handleSpaceDescriptionChange = (event, value)=>{
        var item = dropdownOptions.Space_Description.find(item => {return item.Display_Name === value})
        console.log('[handleSpaceDescriptionChange] item is: ', item)
        setInputs({...inputs, 
            Space_Description: item.Display_Name,
            Space_Abbreviation: item.Display_Abbreviation,
            Space_Description_uuid: item.id
        })
    }

    const handleSpaceAbbreviationChange = (event, value)=>{
        var item = dropdownOptions.Space_Description.find(item => {return item.Display_Abbreviation === value})
        console.log('[handleSpaceAbbreviationChange] item is: ', item)
        setInputs({...inputs, 
            Space_Description: item.Display_Name,
            Space_Abbreviation: item.Display_Abbreviation,
            Space_Description_uuid: item.id
        })
    }



    return (
        <PlusCollapse title="Basic Information" open={true}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    {showIdField ? 
                    <>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Space Id</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Id"
                            value={inputs.Id}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={disableIdField}
                        />
                        <FormHelperText>
                            Space Id
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Space Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Name"
                            value={inputs.Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Name.state}
                        />
                    </Grid>
                    </>
                    : null}
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Space Floor</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Floor_No"
                            value={inputs.Floor_No}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Floor_No.state}
                        />
                        {inputErrorState.Floor_No.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Floor_No.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Space Floor
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Space Description</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Space_Description"
                            forcePopupIcon={!inputErrorState.Space_Description.state}
                            placeholder="Select Space Description"
                            value={inputs.Space_Description}
                            options={spaceDescOptions.Space_Description}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="Space_Description" 
                                    error={inputErrorState.Space_Description.state}
                                />}
                            onChange={handleSpaceDescriptionChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="Space_Description"
                            value={inputs.Space_Description}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Space_Description.state}
                        /> */}
                        {inputErrorState.Space_Description.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Space_Description.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Space Description
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Space Abbreviation</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Space_Abbreviation"
                            forcePopupIcon={!inputErrorState.Space_Abbreviation.state}
                            placeholder="Select Space Abbreviation"
                            value={inputs.Space_Abbreviation}
                            options={spaceDescOptions.Space_Abbreviation}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="Space_Abbreviation" 
                                    error={inputErrorState.Space_Abbreviation.state}
                                />}
                            onChange={handleSpaceAbbreviationChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="Space_Abbreviation"
                            value={inputs.Space_Abbreviation}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Space_Abbreviation.state}
                        /> */}
                        {inputErrorState.Space_Abbreviation.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Space_Abbreviation.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Space Abbreviation
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
            </MDBox>
        </PlusCollapse>
    )
}

export default SpaceBasicInformationSection


SpaceBasicInformationSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    title: "Space",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        handler: () => { }
    },
    showIdField: false
};