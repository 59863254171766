import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { globalPublicData } from '../../globalPublicData';
import { useSelector } from 'react-redux';
import AMMDialog from 'components/AMMDialog';
import SuccessDialog from 'components/SuccessDialog';
import ErrorDialog from 'components/ErrorDialog';



const ActionArea = (props) => {

    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    // const [showViewButton, setShowViewButton] = useState(true)
    const [showEditButton, setShowEditButton] = useState(false)
    const [showDeleteButton, setShowDeleteButton] = useState(false)

    useEffect(()=>{
        
        if (user.roleClaims.includes("amm.hod")){
            setShowEditButton(false)
            setShowDeleteButton(false)
        } else if (user.roleClaims.includes("amm.bsd")){
            if (props.data.Type === "Estate" || props.data.Type === "Property_Group" || props.data.Type === "Building"){
                setShowEditButton(true)
                setShowDeleteButton(true)
            } else {
                setShowEditButton(false)
                setShowDeleteButton(false)
            }
        } else if (user.roleClaims.includes("amm.fmops") || user.roleClaims.includes("amm.fmc")){
            if (props.data.Type === "Asset" || props.data.Type === "Space"){
                setShowEditButton(true)
                setShowDeleteButton(true)
            } else {
                setShowEditButton(false)
                setShowDeleteButton(false)
            }
        }
    },[props])


    //Dialog Modal related fields
    const [openDialog, setOpenDialog] = useState(false)
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)

    const [dialogParams, setDialogParams] = useState({
        title: "Confirmation",
        messageLine1: "Are you sure you would like to proceed?",
        messageLine2: "Note: Please check all inputs before proceeding",
        handleSubmit: () => { }
    })

    const [successDialogParams, setSuccessDialogParams] = useState({ messageLine1: "" })
    const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: "" })

    const identifiedErrorHandler = (errData, handleSubmit)=>{
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: JSON.stringify(errData),
            handleSubmit: handleSubmit
        })
        setOpenErrorDialog(true)
    }


    const catchAllErrorHandler = () => {
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: ""
        })
        setOpenDialog(false)
        setOpenErrorDialog(true)
    }
    
    const handleConfirmation = ({ title, messageLine1, messageLine2, handleSubmit }) => {
        setDialogParams({
            // open: true,
            title: title,
            messageLine1: messageLine1,
            messageLine2: messageLine2,
            handleSubmit: handleSubmit
        })

        setOpenDialog(true)
    }

    const handleEdit = ()=>{
        navigate(props.editPath,{state: props.data})
    }

    const handleView = () => {
        navigate(props.viewPath, { state: props.data })
    }

    const handleDelete = async () => {

        const baseEndpoint = globalPublicData.baseEndpoint
        const endPoint = baseEndpoint + props.deleteAPIPath + "?id="+ props.data.id
        

        console.log('[handleDelete] props.data is: ', props.data)
        console.log('[handleDelete] endpoint is: ', endPoint)

        try {
            const response = await axios.delete(endPoint, axiosConfig)
            console.log('Delete response is: ', response)
            if (response.status === 200) {
                // alert("Object Delete Request Submitted")
                setSuccessDialogParams({
                    messageLine1: "Deletion Ticket has been successfully created",
                    handleSubmit: () => { setOpenSuccessDialog(false) }
                })
                setOpenDialog(false)
                setOpenSuccessDialog(true)
                
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                if (err.response.status === 410){
                    identifiedErrorHandler("A concurrent session was detected.", ()=>{navigate("/fb/app/")})
                } else if (err.response.status === 403){
                    navigate("/fb/app/403")
                } else if (err.response.status === 401){
                    navigate("/fb/app/401")
                } else {
                    identifiedErrorHandler(err.response.data, ()=>{})
                }
            } else {
                catchAllErrorHandler()
            }
        }

    }

    return(
        <>
            <IconButton
                color="info"
                aria-label="edit"
                onClick={handleView}
            >
                <LibraryBooksIcon />
            </IconButton>
            {showEditButton ? 
            <IconButton
                color="warning"
                aria-label="edit"
                onClick={handleEdit}
            >
                <ModeEditOutlineIcon />
            </IconButton>
            : null }
            {showDeleteButton ? 
            <IconButton
                color="error"
                aria-label="delete"
                onClick={()=>{
                    handleConfirmation({
                        title: "Deletion Confirmation",
                        messageLine1: "This action will proceed to submit a Deletion ticket for approval. Would you like to proceed?",
                        messageLine2: "",
                        handleSubmit: handleDelete
                    })
                }}
            >
                <DeleteIcon />
            </IconButton>
            : null}
            <AMMDialog
                open={openDialog}
                title={dialogParams.title}
                messageLine1={dialogParams.messageLine1}
                messageLine2={dialogParams.messageLine2}
                handleSubmit={dialogParams.handleSubmit}
                setOpenDialog={setOpenDialog}
            />
            <SuccessDialog
                open={openSuccessDialog}
                messageLine1={successDialogParams.messageLine1}
                handleSubmit={successDialogParams.handleSubmit}
                setOpenDialog={setOpenSuccessDialog}
            />
            <ErrorDialog
                open={openErrorDialog}
                messageLine1={errorDialogParams.messageLine1}
                messageLine2={errorDialogParams.messageLine2}
                setOpenDialog={setOpenErrorDialog}
            />
        </>
    )
}


export default ActionArea

