// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

import BatchInstructionsSection from "page-sections/batchupload/BatchInstructionsSection";
import BatchUploadSection from "page-sections/batchupload/BatchUploadSection";
import { globalPublicData } from "globalPublicData";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ProgressSectionBSD from "page-sections/approvalSections/ProgressSectionBSD";



const BatchImportEstatesPage = (props) => {

    const navigate = useNavigate()    
    const user = useSelector(state => state.user)
    const [file, setFile] = useState()

    useEffect(()=>{
        if (!user.roleClaims.includes("amm.batchimportestate")){ navigate("/fb/app/403")}
    },[])

    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <Card sx={{ width: "100%" }}>
                        <ProgressSectionBSD activeStep={0} />
                        <MDBox display="flex">
                            <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                                Batch Import Estates
                            </MDTypography>
                        </MDBox>
                        <BatchInstructionsSection />
                        <BatchUploadSection
                            file={file}
                            setFile={setFile}
                            uploadEndpoint={globalPublicData.baseEndpoint + "/estate/batchimport/create"}
                        />
                    </Card>  
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default BatchImportEstatesPage


BatchImportEstatesPage.defaultProps = {
    title: "Batch Import Estates",
    endpoint: "/estate/batchimport/create"
    // createMode: true,
};