

export const handleEstateChange = (fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showPropertyGroupFields, showBuildingFields, showSpaceFields, showMultiSpaceFields) => {
    console.log('[handleEstateChange] fullItem recieved is: ', fullItem)
    
    var _inputs = {
        ...inputs,
        Estate_Name: fullItem.Name,
        Estate_Id: fullItem.Id,
        Estate_uuid: fullItem.id
    }

    if (showPropertyGroupFields){
        _inputs.Property_Group_Name = ""
        _inputs.Property_Group_Id = ""
    }

    if (showBuildingFields) {
        _inputs.Building_Name = ""
        _inputs.Building_Id = ""
    }

    if (showSpaceFields) {
        _inputs.Space_Name = ""
        _inputs.Space_Id = ""
    }

    if (showMultiSpaceFields) {
        _inputs.Space_Names = []
        _inputs.Space_Ids = []
    }

    setInputs(_inputs)

    var _propertyGroupData = fullOptionsData.fullPropertyGroupOptions.filter((item) => {
        return item.Estate_Id === fullItem.Id
    })

    var _buildingData = fullOptionsData.fullBuildingOptions.filter((item) => {
        return item.Estate_Id === fullItem.Id
    })

    var _spaceData = fullOptionsData.fullSpaceOptions.filter((item) => {
        return item.Estate_Id === fullItem.Id
    })

    var _menuOptionsData = {
        estates: {
            name: fullOptionsData.fullEstateOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullEstateOptions.map(item => { return item.Id })
        },
        propertyGroups: {
            name: _propertyGroupData.map(item => { return item.Name }),
            id: _propertyGroupData.map(item => { return item.Id })
        },
        buildings: {
            name: _buildingData.map(item => { return item.Name }),
            id: _buildingData.map(item => { return item.Id })
        },
        spaces: {
            name: _spaceData.map(item => { return item.Name }),
            id: _spaceData.map(item => { return item.Id })
        }
    }

    setMenuOptions(_menuOptionsData)
}

export const handlePropertyGroupChange = (fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showBuildingFields, showSpaceFields, showMultiSpaceFields) => {
    var _inputs = {
        ...inputs,
        Estate_Name: fullItem.Estate_Name,
        Estate_Id: fullItem.Estate_Id,
        Property_Group_Name: fullItem.Name,
        Property_Group_Id: fullItem.Id,
        Property_Group_uuid: fullItem.id,
    }

    if (showBuildingFields){
        _inputs.Building_Name = ""
        _inputs.Building_Id = ""
    }

    if (showSpaceFields) {
        _inputs.Space_Name = ""
        _inputs.Space_Id = ""
    }

    if (showMultiSpaceFields) {
        _inputs.Space_Names = []
        _inputs.Space_Ids = []
    }


    setInputs(_inputs)


    var _buildingData = fullOptionsData.fullBuildingOptions.filter((item) => {
        return item.Property_Group_Id === fullItem.Id
    })

    var _spaceData = fullOptionsData.fullSpaceOptions.filter((item) => {
        return item.Property_Group_Id === fullItem.Id
    })

    var _menuOptionsData = {
        estates: {
            name: fullOptionsData.fullEstateOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullEstateOptions.map(item => { return item.Id })
        },
        propertyGroups: {
            name: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Id })
        },
        buildings: {
            name: _buildingData.map(item => { return item.Name }),
            id: _buildingData.map(item => { return item.Id })
        },
        spaces: {
            name: _spaceData.map(item => { return item.Name }),
            id: _spaceData.map(item => { return item.Id })
        }
    }

    setMenuOptions(_menuOptionsData)
}

export const handlebuildingChange = (fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showSpaceFields, showMultiSpaceFields) => {
    
    console.log("[handleBuildingChange] fullItem recieved is: ", fullItem)
    var _inputs = {
        ...inputs,
        Estate_Name: fullItem.Estate_Name,
        Estate_Id: fullItem.Estate_Id,
        Property_Group_Name: fullItem.Property_Group_Name,
        Property_Group_Id: fullItem.Property_Group_Id,
        Building_Name: fullItem.Name,
        Building_Id: fullItem.Id,
        Building_uuid: fullItem.id,
    }

    if (showSpaceFields){
        _inputs.Space_Name = ""
        _inputs.Space_Id = ""
    }

    if (showMultiSpaceFields){
        _inputs.Space_Names = []
        _inputs.Space_Ids = []
    }

    console.log("[handleBuildingChange] _inputs is: ", _inputs)

    setInputs(_inputs)

    var _spaceData = fullOptionsData.fullSpaceOptions.filter((item) => {
        return item.Building_Id === fullItem.Id
        // Change to Building_Id after new data pumped in
        // return item.Building_Id === fullItem.Id
    })

    var _menuOptionsData = {
        estates: {
            name: fullOptionsData.fullEstateOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullEstateOptions.map(item => { return item.Id })
        },
        propertyGroups: {
            name: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Id })
        },
        buildings: {
            name: fullOptionsData.fullBuildingOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullBuildingOptions.map(item => { return item.Id })
        },
        spaces: {
            name: _spaceData.map(item => { return item.Name }),
            id: _spaceData.map(item => { return item.Id })
        }
    }

    setMenuOptions(_menuOptionsData)
}

export const handleSpaceChange = (fullItem, inputs, setInputs, ) => {
    setInputs({
        ...inputs,
        Estate_Name: fullItem.Estate_Name,
        Estate_Id: fullItem.Estate_Id,
        Property_Group_Name: fullItem.Property_Group_Name,
        Property_Group_Id: fullItem.Property_Group_Id,
        Building_Name: fullItem.Building_Name,
        Building_Id: fullItem.Building_Id,
        Space_Name: fullItem.Name,
        Space_Id: fullItem.Id,
        Space_uuid: fullItem.uuid
    })
}

export const handleSpaceChangeMultiple = (fullItems, inputs, setInputs, fullOptionsData, menuOptions, setMenuOptions) => {
    var _SpaceIds = fullItems.map( item => { return item.Id})
    var _SpaceNames = fullItems.map(item => { return item.Name })

    console.log('[handleSpaceChangeMultiple] _SpaceIds are: ', _SpaceIds)
    console.log('[handleSpaceChangeMultiple] _SpaceNames are: ', _SpaceNames)
    console.log('[handleSpaceChangeMultiple] menuOptions are: ', menuOptions)
    const { Property_Id, Building_Id, Building_Name, Property_Group_Id, Property_Group_Name, Estate_Id, Estate_Name } = fullItems[0]
    
    console.log('[handleSpaceChangeMultiple] Building_Id id: ', Building_Id)

    var _spaceData = fullOptionsData.fullSpaceOptions.filter((item) => {
        return item.Building_Id === Building_Id
        // Change to Building_Id after new data pumped in
        // return item.Building_Id === Building_Id
    })

    console.log('[handleSpaceChangeMultiple] fullOptionsData.fullSpaceOptions are: ', fullOptionsData.fullSpaceOptions)
    console.log('[handleSpaceChangeMultiple] _spaceData are: ', _spaceData)

    // Limiting Spaces Menu Options to Spaces within the same building
    var _menuOptionsData = {
        ...menuOptions,
        spaces: {
            name: _spaceData.map(item => { return item.Name }),
            id: _spaceData.map(item => { return item.Id })
        }
    }

    console.log("_menuOptionsData is: ", _menuOptionsData)

    setMenuOptions(_menuOptionsData)

    console.log('[handleSpaceChangeMultiple] fullItems[0] is : ', fullItems[0] )
    setInputs({
        ...inputs,
        Estate_Name: Estate_Name,
        Estate_Id: Estate_Id,
        Property_Group_Name: Property_Group_Name,
        Property_Group_Id: Property_Group_Id,
        Building_Name: fullItems[0].Building_Name,
        Building_Id: Building_Id || fullItems[0].Property_Id,
        Space_Ids: _SpaceIds,
        Space_Names: _SpaceNames
    })
}