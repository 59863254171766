/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";


import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Material Kit 2 PRO React components
import MKBox from "components/MDBox";
import MKTypography from "components/MDTypography";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { IconButton } from "@mui/material";

function DetailedFooter({ content }) {
  

  return (
    <MKBox component="footer" sx={{backgroundColor: "#3a3a3a", color: "white"}} py={3}>
      <Container>
        <Grid container spacing={3} justifyContent="space-between" mb={3}>
          <Grid item xs={12} lg={4} mb={{ xs: 6, lg: 0 }}>
            <MKTypography variant="h6" color="white" textTransform="uppercase" mb={1}>
              JTC Corporation
              
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={3} pb={1} pr={3}>
              The JTC Summit
              <br />
              8 Jurong Town Hall Road
              <br />
              Singapore 609434
            </MKTypography>
            {/* <MKTypography variant="h6" color="white" mb={0} pb={0} pr={3}>
              1800 568 7000 (Local)
            </MKTypography>
            <MKTypography variant="caption" color="white">
              Hotline is available from Mondays to Fridays, 8:30am - 6:00pm, excluding public holidays
            </MKTypography> */}
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 6, lg: 0 }}>
            <MDTypography variant="body1" color="white" mb={1}>
                Subscribe to our newsletter and stay updated on what's happening at JTC
            </MDTypography>
            <MDButton href="https://form.gov.sg/#!/5efc79c16c04960012ce2309" color="info" size="large">
              Subscribe Now
            </MDButton>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} lg={4} mb={{ xs: 6, lg: 0 }}>
            <MKTypography variant="h6" color="white" mb={0} pb={0} pr={3}>
              1800 568 7000 (Local)
            </MKTypography>
            <MKTypography variant="caption" color="white">
              Hotline is available from Mondays to Fridays, 8:30am - 6:00pm, excluding public holidays
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 6, lg: 0 }}>
            <div>
              <MDButton href="https://www.jtc.gov.sg/about-jtc/contact-us" variant="text" sx={{paddingLeft: "0"}}>
                <MDTypography variant="h6" color="white">Contact Us</MDTypography>
              </MDButton>
              {/* <MDButton variant="text" sx={{ paddingLeft: "0" }}>
                <MDTypography variant="h6" color="white">Feedback</MDTypography>
              </MDButton> */}
              <MDButton href="https://www.reach.gov.sg" variant="text" sx={{ paddingLeft: "0" }}>
                <MDTypography variant="h6" color="white">REACH</MDTypography>
              </MDButton>
            </div>
            {/* <br /> */}
            <div>
              <IconButton href="https://www.facebook.com/jtccorp" color="white">
                <FacebookIcon />
              </IconButton>
              <IconButton href="https://www.instagram.com/jtc_sg/" color="white">
                <InstagramIcon />
              </IconButton>
              <IconButton href="https://www.youtube.com/user/JTCsingapore" color="white">
                <YouTubeIcon />
              </IconButton>
              <IconButton href="https://www.linkedin.com/company/jtc-corporation" color="white">
                <LinkedInIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} pr={3} sx={{ textAlign: "center" }}>
            <Divider light sx={{ backgroundColor: "white" }} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDButton href="https://www.tech.gov.sg/report_vulnerability" variant="text" sx={{ paddingLeft: "0" }}>
              <MDTypography variant="caption" sx={{color: "rgb(170, 170, 170)"}}><strong>Report Vulnerability</strong></MDTypography>
            </MDButton>
            <MDButton href="https://www.jtc.gov.sg/terms-of-use" variant="text" sx={{ paddingLeft: "0" }}>
              <MDTypography variant="caption" sx={{ color: "rgb(170, 170, 170)" }}><strong>Terms of Use</strong></MDTypography>
            </MDButton>
            <MDButton href="https://www.jtc.gov.sg/privacy-statement" variant="text" sx={{ paddingLeft: "0" }}>
              <MDTypography variant="caption" sx={{ color: "rgb(170, 170, 170)" }}><strong>Privacy Statement</strong></MDTypography>
            </MDButton>
            <MDButton href="https://form.gov.sg/forms/jtc/5ba1fae37890ef000f8aad95" variant="text" sx={{ paddingLeft: "0" }}>
              <MDTypography variant="caption" sx={{ color: "rgb(170, 170, 170)" }}><strong>Rate this Site</strong></MDTypography>
            </MDButton>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MDTypography variant="body2" sx={{ color: "rgb(170, 170, 170)" }}>
              Copyright &copy; {new Date().getFullYear()} JTC Corporation
            </MDTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DetailedFooter
// DetailedFooter.propTypes = {
//   content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
// };

export default DetailedFooter;
