import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  value: 0,
  status: 'idle',
  fullEstateOptions: [],
  fullPropertyGroupOptions: [],
  fullBuildingOptions: [
    {
      Name: "All",
      Id: "All"
    }
  ],
  fullSpaceOptions: [
    {
      Name: "All",
      Id: "All"
    }
  ],
  filteredEstateOptions: [
    {
      Name: "All",
      Id: "All"
    }
  ],
  filteredPropertyGroupOptions: [
    {
      Name: "All",
      Id: "All"
    }
  ],
  filteredBuildingOptions: [
    {
      Name: "All",
      Id: "All"
    }
  ],
  filteredSpaceOptions: [
    {
      Name: "All",
      Id: "All"
    }
  ]
};


const menuOptionsSlice = createSlice({
    name: 'menuOptions',
    initialState,
    reducers: {
        updateEstateMenuOptions(state, action){

          console.log('[updateEstateMenuOptions] action.payload is: ', action.payload)
          state.fullEstateOptions = action.payload
        },

        updateFilteredEstateMenuOptions(state, action){
          state.filteredEstateOptions = action.payload
        },

        updatePropertyGroupMenuOptions(state, action){
          state.fullPropertyGroupOptions = action.payload
        },

        updateFilteredPropertyGroupOptions(state, action){
          state.filteredPropertyGroupOptions = action.payload
        },

        updateBuildingMenuOptions(state, action){
          state.fullBuildingOptions = action.payload
        },

        updateFilteredBuildingMenuOptions(state, action){
          state.filteredBuildingOptions = action.payload
        },

        updateSpaceMenuOptions(state, action){
          state.fullSpaceOptions = action.payload
        },

        updateFilteredSpaceMenuOptions(state, action){
          state.filteredSpaceOptions = action.payload
        },

        resetFilteredMenuOptions( state ){
          state.filteredEstateOptions = state.fullEstateOptions
          state.filteredPropertyGroupOptions = state.fullPropertyGroupOptions
          state.filteredBuildingOptions = state.fullBuildingOptions
          state.filteredSpaceOptions = state.fullSpaceOptions

        },
        updateOne(state, action){

          switch (action.payload.Type){
            case "estate":
              state.fullEstateOptions = [...state.fullEstateOptions, action.payload]
              break
            
            case "property_group":
              state.fullPropertyGroupOptions = [...state.fullPropertyGroupOptions, action.payload]
              break

            case "building":
              state.fullBuildingOptions = [...state.fullBuildingOptions, action.payload]
              break
            
            case "space":
              state.fullSpaceOptions = [...state.fullSpaceOptions, action.payload]
              break
            
            default:
          }
        },

        clear(state){

        }
    }
})

export const { 
  updateEstateMenuOptions,
  updatePropertyGroupMenuOptions,
  updateBuildingMenuOptions,
  updateSpaceMenuOptions,
  updateFilteredEstateMenuOptions,
  updateFilteredPropertyGroupOptions,
  updateFilteredBuildingMenuOptions,
  updateFilteredSpaceMenuOptions,
  resetFilteredMenuOptions,
  updateOne, 
  clear } = menuOptionsSlice.actions

export default menuOptionsSlice.reducer