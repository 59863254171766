import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import AssetBasicInformationSection from "page-sections/assets/AssetBasicInformationSection";
import SpaceSubmitArea from "page-sections/spaces/SpaceSubmitArea";
import AssetSubmitArea from "page-sections/assets/AssetSubmitArea";

import { defaultInputState, provisionedFields } from "page-sections/assets/utils";
import AssetOtherInformationSection from "page-sections/assets/AssetOtherInformationSection";
import AssetCustomSection from "page-sections/assets/AssetCustomSection";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "page-sections/assets/utils";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const ViewAssetPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const [cookies, setCookie, removeCookie] = useCookies()
    const user = useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const [editButton, setEditButton] = useState({
        show: false,
        text: "Edit",
        color: "info",
        redirectToEditPage: true
    })

    const [deleteButton, setDeleteButton] = useState({
        show: false,
        text: "delete",
        color: "error"
    })

    useEffect( ()=>{
        if (user.roleClaims.includes("amm.manageasset")){
            setEditButton({
                show: true,
                text: "Edit",
                color: "info",
                redirectToEditPage: true
            })

            setDeleteButton({
                show: true,
                text: "Delete",
                color: "error"
            })
        }

    },[])

    const viewMode = true
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [auditData, setAuditData] = useState([])
    const [filteredDataKeys, setFilteredDataKeys] = useState({})

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
        console.log('[handleInputChange] inputs are: ', inputs)
    }

    const handleCriticalityLevelChange = (event, value) => {
        setInputs({ ...inputs, Criticality_Level: value})
    }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    useEffect( ()=>{
        console.log('[ViewAssetPage] location.state is: ', location.state)
        const recievedDataKeys = Object.keys(location.state || inputs)
        const duplicateFields = new Set(provisionedFields)
        const _filteredDataKeys = recievedDataKeys.filter(item => { return !duplicateFields.has(item) })
        setFilteredDataKeys(_filteredDataKeys)
        console.log('_filteredDataKeys are: ', filteredDataKeys)
    },[inputs])

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewAssetPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/asset/getassetbyuuid/"
                + idToQuery + "/true"

            console.log('[ViewAssetPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            setInputs(res.data[0])
            setAuditData(res.data)
            console.log('[ViewAssetPage][fetchData] res.data is: ', res.data)
        }


        
        console.log('[ViewAssetPage][fetchData] location.state is: ', location.state)
        if (queryParams.get('auditView') !== "true") {
            fetchData()
        }
    }, [])
   


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            View Asset
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showIdField={viewMode}
                        showPropertyGroupFields
                        showBuildingFields
                        showMultiSpaceFields
                        openCollapse={true}
                    />
                    <AssetBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    />
                    <AssetOtherInformationSection
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    />
                    <AssetCustomSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        disableLabel={false}
                        createMode={false}
                        allowDelete={false}
                    />
                    <MDBox p={2} mt={7}>
                    <SpaceAssetSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Asset",
                            color: "info"
                        }}
                        editButton={editButton}
                        deleteButton={deleteButton}
                        submitCreateRequestButton={{
                            show: false,
                            text: "Submit Create Request",
                            color: "info"
                        }}
                        verifyButton={defaultNoShowButtonAttributes}
                        assignBackButton={defaultNoShowButtonAttributes}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/asset/create"
                        updateEndpoint="/asset/update"
                        deleteEndpoint="/asset/delete"
                        editRedirectUrl="/fb/app/home/edit-asset"
                        defaultInputErrorState={defaultInputErrorState}
                    />
                    </MDBox>
                </Card>  
            </MDBox>
            <AuditTrailSection
                auditData={auditData}
                auditPath="/fb/app/home/view-asset-audit"
            />
            <Footer />
        </DashboardLayout>
    )
}

export default ViewAssetPage


ViewAssetPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};