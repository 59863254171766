const selectSpaceExcelFields = (item) => {
    const {
        Id,
        Name,
        Floor_No,
        Building_Id,
        Space_Description,
        Space_Abbreviation,
        Space_Description_Id,
    } = item
    return {
        Id,
        Name,
        Floor_No,
        Building_Id,
        Space_Description,
        Space_Abbreviation,
        Space_Description_Id,
    }
}

export default selectSpaceExcelFields