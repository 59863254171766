// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";


import BatchInstructionsSection from "page-sections/batchupload/BatchInstructionsSection";
import BatchUploadSection from "page-sections/batchupload/BatchUploadSection";
import { globalPublicData } from "globalPublicData";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";



const BatchImportAssetsPage = (props) => {

    const navigate = useNavigate()

    const viewMode = false
    const disableIdField = false
    const user = useSelector(state => state.user)
    const [file, setFile] = useState()
    
    useEffect(()=>{
        if (!user.roleClaims.includes("amm.batchimportasset")){ navigate("/fb/app/403")}
    },[])

    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <Card sx={{ width: "100%" }}>
                        <MDBox display="flex">
                            <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                                Batch Import Assets
                            </MDTypography>
                        </MDBox>
                        <BatchInstructionsSection />
                        <BatchUploadSection
                            file={file}
                            setFile={setFile}
                            uploadEndpoint={globalPublicData.baseEndpoint + "/asset/batchimport/create"}
                        />
                    </Card>  
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default BatchImportAssetsPage


BatchImportAssetsPage.defaultProps = {
    viewMode: false,
    disableIdField: false
};