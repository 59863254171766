import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PlusCollapse from "components/PlusCollapse";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { useFetchDepartmentsQuery } from "redux/features/api/apiSlice";
import { useFetchFMCQuery } from "redux/features/api/apiSlice";
import { updateFMC } from "redux/features/dropdownOptions/dropdownOptionsSlice";
import { updateFullDepartmentData } from "redux/features/dropdownOptions/dropdownOptionsSlice";
import { useCookies } from "react-cookie";


const PropertyGroupSection = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [cookies, setCookie, removeCookie] = useCookies()
    const user = useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const dropdownOptions = useSelector(state => state.dropdownOptions)


    const { title, inputs, inputErrorState, handleInputChange, setInputs, viewMode, disableIdField, showIdField } = props


    useEffect( ()=>{
        const fetchFMCDeptData = async () => {
            console.log('[fetchFMCDeptData] function called')
            const fmcEndpoint = globalPublicData.baseEndpoint + "/facilitymanagement/getfacilityvendorlist"
            const deptEndpoint = globalPublicData.baseEndpoint + "/department/getdepartmentlist"

            const fmcRes = await axios.get(fmcEndpoint, axiosConfig)
            const deptRes = await axios.get(deptEndpoint, axiosConfig)

            dispatch(updateFMC(fmcRes.data))
            dispatch(updateFullDepartmentData(deptRes.data))
        }

        fetchFMCDeptData()

    },[])

    const handleDepartmentChange = (event, value) => {
        var _Department = dropdownOptions.fullDepartmentData.find(item => {
            return item.Department_Id === value
        })
        setInputs({
            ...inputs,
            Department_uuid: _Department.id,
            Department_Id: value

        })
    }

    const handleFMCIdChange = (event, value) => {
        var _FMC = dropdownOptions.FMC.find(item => {
            return item.Id === value
        })
        setInputs({
            ...inputs,
            FMC_uuid: _FMC.id,
            FMC_Id: value

        })
    }


    const handleGFAChange = (event, value) => {
        setInputs({
            ...inputs,
            Gross_Floor_Area: Number(value) || 0
        })
    }


    const fullEstateOptionsData = useSelector(state => state.menuOptions.fullEstateOptions)

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }


    return (
        <>
            {/* <MDBox display="flex">
                <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                    {title}
                </MDTypography>
            </MDBox> */}
            <PlusCollapse title="Basic Information" open={true}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    {showIdField ? 
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Property Group Id</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Id"
                            value={inputs.Id}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={disableIdField}
                        />
                        <FormHelperText>
                            Property Group Identifier Code
                        </FormHelperText>
                    </Grid> : null }
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Property Group Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Name"
                            value={inputs.Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Name.state}
                        />
                        {inputErrorState.Name.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Name.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Name of Property Group
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Department</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Department_Id"
                            forcePopupIcon={!inputErrorState.Department_Id.state}
                            placeholder="Select Department.."
                            value={inputs.Department_Id}
                            options={dropdownOptions.department}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.Department_Id.state} />}
                            onChange={handleDepartmentChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.Department_Id.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Department_Id.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Property Group Department Categorisation
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Property Group FMC ID</strong>
                        </InputLabel>
                        <Autocomplete
                            name="FMC_Id"
                            forcePopupIcon={!inputErrorState.FMC_Id.state}
                            placeholder="Select FMC Id.."
                            value={inputs.FMC_Id}
                            options={dropdownOptions.FMC_Id}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.FMC_Id.state} />}
                            onChange={handleFMCIdChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.FMC_Id.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.FMC_Id.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                ID of FMC Managing the Property Group
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Gross Floor Area (SQM)</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Gross_Floor_Area"
                            value={inputs.Gross_Floor_Area}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            type="number"
                        />
                        <FormHelperText>
                            Gross Floor Area of Property Group in Square Meters
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Property Type</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Property_Type"
                            value={inputs.Property_Type}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Property Type
                        </FormHelperText>
                    </Grid>
                </Grid>
                {/* <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={5}
                >
                    <Grid item xs={12} md={2}>
                        {editButton.show ?
                            <MDButton
                                fullWidth
                                color={editButton.color}
                                onClick={editButton.handler}
                            >
                                {editButton.text || "Submit"}
                            </MDButton>
                            : null}
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <MDButton
                            fullWidth
                            color={submitButton.color}
                            onClick={submitButton.handler}
                        >
                            {submitButton.text || "Submit"}
                        </MDButton>
                    </Grid>
                </Grid> */}
            </MDBox>
        </PlusCollapse>
    </>
    )
}

export default PropertyGroupSection


PropertyGroupSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    showIdField: false,
    title: "Property Group",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        handler: ()=>{}
    }

};