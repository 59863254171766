import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import EstateSection from "page-sections/estates/EstateSection"
import PropertyGroupSection from "page-sections/property-groups/PropertyGroupSection";
import PropertyGroupSubmitArea from "page-sections/property-groups/PropertyGroupSubmitArea";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import HierarchyFormInformation from "../../page-sections/HierarchyFormInformation/HierarchyFormInformation";
import ProgressSectionBSD from "page-sections/approvalSections/ProgressSectionBSD";
import TicketConversationSection from "page-sections/ticketConversation/TicketConversationSection";
import { useCookies } from "react-cookie";
import { defaultInputState } from "page-sections/property-groups/utils";
import { defaultInputErrorState } from "page-sections/property-groups/utils";
import input from "assets/theme/components/form/input";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";


const ViewPropertyGroupTicketPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [viewMode, setViewMode] = useState(true)
    const disableIdField = true
    const queryParams = new URLSearchParams(location.search)

    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    // const [inputs, setInputs] = useState(defaultInputState)
    const [inputs, setInputs] = useState(location.state.Object_Data || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [ticket, setTicket] = useState(location.state)
    const [newComments, setNewComments] = useState([])
    const [activeStep, setActiveStep] = useState(1)
    const [auditData, setAuditData] = useState([])
    const [disableCommentInput, setDisableCommentInput] = useState(false)

    const defaultNoShowState = {
        show: false,
        text: "NA",
        color: "info"
    }

    const [approveButton, setApproveButton] = useState({
        show: false,
        text: "Approve Request",
        color: "info"
    })

    const [cancelButton, setCancelButton] = useState({
        show: false,
        text: "Cancel Request",
        color: "error"
    })

    const [assignBackButton, setAssignBackButton] = useState({
        show: false,
        text: "Route Back Request",
        color: "info"
    })

    const [rejectButton, setRejectButton] = useState({
        show: false,
        text: "Reject Request",
        color: "error"
    })

    const [updateTicketButton, setUpdateTicketButton] = useState({
        show: false,
        text: "Update Ticket",
        color: "info"
    })

    useEffect(()=>{
        if (user.roleClaims.includes("amm.hod") && user.roleClaims.includes("amm.approvemanagepropertygroup") && ticket.Status === "PendingHODApproval"){
            console.log('hod buttons code ran')
            setApproveButton({
                show: true,
                text: "Approve Request",
                color: "info"
            })

            setRejectButton({
                show: true,
                text: "Reject Request",
                color: "error"
            })

            setAssignBackButton({
                show: true,
                text: "Route Back",
                color: "warning"
            })
        }

        if (
            user.roleClaims.includes("amm.bsd") && 
            user.roleClaims.includes("amm.managepropertygroup") &&
            user.email === ticket.Requester_Email &&
            ticket.Status !== "Cancelled" &&
            ticket.Status !== "Approved" &&
            ticket.Status !== "Rejected"
        ){
            setCancelButton({
                show: true,
                text: "Cancel Request",
                color: "error"
            })

            if (ticket.Status === "Draft") {
                setViewMode(false)
                setUpdateTicketButton({
                    show: true,
                    text: "Update Request",
                    color: "info"
                })
            }

        }

    },[])
    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewPropertyGroupTicketPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/ammticket/getticketbyuuid/"
                + idToQuery + "/false"

            console.log('[ViewPropertyGroupTicketPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            console.log("[ViewPropertyGroupTicketPage] res.data is: ", res.data)
            console.log("[ViewPropertyGroupTicketPage] res.data[0].Object_Data is: ", res.data[0].Object_Data)
            
            if (res.data[0].Ticket_Action_Type === "Delete"){
                var getObjectByIdUrl = globalPublicData.baseEndpoint + "/propertygroup/getpropertygroupbyuuid//" + res.data[0].Object_Data.id + "/false"
                const objRes = await axios.get(getObjectByIdUrl, axiosConfig)
                console.log('[fetcData] delete objRes.data[0] is: ', objRes.data[0])
                setInputs(objRes.data[0])
            } else {
                setInputs(res.data[0].Object_Data)
            }

            setTicket(res.data[0])

            if (res.data[0].Status === "PendingHODApproval"){
                if (!user.roleClaims.includes("amm.hod")){ setDisableCommentInput(true)}
                setActiveStep(1)
            } else if (res.data[0].Status === "Approved"){
                setActiveStep(2)
                setDisableCommentInput(true)
            }
            // setAuditData(res.data)
        }
        
        fetchData()
        
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <ProgressSectionBSD activeStep={activeStep} />
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            {"Property Group Ticket (" + ticket.Ticket_Action_Type + ")"}
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={true}
                    />
                    <PropertyGroupSection 
                        // title={"Property Group Ticket (" + ticket.Ticket_Action_Type + ")"} 
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        disableIdField
                    />
                    <MDBox mt={7}>     
                        <TicketConversationSection
                            ticket={ticket}
                            setTicket={setTicket}
                            disableCommentInput={disableCommentInput}
                        />
                        <MDBox p={2} mt={1}>
                            <EstatePGBuildingSubmitArea 
                                inputs={inputs}
                                setInputErrorState={setInputErrorState}
                                approveButton={approveButton}
                                assignBackButton={assignBackButton}
                                rejectButton={rejectButton}
                                cancelButton={cancelButton}
                                updateTicketButton={updateTicketButton}
                                updateBatchTicketButton={defaultNoShowState}
                                ticket={ticket}
                                createEndpoint="/propertygroup/create"
                                updateEndpoint="/propertygroup/update"
                                deleteEndpoint="/propertygroup/delete"
                                editRedirectUrl="/fb/app/home/edit-property-group"
                                defaultInputErrorState={defaultInputErrorState}
                            />
                        </MDBox>
                    </MDBox>    
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewPropertyGroupTicketPage


ViewPropertyGroupTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};