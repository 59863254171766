import { globalPublicData } from "globalPublicData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BatchApprovalSection from "page-sections/batchupload/BatchApprovalSection";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import TicketConversationSection from "page-sections/ticketConversation/TicketConversationSection";
import ProgressSectionBSD from "page-sections/approvalSections/ProgressSectionBSD";
import ProgressSectionSpaceAsset from "page-sections/approvalSections/ProgressSectionSpaceAsset";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";
import BatchInstructionsSection from "page-sections/batchupload/BatchInstructionsSection";
import BatchUploadSection from "page-sections/batchupload/BatchUploadSection";


const ViewBatchSpaceTicketPage = (props) => {

    const { title } = props
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user = useSelector(state => state.user)
    const [ticket, setTicket] = useState(location.state || {})
    const [activeStep, setActiveStep] = useState(1)
    const [disableCommentInput, setDisableCommentInput] = useState(false)
    const [file, setFile] = useState()
    var ticket_uuid = queryParams.get("id")

    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    useEffect(()=>{
        if (!user.roleClaims.includes("amm.viewticket")){ navigate("/fb/app/403")}
    },[])

    const [approveButton, setApproveButton] = useState({
        show: false,
        text: "Approve Request",
        color: "info"
    })

    const [verifyButton, setVerifyButton] = useState({
        show: false,
        text: "Verify Request",
        color: "info"
    })

    const [cancelButton, setCancelButton] = useState({
        show: false,
        text: "Cancel Request",
        color: "error"
    })

    const [assignBackButton, setAssignBackButton] = useState({
        show: false,
        text: "Route Back Request",
        color: "info"
    })

    const [rejectButton, setRejectButton] = useState({
        show: false,
        text: "Reject Request",
        color: "error"
    })

    const [updateTicketButton, setUpdateTicketButton] = useState({
        show: false,
        text: "Update Ticket",
        color: "info"
    })

    useEffect(()=>{
        if (
            user.roleClaims.includes("amm.bsd") && 
            user.roleClaims.includes("amm.approvemanagespace") &&
            ticket.Status === "PendingBSDApproval"
            ){
            setApproveButton({
                show: true,
                text: "Approve Request",
                color: "info"
            })

            setRejectButton({
                show: true,
                text: "Reject Request",
                color: "error"
            })

            setAssignBackButton({
                show: true,
                text: "Route Back",
                color: "warning"
            })
        }
        console.log('user.roleClaims.includes("amm.fmops"): ', user.roleClaims.includes("amm.fmops"))
        console.log('user.roleClaims.includes("amm.verifymanagespace": ', user.roleClaims.includes("amm.verifymanagespace"))
        console.log('ticket.Status= PendingFmOpsVerificationis: ', ticket.Status === "PendingFmOpsVerification")
        if (
            user.roleClaims.includes("amm.fmops") && 
            user.roleClaims.includes("amm.verifymanagespace") &&
            ticket.Status === "PendingFmOpsVerification"
        
            ){

            console.log("set verify button conditions fulfilled")
            setVerifyButton({
                show: true,
                text: "Verify Request",
                color: "info"
            })

            // Pending backend enhancement
            setAssignBackButton({
                show: true,
                text: "Route Back",
                color: "warning"
            })
            // setRejectButton({
            //     show: true,
            //     text: "Reject Request",
            //     color: "error"
            // })
        }

        if (
            user.roleClaims.includes("amm.managespace") &&
            user.email === ticket.Requester_Email &&
            ticket.Status !== "Cancelled" &&
            ticket.Status !== "Rejected" &&
            ticket.Status !== "Approved"
        ){
            setCancelButton({
                show: true,
                text: "Cancel Request",
                color: "error"
            })

            if (ticket.Status === "Draft") {
                // setViewMode(false)
                setUpdateTicketButton({
                    show: true,
                    text: "Update Request",
                    color: "info"
                })
            }

        }

    },[user, ticket])

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewBatchSpaceTicketPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/ammticket/getticketbyuuid/"
                + idToQuery + "/false"

            console.log('[ViewBatchSpaceTicketPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            console.log("[ViewBatchSpaceTicketPage] res.data is: ", res.data)
            console.log("[ViewBatchSpaceTicketPage] res.data[0].Object_Data is: ", res.data[0].Object_Data)

            setTicket(res.data[0])

            if (res.data[0].Status === "PendingBSDApproval"){
                // if (!user.roleClaims.includes("amm.bsd")){ setDisableCommentInput(true)}
                setActiveStep(2)
            } else if (res.data[0].Status === "Approved"){
                setActiveStep(3)
                setDisableCommentInput(true)
            } else if (res.data[0].Status === "PendingFmOpsVerification"){
                setActiveStep(1)
            }
            // setAuditData(res.data)
        }
        
        fetchData()
        
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <Card sx={{ width: "100%" }}>
                        <ProgressSectionSpaceAsset activeStep={activeStep} />
                        <MDBox display="flex">
                            <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                                Batch Space Ticket
                            </MDTypography>
                        </MDBox>
                        {(ticket.Status === "Draft" && user.email === ticket.Requester_Email) ?
                            <>
                                <BatchInstructionsSection />
                                <BatchUploadSection
                                    file={file}
                                    setFile={setFile}
                                    uploadEndpoint={globalPublicData.baseEndpoint + "/space/batchimport/create"}
                                    showUploadButton={false}
                                />
                            </>
                            : <BatchApprovalSection ticket_uuid={ticket_uuid} />
                        }
                        <MDBox mt={7}>
                        <TicketConversationSection
                            ticket={ticket}
                            setTicket={setTicket}
                            disableCommentInput={disableCommentInput}
                        />
                        <MDBox p={2} mt={1}>
                        <SpaceAssetSubmitArea
                            // inputs={inputs}
                            // setInputErrorState={setInputErrorState}
                            bsdApproveButton={approveButton}
                            verifyButton={verifyButton}
                            assignBackButton={assignBackButton}
                            rejectButton={rejectButton}
                            cancelButton={cancelButton}
                            file={file}
                            updateBatchTicketButton={updateTicketButton} // using updateBatch instead of update
                            ticket={ticket}
                            createEndpoint="/space/create"
                            updateEndpoint="/space/update"
                            deleteEndpoint="/space/delete"
                            editRedirectUrl="/fb/app/home/edit-space"
                            // defaultInputErrorState={defaultInputErrorState}
                        />
                        </MDBox>
                        </MDBox>
                    </Card>  
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewBatchSpaceTicketPage


ViewBatchSpaceTicketPage.defaultProps = {
    title: "Batch Space Ticket",
    viewMode: false,
    disableIdField: false
};