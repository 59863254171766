import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import { useLocation, useNavigate } from "react-router-dom";
import EstateSection from "page-sections/estates/EstateSection"
import ProgressSectionBSD from "page-sections/approvalSections/ProgressSectionBSD";
import TicketConversationSection from "page-sections/ticketConversation/TicketConversationSection";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "page-sections/estates/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";


const ViewEstateTicketPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const [viewMode, setViewMode] = useState(true)
    // const viewMode = true
    const disableIdField = true
    // const [inputs, setInputs] = useState({Id: "", Name: "", Department: "", FMC_Id: "", Estate_Geometry: ""})
    const [inputs, setInputs] = useState(location.state.Object_Data)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [ticket, setTicket] = useState(location.state || {})
    const [newComments, setNewComments] = useState([])
    
    const [activeStep, setActiveStep] = useState(1)
    const [disableCommentInput, setDisableCommentInput] = useState(false)
    const defaultNoShowState = {
        show: false,
        text: "NA",
        color: "info"
    }
    
    const [approveButton, setApproveButton] = useState({
        show: false,
        text: "Approve Request",
        color: "info"
    })

    const [cancelButton, setCancelButton] = useState({
        show: false,
        text: "Cancel Request",
        color: "error"
    })

    const [assignBackButton, setAssignBackButton] = useState({
        show: false,
        text: "Route Back Request",
        color: "info"
    })

    const [rejectButton, setRejectButton] = useState({
        show: false,
        text: "Reject Request",
        color: "error"
    })

    const [updateTicketButton, setUpdateTicketButton] = useState({
        show: false,
        text: "Update Ticket",
        color: "info"
    })

    useEffect(()=>{
        if (user.roleClaims.includes("amm.hod") && user.roleClaims.includes("amm.approvemanageestate") && ticket.Status === "PendingHODApproval"){
            setApproveButton({
                show: true,
                text: "Approve Request",
                color: "info"
            })

            setRejectButton({
                show: true,
                text: "Reject Request",
                color: "error"
            })

            setAssignBackButton({
                show: true,
                text: "Route Back",
                color: "warning"
            })
        }

        if (
            user.roleClaims.includes("amm.bsd") && 
            user.roleClaims.includes("amm.manageestate") &&
            user.email === ticket.Requester_Email &&
            ticket.Status !== "Cancelled" &&
            ticket.Status !== "Approved" &&
            ticket.Status !== "Rejected"
        ){
            setCancelButton({
                show: true,
                text: "Cancel Request",
                color: "error"
            })

            if (ticket.Status === "Draft"){
                setViewMode(false)
                setUpdateTicketButton({
                    show: true,
                    text: "Update Request",
                    color: "info"
                })
            }

        }

    },[])

    useEffect(() => {

        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ApproveEstatePage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/ammticket/getticketbyuuid/"
                + idToQuery + "/false"

            console.log('[ApproveEstatePage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            console.log("[ApproveEstatePage] res.data is: ", res.data)
            console.log("[ApproveEstatePage] res.data[0].Object_Data is: ", res.data[0].Object_Data)
            

            if (res.data[0].Ticket_Action_Type === "Delete"){
                var getObjectByIdUrl = globalPublicData.baseEndpoint + "/estate/getestatebyuuid//" + res.data[0].Object_Data.id + "/false"
                const objRes = await axios.get(getObjectByIdUrl, axiosConfig)
                console.log('[fetcData] delete objRes.data[0] is: ', objRes.data[0])
                setInputs(objRes.data[0])
            } else {
                setInputs(res.data[0].Object_Data)
            }

            setTicket(res.data[0])
            
            if (res.data[0].Status === "PendingHODApproval"){
                if (!user.roleClaims.includes("amm.hod")){ setDisableCommentInput(true)}
                setActiveStep(1)
            } else if (res.data[0].Status === "Approved"){
                setActiveStep(2)
                setDisableCommentInput(true)
            } else if (res.data[0].Status === "Cancelled" || res.data[0].Status === "Rejected"){
                setDisableCommentInput(true)
            } else {

            }
            // setAuditData(res.data)
        }
        
        fetchData()
        
    }, [])

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    return (
        <DashboardLayout>
        <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <ProgressSectionBSD activeStep={activeStep} />
                    <EstateSection
                        title={"Estate Ticket (" + ticket.Ticket_Action_Type + ")"}  
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={false}
                    />
                    <MDBox mt={7}>
                        <TicketConversationSection
                            ticket={ticket}
                            setTicket={setTicket}
                            disableCommentInput={disableCommentInput}
                        />
                        <MDBox p={2} mt={1}>             
                            <EstatePGBuildingSubmitArea
                                inputs={inputs}
                                submitRequestButton={{
                                    show: false,
                                    text: "Submit Request",
                                    color: "info"
                                }}
                                setInputErrorState={setInputErrorState}
                                approveButton={approveButton}
                                assignBackButton={assignBackButton}
                                rejectButton={rejectButton}
                                cancelButton={cancelButton}
                                updateTicketButton={updateTicketButton}
                                updateBatchTicketButton={defaultNoShowState}
                                ticket={ticket}
                                createEndpoint="/estate/create"
                                updateEndpoint="/estate/update"
                                deleteEndpoint="/estate/delete"
                                editRedirectUrl="/fb/app/home/edit-estate"
                                defaultInputErrorState={defaultInputErrorState}
                            />
                        </MDBox>
                    </MDBox>
                </Card>  
            </MDBox>
            {/* <TicketConversationSectionCard
                ticket={ticket}
                setTicket={setTicket}
                disableCommentInput={disableCommentInput}
            /> */}
            <Footer />
        </DashboardLayout>
    )
}

export default ViewEstateTicketPage


ViewEstateTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};