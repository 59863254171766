const spaceExcelHeaders = [
    "Space Id",
    "Name",
    "Floor No",
    "Building Id",
    "Space Description",
    "Space Abbreviation",
    "Space Description Id",
]

export default spaceExcelHeaders