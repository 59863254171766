import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  userType: "fmc",
};


const userInterfaceSlice = createSlice({
    name: 'userInterface',
    initialState,
    reducers: {
        updateUserType(state, action){
        var _roleClaims = []

        action.payload.map(item => {
          item.role_claims.map(claim => {
            _roleClaims.push(claim.claim_value)
          })
        })

        if (_roleClaims.includes("amm.hod")){
          state.userType = "hod"
        } else if (_roleClaims.includes("amm.bsd")){
          state.userType = "bsd"
        } else if (_roleClaims.includes("amm.fmops")){
          state.userType = "fmops"
        } else {
          state.userType = "fmc"
        }
          
        },
    }
})

export const { 
  updateUserType,
} = userInterfaceSlice.actions

export default userInterfaceSlice.reducer