import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import BuildingBasicInformationSection from "page-sections/building/BuildingBasicInformationSection";
import BuildingDetailedInformationSection from "page-sections/building/BuildingDetailedInformationSection";
import BuildingSubmitArea from "page-sections/building/BuildingSubmitArea";
import FMCContactInformationSection from "page-sections/contactInformationForms/FMCContactInformationSection";
import JTCContactInformationSection from "page-sections/contactInformationForms/JTCContactInformationSection";
import { defaultInputState, defaultInputErrorState } from "page-sections/building/utils";
import ProgressSectionBSD from "page-sections/approvalSections/ProgressSectionBSD";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const CreateBuildingTicketPage = (props) => {

    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const viewMode = false
    const disableIdField = false

    const [inputs, setInputs] = useState(defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const handleExternalFacingNameChange = (event) => {
        event.persist()
        setInputs({...inputs, External_Facing_Name: event.target.value, Name: event.target.value})
    }
    useEffect(() => {
        if (!user.roleClaims.includes("amm.bsd")) {
            navigate("/fb/app/403")
        }
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                <ProgressSectionBSD activeStep={0} />
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Create Building
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        openCollapse={true}
                    />
                    <BuildingBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        handleExternalFacingNameChange={handleExternalFacingNameChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    <BuildingDetailedInformationSection
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={false}
                    />
                    <MDBox p={2} mt={7}>
                    <EstatePGBuildingSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        submitCreateRequestButton={{
                            show: true,
                            text: "Submit Request",
                            color: "info"
                        }}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/building/create"
                        updateEndpoint="/building/update"
                        deleteEndpoint="/building/delete"
                        editRedirectUrl="/fb/app/home/edit-building"
                        defaultInputErrorState={defaultInputErrorState}
                    />
                    </MDBox>
                    {/* <BuildingSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        submitCreateRequestButton={{
                            show: true,
                            text: "Submit Request",
                            color: "info"
                        }}
                    />              */}
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreateBuildingTicketPage


CreateBuildingTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};