 // @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import PlusCollapse from "components/PlusCollapse";


const BuildingBasicInformationSection = (props) => {

    const navigate = useNavigate()

    const { 
        title, 
        inputs, 
        inputErrorState, 
        handleInputChange, 
        handleExternalFacingNameChange, 
        setInputs, 
        submitButton, 
        editButton, 
        viewMode, 
        disableIdField } = props

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    return (
        <PlusCollapse title="Basic Information" open={true}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Building Id</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Id"
                            value={inputs.Id}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={disableIdField}
                            error={inputErrorState.Id.state}
                        />
                        {inputErrorState.Id.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Id.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Building Id
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Building Internal Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FM_Internal_Name"
                            value={inputs.FM_Internal_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.FM_Internal_Name.state}
                        />
                        {inputErrorState.FM_Internal_Name.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.FM_Internal_Name.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Building Internal Name
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Building External Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="External_Facing_Name"
                            value={inputs.External_Facing_Name}
                            onChange={handleExternalFacingNameChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.External_Facing_Name.state}
                        />
                        {inputErrorState.External_Facing_Name.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.External_Facing_Name.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Building External Facing Name
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Building Abbreviation</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Building_Short_Name"
                            value={inputs.Building_Short_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Building_Short_Name.state}
                        />
                        {inputErrorState.Building_Short_Name.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Building_Short_Name.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Building Abbreviation Name/Short Name
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Address</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTC_Address"
                            value={inputs.JTC_Address}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.JTC_Address.state}
                        />
                        {inputErrorState.JTC_Address.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.JTC_Address.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                JTC Address
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Postal Code</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Postal_Code"
                            value={inputs.Postal_Code}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Postal_Code.state}
                            type="number"
                        />
                        {inputErrorState.Postal_Code.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Postal_Code.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Postal Code
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
            </MDBox>
        </PlusCollapse>
    )
}

export default BuildingBasicInformationSection


BuildingBasicInformationSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    title: "Building",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        handler: ()=>{}
    }

};