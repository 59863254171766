import { globalPublicData } from "globalPublicData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import BatchInstructionsSection from "page-sections/batchupload/BatchInstructionsSection";
import BatchUploadSection from "page-sections/batchupload/BatchUploadSection";
import ProgressSectionSpaceAsset from "page-sections/approvalSections/ProgressSectionSpaceAsset";



const BatchImportSpacesPage = (props) => {

    const navigate = useNavigate()

    const viewMode = false
    const disableIdField = false

    const user = useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const [file, setFile] = useState()
    
    useEffect(()=>{
        if (!user.roleClaims.includes("amm.batchimportspace")){ navigate("/fb/app/403")}
    },[])


    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <Card sx={{ width: "100%" }}>
                        <ProgressSectionSpaceAsset activeStep={0} />
                        <MDBox display="flex">
                            <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                                Batch Import Spaces
                            </MDTypography>
                        </MDBox>
                        <BatchInstructionsSection />
                        <BatchUploadSection
                            file={file}
                            setFile={setFile}
                            uploadEndpoint={globalPublicData.baseEndpoint + "/space/batchimport/create"}
                        />
                    </Card>  
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default BatchImportSpacesPage


BatchImportSpacesPage.defaultProps = {
    viewMode: false,
    disableIdField: false
};