export const defaultInputState = {
    // Id: "",
    Name: "",
    Floor_No: "",
    Estate_Id: "",
    Estate_Name: "",
    Property_Group_Id: "",
    Property_Group_Name: "",
    Building_Id: "",
    Building_Name: "",
    Space_Description: "",
    Space_Abbreviation: "",
    // FM_Internal_Name: "",
    // External_Facing_Name: "",
}

export const defaultInputErrorState = {
    // Id: "",
    Floor_No: { state: false, message: "" },
    Estate_Id: { state: false, message: "" },
    Estate_Name: { state: false, message: "" },
    Property_Group_Id: { state: false, message: "" },
    Property_Group_Name: { state: false, message: "" },
    Building_Id: { state: false, message: "" },
    Building_Name: { state: false, message: "" },
    Space_Description: { state: false, message: "" },
    Space_Abbreviation: { state: false, message: "" },
    // FM_Internal_Name: "",
    // External_Facing_Name: "",
}