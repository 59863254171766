const assetExcelHeaders = [
    "Asset Id",
    "Name",
    "Floor No",
    "Asset System Type",
    "Asset System Type Id",
    "L3 Asset",
    "Asset Group Id",
    "L2 Asset Category",
    "L1 Trade",
    "Asset Group",
    "Asset Abbreviation",
    "Asset Uniclass PR Code",
    "Asset Uniclass SS Code",
    "Criticality Level",
    "Asset Brand",
    "Asset Manufacturer",
    "Asset Model Number",
    "Asset Serial Number",
    "FEMS Date Created",
    "FEMS Date Modified",
    "Warranty Expires On",
    "AIR Template",
    "Installation Date",
    "System Asset Id",
    "FEMS Asset System Code",
    "BIM GUID",
    "Remarks",
    "Estate Id",
    "Property Group Id",
    "Building Id",
    "Space Id(s) with delimiter (|)"
]

export default assetExcelHeaders