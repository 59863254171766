/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import WifiIcon from '@mui/icons-material/Wifi';
import ChairIcon from '@mui/icons-material/Chair';
import PrintIcon from '@mui/icons-material/Print';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

function ProductInfo() {

  const navigate = useNavigate()
  const [inputs, setInputs] = useState({bookingDate: new Date().setDate(new Date().getDate() + 3)})
  const [minDate, setMinDate] = useState(new Date().setDate(new Date().getDate() + 3))
  return (
    <MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h3" fontWeight="bold">
          Peace Haven Lounge
        </MDTypography>
      </MDBox>
      <MDBox mt={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Price
        </MDTypography>
      </MDBox>
      <MDBox mb={1}>
        <MDTypography variant="h5" fontWeight="medium">
          $60/hr
        </MDTypography>
      </MDBox>
      <MDBadge variant="contained" color="success" badgeContent="Available" container />
      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="button" fontWeight="bold" color="black">
          Description
        </MDTypography>
      </MDBox>
      <MDBox component="ul" m={0} pl={4} mb={2}>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
          Nestled in the heart of a bustling city, this exclusive sanctuary invites you to unwind and indulge in a world of refined comfort.
          </MDTypography>
        </MDBox>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
          Step into a realm of understated luxury as you enter the Peace Haven Lounge, adorned with sleek contemporary design elements that effortlessly blend with timeless sophistication. 
          </MDTypography>
        </MDBox>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
          Bathed in soft, ambient lighting, the lounge exudes an aura of tranquility, offering a haven away from the chaos of everyday life.
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={3}>
        <MDBox mt={2} mb={1} ml={0.5}>
          <MDTypography variant="button" fontWeight="regular" color="black">
            <strong>Suitable for: </strong> Breakout sessions, Casual Meetings, Team Hangouts etc
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={3}>
        <MDBox mt={2} mb={1} ml={0.5}>
          <MDTypography variant="button" fontWeight="bold" color="black">
            Amenities
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1} ml={0.5} sx={{display: 'flex', flexDirection: "row"}}>
          <MDBox mx={1}>
            <WifiIcon color="info" fontSize="large"/>
          </MDBox>
          <MDBox mx={1}>
            <ChairIcon color="info" fontSize="large"/>
          </MDBox>
          <MDBox mx={1}>
            <PrintIcon color="info" fontSize="large"/>
          </MDBox>
          <MDBox mx={1}>
            <AcUnitIcon color="info" fontSize="large"/>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5}>
            <MDBox mb={1.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" color="text" fontWeight="regular">
                Date
              </MDTypography>
            </MDBox>
            <DatePicker
                name="bookingDate"
                onChange={(newValue) =>{setInputs({...inputs, bookingDate: new Date(newValue)})}}
                value={inputs.bookingDate}
                renderInput={(params) => <MDInput {...params} fullWidth />}
                // disabled={viewMode}
                minDate={minDate}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid item xs={12} lg={5} container>
          <MDButton variant="gradient" color="info" fullWidth onClick={()=>{navigate("/fb/app/home/view-schedule")}}>
            Find Availability
          </MDButton>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default ProductInfo;
