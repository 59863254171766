import { useState, useEffect, forwardRef } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MDTypography from "components/MDTypography";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const SuccessDialog = (props) => {

    const { 
        open, 
        title, 
        messageLine1, 
        messageLine2, 
        goText, 
        setOpenDialog,
        handleSubmit 
    } = props

    const handleClose = () => {
        setOpenDialog(false)
    }

    const dialogMessageLine1Styles = {
        fontWeight: "700",
        fontSize: "1.5rem",
        lineHeight: "1.375"
    }
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    {title}
                    <CloseIcon 
                        aria-label="close" 
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 16
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{lineHeight: "2.0em"}} id="alert-dialog-slide-description">
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} sx={{fontSize: "7rem", marginTop: "20px", marginBottom: "20px"}} textAlign="center">
                                <CheckCircleIcon color="success" sx={{ fontSize: "20rem", marginLeft: "auto", marginRight: "auto" }} />        
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                {/* <MDTypography variant={"h4"}> */}
                                <MDTypography sx={dialogMessageLine1Styles}>
                                    {messageLine1}
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>{goText}</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default SuccessDialog

SuccessDialog.defaultProps = {
    title: "",
    messageLine1: "Action has been successfully completed",
    messageLine2: "",
    goText: "OK",
    // cancelText: "Cancel",
    handleSubmit: ()=>{
        console.log("Submitted")
    },
    open: true
}