import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import SpaceBasicInformationSection from "page-sections/spaces/SpaceBasicInformationSection";
import SpaceSubmitArea from "page-sections/spaces/SpaceSubmitArea";
import { defaultInputState, defaultInputErrorState } from "page-sections/spaces/utils";
import { useCookies } from "react-cookie";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const EditSpacePage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()

    const user = useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    
    useEffect(()=>{
        if (!user.roleClaims.includes("amm.managespace")){ navigate("/fb/app/403")}
    },[])

    const viewMode = false
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Edit Space
                        </MDTypography>
                    </MDBox>

                    <SpaceBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        openCollapse={false}
                    />
                    <MDBox p={2} mt={7}>
                    <SpaceAssetSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Space",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Space",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Space",
                            color: "error"
                        }}
                        assignBackButton={{
                            show: false,
                            text: "Route Back",
                            color: "warning"
                        }}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/space/create"
                        updateEndpoint="/space/update"
                        deleteEndpoint="/space/delete"
                        editRedirectUrl="/fb/app/home/edit-space"
                        defaultInputErrorState={defaultInputErrorState}

                    />
                    </MDBox>
                    {/* <SpaceSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Space",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Space",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Space",
                            color: "error"
                        }}
                        assignBackButton={{
                            show: false,
                            text: "Route Back",
                            color: "warning"
                        }}
                        updateTicketButton={{
                            show: false,
                            text: "Update Ticket",
                            color: "info"
                        }}

                    />              */}
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default EditSpacePage


EditSpacePage.defaultProps = {
    viewMode: false,
    disableIdField: false

};