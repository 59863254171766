/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import meetingRoom1 from "assets/images/meeting-room-1.jpg"
import meetingRoom2 from "assets/images/meeting-room-2.jpg"
import meetingRoom3 from "assets/images/meeting-room-3.jpg"
import meetingRoom4 from "assets/images/meeting-room-4.jpg"
import meetingRoom5 from "assets/images/meeting-room-5.jpg"
import { useNavigate } from "react-router-dom";

function SearchResultCard(props) {

    const {title, description1, description2, img} = props


    const navigate = useNavigate()
  
    const cardStyles = {
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    }

    const imgStyles = {
        maxheight: "300px",
        maxWidth: "400px",
        // width: "auto",
        // height: "auto"
    }

  return (
        <Card sx={cardStyles}>
          <MDBox m={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3} xxxl={2}>
                <MDBox
                  component="img"
                  src={img}
                  alt="Meeting Room Image"
                  borderRadius="lg"
                  shadow="sm"
                  width="100%"
                  height="100%"
                  sx={imgStyles}
                  position="relative"
                  zIndex={10}
                  mb={2}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={9} xxxl={10}>
                <MDTypography variant="h2" color="black" align="left" sx={{fontSize: "2.0rem"}}>
                  {title}
                </MDTypography>
                <MDTypography variant="body2" color="black" align="left">
                {description1}
                </MDTypography>
                <MDTypography variant="body2" color="black" align="left" sx={{marginTop: "0.5rem"}}>
                {/* {description2} */}
                </MDTypography>
                <MDButton variant="outlined" color="info" sx={{marginTop: "0.5rem"}} onClick={()=>{navigate("/fb/app/home/view-facility")}}>
                  Make Booking
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
  );
}

SearchResultCard.defaultProps = {
    title: "Facility",
    description1: "Sample Facility Description",
    description2: "",
    img: meetingRoom1
}

export default SearchResultCard;
