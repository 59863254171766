import { useState, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import AssetBasicInformationSection from "page-sections/assets/AssetBasicInformationSection";

import {defaultInputErrorState, defaultInputState, provisionedFields } from "page-sections/assets/utils";
import AssetOtherInformationSection from "page-sections/assets/AssetOtherInformationSection";
import AssetCustomSection from "page-sections/assets/AssetCustomSection";

const ViewAssetPageAudit = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const viewMode = true
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [filteredDataKeys, setFilteredDataKeys] = useState({})

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const handleCriticalityLevelChange = (event, value) => {
        setInputs({ ...inputs, Criticality_Level: value})
    }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    useEffect( ()=>{
        console.log('[ViewAssetAuditPage] location.state is: ', location.state)
        const recievedDataKeys = Object.keys(location.state || inputs)
        const duplicateFields = new Set(provisionedFields)
        const _filteredDataKeys = recievedDataKeys.filter(item => { return !duplicateFields.has(item) })
        setFilteredDataKeys(_filteredDataKeys)
        console.log('_filteredDataKeys are: ', filteredDataKeys)
    },[inputs])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            View Asset (Audit)
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={defaultInputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        showMultiSpaceFields
                        openCollapse={true}
                    />
                    <AssetBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={defaultInputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    
                    />
                    <AssetOtherInformationSection
                        inputs={inputs}
                        inputErrorState={defaultInputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    />
                    <AssetCustomSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        disableLabel={false}
                        createMode={false}
                        allowDelete={false}
                    />         
                </Card>  
            </MDBox>
            
            <Footer />
        </DashboardLayout>
    )
}

export default ViewAssetPageAudit


ViewAssetPageAudit.defaultProps = {
    viewMode: false,
    disableIdField: false

};