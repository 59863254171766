import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../../components/MDInput";

import { 
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,

} from "../../redux/features/menuOptions/menuOptionsSlice";

const PropertyGroupAutocomplete = (props) => {

    const {
        name,
        fetchingData,
        inputs,
        setInputs,
        variant,
        hideAllOption,
        hideNAOption,
    } = props

    const dispatch = useDispatch()

    const fullBuildingOptions = useSelector( state => state.menuOptions.fullBuildingOptions)
    const fullSpaceOptions = useSelector( state => state.menuOptions.fullSpaceOptions)
    const filteredOptions = useSelector( state => state.menuOptions.filteredPropertyGroupOptions)

    const hideAllOptionFilter = options => options.filter( opt => opt.Name !== "All")

    const hideNAOptionFilter = options => options.filter( opt => opt.Name !== "NA")

    let filterFunction

    if (hideAllOption){
        filterFunction = hideAllOptionFilter
    } else if (hideNAOption) {
        filterFunction = hideNAOptionFilter
    } else {
        filterFunction = options => options.filter( opt => opt)
    }

    return(
        <>
            <Autocomplete
                multiple
                name={name}
                disabled={fetchingData}
                value={inputs.property_groups}
                options={filteredOptions}
                filterOptions={filterFunction}
                getOptionLabel={option => {
                    if (option.Property_Group_Name === "" || option.Property_Group_Id === "") {
                        return ""
                    } else {
                        return option.Property_Group_Name + " (" + option.Property_Group_Id + ")"
                    }
                }}
                
                renderInput={(params) => <MDInput {...params} key={params.id} variant={variant} />}
                onChange={(event, value) => {
                    console.log('[PropertyGroupAutocomplete] value is: ', value)
                    var tempIds = []
                    value.map(item => {
                        tempIds.push(item.Id)
                    })

                    setInputs({
                        ...inputs,
                        ["property_group_ids"]: tempIds,
                        ["property_groups"]: value
                    })

                    // var additionalDataCriteria = true
                    // if (hideAllOption) {
                    //     additionalDataCriteria = (value.Id !== "NA" && value.Name !== "NA") ? true : false

                    // } else if (hideNAOption) {
                    //     additionalDataCriteria = (value.Id !== "All" && value.Name !== "All") ? true : false
                    // }

                    // if (additionalDataCriteria) {
                    //     dataToAppend = {
                    //         ...dataToAppend,
                    //         ["estate"]: { Name: value.Estate_Name, Id: value.Estate_Id },
                    //         ["estateId"]: value.Estate_Id,
                    //         ["Estate_Name"]: value.Estate_Name,
                    //         ["Estate_Id"]: value.Estate_Id,
                    //     }
                    // } 

                    // setInputs({
                    //     ...inputs,
                    //     ...dataToAppend
                    // })

                    // if (value.id !== "All") {
                    //     var _filteredBuildingOptions = fullBuildingOptions.filter( item => {
                    //         return item.Property_Group_Id == value.Id
                    //     })

                    //     var _filteredSpaceOptions = fullSpaceOptions.filter( item => {
                    //         return item.Property_Group_Id == value.Id
                    //     })
                        
                    //     dispatch(updateFilteredBuildingMenuOptions(_filteredBuildingOptions))
                    //     dispatch(updateFilteredSpaceMenuOptions(_filteredSpaceOptions))
                        
                    // } 
                }}
            />
        </>
    )
}

export default PropertyGroupAutocomplete

PropertyGroupAutocomplete.defaultProps = {
    fetchingData: false,
    name: "propertyGroup",
    variant: "standard",
    hideAllOption: false,
    hideNAOption: true
  };