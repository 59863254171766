import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';


import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";


const JTCContactInformationSection = (props) => {

    const navigate = useNavigate()


    const {inputs, handleInputChange, viewMode, openCollapse } = props


    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }


    return (
        <PlusCollapse title="JTC Contact Information" open={openCollapse}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Executive Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCPE_Contact_Person_Name"
                            value={inputs.JTCPE_Contact_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Executive Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Executive Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCPE_Contact_Person_Email"
                            value={inputs.JTCPE_Contact_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Executive Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Executive Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCPE_Contact_Person_Mobile"
                            value={inputs.JTCPE_Contact_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Executive Mobile
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Executive Covering Person Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCPE_Covering_Person_Name"
                            value={inputs.JTCPE_Covering_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Executive Covering Person Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Executive Covering Person Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCPE_Covering_Person_Email"
                            value={inputs.JTCPE_Covering_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Executive Covering Person Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Executive Covering Person Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCPE_Covering_Person_Mobile"
                            value={inputs.JTCPE_Covering_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Executive Covering Person Mobile
                        </FormHelperText>
                    </Grid>
                </Grid>


                {/* Team Leader */}
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                    mt={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Team Lead Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCTL_Contact_Person_Name"
                            value={inputs.JTCTL_Contact_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Team Lead Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Team Lead Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCTL_Contact_Person_Email"
                            value={inputs.JTCTL_Contact_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Team Lead Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Team Lead Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCTL_Contact_Person_Mobile"
                            value={inputs.JTCTL_Contact_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Team Lead Mobile
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Team Lead Covering Person Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCTL_Covering_Person_Name"
                            value={inputs.JTCTL_Covering_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Team Lead Covering Person Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Team Lead Covering Person Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCTL_Covering_Person_Email"
                            value={inputs.JTCTL_Covering_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Team Lead Covering Person Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC Team Lead Covering Person Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCTL_Covering_Person_Mobile"
                            value={inputs.JTCTL_Covering_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Team Lead Covering Person Mobile
                        </FormHelperText>
                    </Grid>
                </Grid>

                {/* HOD Starts Here */}

                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                    mt={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC HOD Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCHOD_Contact_Person_Name"
                            value={inputs.JTCHOD_Contact_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Head of Department Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC HOD Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCHOD_Contact_Person_Email"
                            value={inputs.JTCHOD_Contact_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Head of Department Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC HOD Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCHOD_Contact_Person_Mobile"
                            value={inputs.JTCHOD_Contact_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Head of Department Mobile
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC HOD Covering Person Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCHOD_Covering_Person_Name"
                            value={inputs.JTCHOD_Covering_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Head of Department Covering Person Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC HOD Covering Person Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCHOD_Covering_Person_Email"
                            value={inputs.JTCHOD_Covering_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Head of Department Covering Person Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>JTC HOD Covering Person Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="JTCHOD_Covering_Person_Mobile"
                            value={inputs.JTCHOD_Covering_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            JTC Head of Department Covering Person Mobile
                        </FormHelperText>
                    </Grid>
                </Grid>
            </MDBox>
        </PlusCollapse>
    )
}

export default JTCContactInformationSection


JTCContactInformationSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    openCollapse: true

};