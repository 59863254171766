// @mui material components
import Card from "@mui/material/Card";
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';

import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

function stringAvatar(name) {

return {
    sx: {
    bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}`,
    // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
};
}


const TicketComment = (props) => {

    const { 
        name, 
        comment,
        timestamp 
    } = props

    return (
        <Grid item xs={12}>
            <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
                <MDBox mr={2}>
                    <Avatar {...stringAvatar(name)} />    
                </MDBox>
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <MDTypography variant="button" fontWeight="medium">
                        {name}
                    </MDTypography>
                    <MDTypography variant="body2" color="dark">
                        {comment}
                    </MDTypography>
                    <MDTypography variant="caption" color="dark">
                        {new Date(timestamp).toLocaleString()}
                    </MDTypography>
                </MDBox>
            </MDBox>
        </Grid>
    )
}

export default TicketComment


TicketComment.defaultProps = {
    name: "Name",
    comment: "sample comment",
    timestamp: "2023-01-31T16:00:00.000Z"
 
};

