import react, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import MDTypography from "../MDTypography"

import logoImg from "../../assets/images/JTC_Logo3x.png"
import MDButton from '../MDButton';

// import "./MUINavBarStyles.css"

const pages = [
    {
        name: "Home",
        path: "/fb/app/home"
    },
    // {
    //     name: "Create Booking",
    //     path: "/fb/app/home/view-facility"
    // },
    {
        name: "View Bookings",
        path: "/fb/app/home/view-bookings"
    }
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const MUINavBar = (props) => {

    const navigate = useNavigate()

    const [openNavMenu, setOpenNavMenu] = useState(false)
    const [openUserMenu, setOpenUserMenu] = useState(false)

    const handleOpenNavMenu = (event) => {
        
        setOpenNavMenu(true)
        
    };
    const handleOpenUserMenu = (event) => {
        setOpenUserMenu(true)
    };

    const handleNavigate = (path) => {
        navigate(path)
    }
    const handleCloseNavMenu = () => {
        setOpenNavMenu(false)
    };

    const handleCloseUserMenu = () => {
        setOpenUserMenu(false)
    };

    return (
        <AppBar my={2} position="fixed" color="white">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box
                        component="img"
                        sx={{
                            height: 48,
                            display: 'flex'
                            // display: {
                            //     xs: 'flex',
                            //     md: 'flex'
                            // },
                        }}
                        mx={2}
                        alt="JTC Logo"
                        src={logoImg}
                        onClick={()=>{navigate("/fb/app/")}}
                    />
                    <MDTypography sx={{display: {xs: 'none'}}} variant="h4">Facility Booking</MDTypography>
                    {props.user ? 
                    <>
                    <Box sx={{ flexGrow: 1, display: {xs: 'flex', md: 'none'}, justifyContent: 'flex-end' }}>
                        <Menu
                            id="menu-appbar"
                            // anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openNavMenu}
                            // open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page, key) => (
                                <MenuItem key={key} onClick={()=>{navigate(page.path)}}>
                                    <MDTypography 
                                        textAlign="center" 
                                        color="dark"
                                        fontWeight="regular"
                                        
                                    >
                                    {page.name}
                                    </MDTypography>
                                </MenuItem>
                            ))}
                        </Menu>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{justifyContent: 'flex-end'}}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginLeft: "20px" }}>
                        {pages.map((page, key) => (
                            <Button
                                key={key}
                                onClick={()=>{navigate(page.path)}}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                <MDTypography
                                    textAlign="center"
                                    color="dark"
                                    sx={{fontSize: "1.0rem"}}
                                    fontWeight="bold"
                                >
                                    {page.name}
                                </MDTypography>
                            </Button>
                        ))}
                    </Box>
                    
                    </>
                    : null}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

MUINavBar.defaultProps = {
    user: false
}

export default MUINavBar;

