import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import dataTableData from "./dataTableData";
import FormField from "layouts/pages/account/components/FormField";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
// import EstateAutocomplete from "components/EstateAutocomplete/EstateAutocomplete";
import PropertyGroupAutocomplete from "components/PropertyGroupAutocomplete/PropertyGroupAutocomplete";
import BuildingAutocomplete from "components/BuildingAutocomplete/BuildingAutocomplete";
import SpaceAutocomplete from "components/SpaceAutocomplete/SpaceAutocomplete";

import { globalPublicData } from "globalPublicData";


import {
    updateQuery,
    updateQueryResults,
    resetQueryState,
    updateUITabValue,
    updateUITabOptions,
} from "redux/features/query/querySlice";
import { Navigate, useNavigate } from "react-router-dom";
import { generateEstateTableData } from "./sections/EstateTable/generateEstateTableData";
import { generatePropertyGroupTableData } from "./sections/PropertyGroupTable/generatePropertyGroupTableData";
import { generateBuildingTableData } from "./sections/BuildingTable/generateBuildingTableData";
import { generateSpaceTableData } from "./sections/SpaceTable/generateSpaceTableData";
import { generateAssetTableData } from "./sections/AssetTable/generateAssetTableData";
import { updateTableData } from "redux/features/query/querySlice";

import EstateAutocompleteWithId from "../../components/EstateAutocompleteWithId/EstateAutocompleteWithId";
import QueryHierarchySection from "../QueryHierarchySection/QueryHierarchySection";
import { useCookies } from "react-cookie";
import ErrorDialog from "components/ErrorDialog";
import { CircularProgress } from "@mui/material";


const QueryMenu = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user =  useSelector(state => state.user)
    const query = useSelector(state => state.query.query)
    const menuOptions = useSelector(state => state.menuOptions)

    const [buttonClicked, setButtonClicked] = useState(false)

    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const initialInputState = {
        name: "",
        is_exact_match: false,
        estate_ids: [],
        estate_uuids: [],
        estate_names: [],
        property_group_ids: [],
        property_group_uuids: [],
        property_group_names: [],
        building_ids: [],
        building_uuids: [],
        building_names: [],
        space_ids: [],
        space_uuids: [],
        space_names: [],
        estates: [],
        property_groups: [],
        buildings: [],
        spaces: [],
        is_deleted: false,
        searchTabValue: "is_fuzzy_match"
    }

    useEffect(()=>{
        console.log("query is: ", query)
    }, [query])

    const [inputs, setInputs] = useState(query || initialInputState)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: ""})

    const identifiedErrorHandler = (errData, handleSubmit)=>{
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: JSON.stringify(errData),
            handleSubmit: handleSubmit
        })
        setOpenErrorDialog(true)
    }

const catchAllErrorHandler = () => {
    setErrorDialogParams({
        messageLine1: "Apologies, an error has occured.",
        messageLine2: "Please try again later."
    })
    setOpenErrorDialog(true)
}


    // const [tabValue, setTabValue] = useState(true);
    const handleSetTabValue = (event, newValue) => {
        // setTabValue(newValue)
        console.log('[QueryMenu][handleSetTabValue] newValue is: ', newValue)
        if (newValue === "is_exact_match"){
            setInputs({ ...inputs, is_exact_match: true, searchTabValue: newValue})
        } else{
            setInputs({ ...inputs, is_exact_match: false, searchTabValue: newValue })
        }
    };

    const handleInputChange = (event) => {
        event.persist()
        setInputs({...inputs, [event.target.name]: event.target.value})
        console.log('[QueryMenu] inputs are: ', inputs)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setButtonClicked(true)

        console.log('[QueryMenu][handleSubmit] inputs are: ', inputs)

        var _estate_ids = []
        inputs.estate_ids.map( id => {
            var _option = menuOptions.fullEstateOptions.find( opt => {
                return opt.Id === id
            })

            console.log('[QueryMenu][handleSubmit] _option is: ', _option)
            _estate_ids.push(_option.id)
        })

        var _property_group_ids = []
        inputs.property_group_ids.map(id => {
            var _option = menuOptions.fullPropertyGroupOptions.find(opt => {
                return opt.Id === id
            })
            _property_group_ids.push(_option.id)
        })

        var _building_ids = []
        inputs.building_ids.map(id => {
            var _option = menuOptions.fullBuildingOptions.find(opt => {
                return opt.Id === id
            })
            _building_ids.push(_option.id)
        })

        var _space_ids = []
        inputs.space_ids.map(id => {
            var _option = menuOptions.fullSpaceOptions.find(opt => {
                return opt.Id === id
            })
            _space_ids.push(_option.id)
        })
        const queryParams = {
            name: inputs.name,
            is_exact_match: inputs.is_exact_match,
            is_deleted: inputs.is_deleted,
            estate_uuids: _estate_ids,
            property_group_uuids: _property_group_ids,
            building_uuids: _building_ids,
            space_uuids: _space_ids
            // estate_ids: inputs.estate_ids,
            // property_group_ids: inputs.property_group_ids,
            // building_ids: inputs.building_ids,
            // space_ids: inputs.space_ids
        }
        // dispatch(updateQuery(inputs))
        var endPoint = globalPublicData.baseEndpoint + "/common/searchbyname"
        console.log("[QueryParams] QueryParams are: ", queryParams)
        try {
            const response = await axios.post( endPoint, queryParams, axiosConfig)
            
            console.log('[QueryMenu][handleSubmit] query response is: ', response)
            const uniqueIds = [...new Set(response.data.map(item => item.Id))]

            var filteredArrayByTimestamp = []

            uniqueIds.map(item => {
                var tempArray = response.data.filter(obj => {
                    return obj.Id === item
                }).sort((a, b) => {
                    return b["_ts"] - a["_ts"]
                })

                if (tempArray[0]["Is_Deleted"] !== true) {

                    filteredArrayByTimestamp.push(tempArray[0])
                }
            })
            setInputs(initialInputState)
            setButtonClicked(false)
        }
        catch (err) {
            console.log(err)
            console.log('err.response.status is: ', err.response.status)
            setInputs(initialInputState)
            setButtonClicked(false)
            if (err.response.data) {
                // alert(JSON.stringify(err.response.data))
                if (err.response.status === 410){
                    identifiedErrorHandler("A concurrent session was detected.", ()=>{navigate("/fb/app/")})
                } else if (err.response.status === 403){
                    navigate("/fb/app/403")
                } else if (err.response.status === 401){
                    navigate("/fb/app/401")
                } else {
                    identifiedErrorHandler(err.response.data, ()=>{})
                }
                
            } else {
                setInputs(initialInputState)
                catchAllErrorHandler()
            }
        }



        var queriedEstates = filteredArrayByTimestamp.filter((item) => { return item.Type === "Estate" })
        var queriedProperties = filteredArrayByTimestamp.filter((item) => { return item.Type === "Property_Group"})
        var queriedBuildings = filteredArrayByTimestamp.filter((item) => {return item.Type === "Building"})
        var queriedBuildingSpaces = filteredArrayByTimestamp.filter((item) => {return item.Type === "Space"})
        var queriedAssets = filteredArrayByTimestamp.filter((item) => {return item.Type === "Asset"})

        const processedQueriedData = {
            queriedEstates: queriedEstates,
            queriedProperties: queriedProperties,
            queriedBuildings: queriedBuildings,
            queriedBuildingSpaces: queriedBuildingSpaces,
            queriedAssets: queriedAssets
        }

        const tableData = {
            estates: generateEstateTableData(queriedEstates).rows,
            propertyGroups: generatePropertyGroupTableData(queriedProperties).rows,
            buildings: generateBuildingTableData(queriedBuildings).rows,
            spaces: generateSpaceTableData(queriedBuildingSpaces).rows,
            assets: generateAssetTableData(queriedAssets).rows
        }
        
        console.log('[handleSubmit] tableData is: ', tableData)
        var tempTabOptions = []

        if (tableData.estates.length > 0) {
            tempTabOptions.push({label: "Estates", value: "estates"})
        }  
        
        if (tableData.propertyGroups.length > 0) {
            tempTabOptions.push({ label: "Property Groups", value: "propertyGroups" })
        }  
        
        if (tableData.buildings.length > 0) {
            tempTabOptions.push({ label: "Buildings", value: "buildings" })
        }  
        
        if (tableData.spaces.length > 0) {
            tempTabOptions.push({ label: "Spaces", value: "spaces" })
        } 

        if (tableData.assets.length > 0) {
            tempTabOptions.push({ label: "Assets", value: "assets" })    
        }

        if (tempTabOptions.length > 0){
            dispatch(updateUITabValue(tempTabOptions[0].value))
        }
        // dispatch(updateUITabValue("buildings"))
        dispatch(updateUITabOptions(tempTabOptions))
        console.log('tempTabOptions is: ', tempTabOptions)
        var _resultsEmpty = true
        if (filteredArrayByTimestamp.length > 0){
            _resultsEmpty = false
        }



        dispatch(updateQueryResults({ ...processedQueriedData, resultsFetched: true, resultsEmpty: _resultsEmpty}))
        dispatch(updateTableData({ ...tableData, resultsFetched: true, resultsEmpty: _resultsEmpty}))
        navigate("/fb/app/home/search/results")
    }


    return(
        <Card sx={{ width: "100%"}}>
            <MDBox display="flex">
                {/* <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="24rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="dark"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    ml={3}
                    mt={-2}
                >
                    <ManageSearchIcon color="inherit" fontSize="medium" />
                </MDBox> */}
                <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                    Search by name
                </MDTypography>
            </MDBox>
            <MDBox p={2} mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                        <FormField 
                            variant="outlined" 
                            name="name" 
                            placeholder="Enter Search Terms.." 
                            label="Search Terms" 
                            onChange={handleInputChange} 
                            value={inputs.name}
                            fullWidth
                        
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <MDButton 
                            color="info" 
                            fullWidth 
                            onClick={handleSubmit}
                            disabled={buttonClicked}
                            
                        >
                            {buttonClicked ? 
                            <CircularProgress color={"white"} sx={{ maxHeight: "20px", maxWidth: "20px"}}/>
                            : "Search"}
                        </MDButton>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <MDTypography sx={{ fontSize: "1rem" }} display="block" fontWeight="bold" color="dark" mb={1}>
                            Search Type
                        </MDTypography>
                        <Tabs 
                            value={inputs.searchTabValue} 
                            onChange={handleSetTabValue}
                        >
                            <Tab label="Fuzzy Match" value="is_fuzzy_match" />
                            <Tab label="Exact Match" value="is_exact_match" />
                        </Tabs>
                    </Grid>
                </Grid>
                <br />
                <QueryHierarchySection
                    inputs={inputs}
                    handleInputChange={handleInputChange}
                    setInputs={setInputs}
                />
                <br />
                <Grid container spacing={2} mt={1} mb={5}>
                    <Grid item xs={12} md={6}>
                        <MDButton 
                            variant="outlined"
                            onClick={() => { 
                                setInputs(initialInputState)
                            }} 
                            color="error"
                        >
                            Reset Query
                        </MDButton>
                    </Grid>
                </Grid>
                
            </MDBox>
            <ErrorDialog
                open={openErrorDialog}
                messageLine1={errorDialogParams.messageLine1}
                messageLine2={errorDialogParams.messageLine2}
                setOpenDialog={setOpenErrorDialog}
                handleSubmit={errorDialogParams.handleSubmit}
            />
        </Card>
    )


}

export default QueryMenu