import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import EstateSection from "page-sections/estates/EstateSection"
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import EstateSubmitArea from "page-sections/estates/EstateSubmitArea";
import { useCookies } from "react-cookie";
import { defaultInputErrorState, defaultInputState } from "page-sections/estates/utils";


const ViewEstatePageAudit = (props) => {

    const navigate = useNavigate()
    const location = useLocation()

    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    useEffect(()=>{
        console.log('location.state is: ', location.state)
    },[])

    const queryParams = new URLSearchParams(location.search)

    // useEffect(() => {
    //     const fetchData = async () => {
    //         var cosmosId = queryParams.get("id")
    //         console.log('[VeiwAuditPage][fetchData] cosmosId is: ', cosmosId)
    //         var idToQuery = cosmosId || location.state.id
    //         const url =
    //             globalPublicData.baseEndpoint
    //             + "/common/getauditlogbyuuid/"
    //             + idToQuery

    //         console.log('[ViewEstateAuditPage][fetchData] url is: ', url)
    //         const res = await axios.get(url, axiosConfig)
    //         setInputs(res.data)
    //         // setAuditData(res.data)
    //         console.log('[VeiwAuditPage][fetchData] res.data is: ', res.data)
    //     }
        
    //     fetchData()
        
    // }, [])


    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <EstateSection 
                        title="View Estate (Audit)"
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode
                        disableIdField
                    />                    
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewEstatePageAudit


ViewEstatePageAudit.defaultProps = {
    viewMode: false,
    disableIdField: false

};