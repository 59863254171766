import { useState, useEffect, forwardRef } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import MDTypography from "components/MDTypography";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const ErrorDialog = (props) => {

    const { 
        open, 
        title, 
        messageLine1, 
        messageLine2, 
        goText, 
        setOpenDialog,
        handleSubmit,
    } = props

    const handleClose = () => {
        if (handleSubmit){ 
            handleSubmit()
            setOpenDialog(false)
        } else {
            setOpenDialog(false)
        }
        
    }

    const dialogMessageLine1Styles = {
        fontWeight: "700",
        fontSize: "1.5rem",
        lineHeight: "1.375"
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    {title}
                    <CloseIcon 
                        aria-label="close" 
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 16
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{lineHeight: "2.0em"}} id="alert-dialog-slide-description">
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} sx={{fontSize: "7rem", marginTop: "20px", marginBottom: "20px"}} textAlign="center">
                                <ErrorIcon color="error"/>        
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <MDTypography sx={dialogMessageLine1Styles}>
                                    {messageLine1}
                                </MDTypography>
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <MDTypography variant={"text"}>
                                    {messageLine2}
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{goText}</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default ErrorDialog

ErrorDialog.defaultProps = {
    title: "",
    messageLine1: "Apologies, an error has occured.",
    messageLine2: "",
    goText: "OK",
    open: true
}