
import ActionArea from "components/Table/ActionArea"
import ResultsIdLinkCell from "page-sections/queryBuilder/components/ResultsIdLinkCell"

export const columns = [
    {
        Header: "Space Id",
        accessor: "spaceId",
        Cell: ({ value: data }) => {
            var _id = data.Id
            return(
            <ResultsIdLinkCell id={_id} object={data}/> 
            )
          },
    },
    {
        Header: "Space Name",
        accessor: "spaceName",
    },
    {
        Header: "Space Description",
        accessor: "spaceDescription",
    },
    {
        Header: "Space Abbreviation",
        accessor: "spaceAbbreviation",
    },
    {
        Header: "Estate Name & Id",
        accessor: "estateNameId",
    },
    {
        Header: "Property Group Name & Id",
        accessor: "propertyGroupNameId",
    },
    {
        Header: "Building Name & Id",
        accessor: "buildingNameId",
    },
    {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ value: data }) => {
            const viewPath = "/fb/app/home/view-space?id=" + data.id
            const editPath = "/fb/app/home/edit-space?id=" + data.id
            
            return(
                <ActionArea
                    viewPath={viewPath}
                    editPath={editPath}
                    deleteAPIPath="/space/delete"
                    data={data}
                />
            )
        }
    }
]