const defaultFontSize = "small"
const defaultFontWeight = "regular"


export const generateRowsData = (rawData) => {
    var itemArray = []
    rawData.map( (item, key) => {
        var tempItem = {
            assetId: item,
            assetName: item.Name,
            assetTrade: item.L1_Trade,
            assetCategory: item.L2_Asset_Category,
            assetDescription: item.L3_Asset,
            assetSystemType: item.Asset_System_Type,
            assetGroup: item.Asset_Group || "NA",
            buildingName: item.Building_Name || "NA",
            spaceName: item.Space_Name || "NA",
            actions: item
        }    

        itemArray.push(tempItem)
    })

    return itemArray
}

