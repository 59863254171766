import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from 'components/MDInput';

import { 
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,

} from "../../redux/features/menuOptions/menuOptionsSlice";

import { NAOption, allOption } from '../../globalPublicData';


const EstateAutocompleteWithId = (props) => {

    const {
        name,
        fetchingData,
        inputs,
        setInputs,
        variant,
        hideAllOption,
        hideNAOption
    } = props

    const dispatch = useDispatch()

    const fullEstateOptions = useSelector( state => state.menuOptions.fullEstateOptions)
    const fullPropertyGroupOptions = useSelector( state => state.menuOptions.fullPropertyGroupOptions)
    const fullBuildingOptions = useSelector( state => state.menuOptions.fullBuildingOptions)
    const fullSpaceOptions = useSelector( state => state.menuOptions.fullSpaceOptions)
    
    const hideAllOptionFilter = options => options.filter( opt => opt.Name !== "All")

    const hideNAOptionFilter = options => options.filter( opt => opt.Name !== "NA")

    let filterFunction

    if (hideAllOption){
        filterFunction = hideAllOptionFilter
    } else if (hideNAOption) {
        filterFunction = hideNAOptionFilter
    } else {
        filterFunction = options => options.filter( opt => opt)
    }

    return(
        <>
            <Autocomplete
                multiple
                disabled={fetchingData}
                name={name}
                placeholder="Filter by Estates..."
                // value={inputs[name]}
                value={inputs.estates}
                options={fullEstateOptions}
                // getOptionLabel={option => { return option.Estate_Name + " (" + option.Estate_Id + ")" }}
                getOptionLabel = {option => { 
                    if (option.Estate_Name === "" || option.Estate_Id === ""){
                        return ""
                    } else {
                        return option.Estate_Name +" (" + option.Estate_Id + ")"   
                    }
                }}
                // filterOptions={hideAllOption ? hideAllOptionFilter : hideNAOptionFilter}
                filterOptions={filterFunction}
                renderInput={(params, key) => <MDInput {...params} variant={variant} key={params.Estate_Id} name="estate" />}
                onChange={(event, value) => {
                    var tempIds = []
                    value.map( item => {
                        tempIds.push(item.Id)
                    })
                    
                    setInputs({
                        ...inputs,
                        ["estate_ids"]: tempIds,
                        ["estates"]: value
                    })

                    // if (value.Id !== "All") {
                    //     var _filteredPropertyGroupOptions = fullPropertyGroupOptions.filter((item) => {
                    //         return item.Estate_Id == value.Id
                    //     })

                    //     var _filteredBuildingOptions = fullBuildingOptions.filter( item => {
                    //         return item.Estate_Id == value.Id
                    //     })

                    //     var _filteredSpaceOptions = fullSpaceOptions.filter( item => {
                    //         return item.Estate_Id == value.Id
                    //     })

                    //     _filteredPropertyGroupOptions.push(NAOption)
                    //     _filteredPropertyGroupOptions.push(allOption)

                    //     _filteredBuildingOptions.push(NAOption)
                    //     _filteredBuildingOptions.push(allOption)

                    //     _filteredSpaceOptions.push(NAOption)
                    //     _filteredSpaceOptions.push(allOption)

                    //     dispatch(updateFilteredPropertyGroupOptions(_filteredPropertyGroupOptions))
                    //     dispatch(updateFilteredBuildingMenuOptions(_filteredBuildingOptions))
                    //     dispatch(updateFilteredSpaceMenuOptions(_filteredSpaceOptions))
                    // } 
                }}
            />
        </>
    )
}

export default EstateAutocompleteWithId

EstateAutocompleteWithId.defaultProps = {
    fetchingData: false,
    name: "estate",
    variant: "standard",
    hideAllOption: false,
    hideNAOption: true
  };