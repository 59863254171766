import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  user_id: "",
  user_name: "",
  first_name: "",
  last_name: "",
  email: "",
  roleClaims: [],
  role_ids: [],
  roles: [],
};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateToken(state, action){
          state.token = action.payload
        },

        clearUser(state){
          state = initialState
        },

        updateUserDetails(state, action){

          const { user_id, user_name, first_name, last_name, email } = action.payload
          state.user_id = user_id || ""
          state.user_name = user_name || ""
          state.first_name = first_name
          state.last_name = last_name
          state.name = first_name + " " + last_name
          state.email = email || ""
        },

        updateRolesAndRoleClaims(state, action){
          var _roleClaims = []
          var _role_ids = []
          var _roles = []
          action.payload.map( item => {
            _role_ids.push(item.role_id)
            _roles.push({
              role_id: item.role_id,
              name: item.name,
              desc: item.desc
            })

            item.role_claims.map( claim => {
              _roleClaims.push(claim.claim_value)
            })
          })
  
          state.role_ids= _role_ids
          state.roles= _roles
          state.roleClaims= _roleClaims
        },
    }
})

export const { 
  updateToken,
  clearUser,
  updateUserDetails,
  updateRolesAndRoleClaims,
  } = userSlice.actions

export default userSlice.reducer