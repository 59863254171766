import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MDBox from 'components/MDBox';

const steps = [
  'Create Ticket (BSD)',
  'Pending HOD Approval',
  'Approved',
];

const ProgressSectionBSD = (props) => {

  const { activeStep } = props
  const boxStyles = {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-2rem"
  }

  return (
    <MDBox sx={boxStyles}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel test="vertical">{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </MDBox>
  );
}

export default ProgressSectionBSD

ProgressSectionBSD.defaultProps = {
    activeStep: 1
}