export const defaultInputState = {
    current_password: "",
    new_password: "",
    confirm_password: ""
}


export const defaultInputErrorState = {
    current_password: {
        state: false,
        message: ""
    },
    new_password: {
        state: false,
        message: ""
    },
    confirm_password: {
        state: false,
        message: ""
    },
}