import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
  department: [ "E1", "E2", "E3", "C1", "C2", "C3", "W1", "W2", "W3", "JI"],
  fullDepartmentData: [],
  FMC_Id: [ "CBRE", "CWS", "FMC_C2", "FMC_E1", "FMC_E3", "FMC_JI", "FMC_W1", "FMC_W2", "FMC_W3", "JLL"],
  pdt_bldg_bldgtypelvl2: ["Amenity", "Business Park", "Commercial", "High-Rise Factory", "Land Custom Built", "Standard Factory", "Workshop", "NA"],
  Building_Type: [],
  Asset_Group: [],
  Asset_System_Type: [],
  FMC_Company:  [
    "Bintai Kindenko Pte. Ltd.",
    "C&W Services (S) Pte. Ltd.",
    "CBM Pte. Ltd.",
    "CBRE GWS Pte. Ltd.",
    "CPG Facilities Management Pte. Ltd.",
    "Engie Services Singapore Pte. Ltd.",
    "Guthrie FMC Pte. Ltd.", 
    "Jones Lang Lasalle Property Consultants Pte. Ltd.",
    "Knight Frank Property Asset Management Pte. Ltd.",
    "Savills Property Management Pte. Ltd.",
    "SMM Pte. Ltd.",
    "UEMS Solutions Pte. Ltd."  
  ],
  FMC_Abbreviation: [
    "BINT", "CWS", "CBM", "CBRE", "CPG", "ENGI", "GUTH", "JLL", "KNIG", "SAVI", "SMM", "UEMS"
  ],
  Space_Description: [],
  FMC: [],
};


const dropdownOptionsSlice = createSlice({
    name: 'dropdownOptions',
    initialState,
    reducers: {
        updateDepartment(state, action){
          state.department = action.payload
        },

        updateFullDepartmentData(state, action){
          var _departmentData = []
          var _departmentIds = []
          console.log('[updateFullDepartmentData] action.payload is: ', action.payload)
          for (let i=0;i<action.payload.length;i++){
            _departmentData.push({
              Department_Id: action.payload[i].Id,
              id: action.payload[i].id,
              Name: action.payload[i].Name
            })

            _departmentIds.push(action.payload[i].Id)
          }

          console.log('[updateFullDepartmentData] _departmentData: ', _departmentData)
          
          state.fullDepartmentData = _departmentData
          state.department = _departmentIds

        },

        updateFmcId(state, action){
          state.FMC_Id = action.payload
        },

        updatePdtBldgType(state, action){
          var _pdt_bldg_bldgtypelvl2 = action.payload
          .filter( item => {return item.Is_Deleted === false})
          .map( item => item.Display_Name)
          state.pdt_bldg_bldgtypelvl2 = _pdt_bldg_bldgtypelvl2
        },

        updateFMCCompany(state, action){
          state.FMC_Company = action.payload
        },

        updateFMCAbbreviation(state, action){
          state.FMC_Abbreviation = action.payload
        },

        updateAssetSystemType(state, action){

          var _assetSystemType = action.payload.map(({
            id,
            Id,
            Type_Key, 
            Type_Name, 
            Category,
            Display_Name, 
          }) => ({
            id,
            Id,
            Type_Key, 
            Type_Name, 
            Category,
            Display_Name,
          }))

          state.Asset_System_Type = _assetSystemType
        },

        updateAssetGroup(state, action){
          var _assetGroups = action.payload.map(({
            id,
            Id,
            L3_Asset, 
            Asset_Group, 
            Asset_Abbreviation, 
            L1_Trade, 
            L2_Asset_Category, 
            Asset_Uniclass_PR_Code,
            Asset_Uniclass_SS_Code
          }) => ({
            id,
            Id,
            L3_Asset, 
            Asset_Group, 
            Asset_Abbreviation, 
            L1_Trade, 
            L2_Asset_Category, 
            Asset_Uniclass_PR_Code,
            Asset_Uniclass_SS_Code
          }))
          console.log('[redux][updateAssetGroup] _assetGroups are: ', _assetGroups)
          state.Asset_Group = _assetGroups
        },

        updateBuildingType(state, action){
          var _buildingTypes = action.payload
          .filter( item => {return item.Is_Deleted === false})
          .map(({
            id,
            Id,
            Display_Name, 
            Display_Abbreviation, 
            
          }) => ({
            id,
            Id,
            Display_Name, 
            Display_Abbreviation, 
          }))
          state.Building_Type = _buildingTypes
        },

        updateSpaceDescription(state, action){
          var _spaceDescriptions = action.payload
            .filter( item => {return item.Is_Deleted === false})
            .map(({
            id,
            Id,
            Display_Name, 
            Display_Abbreviation, 
          }) => ({
            id,
            Id,
            Display_Name, 
            Display_Abbreviation, 
          }))
          state.Space_Description = _spaceDescriptions
        },

        updateFMC(state, action){
          console.log('[updateFMC] action.payload is: ', action.payload)
          var _Fmc  = []
          var _FmcIds = []
          var _FmcCompany = []
          var _FmcAbbreviation = []

          for (let i = 0; i < action.payload.length; i++) {
            _Fmc.push({
              Id: action.payload[i].Id,
              id: action.payload[i].id,
              Name: action.payload[i].Name,
              Name_Abbreviation: action.payload[i].Name_Abbreviation,
              Uen: action.payload[i].Uen
            })

            _FmcIds.push(action.payload[i].Id)
            _FmcAbbreviation.push(action.payload[i].Name_Abbreviation)
            _FmcAbbreviation.push(action.payload[i].Name)
            
          }

          state.FMC = _Fmc
          state.FMC_Id = _FmcIds
          state.FMC_Abbreviation = _FmcAbbreviation
          state.FMC_Company = _FmcCompany
        },
    }
})

export const { 
  updateDepartment,
  updateFullDepartmentData,
  updateFmcId,
  updatePdtBldgType,
  updateFMCCompany,
  updateFMCAbbreviation,
  updateFMC,
  updateBuildingType,
  updateSpaceDescription,
  updateAssetGroup,
  updateAssetSystemType,
  } = dropdownOptionsSlice.actions

export default dropdownOptionsSlice.reducer