import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import AssetBasicInformationSection from "page-sections/assets/AssetBasicInformationSection";
import SpaceSubmitArea from "page-sections/spaces/SpaceSubmitArea";
import AssetSubmitArea from "page-sections/assets/AssetSubmitArea";

import { defaultInputState, provisionedFields } from "page-sections/assets/utils";
import AssetOtherInformationSection from "page-sections/assets/AssetOtherInformationSection";
import AssetCustomSection from "page-sections/assets/AssetCustomSection";
import { defaultInputErrorState } from "page-sections/assets/utils";
import input from "assets/theme/components/form/input";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const EditAssetPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()

    const user = useSelector(state => state.user)
    useEffect(()=>{
        if (!user.roleClaims.includes("amm.manageasset")){ navigate("/fb/app/403")}
    },[])

    const viewMode = false
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [filteredDataKeys, setFilteredDataKeys] = useState({})

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
        console.log('[handleInputChange] inputs are: ', inputs)
    }

    const handleCriticalityLevelChange = (event, value) => {
        setInputs({ ...inputs, Criticality_Level: value})
    }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    useEffect( ()=>{
        console.log('[ViewAssetPage] location.state is: ', location.state)
        const recievedDataKeys = Object.keys(location.state || inputs)
        const duplicateFields = new Set(provisionedFields)
        const _filteredDataKeys = recievedDataKeys.filter(item => { return !duplicateFields.has(item) })
        setFilteredDataKeys(_filteredDataKeys)
        console.log('_filteredDataKeys are: ', filteredDataKeys)
    },[inputs])

   


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Edit Asset
                        </MDTypography>
                    </MDBox>
                    <AssetBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    />
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        showMultiSpaceFields
                        openCollapse={true}
                    />
                    <AssetOtherInformationSection
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    />
                    <AssetCustomSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        editMode={true}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        disableLabel={false}
                        createMode={false}
                        allowDelete={true}
                    />
                    <MDBox p={2} mt={7}>
                    <SpaceAssetSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Asset",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Asset",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Asset",
                            color: "error"
                        }}
                        verifyButton={defaultNoShowButtonAttributes}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/asset/create"
                        updateEndpoint="/asset/update"
                        deleteEndpoint="/asset/delete"
                        editRedirectUrl="/fb/app/home/edit-asset"
                        defaultInputErrorState={defaultInputErrorState}
                    />      
                    </MDBox>   
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default EditAssetPage


EditAssetPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};