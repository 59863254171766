import { columns as estateColumns } from 'page-sections/queryBuilder/sections/EstateTable/estateColumns';
import { columns as propertyGroupColumns } from 'page-sections/queryBuilder/sections/PropertyGroupTable/propertyGroupColumns';
import { columns as buildingColumns } from 'page-sections/queryBuilder/sections/BuildingTable/buildingColumns';
import { columns as spaceColumns } from 'page-sections/queryBuilder/sections/SpaceTable/spaceColumns';
import { columns as assetColumns } from 'page-sections/queryBuilder/sections/AssetTable/assetColumns';


export const tableColumnNames = {
    estates: estateColumns,
    propertyGroups: propertyGroupColumns,
    buildings: buildingColumns,
    spaces: spaceColumns,
    assets: assetColumns
}