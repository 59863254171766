import { useState, useEffect, useRef } from "react";
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";

import EstateSection from "page-sections/estates/EstateSection"
import EstateSubmitArea from "page-sections/estates/EstateSubmitArea";

import { defaultInputState, defaultInputErrorState } from "page-sections/estates/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const CreateEstateTicketPage = (props) => {

    const navigate = useNavigate()
    const user = useSelector(state => state.user)

    const [inputs, setInputs] = useState(defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        if (!user.roleClaims.includes("amm.bsd")) {
            navigate("/fb/app/403")
        }

        setInputErrorState(defaultInputErrorState)
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <EstateSection
                        title="Create Estate" 
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={false}
                        disableIdField={false}
                    />
                    <MDBox p={2} mt={7}>
                        <EstatePGBuildingSubmitArea
                            inputs={inputs}
                            setInputErrorState={setInputErrorState}
                            submitCreateRequestButton={{
                                show: true,
                                text: "Submit Request",
                                color: "info"
                            }}
                            approveButton={{
                                show: false,
                                text: "Approve Request",
                                color: "info"
                            }}
                            updateTicketButton={defaultNoShowButtonAttributes}
                            updateBatchTicketButton={defaultNoShowButtonAttributes}
                            createEndpoint="/estate/create"
                            updateEndpoint="/estate/update"
                            deleteEndpoint="/estate/delete"
                            editRedirectUrl="/fb/app/home/edit-estate" 
                            defaultInputErrorState={defaultInputErrorState}
                        
                        /> 
                    </MDBox>      
                    {/* <EstateSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        submitCreateRequestButton={{
                            show: true,n[]
                            text: "Submit Request",
                            color: "info"
                        }}
                        approveButton={{
                            show: false,
                            text: "Approve Request",
                            color: "info"
                        }}
                    /> */}
             
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreateEstateTicketPage


CreateEstateTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};