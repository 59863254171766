export const defaultInputState = {
    Name: "",
    Department_Id: "",
    FMC_Id: "",
    Geometry: "",
}

export const defaultInputErrorState = {
    Name: {state: false, message: "Estate Name must not be empty"},
    Department_Id: { state: false, message: ""},
    FMC_Id: { state: false, message: "" },
    // Geometry: { state: false, message: "" },
}