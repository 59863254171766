const selectPropertyGroupExcelFields = (item) => {
    const {
        Id,
        FMC_Id,
        Department_Id,
        Estate_Id,
        Name,
        Property_Type,
        Gross_Floor_Area,
    } = item
    return {
        Id,
        FMC_Id,
        Department_Id,
        Estate_Id,
        Name,
        Property_Type,
        Gross_Floor_Area,
    }
}

export default selectPropertyGroupExcelFields