import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import TicketTable from "page-sections/home/TicketTable";
import { ticketColumns } from "page-sections/home/utils";

import VerifiedIcon from '@mui/icons-material/Verified';
import ApprovalIcon from '@mui/icons-material/Approval';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Alert, AlertTitle, Grid } from "@mui/material";
import StatisticsCard from "components/StatisticsCard";

const FMOpsView = (props) => {

    const {
        ticketLoadingStatus,
        approvedTicketsData,
        pendingHodTicketsData,
        pendingBSDTicketsData,
        pendingFMOpsTicketsData,
        pendingDraftTicketsData,
        rejectedTicketsData,
        cancelledTicketsData
    } = props

    const user = useSelector(state => state.user)    

    
    const [rejectedCancelledTicketsData, setRejectedCancelledTicketsData] = useState(
        {
            columns: ticketColumns,
            rows: [...rejectedTicketsData.rows, ...cancelledTicketsData.rows]
        }
    )

    const [pendingActionsTicketsData, setPendingActionsTicketsData] = useState({
        columns: ticketColumns,
        rows: [...pendingDraftTicketsData.rows, ...pendingFMOpsTicketsData.rows]
    })

    useEffect(()=>{
        setRejectedCancelledTicketsData({
            columns: ticketColumns,
            rows: [...rejectedTicketsData.rows, ...cancelledTicketsData.rows]
        })

        setPendingActionsTicketsData({
            columns: ticketColumns,
            rows: [...pendingDraftTicketsData.rows, ...pendingFMOpsTicketsData.rows]
        })

    },[rejectedTicketsData, cancelledTicketsData, pendingDraftTicketsData, pendingFMOpsTicketsData])
    
    return(
        <>        
            <Grid container my={5} spacing={5}>
                <Grid item xs={12} lg={6}>
                    <StatisticsCard
                        color="warning"
                        icon={<BeenhereIcon fontSize="large" />}
                        title={"Pending Actions"}
                        count={pendingFMOpsTicketsData.rows.length + pendingDraftTicketsData.rows.length}
                        percentage={{ color: "success", amount: "-20%", label: "from last week" }}
                        loading={ticketLoadingStatus}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <StatisticsCard
                        color="info"
                        icon={<FactCheckIcon fontSize="large" />}
                        title={"Rejected/Cancelled"}
                        count={rejectedTicketsData.rows.length + cancelledTicketsData.rows.length}
                        percentage={{ color: "error", amount: "+45%", label: "from last week" }}
                        loading={ticketLoadingStatus}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <StatisticsCard
                        color="info"
                        icon={<VerifiedIcon fontSize="large" />}
                        title={"Pending BSD Approval"}
                        count={pendingBSDTicketsData.rows.length}
                        percentage={{ color: "success", amount: "-25%", label: "from last week" }}
                        loading={ticketLoadingStatus}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <StatisticsCard
                        color="success"
                        icon={<ApprovalIcon fontSize="large"/>}
                        title={"Approved Tickets"}
                        count={approvedTicketsData.rows.length}
                        percentage={{color: "success", amount: "+55%", label: "from last week"}}
                        loading={ticketLoadingStatus}
                    />
                </Grid>
            </Grid>
            <TicketTable 
                title="Pending Actions" 
                ticketData={pendingActionsTicketsData} 
                defaultValue={5} 
                loading={ticketLoadingStatus}
            />
            <TicketTable 
                title="Rejected/Cancelled" 
                ticketData={rejectedCancelledTicketsData} 
                defaultValue={5} 
                loading={ticketLoadingStatus}
            />
            <TicketTable 
                title="Pending BSD Approval" 
                ticketData={pendingBSDTicketsData} 
                defaultValue={5}
                loading={ticketLoadingStatus} 
            />
            <TicketTable 
                title="Approved Tickets" 
                ticketData={approvedTicketsData} 
                defaultValue={5}
                loading={ticketLoadingStatus} 
            />
        </>
    )
}

export default FMOpsView