import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";


import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";



import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import BuildingBasicInformationSection from "page-sections/building/BuildingBasicInformationSection";
import BuildingDetailedInformationSection from "page-sections/building/BuildingDetailedInformationSection";
import BuildingSubmitArea from "page-sections/building/BuildingSubmitArea";
import FMCContactInformationSection from "page-sections/contactInformationForms/FMCContactInformationSection";
import JTCContactInformationSection from "page-sections/contactInformationForms/JTCContactInformationSection";

import { defaultInputState, defaultInputErrorState } from "page-sections/building/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const EditBuildingPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()

    const viewMode = false
    const disableIdField = true

    const user = useSelector(state => state.user)
    useEffect(()=>{
        if (!user.roleClaims.includes("amm.managebuilding")){ navigate("/fb/app/403")}
    },[])

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Edit Building
                        </MDTypography>
                    </MDBox>
                    <BuildingBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        openCollapse={false}
                    />
                    <BuildingDetailedInformationSection
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={false}
                    />
                    {/* <FMCContactInformationSection 
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    />
                    <JTCContactInformationSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    /> */}
                    <MDBox p={2} mt={7}>
                    <EstatePGBuildingSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Building",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Building",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Building",
                            color: "error"
                        }}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/building/create"
                        updateEndpoint="/building/update"
                        deleteEndpoint="/building/delete"
                        editRedirectUrl="/fb/app/home/edit-building"
                        defaultInputErrorState={defaultInputErrorState}
                    />
                    </MDBox>
                    {/* <BuildingSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Building",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Building",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Building",
                            color: "error"
                        }}
                    />                  */}
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default EditBuildingPage


EditBuildingPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};