/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useState } from "react";
import axios from "axios"

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Label } from "@mui/icons-material";
import { FormLabel } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { globalPublicData } from "globalPublicData";

function Page_404() {

  const navigate = useNavigate()
  // const [rememberMe, setRememberMe] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('verify');
    const endpoint = globalPublicData.baseEndpoint 
    + "/common/getgrecaptchaverification?token=" 
    + token

    const response = await axios.get(endpoint)
    console.log('grecaptcha response is: ', response)
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSignIn = () => {
    navigate("/fb/app/home")
  }



  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={5} pb={5} mt={1} mb={20} px={3}>
          <MDTypography variant="h1">404 Page Not Found</MDTypography>
          <br />
          <MDTypography variant="body">Apologies, the page you are looking for cannot be found.</MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Page_404;
