export const prepareAllMenuOptions = (data) => {
    var preparedOptions = {}

    console.log("[menuOptionsUtils][prepareAllMenuOptions] data is: ", data)

    const filterArray = ["Estate", "Property_Group", "Building", "Space"]

    filterArray.map((filter) => {
        var tempData = data.filter(item => item.Type === filter)
        preparedOptions[filter] = tempData
        tempData = []
    })

    console.log("[menuOptionsUtils][prepareAllMenuOptions] preparedOptions is: ", preparedOptions)
    return preparedOptions

}

export const prepareAllMenuOptionsBackup = (data) => {
    var preparedOptions = {}
    var tempData

    const uniqueIds = [...new Set(data.map(item => item.Id))]

    var filteredArrayByTimestamp = []

    uniqueIds.map(item => {
        var tempArray = data.filter(obj => {
            return obj.Id === item
        }).sort((a, b) => {
            return b["_ts"] - a["_ts"]
        })

        if (tempArray[0]["Is_Deleted"] !== true) {

            filteredArrayByTimestamp.push(tempArray[0])
        }
    })


    const filterArray = ["estate", "property_group", "building", "space"]

    filterArray.map((filter) => {
        var tempData = filteredArrayByTimestamp.filter(item => item.Type === filter)
        
        tempData.push({
            Name: "All", Id: "All",
            Estate_Name: "All", Estate_Id: "All",
            Property_Group_Name: "All", Property_Group_Id: "All",
            Property_Name: "All", Property_Id: "All",
            Space_Name: "All", Space_Id: "All"
        })

        if (filter !== "estate") {
            tempData.push({
                Name: "NA", Id: "NA",
                // Estate_Name: "NA", Estate_Id: "NA",
                Property_Group_Name: "NA", Property_Group_Id: "NA",
                Property_Name: "NA", Property_Id: "NA",
                Space_Name: "NA", Space_Id: "NA"
            })
        }
        
        preparedOptions[filter] = tempData
        tempData = []
    })

    return preparedOptions

}