import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';


import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";


const FMCContactInformationSection = (props) => {

    const navigate = useNavigate()


    const { inputs, handleInputChange, viewMode, openCollapse } = props


    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }


    return (
        <PlusCollapse title="FMC Contact Information" open={openCollapse}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FMC Company</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FMC_Company"
                            value={inputs.FMC_Company}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Facilities Management Company
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FMC Contact Person Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FMC_Contact_Person_Name"
                            value={inputs.FMC_Contact_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FMC Contact Person Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FMC Contact Person Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FMC_Contact_Person_Email"
                            value={inputs.FMC_Contact_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FMC Contact Person Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FMC Contact Person Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FMC_Contact_Person_Mobile"
                            value={inputs.FMC_Contact_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FMC Contact Person Mobile
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FMC Covering Person Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FMC_Covering_Contact_Person_Name"
                            value={inputs.FMC_Covering_Contact_Person_Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FMC Covering Person Name
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FMC Covering Person Email</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FMC_Covering_Person_Email"
                            value={inputs.FMC_Covering_Person_Email}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FMC Covering Person Email
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FMC Covering Person Mobile</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FMC_Covering_Person_Mobile"
                            value={inputs.FMC_Covering_Person_Mobile}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FMC Covering Person Mobile
                        </FormHelperText>
                    </Grid>
                </Grid>
            </MDBox>
        </PlusCollapse>
    )
}

export default FMCContactInformationSection


FMCContactInformationSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    openCollapse: true

};