import { useCallback, useEffect, useState } from "react";
import axios from "axios"
import validator from "validator";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card"
// @mui icons
import SyncIcon from '@mui/icons-material/Sync';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";

// Authentication layout components
import BasicLayout from "layouts/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Label } from "@mui/icons-material";
import { FormHelperText, FormLabel, Switch } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { globalPublicData } from "globalPublicData";
import useCookies from "react-cookie/cjs/useCookies";
import { useDispatch } from "react-redux";
import { 
  updateToken,
  clearUser,
  updateUserDetails,
  updateRolesAndRoleClaims,
} from "redux/features/user/userSlice";
import ErrorDialog from "components/ErrorDialog";
import SuccessDialog from "components/SuccessDialog";
// import { updateRolesAndRoleClaims } from "redux/features/user/userSlice";

function ForgetPasswordPage() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [inputs, setInputs] = useState({
    email: ""
  })

  const [userNameError, setUserNameError] = useState({
    state: false,
    label: ""
  })

  const [buttonClicked, setButtonClicked] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: ""})
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const [successDialogParams, setSuccessDialogParams] = useState({ messageLine1: ""})
  
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (event) => {
    event.persist()
    setInputs({ ...inputs, [event.target.name]: event.target.value })
}

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('verify');
    const endpoint = globalPublicData.baseEndpoint 
    + "/common/getgrecaptchaverification?token=" 
    + token

    const response = await axios.get(endpoint)
    console.log('grecaptcha response is: ', response)
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const generalInputsErrorHandler = (messageLine1, messageLine2) => {
    setErrorDialogParams({
        messageLine1: messageLine1,
        messageLine2: messageLine2
    })
    // setOpenDialog(false)
    setOpenErrorDialog(true)
}

const handleSetRememberMe = ()=>{

}

  const invalidInputsErrorHandler = () => {
    setErrorDialogParams({
        messageLine1: "Error: Please check your inputs and try again"
    })
    // setOpenDialog(false)
    setOpenErrorDialog(true)
}

const identifiedErrorHandler = (errData)=>{
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: JSON.stringify(errData)
        })
        // setOpenDialog(false)
        setOpenErrorDialog(true)
    }

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSubmit = async () => {

    setButtonClicked(true)
    console.log('validator for email is: ', validator.isEmail(inputs.email))
    
    var validationState = false
    var _userNameError = {
      state: false,
      label: ""
    }

    var messageLine1 = ""
    var messageLine2 = ""
    
    if (validator.isEmpty(inputs.email)){
      messageLine1 = "Please input your email address"
      _userNameError = {state: true, label: messageLine1}
      setUserNameError(_userNameError)
    } else if (!validator.isEmail(inputs.email)){
      messageLine1 = "Please enter a valid email address"
      _userNameError = {state: true, label: messageLine1}
      setUserNameError(_userNameError)
    } else {
      setUserNameError({state: false, label: ""})
    }
    
    if (_userNameError.state === false){

      validationState = true

    } else {
      invalidInputsErrorHandler()
      setButtonClicked(false)
      validationState = false
    }
    
    if (validationState === true){
      const endpoint = globalPublicData.baseEndpoint + "/systemaccess/recoverpassword"
      try {
        const response = await axios.post(endpoint, inputs)
        console.log('response is: ', response)
        if (response.status !== 200){
          throw "Error"
        } else {
          setSuccessDialogParams({
              messageLine1: "A recovery email has been sent. Please check your email for the next steps.",
              handleSubmit: () => { navigate("/fb/app/") }
          })
          setOpenSuccessDialog(true)
          setButtonClicked(false)
        } 
      } catch (err){
        console.log('[handleSignIn] error: ', err)
        if (err.response.data.errors[0].error_code){
          if (err.response.data.errors[0].error_code === "1010107"){
            generalInputsErrorHandler("Error: Please check your inputs and try again")
          } 
        } else {
          identifiedErrorHandler(err.response.data.errors)
        }
        setButtonClicked(false)
      }
    } else {
      generalInputsErrorHandler("Error: Please check your inputs and try again")
      setButtonClicked(false)
    }
  }



  return (
    <BasicLayout image={bgImage}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={9} md={8} xl={6}>
          <Card>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
              <MDBox mb={1}>
                  <FormLabel sx={{marginBottom: "5px", paddingBottom: "5px", lineHeight: "2px"}}>
                    <MDTypography fontWeight="medium" variant="subtitle2">Please enter your work email address*</MDTypography>
                  </FormLabel>
              </MDBox>
              <MDBox mb={2}>
                  <MDInput 
                    type="email" 
                    label="Email" 
                    name="email" 
                    onChange={handleInputChange} 
                    fullWidth
                    error={userNameError.state}
                  />
                  <FormHelperText error={userNameError.state}>
                    <strong>{userNameError.label}</strong>
                  </FormHelperText>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton 
                    onClick={handleSubmit} 
                    variant="gradient" 
                    color="info" 
                    fullWidth
                    disabled={buttonClicked}
                  >
                    {buttonClicked ? <SyncIcon /> : "Submit"}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <SuccessDialog
          open={openSuccessDialog}
          messageLine1={successDialogParams.messageLine1}
          handleSubmit={successDialogParams.handleSubmit}
          setOpenDialog={setOpenSuccessDialog}                
      />
      <ErrorDialog
          open={openErrorDialog}
          messageLine1={errorDialogParams.messageLine1}
          messageLine2={errorDialogParams.messageLine2}
          setOpenDialog={setOpenErrorDialog}
      />
    </BasicLayout>
  );
}

export default ForgetPasswordPage;
