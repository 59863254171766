const defaultFontSize = "small"
const defaultFontWeight = "regular"


export const generateRowsData = (rawData) => {
    var itemArray = []
    rawData.map( (item, key) => {
        var tempItem = {
            propertyGroupId: item,
            propertyGroupName: item.Name,
            department: item.Department_Id || item.Department || "NA",
            propertyGroupFMCId: item.FMC_Id || "NA",
            estateName: item.Estate_Name + " (" + item.Estate_Id + ")",
            propertyType: item.Property_Type || "NA",
            actions: item
        }    

        itemArray.push(tempItem)
    })

    return itemArray
}

