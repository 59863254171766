import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import AssetBasicInformationSection from "page-sections/assets/AssetBasicInformationSection";
import AssetSubmitArea from "page-sections/assets/AssetSubmitArea";

import { defaultInputState, provisionedFields } from "page-sections/assets/utils";
import AssetOtherInformationSection from "page-sections/assets/AssetOtherInformationSection";
import AssetCustomSection from "page-sections/assets/AssetCustomSection";
import { defaultInputErrorState } from "page-sections/assets/utils";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";


const CreateAssetTicketPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const user = useSelector(state => state.user)

    const viewMode = false
    const disableIdField = false

    const [inputs, setInputs] = useState(defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [filteredDataKeys, setFilteredDataKeys] = useState({})

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
        console.log('[handleInputChange] inputs are: ', inputs)
    }

    const handleCriticalityLevelChange = (event, value) => {
        setInputs({ ...inputs, Criticality_Level: value})
    }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    useEffect( ()=>{
        const recievedDataKeys = Object.keys(location.state || inputs)
        const duplicateFields = new Set(provisionedFields)
        const _filteredDataKeys = recievedDataKeys.filter(item => { return !duplicateFields.has(item) })
        setFilteredDataKeys(_filteredDataKeys)
        console.log('_filteredDataKeys are: ', filteredDataKeys)
    },[inputs])

    useEffect( ()=>{
        if (!user.roleClaims.includes("amm.fmops") && !user.roleClaims.includes("amm.fmc")){
            navigate("/fb/app/403")
        }
        console.log("[CreateAssetTicketPage] component loaded")
    },[])

   


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Create Asset
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        showMultiSpaceFields
                        openCollapse={true}
                    />
                    <AssetBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    />
                    <AssetOtherInformationSection
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                    />
                    <AssetCustomSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        disableLabel={false}
                        createMode={true}
                        allowDelete={true}
                    />
                    <MDBox p={2} mt={7}>
                    <SpaceAssetSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        submitCreateRequestButton={{
                            show: true,
                            text: "Submit Request",
                            color: "info"
                        }}
                        editButton={{
                            ...defaultNoShowButtonAttributes,
                            redirectToEditPage: false
                        }}
                        deleteButton={defaultNoShowButtonAttributes}
                        verifyButton={defaultNoShowButtonAttributes}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/asset/create"
                        updateEndpoint="/asset/update"
                        deleteEndpoint="/asset/delete"
                        editRedirectUrl="/fb/app/home/edit-asset"
                        defaultInputErrorState={defaultInputErrorState}
                    />
                    </MDBox>
                    
                    {/* <AssetSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: true,
                            text: "Create Asset",
                            color: "info"
                        }}
                        editButton={{
                            show: false,
                            text: "Edit Asset",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: false,
                            text: "Delete Asset",
                            color: "error"
                        }}
                        verifyButton={{
                            show: false,
                            text: "Verify Asset",
                            color: "info"
                        }}
                    />              */}
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreateAssetTicketPage


CreateAssetTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};