/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components

// Images
import meetingRoom1 from "assets/images/meeting-room-1.jpg"
import meetingRoom2 from "assets/images/meeting-room-2.jpg"
import meetingRoom3 from "assets/images/meeting-room-3.jpg"
import meetingRoom4 from "assets/images/meeting-room-4.jpg"
import meetingRoom5 from "assets/images/meeting-room-5.jpg"
import lounge1 from "assets/images/lounge-1.jpg"
import lounge2 from "assets/images/lounge-2.jpg"
import lounge3 from "assets/images/lounge-3.jpg"
import auditorium1 from 'assets/images/auditorium-1.jpg'


const sampleFacilityData = [
  {
    title: "Peace Haven Lounge",
    img: lounge1,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Silver Lounge",
    img: lounge2,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Gold Lounge",
    img: lounge3,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Raffles Auditorium",
    img: auditorium1,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Meeting Room 1",
    img: meetingRoom1,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Meeting Room 2",
    img: meetingRoom2,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Meeting Room 3",
    img: meetingRoom3,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Meeting Room 4",
    img: meetingRoom4,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
  {
    title: "Meeting Room 5",
    img: meetingRoom5,
    description1: "Looking for an exceptional venue to host your next business gathering? Look no further! Our exclusive meeting rooms offer the ideal environment for productive discussions, innovative brainstorming sessions, and impressive presentations. Whether you're organizing a corporate meeting, client pitch, or team workshop, our versatile spaces are designed to cater to all your professional needs.",
    description2: "Our meeting rooms are not just aesthetically pleasing; they're also equipped with the latest technology to ensure seamless communication and presentation experiences. Stay connected with high-speed internet, engage your audience with high-definition displays, and captivate them with immersive sound systems. Our goal is to provide you with a seamless and hassle-free environment, allowing you to focus solely on the success of your meeting."
  },
]

export default sampleFacilityData;
