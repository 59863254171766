
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Provider } from 'react-redux';
import { persistor, store } from "./redux/app/store"

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// Images
// import brandWhite from "assets/images/logo-ct.png";
// import brandDark from "assets/images/logo-ct-dark.png";

import brandWhite from "assets/images/jtc_logo_white.png";
import brandDark from "assets/images/jtc_logo_white.png";

import SignInBasic from "layouts/authentication/sign-in/basic";
import ViewEstatePage from "pages/estates/ViewEstatePage";
import EditEstatePage from "pages/estates/EditEstatePage";
import ViewPropertyGroupPage from "pages/property-groups/ViewPropertyGroupPage";
import EditPropertyGroupPage from "pages/property-groups/EditPropertyGroupPage";
import ViewBuildingPage from "pages/buildings/ViewBuildingPage";
import EditBuildingPage from "pages/buildings/EditBuildingPage";
import ViewSpacePage from "pages/spaces/ViewSpacePage";
import EditSpacePage from "pages/spaces/EditSpacePage";
import ViewAssetPage from "pages/assets/ViewAssetPage";
import ViewSpacePageAudit from "pages/spaces/ViewSpacePageAudit";
import ViewBuildingPageAudit from "pages/buildings/ViewBuildingPageAudit";
import EditAssetPage from "pages/assets/EditAssetPage";
import ViewAssetPageAudit from "pages/assets/ViewAssetPageAudit";
import ViewEstatePageAudit from "pages/estates/ViewEstatePageAudit";
import ViewPropertyGroupPageAudit from "pages/property-groups/ViewPropertyGroupPageAudit";
import Page_403 from "pages/error-pages/Page_403";
import Page_404 from "pages/error-pages/Page_404";
import ReviewBuildingPage from "pages/buildings/ReviewBuildingPage";
import ApproveBuildingPage from "pages/buildings/ApproveBuildingPage";
import { CookiesProvider } from "react-cookie";

import ViewEstateTicketPage from "pages/estates/ViewEstateTicketPage";
import ViewPropertyGroupTicketPage from "pages/property-groups/ViewPropertyGroupTicketPage";
import ViewBuildingTicketPage from "pages/buildings/ViewBuildingTicketPage";
import ViewSpaceTicketPage from "pages/spaces/ViewSpaceTicketPage";
import ViewAssetTicketPage from "pages/assets/ViewAssetTicketPage";
import { PersistGate } from "redux-persist/integration/react";
import ViewBatchCreateTicketPage from "pages/batchCreateTicket/ViewBatchCreateTicketPage";
import ViewBatchEstateTicketPage from "./pages/estates/ViewBatchEstateTicketPage";
import ViewBatchPropertyGroupTicketPage from "pages/property-groups/ViewBatchPropertyGroupTicketPage";
import ViewBatchBuildingTicketPage from "pages/buildings/ViewBatchBuildingTicketPage";
import ViewBatchSpaceTicketPage from "pages/spaces/ViewBatchSpaceTicketPage";
import ViewBatchAssetTicketPage from "pages/assets/ViewBatchAssetTicketPage";
import ChangePasswordPage from "pages/password/ChangePasswordPage";
import SignInPage from "pages/SignInPage";
import ForgetPasswordPage from "pages/ForgetPasswordPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import Page_401 from "pages/error-pages/Page_401";
import FacilityPage from "pages/facility-page";
import SchedulePage from "pages/schedule";
import CreateBookingPage from "pages/createbooking";
import BookingList from "pages/bookings/booking-list";
import BookingDetails from "pages/bookings/booking-details";
import SearchResults from "pages/search-results";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const basePath = "/amm/api/"

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <SettingsIcon color="inherit"/>
    </MDBox>
  );

  const sidebarToggleButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      // onClick={handleConfiguratorOpen}
    >
      <MenuIcon color="inherit" />
      {/* <Icon fontSize="small" color="inherit">
        settings
      </Icon> */}
    </MDBox>
  );

  

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Leo63ckAAAAAJ95Xf6OL7C-0H0ScKa3oGMliXAJ">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CookiesProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={darkMode ? themeDark : theme}>
                <CssBaseline />
                {layout === "dashboard" && (
                  <>
                    {/* <Sidenav
                      color={sidenavColor}
                      brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                      brandName="Asset Management"
                      routes={routes}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                    /> */}
                    {/* <Configurator /> */}
                    {/* {configsButton} */}
                    {/* {sidebarToggleButton} */}
                  </>
                )}
                {layout === "vr" && <Configurator />}
                <Routes>
                  {getRoutes(routes)}
                  <Route path="/" element={<SignInPage />} />
                  <Route path="/forget-password" element={<ForgetPasswordPage />} />
                  <Route path="/reset-password" element={<ResetPasswordPage />} />
                  <Route path="/fb/app" element={<SignInPage />} />
                  <Route path="/fb/app/forget-password" element={<ForgetPasswordPage />} />
                  <Route path="/fb/app/reset-password" element={<ResetPasswordPage />} />
                  <Route path="/403" element={<Page_403 />} />
                  <Route path="/fb/app/403" element={<Page_403 />} />
                  <Route path="/401" element={<Page_401 />} />
                  <Route path="/fb/app/401" element={<Page_401 />} />
                  <Route path="/404" element={<Page_404 />} />
                  <Route path="/fb/app/home/change-password" element={<ChangePasswordPage />} />
                  <Route path="/fb/app/home/view-estate" element={<ViewEstatePage />} />
                  <Route path="/fb/app/home/view-estate-audit" element={<ViewEstatePageAudit />} />
                  <Route path="/fb/app/home/view-property-group" element={<ViewPropertyGroupPage />} />
                  <Route path="/fb/app/home/view-property-group-audit" element={<ViewPropertyGroupPageAudit />} />
                  <Route path="/fb/app/home/view-building" element={<ViewBuildingPage />} />
                  <Route path="/fb/app/home/view-space" element={<ViewSpacePage />} />
                  <Route path="/fb/app/home/view-asset" element={<ViewAssetPage />} />
                  <Route path="/fb/app/home/view-asset-audit" element={<ViewAssetPageAudit />} />
                  <Route path="/fb/app/home/view-building-audit" element={<ViewBuildingPageAudit />} />
                  <Route path="/fb/app/home/view-space-audit" element={<ViewSpacePageAudit />} />
                  <Route path="/fb/app/home/edit-estate" element={<EditEstatePage />} />
                  <Route path="/fb/app/home/edit-property-group" element={<EditPropertyGroupPage />} />
                  <Route path="/fb/app/home/edit-building" element={<EditBuildingPage />} />
                  <Route path="/fb/app/home/edit-space" element={<EditSpacePage />} />
                  <Route path="/fb/app/home/edit-asset" element={<EditAssetPage />} />
                  <Route path="/fb/app/home/review-estate" element={<ViewEstatePage />} />
                  <Route path="/fb/app/home/review-property-group" element={<ViewPropertyGroupPage />} />
                  <Route path="/fb/app/home/review-building" element={<ReviewBuildingPage />} />
                  <Route path="/fb/app/home/review-space" element={<ViewSpacePage />} />
                  <Route path="/fb/app/home/review-asset" element={<ViewAssetPage />} />
                  <Route path="/fb/app/home/view-estate-ticket" element={<ViewEstateTicketPage />} />
                  <Route path="/fb/app/home/view-property-group-ticket" element={<ViewPropertyGroupTicketPage />} />
                  <Route path="/fb/app/home/view-building-ticket" element={<ViewBuildingTicketPage />} />
                  <Route path="/fb/app/home/view-space-ticket" element={<ViewSpaceTicketPage />} />
                  <Route path="/fb/app/home/view-asset-ticket" element={<ViewAssetTicketPage />} />
                  <Route path="/fb/app/home/approve-estate" element={<ViewEstateTicketPage />} />
                  <Route path="/fb/app/home/approve-property-group" element={<ViewPropertyGroupPage />} />
                  <Route path="/fb/app/home/approve-building" element={<ApproveBuildingPage />} />
                  <Route path="/fb/app/home/approve-space" element={<ViewSpacePage />} />
                  <Route path="/fb/app/home/approve-asset" element={<ViewAssetPage />} />
                  <Route path="/fb/app/home/view-estate-ticket-batch" element={<ViewBatchEstateTicketPage />} />
                  <Route path="/fb/app/home/view-property-group-ticket-batch" element={<ViewBatchPropertyGroupTicketPage />} />
                  <Route path="/fb/app/home/view-building-ticket-batch" element={<ViewBatchBuildingTicketPage />} />
                  <Route path="/fb/app/home/view-space-ticket-batch" element={<ViewBatchSpaceTicketPage />} />
                  <Route path="/fb/app/home/view-asset-ticket-batch" element={<ViewBatchAssetTicketPage />} />
                  <Route path="/fb/app/home/view-facility" element={<FacilityPage />} />
                  <Route path="/fb/app/home/view-schedule" element={<SchedulePage />} />
                  <Route path="/fb/app/home/create-booking" element={<CreateBookingPage />} />
                  <Route path="/fb/app/home/view-bookings" element={<BookingList />} />
                  <Route path="/fb/app/home/view-booking-details" element={<BookingDetails />} />
                  <Route path="/fb/app/home/search-results" element={<SearchResults />} />
                </Routes>
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </CookiesProvider>
      </LocalizationProvider>
    </GoogleReCaptchaProvider>
  );
}
