
export const handleEstateChangeMultiple = (
    fullItems,
    inputs,
    setInputs,
    fullOptionsData,
    menuOptions,
    setMenuOptions) => {
    console.log('[handleEstateChangeMultiple] fullItems recieved are: ', fullItems)
    var _Ids = fullItems.map(item => { return item.Id })
    var _Names = fullItems.map(item => { return item.Name })

    console.log('[handleEstateChangeMultiple] _Ids are: ', _Ids)
    console.log('[handleEstateChangeMultiple] _Names are: ', _Names)
    // console.log('[handleEstateChangeMultiple] menuOptions are: ', menuOptions)
    

    
    var filteredPropertyGroupOptions = []
    var filteredBuildingOptions = []
    var filteredSpaceOptions = []
    _Ids.map( id => {
        console.log("[innerloop][BEFORE] filteredPropertyGroupOptions are: ", filteredPropertyGroupOptions)
        var _filteredPropertyGroupOptions = fullOptionsData.fullPropertyGroupOptions.filter( option => {
            return option.Estate_Id === id
        })

        var _filteredBuildingOptions = fullOptionsData.fullBuildingOptions.filter( option =>{
            return option.Estate_Id === id
        })

        var _filteredSpaceOptions = fullOptionsData.fullSpaceOptions.filter(option => {
            return option.Estate_Id === id
        })
        filteredPropertyGroupOptions = filteredPropertyGroupOptions.concat(_filteredPropertyGroupOptions)
        filteredBuildingOptions = filteredBuildingOptions.concat(_filteredBuildingOptions)
        filteredSpaceOptions = filteredSpaceOptions.concat(_filteredSpaceOptions)
        console.log("[innerloop] _filteredPropertyGroupOptions are: ", _filteredPropertyGroupOptions)
        console.log("[innerloop] filteredPropertyGroupOptions are: ", filteredPropertyGroupOptions)

    })

    console.log("[final] filteredPropertyGroupOptions are: ", filteredPropertyGroupOptions)
    console.log("[final] filteredBuildingOptions are: ", filteredBuildingOptions)
    console.log("[final] filteredSpaceOptions are: ", filteredSpaceOptions)



    // Limiting Spaces Menu Options to Spaces within the same building
    var _menuOptionsData = {
        // estates: {
        //     name: fullOptionsData.fullEstateOptions.map( item => { return item.Name }),
        //     id: fullOptionsData.fullEstateOptions.map( item => {return item.Id })
        // },
        ...menuOptions,
        propertyGroups: {
            name: filteredPropertyGroupOptions.map( item => { return item.Name }),
            id: filteredPropertyGroupOptions.map( item => { return item.Id })
        },
        buildings: {
            name: filteredBuildingOptions.map(item => { return item.Name }),
            id: filteredBuildingOptions.map(item => { return item.Id })
        },
        spaces: {
            name: filteredSpaceOptions.map(item => { return item.Name }),
            id: filteredSpaceOptions.map(item => { return item.Id })
        }
    }

    console.log("_menuOptionsData is: ", _menuOptionsData)

    setMenuOptions(_menuOptionsData)

    setInputs({
        ...inputs,
        estate_ids: _Ids,
        estate_names: _Names,
        property_group_ids: [],
        property_group_names: [],
        building_ids: [],
        building_names: []
    })
}


export const handlePropertyGroupChangeMultiple = (
    fullItems,
    inputs,
    setInputs,
    fullOptionsData,
    menuOptions,
    setMenuOptions) => {
    console.log('[handlePropertyGroupChangeMultiple] fullItems recieved are: ', fullItems)
    var _Ids = fullItems.map(item => { return item.Id })
    var _Names = fullItems.map(item => { return item.Name })

    console.log('[handlePropertyGroupChangeMultiple] _Ids are: ', _Ids)
    console.log('[handlePropertyGroupChangeMultiple] _Names are: ', _Names)
    // console.log('[handleEstateChangeMultiple] menuOptions are: ', menuOptions)



    // var filteredPropertyGroupOptions = []
    var filteredBuildingOptions = []
    var filteredSpaceOptions = []
    _Ids.map(id => {

        var _filteredBuildingOptions = fullOptionsData.fullBuildingOptions.filter(option => {
            return option.Property_Group_Id === id
        })

        var _filteredSpaceOptions = fullOptionsData.fullSpaceOptions.filter(option => {
            return option.Property_Group_Id === id
        })
        
        filteredBuildingOptions = filteredBuildingOptions.concat(_filteredBuildingOptions)
        filteredSpaceOptions = filteredSpaceOptions.concat(_filteredSpaceOptions)

    })

    console.log("[final] filteredBuildingOptions are: ", filteredBuildingOptions)
    console.log("[final] filteredSpaceOptions are: ", filteredSpaceOptions)



    // Limiting Spaces Menu Options to Spaces within the same building
    var _menuOptionsData = {
        ...menuOptions,
        buildings: {
            name: filteredBuildingOptions.map(item => { return item.Name }),
            id: filteredBuildingOptions.map(item => { return item.Id })
        },
        spaces: {
            name: filteredSpaceOptions.map(item => { return item.Name }),
            id: filteredSpaceOptions.map(item => { return item.Id })
        }
    }

    console.log("_menuOptionsData is: ", _menuOptionsData)

    setMenuOptions(_menuOptionsData)

    setInputs({
        ...inputs,
        property_group_ids: _Ids,
        property_group_names: _Names,
        building_ids: [],
        building_names: [],
        space_ids: [],
        space_names: [],
    })
}

export const handleBuildingChangeMultiple = (
    fullItems,
    inputs,
    setInputs,
    fullOptionsData,
    menuOptions,
    setMenuOptions) => {
    console.log('[handleBuildingChangeMultiple] fullItems recieved are: ', fullItems)
    var _Ids = fullItems.map(item => { return item.Id })
    var _Names = fullItems.map(item => { return item.Name })

    console.log('[handleBuildingChangeMultiple] _Ids are: ', _Ids)
    console.log('[handleBuildingChangeMultiple] _Names are: ', _Names)
    // console.log('[handleEstateChangeMultiple] menuOptions are: ', menuOptions)



    // var filteredPropertyGroupOptions = []
    var filteredSpaceOptions = []
    _Ids.map(id => {

        var _filteredSpaceOptions = fullOptionsData.fullSpaceOptions.filter(option => {
            return option.Building_Id === id
        })

        // filteredBuildingOptions = filteredBuildingOptions.concat(_filteredBuildingOptions)
        filteredSpaceOptions = filteredSpaceOptions.concat(_filteredSpaceOptions)

    })

    // console.log("[final] filteredBuildingOptions are: ", filteredBuildingOptions)
    console.log("[final] filteredSpaceOptions are: ", filteredSpaceOptions)



    // Limiting Spaces Menu Options to Spaces within the same building
    var _menuOptionsData = {
        ...menuOptions,
        spaces: {
            name: filteredSpaceOptions.map(item => { return item.Name }),
            id: filteredSpaceOptions.map(item => { return item.Id })
        }
    }

    console.log("_menuOptionsData is: ", _menuOptionsData)

    setMenuOptions(_menuOptionsData)

    setInputs({
        ...inputs,
        building_ids: _Ids,
        building_names: _Names,
        space_ids: [],
        space_names: [],
    })
}


export const handleSpaceChangeMultiple = (
    fullItems,
    inputs,
    setInputs,
    fullOptionsData,
    menuOptions,
    setMenuOptions) => {
    console.log('[handleSpaceChangeMultiple] fullItems recieved are: ', fullItems)
    var _Ids = fullItems.map(item => { return item.Id })
    var _Names = fullItems.map(item => { return item.Name })

    console.log('[handleSpaceChangeMultiple] _Ids are: ', _Ids)
    console.log('[handleSpaceChangeMultiple] _Names are: ', _Names)

    setInputs({
        ...inputs,
        // building_ids: _Ids,
        // building_names: _Names,
        space_ids: _Ids,
        space_names: _Names,
    })
}

export const handleSpaceChangeMultipleOri = (fullItems, inputs, setInputs, fullOptionsData, menuOptions, setMenuOptions) => {
    var _SpaceIds = fullItems.map( item => { return item.Id})
    var _SpaceNames = fullItems.map(item => { return item.Name })

    console.log('[handleSpaceChangeMultiple] _SpaceIds are: ', _SpaceIds)
    console.log('[handleSpaceChangeMultiple] _SpaceNames are: ', _SpaceNames)
    console.log('[handleSpaceChangeMultiple] menuOptions are: ', menuOptions)
    const { Property_Id, Building_Id, Building_Name, Property_Group_Id, Property_Group_Name, Estate_Id, Estate_Name } = fullItems[0]
    
    console.log('[handleSpaceChangeMultiple] Building_Id id: ', Building_Id)

    var _spaceData = fullOptionsData.fullSpaceOptions.filter((item) => {
        return item.Property_Id === Property_Id
        // Change to Building_Id after new data pumped in
        // return item.Building_Id === Building_Id
    })

    console.log('[handleSpaceChangeMultiple] fullOptionsData.fullSpaceOptions are: ', fullOptionsData.fullSpaceOptions)
    console.log('[handleSpaceChangeMultiple] _spaceData are: ', _spaceData)

    // Limiting Spaces Menu Options to Spaces within the same building
    var _menuOptionsData = {
        ...menuOptions,
        spaces: {
            name: _spaceData.map(item => { return item.Name }),
            id: _spaceData.map(item => { return item.Id })
        }
    }

    console.log("_menuOptionsData is: ", _menuOptionsData)

    setMenuOptions(_menuOptionsData)

    console.log('[handleSpaceChangeMultiple] fullItems[0] is : ', fullItems[0] )
    
    setInputs({
        ...inputs,
        Estate_Name: Estate_Name,
        Estate_Id: Estate_Id,
        Property_Group_Name: Property_Group_Name,
        Property_Group_Id: Property_Group_Id,
        Building_Name: fullItems[0].Building_Name,
        Building_Id: Building_Id || fullItems[0].Property_Id,
        Space_Ids: _SpaceIds,
        Space_Names: _SpaceNames
    })
}