/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";
import DetailedFooter from "components/Footers/DetailedFooter";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

function CoverLayout({ coverHeight, image, children }) {

  const footerContent = {
    brand: {
      name: "MATERIAL UI DESIGN",
      description: "The next generation of design systems.",
    },
    socials: [
      {
        icon: <i className="fab fa-facebook" />,
        link: "https://www.facebook.com/CreativeTim/",
      },
      {
        icon: <i className="fab fa-twitter" />,
        link: "https://twitter.com/creativetim",
      },
      {
        icon: <i className="fab fa-instagram" />,
        link: "https://www.instagram.com/creativetimofficial/",
      },
      {
        icon: <i className="fab fa-pinterest" />,
        link: "https://ro.pinterest.com/thecreativetim/",
      },
      {
        icon: <i className="fab fa-github" />,
        link: "https://github.com/creativetimofficial",
      },
    ],
    menus: [
      {
        name: "company",
        items: [
          { name: "about us", href: "https://www.creative-tim.com" },
          { name: "career", href: "https://www.creative-tim.com" },
          { name: "press", href: "https://www.creative-tim.com" },
          { name: "blog", href: "https://www.creative-tim.com" },
        ],
      },
      {
        name: "Pages",
        items: [
          { name: "login", href: "https://www.creative-tim.com" },
          { name: "register", href: "https://www.creative-tim.com" },
          { name: "add list", href: "https://www.creative-tim.com" },
          { name: "contact", href: "https://www.creative-tim.com" },
        ],
      },
      {
        name: "legal",
        items: [
          { name: "terms", href: "https://www.creative-tim.com" },
          { name: "about us", href: "https://www.creative-tim.com" },
          { name: "team", href: "https://services.creative-tim.com" },
          { name: "privacy", href: "https://www.creative-tim.com" },
        ],
      },
      {
        name: "resources",
        items: [
          { name: "blog", href: "https://www.creative-tim.com" },
          { name: "services", href: "https://www.creative-tim.com" },
          { name: "product", href: "https://www.creative-tim.com" },
          { name: "pricing", href: "https://www.creative-tim.com" },
        ],
      },
    ],
    copyright: (
      <MDTypography variant="button" color="secondary">
        Copyright Material Design by{" "}
        <MDTypography
          component="a"
          href="https://www.creative-tim.com"
          target="_blank"
          rel="noreferrer"
          variant="button"
          color="secondary"
        >
          Creative Tim
        </MDTypography>
        .
      </MDTypography>
    ),
  };



  return (
    <PageLayout sx={{backgroundColor: "white"}}>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-pro-react",
          label: "buy now",
        }}
        transparent
        light
      />
      <MDBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundColor: "white",
          // backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          //   image &&
          //   `${linearGradient(
          //     rgba(gradients.dark.main, 0.4),
          //     rgba(gradients.dark.state, 0.4)
          //   )}, url(${image})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox mt={{ xs: -20, lg: -18 }} px={1} width="calc(100% - 2rem)" mx="auto" sx={{backgroundColor: "white"}}>
        <Grid container spacing={1} justifyContent="center" sx={{ backgroundColor: "white" }}>
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} sx={{ backgroundColor: "white" }}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <DetailedFooter content={footerContent} />
    </PageLayout>
  );
}

// Setting default props for the CoverLayout
CoverLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
