import { useState, useEffect, useRef, useCallback } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";

import 'react-dropzone-uploader/dist/styles.css'
import {useDropzone} from 'react-dropzone';
import Papa from "papaparse"
import { useCookies } from "react-cookie";
import AMMDialog from "components/AMMDialog";
import SuccessDialog from "components/SuccessDialog";
import ErrorDialog from "components/ErrorDialog";



const BatchUploadSection = (props) => {

    const navigate = useNavigate()
    const user =  useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data"
        }
      }

    const { file, setFile, uploadEndpoint, showUploadButton } = props

    const [disableUploadButton, setDisableUploadButton] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)

    const [dialogParams, setDialogParams] = useState({
        title: "Confirmation",
        messageLine1: "Are you sure you would like to proceed?",
        messageLine2: "Note: Please check all inputs before proceeding",
        handleSubmit: ()=>{}
    })

    const [successDialogParams, setSuccessDialogParams] = useState({ messageLine1: ""})
    const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: ""})

    const handleConfirmation = ( {title, messageLine1, messageLine2, handleSubmit} ) => {
        setDialogParams({
            // open: true,
            title: title,
            messageLine1: messageLine1,
            messageLine2: messageLine2,
            handleSubmit: handleSubmit
        })

        setOpenDialog(true)
    }

    const invalidInputsErrorHandler = () => {
        setErrorDialogParams({
            messageLine1: "Error: Please check your inputs and try again"
        })
        setOpenDialog(false)
        setOpenErrorDialog(true)
    }

    const generalErrorHandler = ( messageLine1, messageLine2 ) => {
        setErrorDialogParams({
            messageLine1: messageLine1,
            messageLine2: messageLine2
        })
        setOpenDialog(false)
        setOpenErrorDialog(true)
    }

    const identifiedErrorHandler = (errData, handleSubmit)=>{
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: JSON.stringify(errData),
            handleSubmit: handleSubmit
        })
        setOpenErrorDialog(true)
    }

    const catchAllErrorHandler = () => {
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: ""
        })
        setOpenDialog(false)
        setOpenErrorDialog(true)
    }

    // const [file, setFile] = useState()
    const [jsonToPost, setJsonToPost] = useState()

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ['.xlsx']
        },
        maxFiles: 1,
        onDrop: acceptedFiles => {
            console.log('acceptedFiles are: ', acceptedFiles)
            setFile(acceptedFiles[0])
            // setFile(acceptedFiles.map( file => Object.assign(file)))
            console.log('file is: ', file)
            if (acceptedFiles.length > 0){
                const reader = new FileReader()
                let readFile
                reader.onload = async e => {
                    // console.log(e.target.result)
                    const csvFile = Papa.parse(e.target.result, {header: true })
                    const parsedData = csvFile?.data
                    console.log('parsed data is: ', parsedData)
                    setJsonToPost(parsedData)
                }
                reader.readAsText(acceptedFiles[0])

            }
        }
    });

    const [droppedFiles, setDroppedFiles] = useState()

    useEffect(()=>{

        const _droppedFiles = acceptedFiles.map(file => (
            <li key={file.path}>
              {file.path} - {file.size} bytes
            </li>
          ));
        
        setDroppedFiles(_droppedFiles)

    }, [file])
    
    // receives array of files that are done uploading when submit button is clicked
    // const handleSubmit = async () => {
    //     console.log("[BatchUploadSection] jsonToPost is: ", jsonToPost) 

    //     var dataToSend = []

    //     jsonToPost.map( item => {
    //         if (item.Id){
    //             dataToSend.push(item)
    //         }
    //     })

    //     console.log('[BatchUploadSection][handleSubmit] dataToSend is: ', dataToSend)
    //     const response = await axios.post(uploadEndpoint, dataToSend, axiosConfig)
    //     console.log('[BatchUploadSection][handleSubmit] response is: ', response)
    //     if (response.status === 200){
    //         alert("Batch Upload Success!")
    //         navigate("/fb/app/home")
    //     }
    
    // }

    const handleSubmit = async ()=>{
        setDisableUploadButton(true)
        if (file){
            const formData = new FormData();
            formData.append(file.path, file)
            try {
                const response = await axios.post(uploadEndpoint, formData, axiosConfig)
                console.log(response)
                if (response.status === 200){
                    setSuccessDialogParams({
                        messageLine1: "Batch Upload Ticket has been submitted for verification/approval",
                        handleSubmit: () => { navigate("/fb/app/home") }
                    })
                    setOpenDialog(false)
                    setOpenSuccessDialog(true)
                    // setFile(null)
                    // setDroppedFiles(null)
                }
                
                
            } catch (err){
                console.log(err)
                if (err.response.data) {
                    setDisableUploadButton(false)
                    if (err.response.status === 410){
                        identifiedErrorHandler("A concurrent session was detected.", ()=>{navigate("/fb/app/")})
                    } else if (err.response.status === 403){
                        navigate("/fb/app/403")
                    } else if (err.response.status === 401){
                        navigate("/fb/app/401")
                    } else {
                        identifiedErrorHandler(err.response.data, ()=>{})
                    }
                    
                } else {
                    setDisableUploadButton(false)
                    catchAllErrorHandler()
                }
                // setFile(null)
                // setDroppedFiles(null)
            }
        } else {
            setDisableUploadButton(false)
            generalErrorHandler("Error: Please upload a file and try again.")
        }
    }
    
    return (
        <PlusCollapse title="Upload Data" open={true}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={12}
                    >
                        <MDBox>
                            <div {...getRootProps({className: 'dropzone'})} style={{padding: "40px", borderWidth: "2px", borderRadius: "20px", borderStyle: "solid", borderColor: "#3788d8", marginBottom: "10px"}}>
                                <input {...getInputProps()} />
                                <p>Drag & drop the populated Excel xlsx template here, or click to select file</p>
                            </div>
                            <aside>
                                <h4>File:</h4>
                                <ul>{droppedFiles}</ul>
                            </aside>
                        </MDBox>
                        {showUploadButton ?
                        <MDButton 
                            onClick={()=>{
                                if (file){
                                    handleConfirmation({
                                        title: "Upload Confirmation",
                                        messageLine1: "This action will proceed to upload "+ file.path + " for verification/approval. Would you like to proceed?",
                                        messageLine2: "",
                                        handleSubmit: handleSubmit
                                    })
                                } else {
                                    generalErrorHandler("Error: Please upload a file and try again.")

                                }
                            }} 
                            color="info"
                            disabled={disableUploadButton}
                        >Upload File</MDButton>
                        : null}
                    </Grid>
                    <AMMDialog
                        open={openDialog}
                        title={dialogParams.title}
                        messageLine1={dialogParams.messageLine1}
                        messageLine2={dialogParams.messageLine2}
                        handleSubmit={dialogParams.handleSubmit}    
                        setOpenDialog={setOpenDialog}                
                    />
                    <SuccessDialog
                        open={openSuccessDialog}
                        messageLine1={successDialogParams.messageLine1}
                        handleSubmit={successDialogParams.handleSubmit}
                        setOpenDialog={setOpenSuccessDialog}                
                    />
                    <ErrorDialog
                        open={openErrorDialog}
                        messageLine1={errorDialogParams.messageLine1}
                        messageLine2={errorDialogParams.messageLine2}
                        setOpenDialog={setOpenErrorDialog}
                    />
                </Grid>
            </MDBox>
        </PlusCollapse>
    )
}

export default BatchUploadSection


BatchUploadSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    showUploadButton: true,
    title: "Building",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        handler: ()=>{}
    }

};