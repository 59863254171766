import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import SpaceBasicInformationSection from "page-sections/spaces/SpaceBasicInformationSection";
import SpaceSubmitArea from "page-sections/spaces/SpaceSubmitArea";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import { defaultInputState } from "page-sections/spaces/utils";
import TimelineItem from "examples/Timeline/TimelineItem";
import PlusCollapse from "components/PlusCollapse";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "page-sections/spaces/utils";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const ViewSpacePage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }


    const viewMode = true
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [auditData, setAuditData] = useState([])
    const [auditView, setAuditView] = useState(false)

    useEffect( ()=>{
        if (queryParams.get('auditView') === "true"){
            setAuditView(true)
        }
    },[])

    const [editButton, setEditButton] = useState({
        show: false,
        text: "Edit",
        color: "info",
        redirectToEditPage: true
    })

    const [deleteButton, setDeleteButton] = useState({
        show: false,
        text: "delete",
        color: "error"
    })

    useEffect( ()=>{
        if (user.roleClaims.includes("amm.managespace")){
            setEditButton({
                show: true,
                text: "Edit",
                color: "info",
                redirectToEditPage: true
            })

            setDeleteButton({
                show: true,
                text: "Delete",
                color: "error"
            })
        }

    },[])
    
    useEffect(()=>{
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewSpacePage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url = 
            globalPublicData.baseEndpoint 
            + "/space/getspacebyuuid/" 
            + idToQuery + "/true" 

            console.log('[ViewSpacPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            setInputs(res.data[0])
            setAuditData(res.data)
            console.log('[ViewSpacPage][fetchData] res.data is: ', res.data)
        }
        
        
        fetchData()
        
    },[])


    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            View Space
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        openCollapse={true}
                    />
                    <SpaceBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    {!auditView ? 
                    <MDBox p={2} mt={7}>
                        <SpaceAssetSubmitArea
                        inputs={inputs}
                            setInputErrorState={setInputErrorState}
                            createButton={{
                                show: false,
                                text: "Create Space",
                                color: "info"
                            }}
                            editButton={editButton}
                            deleteButton={deleteButton}
                            assignBackButton={defaultNoShowButtonAttributes}
                            updateTicketButton={defaultNoShowButtonAttributes}
                            updateBatchTicketButton={defaultNoShowButtonAttributes}
                            createEndpoint="/space/create"
                            updateEndpoint="/space/update"
                            deleteEndpoint="/space/delete"
                            editRedirectUrl="/fb/app/home/edit-space"
                            defaultInputErrorState={defaultInputErrorState}

                        />
                    </MDBox>
                    : null }         
                </Card>  
            </MDBox>
            <AuditTrailSection
                auditData={auditData}
                auditPath="/fb/app/home/view-space-audit"
            />
            {/* {!auditView ? 
            <MDBox my={3}>
                <Card sx={{ width: "100%" }}>
                    <PlusCollapse title="Audit Trail" open={true}>
                        <MDBox p={2}>
                            {auditData.map( (item, key) => {
                                const arrayLength = auditData.length
                                var _title = "Item modified by " + item.Modified_By
                                var _dateTime = new Date(item.Modified_DateTime).toLocaleString()
                                var _icon = <CreateIcon />
                                const _viewPath = "/dashboard/view-space-audit"
                                const _searchParams = "?id=" + item.id + "&auditView=true"
                                if (key === arrayLength - 1){
                                    _title = "Item created by " + item.Created_By
                                    _dateTime = new Date(item.Created_DateTime).toLocaleString()
                                    _icon = <AddIcon sx={{fontWeight: "bold"}} />
                                } 

                                if (key !== 0){

                                return(
                                    <TimelineItem
                                        key={key}
                                        color="dark"
                                        icon={_icon}
                                        title={_title}
                                        dateTime={_dateTime}
                                        viewPath={_viewPath}
                                        searchParams={_searchParams}
                                        lastItem={key === arrayLength - 1}
                                        item={item}
                                    />
                                )
                                }
                            })}
                        </MDBox>
                    </PlusCollapse>
                </Card>
            </MDBox>
            : null } */}
            <Footer />
        </DashboardLayout>
    )
}

export default ViewSpacePage


ViewSpacePage.defaultProps = {
    auditView: false,
    viewMode: false,
    disableIdField: false

};