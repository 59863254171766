/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { ExportToCsv } from "export-to-csv";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import MDButton from "components/MDButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUITabValue } from "redux/features/query/querySlice";
import { Menu, MenuItem } from "@mui/material";
import propertyGroupExcelHeaders from "page-sections/batchupload/utils/propertygroup/propertyGroupExcelHeaders";
import selectPropertyGroupExcelFields from "page-sections/batchupload/utils/propertygroup/selectPropertyGroupExcelFields";
import estateExcelHeaders from "page-sections/batchupload/utils/estate/estateExcelHeaders";
import selectEstateExcelFields from "page-sections/batchupload/utils/estate/selectEstateExcelFields";
import buildingExcelHeaders from "page-sections/batchupload/utils/building/buildingExcelHeaders";
import selectBuildingExcelFields from "page-sections/batchupload/utils/building/selectBuildingExcelFields";
import spaceExcelHeaders from "page-sections/batchupload/utils/space/spaceExcelHeaders";
import selectSpaceExcelFields from "page-sections/batchupload/utils/space/selectSpaceExcelFields";
import assetExcelHeaders from "page-sections/batchupload/utils/asset/assetExcelHeaders";
import selectAssetExcelFields from "page-sections/batchupload/utils/asset/selectAssetExcelFields";


function NoResultsSection(){
  const navigate = useNavigate()

  return(
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <MDTypography variant="h3" align="center" mt={5}>
          There are no results to display
        </MDTypography>
      </Grid>
      <Grid item xs={12} md={3}>
        <MDButton fullWidth color="info" onClick={() => { navigate("/fb/app/home/search") }}>
          Create a Search
        </MDButton>
      </Grid>
    </Grid>
  )
}


function QueryResultsHeader({ handleTableViewChange, tabValue, setTabValue, children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [exportButtonArray, setExportButtonArray] = useState(["Estates", "Property Groups", "Buildings", "Spaces", "Assets"])
  

  // const [tabValue, setTabValue] = useState("estates");

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const results = useSelector(state => state.query.results)
  const tableData = useSelector( state => state.query.tableData)
  const userInterface = useSelector(state => state.query.userInterface)

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleClick = (event) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false)
  };

  const baseCsvOptions = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    showTitle: false,
  };

  const handleExportEstate = () => {
    var fileName = "Estates_" + new Date().toISOString()
    var headers = estateExcelHeaders
    const options = {
      ...baseCsvOptions, 
      filename: fileName,
      useKeysAsHeaders: false,
      headers: headers
    }
    const csvExporter = new ExportToCsv(options)

    var _data = results.queriedEstates.map( item => {
      var _estate = selectEstateExcelFields(item)
      Object.keys(_estate).map( key => {
        if (_estate[key] === null){
          _estate[key] = ""
        } else if (_estate[key] === undefined){
          _estate[key] = ""
        }
      })

      return _estate
    })
    
    csvExporter.generateCsv(_data)
  }

  const handleExportPropertyGroup = () => {
    var fileName = "PropertyGroup_" + new Date().toISOString()
    var headers = propertyGroupExcelHeaders

    const options = {
      ...baseCsvOptions, 
      filename: fileName,
      useKeysAsHeaders: false,
      headers: headers
    }

    const csvExporter = new ExportToCsv(options)
    
    var _data = results.queriedProperties.map( item => {
      
      var _propertygroup = selectPropertyGroupExcelFields(item)

      Object.keys(_propertygroup).map( key => {
        if (_propertygroup[key] === null){
          _propertygroup[key] = ""
        } else if (_propertygroup[key] === undefined){
          _propertygroup[key] = ""
        }
      })

      return _propertygroup
    })
 
    csvExporter.generateCsv(_data)
    // csvExporter.generateCsv(results.queriedProperties)
  }

  const handleExportBuilding = () => {
    var fileName = "Building_" + new Date().toISOString()
    var headers = buildingExcelHeaders

    const options = {
      ...baseCsvOptions, 
      filename: fileName,
      useKeysAsHeaders: false,
      headers: headers
    }
    const csvExporter = new ExportToCsv(options)

    // var _data = results.queriedBuildings.map(selectBuildingExcelFields)
    var _data = results.queriedBuildings.map( item => {
      var _building = selectBuildingExcelFields(item)

      Object.keys(_building).map( key => {
        if (_building[key] === null){
          _building[key] = ""
        } else if (_building[key] === undefined){
          _building[key] = ""
        }
      })
      // var _FEMS_Building_System_Code = _building.FEMS_Building_System_Code
      // var _Number_Of_Floors = _building.Number_Of_Floors
      // var _Gross_Floor_area = _building._Gross_Floor_area
      // if (_building.FEMS_Building_System_Code === null){
      //   _FEMS_Building_System_Code = ""
      // }

      // if (_building.Number_Of_Floors === null){
      //   _Number_Of_Floors = ""
      // }

      // if (_building.Gross_Floor_Area === null){
      //   _Gross_Floor_area = ""
      // }

      // _building.FEMS_BuildingSystem_Code = _FEMS_Building_System_Code
      // _building.Number_Of_Floors = _Number_Of_Floors
      // _building.Gross_Floor_Area = _Gross_Floor_area

      return _building

    })
    csvExporter.generateCsv(_data)
  }

  const handleExportSpace = () => {
    var fileName = "Space_" + new Date().toISOString()
    var headers = spaceExcelHeaders

    const options = {
      ...baseCsvOptions, 
      filename: fileName,
      useKeysAsHeaders: false,
      headers: headers
    }
    const csvExporter = new ExportToCsv(options)

    var _data = results.queriedBuildingSpaces.map(selectSpaceExcelFields)
    csvExporter.generateCsv(_data)
  }


  const handleExportAsset = () => {
    var fileName = "Assets_" + new Date().toISOString()
    var headers = assetExcelHeaders

    const options = {
      ...baseCsvOptions, 
      filename: fileName,
      useKeysAsHeaders: false,
      headers: headers
    }
    const csvExporter = new ExportToCsv(options)

    console.log('results.queredAssets is: ', results.queriedAssets)

    var _data = results.queriedAssets.map(
      item => {
        var _asset = selectAssetExcelFields(item)
        if (Array.isArray(_asset.Space_Ids)){
          console.log('_asset is: ', _asset)
        }
        

        Object.keys(_asset).map( key => {
          if (_asset[key] === null){
            _asset[key] = ""
          } else if (_asset[key] === undefined){
            _asset[key] = ""
          }
        })

        // var _FEMS_Asset_System_Code = _asset.FEMS_Asset_System_Code
        // if (_asset.FEMS_Asset_System_Code === null){
        //   _FEMS_Asset_System_Code = ""
        // }

        var _space_ids = ""

        if (_asset.Space_Ids){
        _asset.Space_Ids.map( (item, i, row) => {
          _space_ids += item 
          if (i+1 !== row.length){
            _space_ids += "|"
          } 
        })
      }
        _asset.Space_Ids = _space_ids
        // _asset.FEMS_Asset_System_Code = _FEMS_Asset_System_Code
        return _asset
      })
    csvExporter.generateCsv(_data)
  }
  
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    // setTabValue(newValue)
    dispatch(updateUITabValue(newValue))
    handleTableViewChange(newValue)
  };

  

  const [resultsHeader, setResultsHeader] = useState(<NoResultsSection />)

  useEffect( () => {
    if (results.resultsFetched && !results.resultsEmpty){
      setExportButtonArray(userInterface.tabOptions.map( item => item.label))

      setResultsHeader(
        <Grid container spacing={3} alignItems="center">
          <Grid item mt={2} xs={12} md={8} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={userInterface.tabValue} onChange={handleSetTabValue}>
                {userInterface.tabOptions.map( (item, key) => {

                  return(
                    <Tab key={item.value} label={item.label} value={item.value} />
                  )

                })}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      ) 
    } else{
      setResultsHeader(<NoResultsSection />)
    }

  },[results, userInterface])

  return (
    <MDBox position="relative" my={5}>
      <Card
        sx={{
          position: "relative",
          // mt: -8,
          // mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={10}>
            <MDBox display="flex">
              <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                Search Results
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2}>
          <MDButton
            id="basic-button"
            aria-controls={openMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClick}
            color={"info"}
            sx={{ mt: 2, mb: 1, ml: 3}}
          >
            Export CSV
          </MDButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {exportButtonArray.includes("Estates") ? <MenuItem key="exportestate" onClick={handleExportEstate}>Estates</MenuItem> : null}
            {exportButtonArray.includes("Property Groups") ? <MenuItem key="exportpropertygroup" onClick={handleExportPropertyGroup}>Property Groups</MenuItem> : null}
            {exportButtonArray.includes("Buildings") ? <MenuItem key="exportbuilding" onClick={handleExportBuilding}>Buildings</MenuItem> : null}
            {exportButtonArray.includes("Spaces") ? <MenuItem key="exportspace" onClick={handleExportSpace}>Spaces</MenuItem> : null}
            {exportButtonArray.includes("Assets") ? <MenuItem key="exportasset" onClick={handleExportAsset}>Assets</MenuItem> : null}
          </Menu>
            {/* <MDButton color="info"sx={{ mt: 2, mb: 1, ml: 3}}>
              Export CSV
            </MDButton> */}
          </Grid>
        </Grid>
        {resultsHeader}
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
QueryResultsHeader.defaultProps = {
  children: "",
};

// Typechecking props for the Header
QueryResultsHeader.propTypes = {
  children: PropTypes.node,
};

export default QueryResultsHeader;
