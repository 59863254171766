import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import SpaceBasicInformationSection from "page-sections/spaces/SpaceBasicInformationSection";
import SpaceSubmitArea from "page-sections/spaces/SpaceSubmitArea";
import { defaultInputState } from "page-sections/spaces/utils";
import TimelineItem from "examples/Timeline/TimelineItem";
import PlusCollapse from "components/PlusCollapse";
import { defaultInputErrorState } from "page-sections/spaces/utils";

const ViewSpacePageAudit = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const viewMode = true
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    
    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            View Space (Audit)
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        openCollapse={true}
                    />
                    <SpaceBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewSpacePageAudit


ViewSpacePageAudit.defaultProps = {
    auditView: true,
    viewMode: false,
    disableIdField: false

};