import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import KeyIcon from '@mui/icons-material/Key';

import { ToDoList } from "../../page-sections/home/ToDoList";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import ChangePasswordSection from "page-sections/password/ChangePasswordSection";


const ChangePasswordPage = () => {
    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    return(
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <ChangePasswordSection />
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ChangePasswordPage