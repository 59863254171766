const defaultFontSize = "small"
const defaultFontWeight = "regular"


export const generateRowsData = (rawData) => {
    var itemArray = []
    rawData.map((item, key) => {
        
        var _estateNameId = item.Estate_Name +  " (" + item.Estate_Id + ")"
        var _propertyGroupNameId = item.Property_Group_Name +  " (" + item.Property_Group_Id + ")"
        var _buildingNameId = item.Building_Name +  " (" + item.Building_Id + ")"

        var tempItem = {
            spaceNameId: item.Name + " (" + item.Id + ")",
            spaceName: item.Name,
            spaceId: item,
            spaceDescription: item.Space_Description,
            spaceAbbreviation: item.Space_Abbreviation,
            propertyGroupNameId: _propertyGroupNameId,
            estateNameId: _estateNameId,
            buildingNameId: _buildingNameId,
            floor: item.Floor || "NA",
            spaceNumber: item.Space_Number || "NA",
            actions: item
        }
        itemArray.push(tempItem)
    })

    return itemArray
}

