/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

// @mui material components
import Icon from "@mui/material/Icon";
import DoneIcon from '@mui/icons-material/Done';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function StatusCell({ icon, color, status }) {
  const [renderedIcon, setRenderedIcon] = useState(<CachedIcon />)

  const renderedIconStyles = {
    fontWeight: "900",
    fontSize: "1rem"
  }
  useEffect( ()=>{
    if (status === "Completed" || status === "Refunded"){
      setRenderedIcon(<DoneIcon sx={renderedIconStyles}  />)
    } else if (status === "Rejected"){
      setRenderedIcon(<CloseIcon sx={renderedIconStyles}  />)
    } else {
      setRenderedIcon(<CachedIcon sx={renderedIconStyles} />)
    }
  },[])

  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mr={1}>
        <MDButton variant="contained" color={color} size="small" iconOnly circular>
          {renderedIcon}
        </MDButton>
      </MDBox>
      <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
        {status}
      </MDTypography>
    </MDBox>
  );
}

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusCell;
