
import ActionArea from "components/Table/ActionArea"
import ResultsIdLinkCell from "page-sections/queryBuilder/components/ResultsIdLinkCell"

export const columns = [
    {
        Header: "Id",
        accessor: "buildingId",
        Cell: ({ value: data }) => {
            var _id = data.Id
            return(
            <ResultsIdLinkCell id={_id} object={data}/> 
            )
          },
    },
    {
        Header: "External Facing Name",
        accessor: "externalFacingName",
    },
    {
        Header: "Building Short Name",
        accessor: "buildingShortName",
    },
    {
        Header: "JTC Address",
        accessor: "jtcAddress",
    },
    {
        Header: "Department",
        accessor: "department",
    },
    {
        Header: "FMC ID",
        accessor: "fmcId",
    },
    {
        Header: "Postal Code",
        accessor: "postalCode",
    },
    {
        Header: "Building Type",
        accessor: "buildingType",
    },
    {
        Header: "Estate Name",
        accessor: "estateName",
    },
    {
        Header: "Property Group Name",
        accessor: "propertyGroupName",
    },
    {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ value: data }) => {
            const viewPath = "/fb/app/home/view-building?id=" + data.id
            const editPath = "/fb/app/home/edit-building?id=" + data.id

            return(
                <ActionArea
                    viewPath={viewPath}
                    editPath={editPath}
                    deleteAPIPath="/building/delete"
                    data={data}
                />
            )
        }
    }
]