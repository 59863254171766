export const defaultInputState = {
        Name: "",
        Department_Id: "",
        FMC_Id: "",
        Gross_Floor_Area: 0,
        Property_Type: "",
        Estate_Id: "",
        Estate_Name: "",
    }

export const defaultInputErrorState = {
    Name: { state: false, message: "" },
    Department_Id: { state: false, message: "" },
    FMC_Id: { state: false, message: "" },
    // Gross_Floor_Area: { state: false, message: "" },
    // Property_Type: { state: false, message: "" },
    Estate_Id: { state: false, message: "" },
    Estate_Name: { state: false, message: "" },

}