import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React context
import {
    useMaterialUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
    } from "context";
    
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,

} from "redux/features/menuOptions/menuOptionsSlice";
import { prepareAllMenuOptions } from "utils/menuOptionsUtils";
import useCookies from "react-cookie/cjs/useCookies";
import { globalPublicData } from "globalPublicData";
import axios from "axios";
import StatisticsCard from "components/StatisticsCard";
import ApprovalIcon from '@mui/icons-material/Approval';
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/bg-jtc-landing-landscape.jpg";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";


// Images
import meetingRoom1 from "assets/images/meeting-room-1.jpg";
import hotdesk1 from "assets/images/hotdesk-1.jpg"
import auditorium1 from "assets/images/auditorium-1.jpg"
import lounge1 from "assets/images/lounge-1.jpg"
import BasicLayout from "layouts/BasicLayout";
import MDInput from "components/MDInput";
import { Autocomplete, InputLabel } from "@mui/material";
import { BorderColor } from "@mui/icons-material";
import MDButton from "components/MDButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";


const FBHomePage = () => {

    const [controller, dispatch] = useMaterialUIController();
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies()
    const user = useSelector(state => state.user)
    const userInterface = useSelector(state => state.userInterface)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const [inputs, setInputs] = useState({
        date: new Date().setDate(new Date().getDate() + 3),
        buildingName: "JTC Summit",
        facilityType: "Meeting Room"
    })

    const [minDate, setMinDate] = useState(new Date().setDate(new Date().getDate() + 3))

    const autoCompleteStyles = {
        backgroundColor: "#006CEB",
        borderColor: "#0055B8",
        // borderRadius: "50px",
        color: "#FFFFFF",
        "& input": {
            color: '#FFFFFF',
            fontWeight: '500'
        },
        "& .MuiInputBase-root": {
            color: '#FFFFFF',
            fontWeight: '500'
        },
        "& .MuiInputLabel-root": {
            color: '#FFFFFF',
            fontWeight: '500',
            fontSize: '1.2rem',
            lineHeight: '1.1',
            overflow: 'visible'
        },
        "& .MuiAutocomplete-endAdornment .MuiButtonBase-root": {
            color: '#FFFFFF',
            fontWeight: '700'
        }
    }

    const datePickerStyles = {
        backgroundColor: "#006CEB",
        borderColor: "#0055B8",
        height: '100%',
        // borderRadius: "50px",
        color: "#FFFFFF",
        "& input": {
            color: '#FFFFFF',
            fontWeight: '500',
            fontSize: '1.2rem',
        },
        "& .MuiInputBase-root": {
            color: '#FFFFFF',
            fontWeight: '500'
        },
        "& .MuiInputLabel-root": {
            color: '#FFFFFF',
            fontWeight: '500',
            fontSize: '1.2rem',
            lineHeight: '1.1',
            overflow: 'visible'
        },
        "& .MuiInputAdornment-root .MuiButtonBase-root": {
            color: '#FFFFFF',
            fontWeight: '700'
        }
    }


    const dispatchRedux = useDispatch()


    return(
        <>        
         <BasicLayout user>
            <MDBox 
                position="relative" 
                mb={5}
            >
                <MDBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="12.75rem"
                    // borderRadius="xl"
                    sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                    }}
                />
                <Card
                    sx={{
                    position: "relative",
                    mt: 0,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                    }}
                >
                    <Grid 
                        container
                        justifyContent={"center"}
                    >
                        <Grid item xs={12} md={10}>
                            <MDBox
                              position="relative"
                              borderRadius="xl"
                              mt={-10}
                              mx={2}
                              className="card-header"
                            >
                                <Card sx={{backgroundColor: "#0055B8"}}>
                                    <Grid 
                                        container 
                                        sx={{marginBottom: '3rem'}} 
                                        justifyContent={"center"} 
                                        alignItems={"center"} 
                                        spacing={2}
                                    >
                                        <Grid item xs={10}>
                                            <MDTypography variant="h2" color="white" align="center" sx={{marginTop: '1rem', marginBottom: '1rem', fontWeight: '500'}}>
                                            What would you  like to do today?
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={10} md={3}>
                                            <Autocomplete
                                                name="buildingName"
                                                placeholder="Select Building.."
                                                // value={inputs.Department_Id}
                                                options={["JTC Summit", "Fusionopolis", "Biopolis"]}
                                                renderInput={(params, key) => <MDInput {...params}                                                 
                                                sx={autoCompleteStyles}
                                                label="Select Building"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={3}>
                                            <Autocomplete
                                                name="facilityType"
                                                placeholder="Select Facility Type.."
                                                // value={inputs.Department_Id}
                                                options={["Meeting Room", "Hot Desk", "Auditorium", "Lounge"]}
                                                renderInput={(params, key) => <MDInput {...params}                                                 
                                                sx={autoCompleteStyles}
                                                label="Select Facility Type"
                                                />}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={3}>
                                            <DatePicker
                                                name="date"
                                                onChange={(newValue) =>{setInputs({...inputs, date: new Date(newValue)})}}
                                                value={inputs.date}
                                                renderInput={(params) => <MDInput {...params} fullWidth sx={datePickerStyles} />}
                                                // disabled={viewMode}
                                                minDate={minDate}
                                                
                                            />
                                        </Grid>
                                        <Grid item xs={5} md={2} xl={1}>
                                            <MDButton
                                                 color="white"
                                                 fullWidth
                                                //  size="large"
                                            >
                                                <MDTypography
                                                    color="info"
                                                    fontWeight="bold"
                                                    onClick={()=>{navigate("/fb/app/home/search-results")}}
                                                >
                                                    Search
                                                </MDTypography>
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                    
                                </Card>
                            </MDBox>
                        </Grid>
                    </Grid>
                    {/* <MDTypography variant="h2" align="center">
                        What would you  like to do today?
                    </MDTypography> */}
                    <MDBox p={2}>
                        <Grid container my={5} spacing={3}>
                            <Grid item xs={12} md={6} xl={3}>
                                <DefaultProjectCard
                                    image={meetingRoom1}
                                    label=""
                                    title="Meeting Room"
                                    description="As Uber works through a huge amount of internal management turmoil."
                                    action={{
                                    type: "internal",
                                    route: "/fb/app/home/view-facility",
                                    color: "info",
                                    label: "Make Booking",
                                    }}
                                    authors={[]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <DefaultProjectCard
                                        image={hotdesk1}
                                        label=""
                                        title="Hot Desk"
                                        description="As Uber works through a huge amount of internal management turmoil."
                                        action={{
                                        type: "internal",
                                        route: "/fb/app/home/view-facility",
                                        color: "info",
                                        label: "Make Booking",
                                        }}
                                        authors={[]}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <DefaultProjectCard
                                        image={auditorium1}
                                        label=""
                                        title="Auditorium"
                                        description="As Uber works through a huge amount of internal management turmoil."
                                        action={{
                                        type: "internal",
                                        route: "/fb/app/home/view-facility",
                                        color: "info",
                                        label: "Make Booking",
                                        }}
                                        authors={[]}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} xl={3}>
                                <DefaultProjectCard
                                        image={lounge1}
                                        label=""
                                        title="Lounge"
                                        description="As Uber works through a huge amount of internal management turmoil."
                                        action={{
                                        type: "internal",
                                        route: "/fb/app/home/view-facility",
                                        color: "info",
                                        label: "Make Booking",
                                        }}
                                        authors={[]}
                                    />
                            </Grid>
                        </Grid>
                    </MDBox>
                </Card>
            </MDBox>
        </BasicLayout>
        </>
    )
}

export default FBHomePage