import { configureStore, combineReducer, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'

import menuOptionsReducer from "../features/menuOptions/menuOptionsSlice"
import queryReducer from "../features/query/querySlice"
import dropdownOptionsReducer from "../features/dropdownOptions/dropdownOptionsSlice"
import userReducer from "../features/user/userSlice"
import userInterfaceReducer from 'redux/features/userInterface/userInterfaceSlice'
// import userInterfaceReducer from "../features/userInterface/userInterfaceSlice"
import { apiSlice } from "../features/api/apiSlice"
import persistReducer from 'redux-persist/es/persistReducer'
import persistStore from 'redux-persist/es/persistStore'
import thunk from 'redux-thunk'

const persistConfig = {
    key: "root",
    storage
}

const reducers = combineReducers({
    menuOptions: menuOptionsReducer, 
    query: queryReducer,
    dropdownOptions: dropdownOptionsReducer,
    user: userReducer,
    userInterface: userInterfaceReducer
    // [apiSlice.reducerPath]: apiSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    // reducer: {
    //     menuOptions: menuOptionsReducer, 
    //     query: queryReducer,
    //     dropdownOptions: dropdownOptionsReducer,
    //     user: userReducer,
    //     [apiSlice.reducerPath]: apiSlice.reducer,
    // },

    reducer: persistedReducer,
    // middleware: (getDefaultMiddleware) => {
    //     return getDefaultMiddleware().concat(apiSlice.middleware)
    // }
    middleware: [thunk]
})

export const persistor = persistStore(store)

