import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import EstateSection from "page-sections/estates/EstateSection"
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import EstateSubmitArea from "page-sections/estates/EstateSubmitArea";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "page-sections/estates/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";


const ViewEstatePage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const defaultInputState = {
        Type: "estate",
        Estate_Id: "",
        Estate_Name: "",
        Estate_Name_JMM: "",
        Estate_Geometry: "",
        FMC_Id: "",
        Created_By: "Test User",
        Modified_By: "Test User",
        Date_Created: new Date().toISOString(),
        Date_Modified: new Date().toISOString()
    }



    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState]= useState(defaultInputErrorState)
    const [auditData, setAuditData] = useState([])
    

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const [editButton, setEditButton] = useState({
        show: false,
        text: "Edit",
        color: "info",
        redirectToEditPage: true
    })

    const [deleteButton, setDeleteButton] = useState({
        show: false,
        text: "delete",
        color: "error"
    })

    

    useEffect( ()=>{
        if (user.roleClaims.includes("amm.manageestate")){
            setEditButton({
                show: true,
                text: "Edit",
                color: "info",
                redirectToEditPage: true
            })

            setDeleteButton({
                show: true,
                text: "Delete",
                color: "error"
            })
        }

    },[])

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewEstatePage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/estate/getestatebyuuid//"
                + idToQuery + "/true"

            console.log('[ViewEstatePage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            setInputs(res.data[0])
            setAuditData(res.data)
            console.log('[ViewEstatePage][fetchData] res.data is: ', res.data)
        }
        
        fetchData()
        
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <EstateSection 
                        title="View Estate"
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode
                        disableIdField
                    />
                    <MDBox p={2} mt={7}>
                    <EstatePGBuildingSubmitArea
                        inputs={inputs}
                        createButton={{
                            show: false,
                            text: "Create Estate",
                            color: "info"
                        }}
                        editButton={editButton}
                        deleteButton={deleteButton}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/estate/create"
                        updateEndpoint="/estate/update"
                        deleteEndpoint="/estate/delete"
                        editRedirectUrl="/fb/app/home/edit-estate"
                        defaultInputErrorState={defaultInputErrorState}
                    />
                    </MDBox>
                    {/* <EstateSubmitArea
                        inputs={inputs}
                        createButton={{
                            show: false,
                            text: "Create Estate",
                            color: "info"
                        }}
                        editButton={editButton}
                        deleteButton={deleteButton}
                    />                     */}
                </Card>  
            </MDBox>
            <AuditTrailSection
                auditData={auditData}
                auditPath="/fb/app/home/view-estate-audit"
            />
            <Footer />
        </DashboardLayout>
    )
}

export default ViewEstatePage


ViewEstatePage.defaultProps = {
    viewMode: false,
    disableIdField: false

};