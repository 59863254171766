import { useState, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const AssetCustomField = (props) => {

    const navigate = useNavigate()

    const { inputs, handleInputChange, setInputs, viewMode, createMode, editPage, disableInput, disableLabel} = props

    const [ customInputs, setCustomInputs] = useState({
        New_Asset_Attribute_Name: "",
        New_Asset_Attribute_Value: ""
    })

    const [customInputsArray, setCustomInputsArray] = useState([])

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    const handleCustomInputChange = (event) => {
        event.persist()
        setCustomInputs({ ...customInputs, [event.target.name]: event.target.value })
        console.log('[handleCustomInputChange] inputs are: ', customInputs)
    }

    const handleAddCustomField = (customField) => {
        console.log('customField recieved is: ', customField)
        var fieldNameWithUnderscore = customField.New_Asset_Attribute_Name.split(" ").join("_")
        var tempAdditionalInputsArray = customInputsArray
        tempAdditionalInputsArray.push(customField)
        setCustomInputsArray(tempAdditionalInputsArray)
        setInputs({
            ...inputs,
            [fieldNameWithUnderscore]: customField.New_Asset_Attribute_Value,
        })
        setCustomInputs({
            New_Asset_Attribute_Name: "",
            New_Asset_Attribute_Value: ""
        })
    }

    // const handleDeleteCustomField = (customField) => {
    //     console.log('[handleDeleteCustomField] customField recieved is: ', customField)
    //     var tempAdditionalInputsArray = additionalInputs
    //     var indexToDelete = tempAdditionalInputsArray.findIndex(item => { return item.New_Asset_Attribute_Name === customField.New_Asset_Attribute_Name })
    //     console.log('[handleDeleteCustomField] indexToDelete is: ', indexToDelete)
    //     tempAdditionalInputsArray.splice(indexToDelete, 1)
    //     var fieldNameWithUnderscore = customField.New_Asset_Attribute_Name.split(" ").join("_")
    //     var tempInputs = inputs
    //     delete tempInputs[fieldNameWithUnderscore]
    //     setInputs(tempInputs)
    //     setAdditionalInputs(tempAdditionalInputsArray)
    //     setAdditionalInputsSection(additionalInputs.map((item, key) => {
    //         return (
    //             <>
    //                 <CustomAttribute
    //                     key={key}
    //                     inputs={item}
    //                     handleInputChange={handleInputChange}
    //                     handleAddCustomField={handleAddCustomField}
    //                     handleDeleteCustomField={handleDeleteCustomField}
    //                     disableLabel={true}
    //                     disableInput={true}
    //                     createMode={false}
    //                 />
    //                 <br />
    //             </>
    //         )
    //     }))
    // }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    return (
        <>
            <Grid
                item
                xs={12}
                md={3}
            >
                <InputLabel sx={inputLabelStyles}>
                    <strong>Label </strong>
                </InputLabel>
                <br />
                <MDInput
                    variant="outlined"
                    name="New_Asset_Attribute_Name"
                    value={customInputs.New_Asset_Attribute_Name}
                    onChange={handleCustomInputChange}
                    fullWidth
                    disabled={disableLabel}
                />
                {createMode ? <FormHelperText>
                    New Attribute Name (eg, Wattage)
                </FormHelperText> : null}
            </Grid>
            <Grid
                item
                xs={12}
                md={5}
            >
                <InputLabel sx={inputLabelStyles}>
                    <strong>Value </strong>
                </InputLabel>
                <br />
                <MDInput
                    variant="outlined"
                    name="New_Asset_Attribute_Value"
                    value={customInputs.New_Asset_Attribute_Value}
                    onChange={handleCustomInputChange}
                    fullWidth
                    disabled={disableInput}
                />
                {createMode ? <FormHelperText>
                    New Attribute Value (eg, 60 Watts)
                </FormHelperText> : null}
            </Grid>
            <Grid item alignItems="center"
                justifyContent="center"
                xs={12} md={2}
            >
                {createMode ?
                    <MDButton
                        color="success"
                        variant="gradient"
                        size="large"
                        sx={{ marginTop: "20px" }}
                        onClick={() => { handleAddCustomField(customInputs)}}
                    >
                        <AddIcon />
                    </MDButton>
                    :
                    <MDButton
                        color="error"
                        variant="gradient"
                        size="large"
                        sx={{ marginTop: "20px"}}
                        // onClick={() => {
                        //     if (editPage) {
                        //         handleDeleteCustomField({ Asset_Attribute_Name: valueFieldName })
                        //     } else {
                        //         handleDeleteCustomField({ New_Asset_Attribute_Name: inputs.New_Asset_Attribute_Name, New_Asset_Attribute_Value: inputs.New_Asset_Attribute_Value })
                        //     }
                        // }}
                    >
                        <DeleteIcon />
                    </MDButton>
                }
            </Grid>
        </>
    )
}

export default AssetCustomField

AssetCustomField.defaultProps = {
    viewMode: false,
    disableIdField: false,
    openCollapse: true,
};