// @mui material components
import Card from "@mui/material/Card";
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

import MDBox from "components/MDBox";

import TimelineItem from "examples/Timeline/TimelineItem";
import PlusCollapse from "components/PlusCollapse";
import { useEffect } from "react";

const AuditTrailSection = (props) => {

    const { auditData, auditPath } = props

    return (
        <MDBox my={3}>
            <Card sx={{ width: "100%" }}>
                <PlusCollapse title="Audit Trail" open={true}>
                    <MDBox p={2}>
                        {auditData.map((item, key) => {
                            console.log('[auditData] item is: ', item)
                            console.log('[auditData] auditData.length is: ', auditData.length)
                            console.log('[auditData] key is: ', key)
                            const arrayLength = auditData.length
                            var _title = "Item modified by " + item.Modified_By
                            var _dateTime = new Date(item.Modified_DateTime).toLocaleString()
                            var _icon = <CreateIcon />
                            const _viewPath = auditPath
                            const _searchParams = "?id=" + item.id + "&auditView=true"
                            if (key === arrayLength - 1) {
                                _title = "Item created by " + item.Created_By
                                _dateTime = new Date(item.Created_DateTime).toLocaleString()
                                _icon = <AddIcon />
                            }
                            
                            // if (key !== 0 || arrayLength === 1) {
                                

                                return (
                                    <TimelineItem
                                        key={key}
                                        color="dark"
                                        icon={_icon}
                                        title={_title}
                                        dateTime={_dateTime}
                                        viewPath={_viewPath}
                                        searchParams={_searchParams}
                                        lastItem={key === arrayLength - 1}
                                        item={item}
                                    />
                                )
                            // }
                        })}
                    </MDBox>
                </PlusCollapse>
            </Card>
        </MDBox>

        
    )
}

export default AuditTrailSection


AuditTrailSection.defaultProps = {
    auditData: [],
    auditPath: "/dashboard"
};