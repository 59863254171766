import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";
import { updateFullDepartmentData } from "redux/features/dropdownOptions/dropdownOptionsSlice";
import { updateFMC } from "redux/features/dropdownOptions/dropdownOptionsSlice";
import { useCookies } from "react-cookie";
import { updateBuildingType } from "redux/features/dropdownOptions/dropdownOptionsSlice";
import { updatePdtBldgType } from "redux/features/dropdownOptions/dropdownOptionsSlice";


const BuildingDetailedInformationSection = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies()
    const user = useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }
      
    const dropdownOptions = useSelector(state => state.dropdownOptions)

    const { 
        inputs, 
        inputErrorState, 
        handleInputChange, 
        setInputs, 
        viewMode, 
        openCollapse,
    } = props

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    useEffect( ()=>{
        const fetchFMCDeptBuildingTypeData = async () => {
            console.log('[fetchFMCDeptData] function called')
            const fmcEndpoint = globalPublicData.baseEndpoint + "/facilitymanagement/getfacilityvendorlist"
            const deptEndpoint = globalPublicData.baseEndpoint + "/department/getdepartmentlist"
            const buildingTypeEndpoint = globalPublicData.baseEndpoint + "/systemtype/getammsystemtypebycategory/Building_Type"

            const fmcRes = await axios.get(fmcEndpoint, axiosConfig)
            const deptRes = await axios.get(deptEndpoint, axiosConfig)
            const buildingTypeRes = await axios.get(buildingTypeEndpoint, axiosConfig)

            dispatch(updateFMC(fmcRes.data))
            dispatch(updateFullDepartmentData(deptRes.data))
            dispatch(updateBuildingType(buildingTypeRes.data))
            dispatch(updatePdtBldgType(buildingTypeRes.data))
        }

        fetchFMCDeptBuildingTypeData()

    },[])

    const handleDepartmentChange = (event, value) => {
        var _Department = dropdownOptions.fullDepartmentData.find(item => {
            return item.Department_Id === value
        })
        setInputs({
            ...inputs,
            Department_uuid: _Department.id,
            Department_Id: value

        })
    }

    const handleFMCIdChange = (event, value) => {
        var _FMC = dropdownOptions.FMC.find(item => {
            return item.Id === value
        })
        setInputs({
            ...inputs,
            FMC_uuid: _FMC.id,
            FMC_Id: value,
            FMC_Company_Name: _FMC.Name,

        })
    }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    const handleBuildingTypeChange = (event, value) => {

        var item = dropdownOptions.Building_Type.find(item => {return item.Display_Name === value})
        setInputs({ 
            ...inputs, 
            Building_Type: value, 
            Building_Type_uuid: item.id
        })
    }


    return (
        <PlusCollapse title="Detailed Information" open={openCollapse}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Longtitude</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Longtitude"
                            value={inputs.Longtitude}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            type="number"
                        />
                        <FormHelperText>
                            Longtitude
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Latitude</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Latitude"
                            value={inputs.Latitude}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            type="number"
                        />
                        <FormHelperText>
                            Latitude
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Building Gross Floor Area (SQM)</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Gross_Floor_Area"
                            value={inputs.Gross_Floor_Area}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            type="number"
                        />
                        <FormHelperText>
                            Gross Floor Area of Building in Square Meters (SQM)
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Department</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Department_Id"
                            forcePopupIcon={!inputErrorState.Department_Id.state}
                            placeholder="Select Department.."
                            value={inputs.Department_Id}
                            options={dropdownOptions.department}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.Department_Id.state} />}
                            onChange={handleDepartmentChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.Department_Id.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Department_Id.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Building Department Categorisation
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Building FMC ID</strong>
                        </InputLabel>
                        <Autocomplete
                            name="FMC_Id"
                            forcePopupIcon={!inputErrorState.FMC_Id.state}
                            placeholder="Select FMC Id.."
                            value={inputs.FMC_Id}
                            options={dropdownOptions.FMC_Id}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.FMC_Id.state} />}
                            onChange={handleFMCIdChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.FMC_Id.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.FMC_Id.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                ID of FMC Managing the Building
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FEM Building System Code</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FEMS_Building_System_Code"
                            value={inputs.handleFEMSBuildingSystemCodeChange}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            type="number"
                        />
                        <FormHelperText>
                            FEMS Building System Code
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Number of Floors</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Number_Of_Floors"
                            value={inputs.Number_Of_Floors}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            type="number"
                        />
                        <FormHelperText>
                            Number of Floors in the Building
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Green Mark Award</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Green_Mark_Award"
                            value={inputs.Green_Mark_Award}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Green Mark Award
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel required>
                            <strong>Green Mark Certification Expiry Date</strong>
                        </InputLabel>
                        <br />
                        <DatePicker
                            name="Green_Mark_Certification_Expiry_Date"
                            value={inputs.Green_Mark_Certification_Expiry_Date}
                            onChange={
                                (newValue) => { handleDateTimeChange("Green_Mark_Certification_Expiry_Date", newValue) }
                            }
                            renderInput={(params) => <MDInput {...params} fullWidth />}
                            disabled={viewMode}
                            minDate={new Date()}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel required>
                            <strong>Periodic Structural Inspection Date</strong>
                        </InputLabel>
                        <br />
                        <DatePicker
                            name="Periodic_Structural_Inspection_Date"
                            value={inputs.Periodic_Structural_Inspection_Date}
                            onChange={
                                (newValue) => { handleDateTimeChange("Periodic_Structural_Inspection_Date", newValue) }
                            }
                            renderInput={(params) => <MDInput {...params} fullWidth />}
                            disabled={viewMode}
                            minDate={new Date()}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Building Type</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Building_Type"
                            placeholder="Select Building Type..."
                            value={inputs.Building_Type}
                            options={dropdownOptions.pdt_bldg_bldgtypelvl2}
                            renderInput={(params, key) => <MDInput {...params} />}
                            onChange={handleBuildingTypeChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="Building_Type"
                            value={inputs.Building_Type}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        /> */}
                        <FormHelperText>
                            Building Type
                        </FormHelperText>
                    </Grid>

                </Grid>
                
            </MDBox>
        </PlusCollapse>
    )
}

export default BuildingDetailedInformationSection

BuildingDetailedInformationSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    openCollapse: true,

};