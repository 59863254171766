/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "./components/IdCell";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import CustomerCell from "./components/CustomerCell";
import IdLinkCell from "./components/IdLinkCell";

export const ticketColumns = [
  { Header: "id", 
    accessor: "id", 
    Cell: ({ value: data }) => {
      var _id = "#" + data.id.slice(-5)
      return(
      <IdLinkCell id={_id} object={data}/> 
      )
    },
    width: "10%"

  },
  {
    Header: "operation",
    accessor: "operation",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "object type",
    accessor: "objectType",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "DEPT",
    accessor: "department",
    Cell: ({ value }) => <DefaultCell value={value} />,
    width: "8%"
  },
  {
    Header: "Create Date",
    accessor: "createDate",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Last Modified Date",
    accessor: "lastModifiedDate",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Requester",
    accessor: "requester",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      if (value === "Approved") {
        status = <StatusCell color="success" status="Approved" />;
      } else if (value === "Rejected"){
        status = <StatusCell color="light" status="Rejected" />;
      } else if (value === "Cancelled"){
        status = <StatusCell color="light" status="Cancelled" />;
      } else if (value === "PendingHODApproval"){
        status = <StatusCell color="info" status="Pending HOD Approval" />
      } else if (value === "PendingBSDApproval"){
        status = <StatusCell color="info" status="Pending BSD Review" />
      } else if (value === "PendingFmOpsVerification"){
        status = <StatusCell color="info" status="Pending FM Ops Verification" />
      } else if (value === "Draft"){
        status = <StatusCell color="warning" status="Routed Back" />
      } else {
        status = <StatusCell color="warning" status="In Progress" />
      }

      return status;
    },
  },
  // {
  //   Header: "action",
  //   accessor: "action",
  //   Cell: ({ value }) => {
  //     let status;

  //     if (value === "Approved") {
  //       status = <StatusCell color="success" status="Approved" />;
  //     } 
  //     else {
  //       status = <StatusCell color="warning" status="In Progress" />
  //     }

  //     return status;
  //   },
  // },
]

export const prepareTicketRows = (data) => {
  
  var ticketRows = []
  for (let i=0;i<data.length;i++){
    var _item = data[i]
    var _createDate = new Date(_item.Created_DateTime).toLocaleDateString()
    var _lastModifiedDate = ""
    if (_item.Modified_DateTime === ""){
      _lastModifiedDate = _createDate
    } else {
      _lastModifiedDate = new Date(_item.Modified_DateTime).toLocaleDateString()
    }

    var _department = "NA"
    if (_item.Object_Data){_department = _item.Object_Data.Department_Id || "NA"}
    
    var ticketRow = {
      id: _item,
      createDate: _createDate,
      lastModifiedDate: _lastModifiedDate,
      status: _item.Status,
      operation: _item.Ticket_Action_Type,
      objectType: _item.Object_Type,
      department: _department,
      requester: _item.Requester_Name
    }

    ticketRows.push(ticketRow)

  }

  console.log("[prepareTicketRows] ticketRows are: ", ticketRows)
  return ticketRows



}

