import { useState, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";

import AssetCustomField from "./AssetCustomField";
import { provisionedFields } from "./utils";
import AssetCustomFieldRead from "./AssetCustomFieldRead";

const AssetCustomSection = (props) => {

    const navigate = useNavigate()

    const { inputs, handleInputChange, setInputs, viewMode, editMode, openCollapse, createMode, editPage, allowDelete, disableInput, disableLabel} = props

    const [filteredDataKeys, setFilteredDataKeys] = useState([])

    useEffect( ()=>{
        const dataKeys = Object.keys(inputs)
        const duplicateFields = new Set(provisionedFields)
        const _filteredDataKeys = dataKeys.filter(item => { return !duplicateFields.has(item) })
        setFilteredDataKeys(_filteredDataKeys)
    },[inputs])

    let allowEdit
    useEffect(()=>{
        if (editPage){
            allowEdit = true
        } else {
            allowEdit = false
        }
    },[])
    

    return (
        <PlusCollapse title="Custom Asset Information" open={openCollapse}>
            <MDBox p={2} mt={3}>
                    {filteredDataKeys.map( (dataKey) => {
                        return(
                            <AssetCustomFieldRead 
                                key={dataKey}
                                inputs={inputs}
                                handleInputChange={handleInputChange}
                                setInputs={setInputs}
                                viewMode={!editMode}
                                filteredDataKeys={filteredDataKeys}
                                setFilteredDataKeys={setFilteredDataKeys}
                                allowDelete={allowDelete}
                                fieldData={{
                                    key: dataKey,
                                    label: dataKey,
                                    value: inputs[dataKey]
                                }}
                            />
                        )
                    })}
                {createMode || editMode ? 
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <AssetCustomField
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableLabel={false}
                        createMode
                    />
                </Grid>
                : null}
            </MDBox>
        </PlusCollapse>
    )
}

export default AssetCustomSection

AssetCustomSection.defaultProps = {
    viewMode: false,
    editMode: false,
    openCollapse: true,
    createMode: false

};