// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";


const AssetOtherInformationSection = (props) => {

    const navigate = useNavigate()

    const { inputs, inputErrorState,handleInputChange, setInputs, viewMode, openCollapse} = props

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    return (
        <PlusCollapse title="Other Information" open={openCollapse}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FEMS Date Created</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FEMS_Date_Created"
                            value={inputs.FEMS_Date_Created}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Date Created in FEMS
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FEMS Date Modified</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FEMS_Date_Modified"
                            value={inputs.FEMS_Date_Modified}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FEMS Date Modified
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>AIR Template</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="AIR_Template"
                            value={inputs.AIR_Template}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            AIR Template
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>System Asset Id</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="System_Asset_Id"
                            value={inputs.System_Asset_Id}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            System Asset Id
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>FEMS Asset System Code</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="FEMS_Asset_System_Code"
                            value={inputs.FEMS_Asset_System_Code}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            FEMS Asset System Code
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>BIM GUID</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="BIM_GUID"
                            value={inputs.BIM_GUID}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            BIM GUID
                        </FormHelperText>
                    </Grid>
                </Grid>
            </MDBox>
        </PlusCollapse>
    )
}

export default AssetOtherInformationSection

AssetOtherInformationSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    openCollapse: true,

};