import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";


import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";



import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import BuildingBasicInformationSection from "page-sections/building/BuildingBasicInformationSection";
import BuildingDetailedInformationSection from "page-sections/building/BuildingDetailedInformationSection";
import BuildingSubmitArea from "page-sections/building/BuildingSubmitArea";
import FMCContactInformationSection from "page-sections/contactInformationForms/FMCContactInformationSection";
import JTCContactInformationSection from "page-sections/contactInformationForms/JTCContactInformationSection";

import { defaultInputState } from "page-sections/building/utils";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";

const ApproveBuildingPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const viewMode = true
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [auditData, setAuditData] = useState([])

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ReviewBuildingPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/building/getbuildingbyuuid//"
                + idToQuery + "/true"

            console.log('[ReviewBuildingPage][fetchData] url is: ', url)
            const res = await axios.get(url)
            setInputs(res.data[0])
            setAuditData(res.data)
            console.log('[ReviewBuildingPage][fetchData] res.data is: ', res.data)
        }
        
        fetchData()
        
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Approve Building Request
                        </MDTypography>
                    </MDBox>

                    <BuildingBasicInformationSection
                        inputs={inputs} 
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    <HierarchyFormInformation
                        inputs={inputs}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        openCollapse={false}
                    />
                    <BuildingDetailedInformationSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={false}
                    />
                    {/* <FMCContactInformationSection 
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    />
                    <JTCContactInformationSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    /> */}
                    <BuildingSubmitArea
                        inputs={inputs}
                        createButton={{
                            show: false,
                            text: "Create Building",
                            color: "info"
                        }}
                        editButton={{
                            show: false,
                            text: "Edit Building",
                            color: "warning",
                            redirectToEditPage: true
                        }}
                        deleteButton={{
                            show: false,
                            text: "Delete Building",
                            color: "error"
                        }}
                        submitRequestButton={{
                            show: false,
                            text: "Submit Request",
                            color: "info"
                        }}
                        sendToBSDButtonCreate={{
                            show: false,
                            text: "Submit for Verification",
                            color: "info"
                        }}
                        returnToFMOpsCreate={{
                            show: true,
                            text: "Return for Amendments",
                            color: "warning"
                        }}
                        approveButtonCreate={{
                            show: true,
                            text: "Approve Request",
                            color: "info"
                        }}
                    />                 
                </Card>  
            </MDBox>
            {/* <AuditTrailSection
                auditData={auditData}
                auditPath="/dashboard/view-building-audit"
            /> */}
            <Footer />
        </DashboardLayout>
    )
}

export default ApproveBuildingPage


ApproveBuildingPage.defaultProps = {
    viewMode: true,
    disableIdField: true

};