import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import EstateSection from "page-sections/estates/EstateSection"
import EstateSubmitArea from "page-sections/estates/EstateSubmitArea";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "page-sections/estates/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";


const EditEstatePage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()

    const user = useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    useEffect(()=>{
        if (!user.roleClaims.includes("amm.manageestate")){ navigate("/fb/app/403")}
    },[])

    const defaultInputState = {
        Type: "estate",
        Estate_Id: "",
        Estate_Name: "",
        Estate_Name_JMM: "",
        Estate_Geometry: "",
        FMC_Id: "",
        Created_By: "Test User",
        Modified_By: "Test User",
        Date_Created: new Date().toISOString(),
        Date_Modified: new Date().toISOString()
    }



    const [inputs, setInputs] = useState(location.state || defaultInputState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <EstateSection 
                        title="Edit Estate"
                        inputs={inputs} 
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        inputErrorState={inputErrorState}
                        disableIdField
                    />
                    <MDBox p={2} mt={7}>
                    <EstatePGBuildingSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Estate",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Estate",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Estate",
                            color: "error"
                        }}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/estate/create"
                        updateEndpoint="/estate/update"
                        deleteEndpoint="/estate/delete"
                        editRedirectUrl="/fb/app/home/edit-estate"
                        defaultInputErrorState={defaultInputErrorState}

                    />
                    </MDBox>
                    {/* <EstateSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Estate",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Estate",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Estate",
                            color: "error"
                        }}
                    />           */}
                              
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default EditEstatePage
