export const globalPublicData = {
    // baseEndpoint: "https://localhost:5000"
    // baseEndpoint: "https://as-fem-amm-dev-002.azurewebsites.net/amm/api-ext-v1"
    // baseEndpoint: "https://as-fem-amm-internet-001-dev.azurewebsites.net"
    baseEndpoint: "https://fem.jtcqas.gov.sg/amm/api"
    // baseEndpoint: "https://asv-femamm-siteapp-ext-001.azurewebsites.net/amm/api"
    // baseEndpoint: "https://as-fem-amm-dev-001.azurewebsites.net/amm/api",
    // FOR DEV ////////////
    // baseEndpoint: "https://localhost:5000",
    // baseEndpoint: "http://localhost:5001",
}

export const NAOption = {
    Name: "NA", Id: "NA",
    Property_Group_Name: "NA", Property_Group_Id: "NA",
    Property_Name: "NA", Property_Id: "NA",
    Space_Name: "NA", Space_Id: "NA"
}

export const allOption = {
    Name: "All", Id: "All",
    Estate_Name: "All", Estate_Id: "All",
    Property_Group_Name: "All", Property_Group_Id: "All",
    Property_Name: "All", Property_Id: "All",
    Space_Name: "All", Space_Id: "All"
}
