
import ActionArea from "components/Table/ActionArea"
import ResultsIdLinkCell from "page-sections/queryBuilder/components/ResultsIdLinkCell"

export const columns = [
    {
        Header: "Estate Id",
        accessor: "estateId",
        Cell: ({ value: data }) => {
            var _id = data.Id
            return(
            <ResultsIdLinkCell id={_id} object={data}/> 
            )
          },
    },
    {
        Header: "Estate Name",
        accessor: "estateName",
        // width: "30%",
        // Cell: ({ value: data }) => {

        //     return (
        //     <MultiLineCell
        //         data = { data }
        //         // key={key + "spaceNameId"}
        //         path={viewPath}
        //     />
        // )} 
    },
    {
        Header: "Department",
        accessor: "estateDepartment",
    },
    {
        Header: "Estate FMC ID",
        accessor: "estateFmcId",
    },
    {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ value: data }) => {

            const viewPath = "/fb/app/home/view-estate?id=" + data.id
            const editPath = "/fb/app/home/edit-estate?id=" + data.id

            return(
                <ActionArea
                    viewPath={viewPath}
                    editPath={editPath}
                    deleteAPIPath="/estate/delete"
                    data={data}
                />
            )
        }
    }
]