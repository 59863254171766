/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import BasicLayout from "layouts/BasicLayout";
import DefaultPageHeader from "page-sections/DefaultPageHeader";
import MDButton from "components/MDButton";
import AMMDialog from "components/AMMDialog";
import SuccessDialog from "components/SuccessDialog";
import { useNavigate } from "react-router-dom";

function CreateBookingPage() {

  const navigate = useNavigate()

  const defaultInputState = {
    facilityName: "Peace Haven Lounge",
    bookingDate: new Date(),
    bookingTimeStart: new Date(),
    bookingTimeEnd: new Date(),
    remarks: ""
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)

  const [dialogParams, setDialogParams] = useState({
      title: "Confirmation",
      messageLine1: "Are you sure you would like to proceed?",
      messageLine2: "Note: Please check all inputs before proceeding",
      handleSubmit: ()=>{}
  })

  const [successDialogParams, setSuccessDialogParams] = useState({ messageLine1: ""})

  const handleConfirmation = ( {title, messageLine1, messageLine2, handleSubmit} ) => {
    setDialogParams({
        // open: true,
        title: title,
        messageLine1: messageLine1,
        messageLine2: messageLine2,
        handleSubmit: handleSubmit
    })

    setOpenDialog(true)
}

const handleSuccess = () => {
  setSuccessDialogParams({
    messageLine1: "Facility Booking has been successfully completed",
    handleSubmit: () => { navigate("/fb/app/home/view-bookings") }
    })
    setOpenDialog(false)
    setOpenSuccessDialog(true)
}


  const [inputs, setInputs] = useState(defaultInputState)

  const inputLabelStyles = {
    fontWeight: "700px",
    color: "black",
    marginBottom: "10px"
  }

  const handleBookingDateChange = (value) => {
    setInputs({...inputs, bookingDate: new Date(value)})
  }

  const handleSubmit = ()=>{
    handleConfirmation({
      title: "Booking Confirmation",
      messageLine1: "This action will proceed to submit a booking request. Would you like to proceed?",
      messageLine2: "",
      handleSubmit: handleSuccess
  })
  }

  return (
    <BasicLayout>
      <DefaultPageHeader title="Create Booking"/>
      <MDBox>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3} my={3}>
            <Grid container alignItems="center" justifyContent={"center"} spacing={3}>
            <Grid item xs={12} md={7} sx={{ height: "max-content" }}>
                  <InputLabel sx={inputLabelStyles} required>
                      <strong>Facility Name</strong>
                  </InputLabel>
                  <MDInput
                      variant="outlined"
                      name="facilityName"
                      value={inputs.facilityName}
                      // onChange={handleInputChange}
                      fullWidth
                      disabled
                  />
              </Grid>
              <Grid item xs={12} md={7} sx={{ height: "max-content" }}>
                  <InputLabel sx={inputLabelStyles} required>
                      <strong>Date of Booking</strong>
                  </InputLabel>
                  <DatePicker
                    name="bookingDate"
                    value={inputs.bookingDate}
                    onChange={handleBookingDateChange}
                    renderInput={(params) => <MDInput {...params} fullWidth />}
                    disablePast
                  />
              </Grid>
              <Grid item xs={12} md={7} sx={{ height: "max-content" }}>
                  <InputLabel sx={inputLabelStyles} required>
                      <strong>Booking Start Time</strong>
                  </InputLabel>
                  <TimePicker
                    name="bookingTimeStart"
                    value={inputs.bookingTimeStart}
                    onChange={(newValue) =>{setInputs({...inputs, bookingTimeStart: new Date(newValue)})}}
                    renderInput={(params) => <MDInput {...params} fullWidth />}
                  />
              </Grid>
              <Grid item xs={12} md={7} sx={{ height: "max-content" }}>
                  <InputLabel sx={inputLabelStyles} required>
                      <strong>Booking End Time</strong>
                  </InputLabel>
                  <TimePicker
                    name="bookingTimeEnd"
                    value={inputs.bookingTimeEnd}
                    onChange={(newValue) =>{setInputs({...inputs, bookingTimeEnd: new Date(newValue)})}}
                    renderInput={(params) => <MDInput {...params} fullWidth />}
                  />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent={"center"} spacing={3} mt={5}>
              <Grid item xs={12} md={3}>
                <MDButton fullWidth color="info" onClick={handleSubmit}>
                  Proceed to Checkout
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <AMMDialog
          open={openDialog}
          title={dialogParams.title}
          messageLine1={dialogParams.messageLine1}
          messageLine2={dialogParams.messageLine2}
          handleSubmit={dialogParams.handleSubmit}    
          setOpenDialog={setOpenDialog}                
      />
      <SuccessDialog
          open={openSuccessDialog}
          messageLine1={successDialogParams.messageLine1}
          handleSubmit={successDialogParams.handleSubmit}
          setOpenDialog={setOpenSuccessDialog}                
      />
    </BasicLayout>
  );
}

export default CreateBookingPage;
