const defaultFontSize = "small"
const defaultFontWeight = "regular"


export const generateRowsData = (rawData) => {
    var itemArray = []
    rawData.map( (item, key) => {
        var tempItem = {
            buildingId: item,
            buildingName: item.Name,
            externalFacingName: item.External_Facing_Name || "NA",
            jtcAddress: item.JTC_Address,
            department: item.Department_Id || item.Department || "NA",
            fmcId: item.FMC_Id || "NA",
            postalCode: item.Postal_Code || "NA",
            buildingType: item.Building_Type || "NA",
            buildingShortName: item.Building_Short_Name || "NA",
            estateName: item.Estate_Name + " (" + item.Estate_Id + ")",
            propertyGroupName: item.Property_Group_Name + " (" + item.Property_Group_Id + ")",
            actions: item
        }    

        itemArray.push(tempItem)
    })

    return itemArray
}

