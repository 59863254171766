import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from 'components/MDInput';

import { 
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,

} from "../../redux/features/menuOptions/menuOptionsSlice";

import { NAOption, allOption } from '../../globalPublicData';

const BuildingAutocomplete = (props) => {

    const {
        name,
        fetchingData,
        inputs,
        setInputs,
        variant,
        hideAllOption,
        hideNAOption
    } = props

    const dispatch = useDispatch()

    const fullPropertyGroupOptions = useSelector( state => state.menuOptions.fullPropertyGroupOptions)
    const fullBuildingOptions = useSelector( state => state.menuOptions.fullBuildingOptions)
    const fullSpaceOptions = useSelector( state => state.menuOptions.fullSpaceOptions)
    const filteredOptions = useSelector( state => state.menuOptions.filteredBuildingOptions)

    const hideAllOptionFilter = options => options.filter( opt => opt.Name !== "All")

    const hideNAOptionFilter = options => options.filter( opt => opt.Name !== "NA")

    let filterFunction

    if (hideAllOption){
        filterFunction = hideAllOptionFilter
    } else if (hideNAOption) {
        filterFunction = hideNAOptionFilter
    } else {
        filterFunction = options => options.filter( opt => opt)
    }

    return(
        <>
            <Autocomplete
                multiple
                name={name}
                disabled={fetchingData}
                value={inputs.buildings}
                options={filteredOptions}
                getOptionLabel={option => {
                    if (option.Property_Name === "" || option.Property_Id === "") {
                        return ""
                    } else {
                        return option.Property_Name + " (" + option.Property_Id + ")"
                    }
                }}

                filterOptions={filterFunction}
                renderInput={(params) => <MDInput {...params} key={params.id} variant={variant} />}
                onChange={(event, value) => {
                    var tempIds = []
                    value.map(item => {
                        tempIds.push(item.Id)
                    })

                    setInputs({
                        ...inputs,
                        ["building_ids"]: tempIds,
                        ["buildings"]: value
                    })

                    // var dataToAppend = {
                    //     ["property"]: { Name: value.Name, Id: value.Id },
                    //     ["propertyId"]: value.Id,
                    //     ["Property_Name"]: value.Name,
                    //     ["Property_Id"]: value.Id, 
                    // }

                    // var additionalDataCriteria = true
                    // if (hideAllOption) {
                    //     additionalDataCriteria = (value.Id !== "NA" && value.Name !== "NA") ? true : false

                    // } else if (hideNAOption) {
                    //     additionalDataCriteria = (value.Id !== "All" && value.Name !== "All") ? true : false
                    // }

                    // if (additionalDataCriteria) {
                    //     dataToAppend = {
                    //         ...dataToAppend,
                    //         ["estate"]: { Name: value.Estate_Name, Id: value.Estate_Id },
                    //         ["estateId"]: value.Estate_Id,
                    //         ["Estate_Name"]: value.Estate_Name,
                    //         ["Estate_Id"]: value.Estate_Id,
                    //         ["propertyGroup"]: { Name: value.Property_Group_Name, Id: value.Property_Group_Id },
                    //         ["propertyGroupId"]: value.Property_Group_Id,
                    //         ["Property_Group_Name"]: value.Property_Group_Name,
                    //         ["Property_Group_Id"]: value.Property_Group_Id, 
                    //     }
                    // } 

                    // setInputs({
                    //     ...inputs,
                    //     ...dataToAppend
                    // })

                    // if (value.id !== "All") {

                    //     var _filteredSpaceOptions = fullSpaceOptions.filter( item => {
                    //         return item.Property_Id == value.Id
                    //     })

                    //     // _filteredSpaceOptions.push(NAOption)
                    //     // _filteredSpaceOptions.push(allOption)
                        
                    //     dispatch(updateFilteredSpaceMenuOptions(_filteredSpaceOptions))
                    // } 
                }}
            />
        </>
    )
}

export default BuildingAutocomplete

BuildingAutocomplete.defaultProps = {
    fetchingData: false,
    name: "property",
    variant: "standard",
    hideAllOption: false,
    hideNAOption: true
  };