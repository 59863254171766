/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

function BasicLayout({ image, children, user }) {

  return (
    <PageLayout user={user}>
        <Grid 
          container 
          spacing={1} 
          justifyContent="center" 
          alignItems="center" 
          // sx={{margin: "auto"}}
        >
          <Grid 
            item 
            xs={12} 
          >
            {children}
          </Grid>
        </Grid>
    </PageLayout>
  );
}

BasicLayout.defaultProps = {
  user: false
}
// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
