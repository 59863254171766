import KeyIcon from '@mui/icons-material/Key';
import { Card, FormHelperText, Grid, InputLabel } from '@mui/material';
import axios from 'axios';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { globalPublicData } from 'globalPublicData';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { defaultInputErrorState, defaultInputState } from './utils';

import CircleIcon from '@mui/icons-material/Circle';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SuccessDialog from 'components/SuccessDialog';
import ErrorDialog from 'components/ErrorDialog';


const ChangePasswordSection = () => {
    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    const endpoint = globalPublicData.baseEndpoint + "/systemaccess/changepassword"

    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const [inputs, setInputs] = useState(defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [successDialogParams, setSuccessDialogParams] = useState({ messageLine1: ""})
    const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: ""})

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
        console.log('[ChangePasswordSection] inputs are: ', inputs)
    }

    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }
    
    const uppercaseAlphabetRegex = new RegExp('[A-Z]')
    const lowercaseAlphabetRegex = new RegExp('[a-z]')
    const numericalRegex = /\d/
    const specialCharacterRegex = new RegExp('[-+_!@#$%^&*.,?]')


    const handleSubmit = async ()=>{
        var validationState = false
        if (inputs.new_password.length < 12){
            validationState = false
            setErrorDialogParams({
                messageLine1: "Error: Please ensure password length is at least 12 characters"
            })
            setOpenErrorDialog(true)
        } else if (!uppercaseAlphabetRegex.test(inputs.new_password)){
            validationState = false
            setErrorDialogParams({
                messageLine1: "Error: Please ensure password contains at least 1 uppercase character"
            })
            setOpenErrorDialog(true)
        } else if (!lowercaseAlphabetRegex.test(inputs.new_password)){
            validationState = false
            setErrorDialogParams({
                messageLine1: "Error: Please ensure password contains at least 1 lowercase character"
            })
            setOpenErrorDialog(true)
        } else if (!numericalRegex.test(inputs.new_password)){
            validationState = false
            setErrorDialogParams({
                messageLine1: "Error: Please ensure password contains at least 1 number"
            })
            setOpenErrorDialog(true)
        } else if (!specialCharacterRegex.test(inputs.new_password)){
            validationState = false
            setErrorDialogParams({
                messageLine1: "Error: Please ensure password contains at least 1 special character '[-+_!@#$%^&*.,?]'"
            })
            setOpenErrorDialog(true)
        } else if (inputs.new_password !== inputs.confirm_password){
            console.log('matching password code ran (not matching)')
            validationState = false
            setErrorDialogParams({
                messageLine1: "Error: New and re-entered passwords do not match."
            })
            setOpenErrorDialog(true)
        } else {
            validationState = true
        }

        if (validationState === true){

            try {
                const response = await axios.post(endpoint, inputs, axiosConfig)
                console.log('[ChangePasswordSection] response is: ', response)
                if (response.status === 200){
                    alert("Password Changed")
                    navigate("/fb/app")
                } else {
                    alert("Apologies, an error has occured")
                }

            } catch (err){
                console.log('[ChangePasswordSection] err is: ', err)
                setErrorDialogParams({
                    messageLine1: "Apologies, there was an error",
                    messageLine2: "Please check your inputs and try again"
                })
                setOpenErrorDialog(true)
            }
        } 
    }


    return(
        <Card sx={{ width: "100%"}}>
            <MDBox display="flex">
                <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="dark"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    ml={3}
                    mt={-2}
                >
                    <KeyIcon color="inherit" fontSize="medium" />
                </MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                    Change Password
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Current Password</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="current_password"
                            type="password" 
                            value={inputs.current_password}
                            onChange={handleInputChange}
                            fullWidth
                            // disabled={viewMode}
                            error={inputErrorState.current_password.state}
                        />
                        {inputErrorState.current_password.state ? 
                        <FormHelperText error>
                            <strong>{inputErrorState.current_password.message}</strong>
                        </FormHelperText>
                        : 
                        <FormHelperText>
                            Current Password
                        </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>New Password</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="new_password"
                            type="password" 
                            value={inputs.new_password}
                            onChange={handleInputChange}
                            fullWidth
                            // disabled={viewMode}
                            error={inputErrorState.new_password.state}
                        />
                        {inputErrorState.new_password.state ? 
                        <FormHelperText error>
                            <strong>{inputErrorState.new_password.message}</strong>
                        </FormHelperText>
                        : 
                        <FormHelperText>
                            New Password
                        </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Confirm Password</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="confirm_password"
                            type="password" 
                            value={inputs.confirm_password}
                            onChange={handleInputChange}
                            fullWidth
                            // disabled={viewMode}
                            error={inputErrorState.confirm_password.state}
                        />
                        {inputErrorState.confirm_password.state ? 
                        <FormHelperText error>
                            <strong>{inputErrorState.confirm_password.message}</strong>
                        </FormHelperText>
                        : 
                        <FormHelperText>
                            Password Confirmation
                        </FormHelperText>
                        }
                    </Grid>
                </Grid>
                <Grid container justifyContent={"flex-start"} spacing={2} mt={1}>
                    <Grid item xs={12} md={7}>
                        {inputs.new_password.length > 11 ? 
                            <MDTypography variant="body2" fontWeight="medium" color="success">
                               <DoneIcon sx={{verticalAlign: "middle"}}/> Password is at least 12 characters
                            </MDTypography>
                            :
                            <MDTypography variant="body2" fontWeight="medium" color="error">
                                <CloseIcon sx={{verticalAlign: "middle"}}/> Password is at least 12 characters
                            </MDTypography>
                        }
                        {
                            uppercaseAlphabetRegex.test(inputs.new_password) ?
                            <MDTypography variant="body2" fontWeight="medium" color="success">
                               <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 uppercase letter
                            </MDTypography>
                            :
                            <MDTypography variant="body2" fontWeight="medium" color="error">
                                <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 uppercase letter
                            </MDTypography>
                        }
                        {
                            lowercaseAlphabetRegex.test(inputs.new_password) ?
                            <MDTypography variant="body2" fontWeight="medium" color="success">
                               <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 lowercase letter
                            </MDTypography>
                            :
                            <MDTypography variant="body2" fontWeight="medium" color="error">
                                <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 lower letter
                            </MDTypography>
                        }
                        {
                            numericalRegex.test(inputs.new_password) ?
                            <MDTypography variant="body2" fontWeight="medium" color="success">
                               <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 number
                            </MDTypography>
                            :
                            <MDTypography variant="body2" fontWeight="medium" color="error">
                                <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 number
                            </MDTypography>
                        }
                        {
                            specialCharacterRegex.test(inputs.new_password) ?
                            <MDTypography variant="body2" fontWeight="medium" color="success">
                               <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 special character
                            </MDTypography>
                            :
                            <MDTypography variant="body2" fontWeight="medium" color="error">
                                <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 special character
                            </MDTypography>
                        }
                                                {
                            (inputs.new_password === inputs.confirm_password) ?
                            <MDTypography variant="body2" fontWeight="medium" color="success">
                               <DoneIcon sx={{verticalAlign: "middle"}}/> New password and re-entered password matches
                            </MDTypography>
                            :
                            <MDTypography variant="body2" fontWeight="medium" color="error">
                                <CloseIcon sx={{verticalAlign: "middle"}}/> New password and re-entered password do not match
                            </MDTypography>
                        }
                    </Grid>
                </Grid>
                <Grid 
                    container
                    justifyContent="flex-end"
                    spacing={3}
                    mt={5}
                >
                    <Grid item xs={12} md={3}>
                        <MDButton 
                            fullWidth
                            color="info"
                            onClick={handleSubmit}
                        >
                            Change Password
                        </MDButton>
                    </Grid>
                </Grid>
                <SuccessDialog
                    open={openSuccessDialog}
                    messageLine1={successDialogParams.messageLine1}
                    handleSubmit={successDialogParams.handleSubmit}
                    setOpenDialog={setOpenSuccessDialog}                
                />
                <ErrorDialog
                    open={openErrorDialog}
                    messageLine1={errorDialogParams.messageLine1}
                    messageLine2={errorDialogParams.messageLine2}
                    setOpenDialog={setOpenErrorDialog}
                />
            </MDBox>
        </Card>
    )
}

export default ChangePasswordSection