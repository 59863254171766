import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import Randomstring from "randomstring";

import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PlusCollapse from "components/PlusCollapse";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { prepareAllMenuOptions } from "utils/menuOptionsUtils";

import { handlebuildingChange, handleEstateChange, handlePropertyGroupChange, handleSpaceChange, handleSpaceChangeMultiple } from "./utils";
import { useCookies } from "react-cookie";


const HierarchyFormInformation = (props) => {

    const { 
        inputs, 
        inputErrorState,
        handleInputChange, 
        setInputs, 
        viewMode, 
        showPropertyGroupFields, 
        showBuildingFields, 
        showSpaceFields, 
        showMultiSpaceFields, 
        openCollapse } = props

    const navigate = useNavigate()

    const [cookies, setCookie, removeCookie] = useCookies()
    const user = useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const dispatch = useDispatch()
    
    const dispatchReduxStates = (data) => {
        const { Estate, Property_Group, Building, Space } = prepareAllMenuOptions(data)

        dispatch(updateEstateMenuOptions(Estate))
        dispatch(updateFilteredEstateMenuOptions(Estate))
        dispatch(updatePropertyGroupMenuOptions(Property_Group))
        dispatch(updateFilteredPropertyGroupOptions(Property_Group))
        dispatch(updateBuildingMenuOptions(Building))
        dispatch(updateFilteredBuildingMenuOptions(Building))
        dispatch(updateSpaceMenuOptions(Space))
        dispatch(updateFilteredSpaceMenuOptions(Space))
    }

    useEffect(() => {

        async function populateData() {
            const url = globalPublicData.baseEndpoint + "/common/getmenuoptions"
            const response = await axios.get(url, axiosConfig)
            dispatchReduxStates(response.data)
        }
        populateData()
    }, [])

    const fullOptionsData = useSelector(state => state.menuOptions)
    const initialMenuOptionsData = {
        estates: {
            name: fullOptionsData.fullEstateOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullEstateOptions.map(item => { return item.Id })
        },
        propertyGroups: {
            name: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Id })
        },
        buildings: {
            name: fullOptionsData.fullBuildingOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullBuildingOptions.map(item => { return item.Id })
        },
        spaces: {
            name: fullOptionsData.fullSpaceOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullSpaceOptions.map(item => { return item.Id })
        }
    }
    const [menuOptions, setMenuOptions] = useState(initialMenuOptionsData)

    useEffect( ()=>{
        const _menuOptions = {
            estates: {
                name: fullOptionsData.fullEstateOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullEstateOptions.map(item => { return item.Id })
            },
            propertyGroups: {
                name: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Id })
            },
            buildings: {
                name: fullOptionsData.fullBuildingOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullBuildingOptions.map(item => { return item.Id })
            },
            spaces: {
                name: fullOptionsData.fullSpaceOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullSpaceOptions.map(item => { return item.Id })
            }
        }
        setMenuOptions(_menuOptions)

    },[fullOptionsData])

    const handleEstateIdChange = (event, value) => {
        var fullItem = fullOptionsData.fullEstateOptions.find(item => { return item.Id === value })
        handleEstateChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showPropertyGroupFields, showBuildingFields, showSpaceFields, showMultiSpaceFields)
    }

    const handleEstateNameChange = (event, value) => {
        var fullItem = fullOptionsData.fullEstateOptions.find(item => { return item.Name === value })
        handleEstateChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showPropertyGroupFields, showBuildingFields, showSpaceFields, showMultiSpaceFields)
    }

    const handlePropertyGroupIdChange = (event, value) => {
        var fullItem = fullOptionsData.fullPropertyGroupOptions.find(item => { return item.Id === value })
        handlePropertyGroupChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showBuildingFields, showSpaceFields, showMultiSpaceFields)
    }

    const handlePropertyGroupNameChange = (event, value) => {
        var fullItem = fullOptionsData.fullPropertyGroupOptions.find(item => { return item.Name === value })
        handlePropertyGroupChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showBuildingFields, showSpaceFields, showMultiSpaceFields)
    }

    const handleBuildingIdChange = (event, value) => {
        var fullItem = fullOptionsData.fullBuildingOptions.find(item => { return item.Id === value })
        handlebuildingChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showSpaceFields, showMultiSpaceFields)
    }

    const handleBuildingNameChange = (event, value) => {
        var fullItem = fullOptionsData.fullBuildingOptions.find(item => { return item.Name === value })
        handlebuildingChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions, showSpaceFields, showMultiSpaceFields)
    }

    const clearBuildingSelection = () => {
        var fullItem = []
        
    }

    const handleSpaceIdChange = (event, value) => {
        var fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Id === value })
        handleSpaceChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions)
    }

    const handleSpaceNameChange = (event, value) => {
        var fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Name === value })
        handleSpaceChange(fullItem, inputs, setInputs, fullOptionsData, setMenuOptions)
    }

    const handleSpaceIdChangeMultiple = (event, value) => {
        var fullItems = []
        value.map((spaceId) => {
            var _fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Id === spaceId })
            fullItems.push(_fullItem)
        })
        console.log(fullItems)
        handleSpaceChangeMultiple(fullItems, inputs, setInputs, fullOptionsData, menuOptions, setMenuOptions)
    }

    const handleSpaceNameChangeMultiple = (event, value) => {
        var fullItems = []
        value.map( (spaceName) => {
            var _fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Name === spaceName })
            fullItems.push(_fullItem)
        })
        console.log(fullItems)
        // var fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Name === value })
        handleSpaceChangeMultiple(fullItems, inputs, setInputs, fullOptionsData, menuOptions, setMenuOptions)
    }

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }


    return (
        <>
        <PlusCollapse
            title="Hierarchy Information"
            open={openCollapse}
        >
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={2}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Estate Id</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Estate_Id"
                            forcePopupIcon={!inputErrorState.Estate_Id.state}
                            placeholder="Select Estate by Id"
                            value={inputs.Estate_Id}
                            options={menuOptions.estates.id}
                            renderInput={(params, key) => <MDInput {...params} name="Estate_Id" error={inputErrorState.Estate_Id.state}/>}
                            onChange={handleEstateIdChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        {inputErrorState.Estate_Id.state ? 
                            <FormHelperText error>
                                <strong>{inputErrorState.Estate_Id.message}</strong>
                            </FormHelperText>    
                        :
                        <FormHelperText>
                            Estate Id
                        </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Estate Name</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Estate_Name"
                            forcePopupIcon={!inputErrorState.Estate_Name.state}
                            placeholder="Select Estate by Name"
                            value={inputs.Estate_Name}
                            options={menuOptions.estates.name}
                            renderInput={
                                (params, key) => <MDInput {...params} name="Estate_Name" error={inputErrorState.Estate_Name.state} />}
                            onChange={handleEstateNameChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        {inputErrorState.Estate_Name.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Estate_Name.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Name of Estate
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
                { showPropertyGroupFields ? 
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                    mt={1}
                >
                    <Grid
                        item
                        xs={12}
                        md={2}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Property Group Id</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Property_Group_Id"
                            forcePopupIcon={!inputErrorState.Property_Group_Id.state}
                            placeholder="Select Property Group by Id"
                            value={inputs.Property_Group_Id}
                            options={menuOptions.propertyGroups.id}
                                    renderInput={(params, key) => <MDInput {...params} error={inputErrorState.Property_Group_Id.state} />}
                            onChange={handlePropertyGroupIdChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        {inputErrorState.Property_Group_Id.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Property_Group_Id.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Property Group Id
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Property Group Name</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Property_Group_Name"
                            forcePopupIcon={!inputErrorState.Property_Group_Name.state}
                            placeholder="Select Property Group by Name"
                            value={inputs.Property_Group_Name}
                            options={menuOptions.propertyGroups.name}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.Property_Group_Name.state} />}
                            onChange={handlePropertyGroupNameChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        {inputErrorState.Property_Group_Name.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Property_Group_Name.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Name of Property Group
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
                : null}
                { showBuildingFields ? 
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                    mt={1}
                >
                    <Grid
                        item
                        xs={12}
                        md={2}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Building Id</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Building_Id"
                            forcePopupIcon={!inputErrorState.Building_Id.state}
                            placeholder="Select Building by Id"
                            value={inputs.Building_Id}
                            options={menuOptions.buildings.id}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.Building_Id.state} />}
                            onChange={handleBuildingIdChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        {inputErrorState.Building_Id.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Building_Id.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Building Id
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Building Name</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Building_Name"
                            forcePopupIcon={!inputErrorState.Building_Name.state}
                            placeholder="Select Building by Name"
                            value={inputs.Building_Name}
                            options={menuOptions.buildings.name}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.Building_Name.state} />}
                            onChange={handleBuildingNameChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        {inputErrorState.Building_Name.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Building_Name.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Name of Building
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
                : null }
                {showSpaceFields ? 
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                    mt={1}
                >
                    <Grid
                        item
                        xs={12}
                        md={2}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Space Id</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Space_Id"
                            placeholder="Select Space by Id"
                            value={inputs.Space_Id}
                            options={menuOptions.spaces.id}
                            renderInput={(params, key) => <MDInput {...params} />}
                            onChange={handleSpaceIdChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        <FormHelperText>
                            Space Identifier Code
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Space Name</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Space_Name"
                            placeholder="Select Space by Name"
                            value={inputs.Space_Name}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option + Randomstring.generate()}>
                                        {option}
                                    </li>
                                );
                            }}
                            options={menuOptions.spaces.name}
                            renderInput={(params, key) => <MDInput {...params} />}
                            onChange={handleSpaceNameChange}
                            disabled={viewMode}
                            disableClearable
                        />
                        <FormHelperText>
                            Name of Space
                        </FormHelperText>
                    </Grid>
                </Grid>
                : null }
                {showMultiSpaceFields ?
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={3}
                            mt={1}
                        >
                            <Grid
                                item
                                xs={12}
                                md={2}
                            >
                                <InputLabel sx={inputLabelStyles} required>
                                    <strong>Space Id(s)</strong>
                                </InputLabel>
                                <Autocomplete
                                    multiple
                                    name="Space_Ids"
                                    placeholder="Select Spaces by Id"
                                    value={inputs.Space_Ids}
                                    options={menuOptions.spaces.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option + Randomstring.generate()}>
                                                {option}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params, key) => <MDInput {...params} />}
                                    onChange={handleSpaceIdChangeMultiple}
                                    disabled={viewMode}
                                    disableClearable
                                />
                                <FormHelperText>
                                    Space Identifier Code
                                </FormHelperText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={7}
                            >
                                <InputLabel sx={inputLabelStyles} required>
                                    <strong>Space Name(s)</strong>
                                </InputLabel>
                                <Autocomplete
                                    multiple
                                    name="Space_Names"
                                    placeholder="Select Spaces by Name"
                                    value={inputs.Space_Names}
                                    options={menuOptions.spaces.name}
                                    renderInput={(params, key) => <MDInput {...params} />}
                                    onChange={handleSpaceNameChangeMultiple}
                                    disabled={viewMode}
                                    disableClearable
                                />
                                <FormHelperText>
                                    Name of Space
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        : null}
            </MDBox>
        </PlusCollapse>
        </>
    )
}

export default HierarchyFormInformation


HierarchyFormInformation.defaultProps = {
    viewMode: false,
    disableIdField: false,
    showPropertyGroupFields: false,
    showBuildingFields: false,
    showSpaceFields: false,
    showMultiSpaceFields: false,
    openCollapse: true,

};