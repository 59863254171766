import { useCallback, useEffect, useState } from "react";
import axios from "axios"
import validator from "validator";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card"
// @mui icons
import SyncIcon from '@mui/icons-material/Sync';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Label } from "@mui/icons-material";
import { FormHelperText, FormLabel, Grid, Switch } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { globalPublicData } from "globalPublicData";
import useCookies from "react-cookie/cjs/useCookies";
import { useDispatch } from "react-redux";
import { 
  updateToken,
  clearUser,
  updateUserDetails,
  updateRolesAndRoleClaims,
} from "redux/features/user/userSlice";
import ErrorDialog from "components/ErrorDialog";
import SuccessDialog from "components/SuccessDialog";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
// import { updateRolesAndRoleClaims } from "redux/features/user/userSlice";

function ResetPasswordPage() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get("code")
  const _id = queryParams.get("id")

  const uppercaseAlphabetRegex = new RegExp('[A-Z]')
    const lowercaseAlphabetRegex = new RegExp('[a-z]')
    const numericalRegex = /\d/
    const specialCharacterRegex = new RegExp('[-+_!@#$%^&*.,?]')

  const [inputs, setInputs] = useState({
    id: _id,
    code: code,
    email: "",
    new_password: "",
    confirm_password: ""
  })

  const [userNameError, setUserNameError] = useState({
    state: false,
    label: ""
  })

  useEffect(()=>{
    console.log("Code is: ", code)
    console.log("Query ID is: ", _id)
  },[])

  const [buttonClicked, setButtonClicked] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: ""})
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const [successDialogParams, setSuccessDialogParams] = useState({ messageLine1: ""})
  
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = (event) => {
    event.persist()
    setInputs({ ...inputs, [event.target.name]: event.target.value })
}

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('verify');
    const endpoint = globalPublicData.baseEndpoint 
    + "/common/getgrecaptchaverification?token=" 
    + token

    const response = await axios.get(endpoint)
    console.log('grecaptcha response is: ', response)
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const generalInputsErrorHandler = (messageLine1, messageLine2) => {
    setErrorDialogParams({
        messageLine1: messageLine1,
        messageLine2: messageLine2
    })
    // setOpenDialog(false)
    setOpenErrorDialog(true)
}

const handleSetRememberMe = ()=>{

}

  const invalidInputsErrorHandler = () => {
    setErrorDialogParams({
        messageLine1: "Error: Please check your inputs and try again"
    })
    // setOpenDialog(false)
    setOpenErrorDialog(true)
}

const identifiedErrorHandler = (errData)=>{
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: JSON.stringify(errData)
        })
        // setOpenDialog(false)
        setOpenErrorDialog(true)
    }

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSubmit = async () => {

    setButtonClicked(true)
    var validationState = false
    var _userNameError = {
      state: false,
      label: ""
    }

    var messageLine1 = ""
    var messageLine2 = ""
    
    if (validator.isEmpty(inputs.email)){
      messageLine1 = "Please input your email address"
      _userNameError = {state: true, label: messageLine1}
      setUserNameError(_userNameError)
    } else if (!validator.isEmail(inputs.email)){
      messageLine1 = "Please enter a valid email address"
      _userNameError = {state: true, label: messageLine1}
      setUserNameError(_userNameError)
    } else {
      setUserNameError({state: false, label: ""})
    }
    
    if (_userNameError.state === false){
        if (inputs.new_password.length < 12){
          validationState = false
          setErrorDialogParams({
              messageLine1: "Error: Please ensure password length is at least 12 characters"
          })
          setOpenErrorDialog(true)
      } else if (!uppercaseAlphabetRegex.test(inputs.new_password)){
          validationState = false
          setErrorDialogParams({
              messageLine1: "Error: Please ensure password contains at least 1 uppercase character"
          })
          setOpenErrorDialog(true)
      } else if (!lowercaseAlphabetRegex.test(inputs.new_password)){
          validationState = false
          setErrorDialogParams({
              messageLine1: "Error: Please ensure password contains at least 1 lowercase character"
          })
          setOpenErrorDialog(true)
      } else if (!numericalRegex.test(inputs.new_password)){
          validationState = false
          setErrorDialogParams({
              messageLine1: "Error: Please ensure password contains at least 1 number"
          })
          setOpenErrorDialog(true)
      } else if (!specialCharacterRegex.test(inputs.new_password)){
          validationState = false
          setErrorDialogParams({
              messageLine1: "Error: Please ensure password contains at least 1 special character '[-+_!@#$%^&*.,?]'"
          })
          setOpenErrorDialog(true)
      } else if (inputs.new_password !== inputs.confirm_password){
          console.log('matching password code ran (not matching)')
          validationState = false
          setErrorDialogParams({
              messageLine1: "Error: New and re-entered passwords do not match."
          })
          setOpenErrorDialog(true)
      } else {
          validationState = true
      }

    } else {
      invalidInputsErrorHandler()
      setButtonClicked(false)
      validationState = false
    }
    
    if (validationState === true){
      const endpoint = globalPublicData.baseEndpoint + "/systemaccess/resetpassword"

      try {
        const response = await axios.post(endpoint, inputs)
        console.log('response is: ', response)
        if (response.status !== 200){
          throw "Error"
        } else {
          setSuccessDialogParams({
              messageLine1: "Password reset has successfully completed.",
              handleSubmit: () => { navigate("/fb/app/") }
          })
          setOpenSuccessDialog(true)
          setButtonClicked(false)
        } 
      } catch (err){
        console.log('[handleSignIn] error: ', err)
        if (err.response.data.errors[0].error_code){
          if (err.response.data.errors[0].error_code === "1010107"){
            generalInputsErrorHandler("Error: Please check your inputs and try again")
          } 
        } else {
          identifiedErrorHandler(err.response.data.errors)
        }
        setButtonClicked(false)
      }
    } else {
      // generalInputsErrorHandler("Error: Please check your inputs and try again")
      setButtonClicked(false)
    }
  }



  return (
    <BasicLayout image={bgImage}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={9} md={8} xl={6}>
          <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                <MDBox mb={1}>
                    <FormLabel sx={{marginBottom: "5px", paddingBottom: "5px", lineHeight: "2px"}}>
                      <MDTypography fontWeight="medium" variant="subtitle2">Please enter your work email address*</MDTypography>
                    </FormLabel>
                </MDBox>
                <MDBox mb={2}>
                    <MDInput 
                      type="email" 
                      label="Email" 
                      name="email" 
                      onChange={handleInputChange} 
                      fullWidth
                      error={userNameError.state}
                    />
                    <FormHelperText error={userNameError.state}>
                      <strong>{userNameError.label}</strong>
                    </FormHelperText>
                </MDBox>
                <MDBox mb={2}>
                <MDInput 
                  type="password" 
                  label="New Password" 
                  name="new_password" 
                  value={inputs.new_password}
                  onChange={handleInputChange} 
                  fullWidth 
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput 
                  type="password" 
                  label="Re-Enter Password" 
                  name="confirm_password" 
                  value={inputs.confirm_password}
                  onChange={handleInputChange} 
                  fullWidth 
                />
              </MDBox>
              <Grid container justifyContent={"flex-start"} spacing={2} mt={1}>
                <Grid item xs={12}>
                    {inputs.new_password.length > 11 ? 
                        <MDTypography variant="body2" fontWeight="medium" color="success">
                            <DoneIcon sx={{verticalAlign: "middle"}}/> Password is at least 12 characters
                        </MDTypography>
                        :
                        <MDTypography variant="body2" fontWeight="medium" color="error">
                            <CloseIcon sx={{verticalAlign: "middle"}}/> Password is at least 12 characters
                        </MDTypography>
                    }
                    {
                        uppercaseAlphabetRegex.test(inputs.new_password) ?
                        <MDTypography variant="body2" fontWeight="medium" color="success">
                            <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 uppercase letter
                        </MDTypography>
                        :
                        <MDTypography variant="body2" fontWeight="medium" color="error">
                            <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 uppercase letter
                        </MDTypography>
                    }
                    {
                        lowercaseAlphabetRegex.test(inputs.new_password) ?
                        <MDTypography variant="body2" fontWeight="medium" color="success">
                            <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 lowercase letter
                        </MDTypography>
                        :
                        <MDTypography variant="body2" fontWeight="medium" color="error">
                            <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 lower letter
                        </MDTypography>
                    }
                    {
                        numericalRegex.test(inputs.new_password) ?
                        <MDTypography variant="body2" fontWeight="medium" color="success">
                            <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 number
                        </MDTypography>
                        :
                        <MDTypography variant="body2" fontWeight="medium" color="error">
                            <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 number
                        </MDTypography>
                    }
                    {
                        specialCharacterRegex.test(inputs.new_password) ?
                        <MDTypography variant="body2" fontWeight="medium" color="success">
                            <DoneIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 special character
                        </MDTypography>
                        :
                        <MDTypography variant="body2" fontWeight="medium" color="error">
                            <CloseIcon sx={{verticalAlign: "middle"}}/> Password contains at least 1 special character
                        </MDTypography>
                    }
                                            {
                        (inputs.new_password === inputs.confirm_password) ?
                        <MDTypography variant="body2" fontWeight="medium" color="success">
                            <DoneIcon sx={{verticalAlign: "middle"}}/> New password and re-entered password matches
                        </MDTypography>
                        :
                        <MDTypography variant="body2" fontWeight="medium" color="error">
                            <CloseIcon sx={{verticalAlign: "middle"}}/> New password and re-entered password do not match
                        </MDTypography>
                    }
                </Grid>
            </Grid>
              <MDBox mt={4} mb={1}>
                <MDButton 
                  onClick={handleSubmit} 
                  variant="gradient" 
                  color="info" 
                  fullWidth
                  disabled={buttonClicked}
                >
                  {buttonClicked ? <SyncIcon /> : "Submit"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>
    </Grid>
    <SuccessDialog
        open={openSuccessDialog}
        messageLine1={successDialogParams.messageLine1}
        handleSubmit={successDialogParams.handleSubmit}
        setOpenDialog={setOpenSuccessDialog}                
    />
    <ErrorDialog
        open={openErrorDialog}
        messageLine1={errorDialogParams.messageLine1}
        messageLine2={errorDialogParams.messageLine2}
        setOpenDialog={setOpenErrorDialog}
    />
    </BasicLayout>
  );
}

export default ResetPasswordPage;
