import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import EstateSection from "page-sections/estates/EstateSection"
import PropertyGroupSection from "page-sections/property-groups/PropertyGroupSection";
import PropertyGroupSubmitArea from "page-sections/property-groups/PropertyGroupSubmitArea";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import HierarchyFormInformation from "../../page-sections/HierarchyFormInformation/HierarchyFormInformation";
import { defaultInputState, defaultInputErrorState } from "page-sections/property-groups/utils";


const ViewPropertyGroupPageAudit = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const viewMode = true
    const disableIdField = true

    const user =  useSelector(state => state.user)
    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const queryParams = new URLSearchParams(location.search)

    // useEffect(() => {
    //     const fetchData = async () => {
    //         var cosmosId = queryParams.get("id")
    //         console.log('[VeiwAuditPage][fetchData] cosmosId is: ', cosmosId)
    //         var idToQuery = cosmosId || location.state.id
    //         const url =
    //             globalPublicData.baseEndpoint
    //             + "/common/getauditlogbyuuid/"
    //             + idToQuery

    //         console.log('[ViewEstateAuditPage][fetchData] url is: ', url)
    //         const res = await axios.get(url, axiosConfig)
    //         setInputs(res.data)
    //         // setAuditData(res.data)
    //         console.log('[VeiwAuditPage][fetchData] res.data is: ', res.data)
    //     }
        
    //     fetchData()
        
    // }, [])
    
    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            View Property Group (Audit)
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={true}
                    />    
                    <PropertyGroupSection 
                        // title="View Property Group (Audit)"
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        handleInputChange={handleInputChange}
                        viewMode
                        disableIdField
                    />        
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewPropertyGroupPageAudit


ViewPropertyGroupPageAudit.defaultProps = {
    viewMode: false,
    disableIdField: false
};