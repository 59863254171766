import { useState, useEffect, useRef } from "react";
// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import SpaceBasicInformationSection from "page-sections/spaces/SpaceBasicInformationSection";
import SpaceSubmitArea from "page-sections/spaces/SpaceSubmitArea";
import { defaultInputState } from "page-sections/spaces/utils";
import { defaultInputErrorState } from "page-sections/spaces/utils";
import { useSelector } from "react-redux";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const CreateSpaceTicketPage = (props) => {

    const navigate = useNavigate()

    const viewMode = false
    const disableIdField = false
    const user = useSelector(state => state.user)

    const [inputs, setInputs] = useState(defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
        console.log('[handleInputChange] inputs are: ', inputs)
    }

    useEffect(() => {
        if (!user.roleClaims.includes("amm.fmops") && !user.roleClaims.includes("amm.fmc")) {
            navigate("/fb/app/403")
        }

        setInputErrorState(defaultInputErrorState)
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Create Space
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        openCollapse={true}
                    />
                    <SpaceBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    <MDBox p={2} mt={7}>
                    <SpaceAssetSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        submitCreateRequestButton={{
                            show: true,
                            text: "Submit Request",
                            color: "info"
                        }}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/space/create"
                        updateEndpoint="/space/update"
                        deleteEndpoint="/space/delete"
                        editRedirectUrl="/fb/app/home/edit-space"
                        defaultInputErrorState={defaultInputErrorState}

                    />
                    </MDBox>
                    {/* <SpaceSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        submitCreateRequestButton={{
                            show: true,
                            text: "Submit Request",
                            color: "info"
                        }}
                    />              */}
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreateSpaceTicketPage


CreateSpaceTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};