/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const calendarEventsData = [
  {
    title: "All day conference",
    start: "2023-06-01T12:30:00",
    end: "2023-06-01T16:30:00",
    className: "success",
  },

  {
    title: "Meeting with Mary",
    start: "2023-06-03T12:30:00",
    end: "2023-06-03T14:30:00",
    className: "info",
  },

  {
    title: "Cyber Week",
    start: "2023-06-04T10:30:00",
    end: "2023-06-04T12:30:00",
    className: "warning",
  },

  {
    title: "Winter Hackaton",
    start: "2023-06-05T12:30:00",
    end: "2023-06-05T15:00:00",
    className: "error",
  },

  {
    title: "Digital event",
    start: "2023-06-06T08:30:00",
    end: "2023-06-06T12:45:00",
    className: "warning",
  },

  {
    title: "Marketing event",
    start: "2023-06-07T12:30:00",
    end: "2023-06-07T13:30:00",
    className: "primary",
  },

  {
    title: "Teambuilding Workshop",
    start: "2023-06-08T12:30:00",
    end: "2023-06-08T17:30:00",
    className: "error",
  },

  {
    title: "Black Friday",
    start: "2023-06-25T12:30:00",
    end: "2023-06-25T16:30:00",
    className: "info",
  },
];

export default calendarEventsData;
