const selectAssetExcelFields = (item) => {
    const {
        Id,
        Name,
        Floor_No,
        Asset_System_Type,
        Asset_System_Type_Id,
        L3_Asset,
        Asset_Group_Id,
        L2_Asset_Category,
        L1_Trade,
        Asset_Group,
        Asset_Abbreviation,
        Asset_Uniclass_PR_Code,
        Asset_Uniclass_SS_Code,
        Criticality_Level,
        Asset_Brand,
        Asset_Manufacturer,
        Asset_Model_Number,
        Asset_Serial_Number,
        FEMS_Date_Created,
        FEMS_Date_Modified,
        Warranty_Expires_On,
        AIR_Template,
        Installation_Date,
        System_Asset_Id,
        FEMS_Asset_System_Code,
        BIM_GUID,
        Remarks,
        Estate_Id,
        Property_Group_Id,
        Building_Id,
        Space_Ids
        // Space_Ids Pending sample data 
    } = item
    return {
        Id,
        Name,
        Floor_No,
        Asset_System_Type,
        Asset_System_Type_Id,
        L3_Asset,
        Asset_Group_Id,
        L2_Asset_Category,
        L1_Trade,
        Asset_Group,
        Asset_Abbreviation,
        Asset_Uniclass_PR_Code,
        Asset_Uniclass_SS_Code,
        Criticality_Level,
        Asset_Brand,
        Asset_Manufacturer,
        Asset_Model_Number,
        Asset_Serial_Number,
        FEMS_Date_Created,
        FEMS_Date_Modified,
        Warranty_Expires_On,
        AIR_Template,
        Installation_Date,
        System_Asset_Id,
        FEMS_Asset_System_Code,
        BIM_GUID,
        Remarks,
        Estate_Id,
        Property_Group_Id,
        Building_Id,
        Space_Ids
    }
}

export default selectAssetExcelFields