import { useState, useEffect, forwardRef } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const AMMDialog = (props) => {

    const { 
        open, 
        title, 
        messageLine1, 
        messageLine2, 
        cancelText, 
        goText, 
        handleSubmit, 
        setOpenDialog 
    } = props

    const handleClose = () => {
        setOpenDialog(false)
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    {title}
                    <CloseIcon 
                        aria-label="close" 
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 16
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{lineHeight: "2.0em"}} id="alert-dialog-slide-description">
                        {messageLine1}
                        <br />
                        {messageLine2}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{cancelText}</Button>
                    <Button onClick={handleSubmit}>{goText}</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default AMMDialog

AMMDialog.defaultProps = {
    title: "Confirmation",
    messageLine1: "Are you sure you would like to proceed?",
    messageLine2: "",
    goText: "Proceed",
    cancelText: "Cancel",
    handleSubmit: ()=>{
        console.log("Submitted")
    },
    open: false
}