

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";
import { useEffect, useState } from "react";
import axios from "axios";
import { globalPublicData } from "globalPublicData";
import { useDispatch, useSelector } from "react-redux";
import { updateAssetGroup } from "redux/features/dropdownOptions/dropdownOptionsSlice";
import { updateAssetSystemType } from "redux/features/dropdownOptions/dropdownOptionsSlice";


const AssetBasicInformationSection = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user =  useSelector(state => state.user)
    const dropdownOptions = useSelector(state => state.dropdownOptions)
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }

    useEffect(()=>{
        const fetchAssetGroupData = async ()=>{
            const url = globalPublicData.baseEndpoint + "/assetgroup/getassetgrouplist?is_deleted=false"
            const res = await axios.get(url, axiosConfig)
            console.log('[AssetbasicInformationSection][fetchAssetGroupData] res.data is: ', res.data)
            dispatch(updateAssetGroup(res.data))
        }
        
        const fetchAssetSystemTypeData = async ()=>{
            const url = globalPublicData.baseEndpoint + "/systemtype/getammsystemtypebycategory/Asset_System_Type"
            const res = await axios.get(url, axiosConfig)
            console.log('[AssetbasicInformationSection][fetchAssetSystemTypeData] res.data is: ', res.data)
            var _filteredData = res.data.filter(item => {return item.Is_Deleted === false})
            dispatch(updateAssetSystemType(_filteredData))
        }

        fetchAssetGroupData()
        fetchAssetSystemTypeData()
    },[])

    const [assetGroupOptions, setAssetGroupOptions] = useState({
        Asset_Group: [...new Set(dropdownOptions.Asset_Group.map( item => { return item.Asset_Group}))],
        L3_Asset: dropdownOptions.Asset_Group.map( item => { return item.L3_Asset}),
        Asset_Abbreviation: [...new Set(dropdownOptions.Asset_Group.map( item => { return item.Asset_Abbreviation}))],
        L1_Trade: [...new Set(dropdownOptions.Asset_Group.map( item => { return item.L1_Trade}))],
        L2_Asset_Category: [...new Set(dropdownOptions.Asset_Group.map( item => { return item.L2_Asset_Category}))],
        Asset_Uniclass_PR_Code: [...new Set(dropdownOptions.Asset_Group.map( item => { return item.Asset_Uniclass_PR_Code}))],
        Asset_Uniclass_SS_Code: [...new Set(dropdownOptions.Asset_Group.map( item => { return item.Asset_Uniclass_SS_Code}))],
    })

    const [assetSystemTypeOptions, setAssetSystemTypeOptions] = useState(
        [...new Set(dropdownOptions.Asset_System_Type.map(item => {return item.Display_Name}))])

    const handleAssetSystemTypeChange = (event, value) => {
        const item = dropdownOptions.Asset_System_Type.find(item => {return item.Display_Name === value})
        setInputs({
            ...inputs, 
            Asset_System_Type: value,
            Asset_System_Type_uuid: item.id
        })
    }

    const handleAssetGroupChange = (event, value)=>{
        var item = dropdownOptions.Asset_Group.find(item => {return item.Asset_Group === value})
        var _filteredAssetGroups = dropdownOptions.Asset_Group.filter( item => { return item.Asset_Group === value})
       
        setAssetGroupOptions({...assetGroupOptions, 
            L3_Asset: _filteredAssetGroups.map( item => { return item.L3_Asset}),
            Asset_Abbreviation: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Abbreviation}))],
            L1_Trade: [...new Set(_filteredAssetGroups.map( item => { return item.L1_Trade}))],
            L2_Asset_Category: [...new Set(_filteredAssetGroups.map( item => { return item.L2_Asset_Category}))],
            Asset_Uniclass_PR_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_PR_Code}))],
            Asset_Uniclass_SS_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_SS_Code}))],
        })
        setInputs({...inputs, Asset_Group: value})
    }

    const handleAssetTradeChange = (event, value)=>{
        var item = dropdownOptions.Asset_Group.find(item => {return item.Asset_Group === value})
        var _filteredAssetGroups = dropdownOptions.Asset_Group.filter( item => { return item.L1_Trade === value})
       
        setAssetGroupOptions({...assetGroupOptions, 
            Asset_Group: [...new Set(_filteredAssetGroups.map(item => {return item.Asset_Group}))],
            L3_Asset: _filteredAssetGroups.map( item => { return item.L3_Asset}),
            Asset_Abbreviation: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Abbreviation}))],
            // L1_Trade: [...new Set(_filteredAssetGroups.map( item => { return item.L1_Trade}))],
            L2_Asset_Category: [...new Set(_filteredAssetGroups.map( item => { return item.L2_Asset_Category}))],
            Asset_Uniclass_PR_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_PR_Code}))],
            Asset_Uniclass_SS_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_SS_Code}))],
        })
        setInputs({...inputs, L1_Trade: value})
    }

    const handleAssetCategoryChange = (event, value)=>{
        var item = dropdownOptions.Asset_Group.find(item => {return item.L2_Asset_Category === value})
        var _filteredAssetGroups = dropdownOptions.Asset_Group.filter( item => { return item.L2_Asset_Category === value})
       
        setAssetGroupOptions({...assetGroupOptions, 
            Asset_Group: [...new Set(_filteredAssetGroups.map(item => {return item.Asset_Group}))],
            L3_Asset: _filteredAssetGroups.map( item => { return item.L3_Asset}),
            Asset_Abbreviation: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Abbreviation}))],
            L1_Trade: [...new Set(_filteredAssetGroups.map( item => { return item.L1_Trade}))],
            // L2_Asset_Category: [...new Set(_filteredAssetGroups.map( item => { return item.L2_Asset_Category}))],
            Asset_Uniclass_PR_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_PR_Code}))],
            Asset_Uniclass_SS_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_SS_Code}))],
        })
        setInputs({...inputs, L2_Asset_Category: value})
    }

    const handleAssetAbbreviationChange = (event, value)=>{
        var item = dropdownOptions.Asset_Abbreviation.find(item => {return item.Asset_Abbreviation === value})
        var _filteredAssetGroups = dropdownOptions.Asset_Group.filter( item => { return item.Asset_Abbreviation === value})
       
        setAssetGroupOptions({...assetGroupOptions, 
            Asset_Group: [...new Set(_filteredAssetGroups.map(item => {return item.Asset_Group}))],
            L3_Asset: _filteredAssetGroups.map( item => { return item.L3_Asset}),
            // Asset_Abbreviation: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Abbreviation}))],
            L1_Trade: [...new Set(_filteredAssetGroups.map( item => { return item.L1_Trade}))],
            L2_Asset_Category: [...new Set(_filteredAssetGroups.map( item => { return item.L2_Asset_Category}))],
            Asset_Uniclass_PR_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_PR_Code}))],
            Asset_Uniclass_SS_Code: [...new Set(_filteredAssetGroups.map( item => { return item.Asset_Uniclass_SS_Code}))],
        })
        setInputs({...inputs, Asset_Abbreviation: value})
    }

    const handleL3AssetChange = (event, value)=>{
        var item = dropdownOptions.Asset_Group.find(item => {return item.L3_Asset === value})
        console.log('[handleL3AssetChange] item is: ', item)
        setInputs({...inputs, 
            L3_Asset: value,
            Asset_Group: item.Asset_Group,
            Asset_Abbreviation: item.Asset_Abbreviation,
            L1_Trade: item.L1_Trade,
            L2_Asset_Category: item.L2_Asset_Category,
            Asset_Uniclass_PR_Code: item.Asset_Uniclass_PR_Code,
            Asset_Uniclass_SS_Code: item.Asset_Uniclass_SS_Code,
            Asset_Group_uuid: item.id
        })
    }

    const { title, inputs, inputErrorState, handleInputChange, setInputs, submitButton, editButton, viewMode, disableIdField, handleDateTimeChange, showIdField } = props

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    return (
        <PlusCollapse title="Basic Information" open={true}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    {showIdField ? 
                    <>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Id</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Id"
                            value={inputs.Id}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={disableIdField}
                        />
                        <FormHelperText>
                            Asset Id
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Name"
                            value={inputs.Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={disableIdField}
                            error={inputErrorState.Name.state}
                        />
                    </Grid>
                    </>
                     : null}
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Floor Number</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Floor_No"
                            value={inputs.Floor_No}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Floor_No.state}
                        />
                        {inputErrorState.Floor_No.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Floor_No.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Floor on which Asset is placed ("NA" if not applicable)
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Group</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Asset_Group"
                            forcePopupIcon={!inputErrorState.Asset_Group.state}
                            placeholder="Select Asset Group"
                            value={inputs.Asset_Group}
                            options={assetGroupOptions.Asset_Group}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="Asset_Group" 
                                    error={inputErrorState.Asset_Group.state}
                                />}
                            onChange={handleAssetGroupChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.Asset_Group.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Asset_Group.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Asset Group
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Trade</strong>
                        </InputLabel>
                        <Autocomplete
                            name="L1_Trade"
                            forcePopupIcon={!inputErrorState.L1_Trade.state}
                            placeholder="Select L1 Trade"
                            value={inputs.L1_Trade}
                            options={assetGroupOptions.L1_Trade}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="L1_Trade" 
                                    error={inputErrorState.L1_Trade.state}
                                />}
                            onChange={handleAssetTradeChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="L1_Trade"
                            value={inputs.L1_Trade}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.L1_Trade.state}
                        /> */}
                        {inputErrorState.L1_Trade.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.L1_Trade.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                L1 Asset Trade
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Category</strong>
                        </InputLabel>
                        <Autocomplete
                            name="L2_Asset_Category"
                            forcePopupIcon={!inputErrorState.L2_Asset_Category.state}
                            placeholder="Select L2 Asset Category"
                            value={inputs.L2_Asset_Category}
                            options={assetGroupOptions.L2_Asset_Category}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="L2_Asset_Category" 
                                    error={inputErrorState.L2_Asset_Category.state}
                                />}
                            onChange={handleAssetCategoryChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="L2_Asset_Category"
                            value={inputs.L2_Asset_Category}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.L2_Asset_Category.state}
                        /> */}
                        {inputErrorState.L2_Asset_Category.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.L2_Asset_Category.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                L2 Asset Category
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset</strong>
                        </InputLabel>
                        <Autocomplete
                            name="L3_Asset"
                            forcePopupIcon={!inputErrorState.L3_Asset.state}
                            placeholder="Select L3 Asset "
                            value={inputs.L3_Asset}
                            options={assetGroupOptions.L3_Asset}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="L3_Asset" 
                                    error={inputErrorState.L3_Asset.state}
                                />}
                            onChange={handleL3AssetChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.L3_Asset.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.L3_Asset.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                L3 Asset
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Abbreviation</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Asset_Abbreviation"
                            forcePopupIcon={!inputErrorState.Asset_Abbreviation.state}
                            placeholder="Select Asset Abbreviation "
                            value={inputs.Asset_Abbreviation}
                            options={assetGroupOptions.Asset_Abbreviation}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="Asset_Abbreviation" 
                                    error={inputErrorState.Asset_Abbreviation.state}
                                />}
                            onChange={handleAssetAbbreviationChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="Asset_Abbreviation"
                            value={inputs.Asset_Abbreviation}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Asset_Abbreviation.state}
                        /> */}
                        {inputErrorState.Asset_Abbreviation.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Asset_Abbreviation.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Asset Abbreviation
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Uniclass PR Code</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Asset_Uniclass_PR_Code"
                            forcePopupIcon={!inputErrorState.Asset_Uniclass_PR_Code.state}
                            placeholder="Select Asset Uniclass PR Code "
                            value={inputs.Asset_Uniclass_PR_Code}
                            options={assetGroupOptions.Asset_Uniclass_PR_Code}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="Asset_Uniclass_PR_Code" 
                                    error={inputErrorState.Asset_Uniclass_PR_Code.state}
                                />}
                            onChange={handleL3AssetChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="Asset_Uniclass_PR_Code"
                            value={inputs.Asset_Uniclass_PR_Code}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Asset_Uniclass_PR_Code.state}
                        /> */}
                        {inputErrorState.Asset_Uniclass_PR_Code.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Asset_Uniclass_PR_Code.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Asset Uniclass PR Code
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Uniclass SS Code</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Asset_Uniclass_SS_Code"
                            forcePopupIcon={!inputErrorState.Asset_Uniclass_SS_Code.state}
                            placeholder="Select Asset Uniclass SS Code "
                            value={inputs.Asset_Uniclass_SS_Code}
                            options={assetGroupOptions.Asset_Uniclass_SS_Code}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="Asset_Uniclass_SS_Code" 
                                    error={inputErrorState.Asset_Uniclass_SS_Code.state}
                                />}
                            onChange={handleL3AssetChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.Asset_Uniclass_SS_Code.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Asset_Uniclass_SS_Code.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Asset Uniclass SS Code
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Asset System Type</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Asset_System_Type"
                            forcePopupIcon={!inputErrorState.Asset_System_Type.state}
                            placeholder="Select Asset System Type"
                            value={inputs.Asset_System_Type}
                            options={assetSystemTypeOptions}
                            renderInput={(params, key) => 
                                <MDInput {...params} 
                                    name="Asset_System_Type" 
                                    error={inputErrorState.Asset_System_Type.state}
                                />}
                            onChange={handleAssetSystemTypeChange}
                            disabled={viewMode}
                        />
                        {/* <MDInput
                            variant="outlined"
                            name="Asset_System_Type"
                            value={inputs.Asset_System_Type}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Asset_System_Type.state}
                        /> */}
                        {inputErrorState.Asset_System_Type.state ?
                            <FormHelperText error>
                                <strong>{inputErrorState.Asset_System_Type.message}</strong>
                            </FormHelperText>
                            :
                            <FormHelperText>
                                Asset System Type
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Criticality Level</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Criticality_Level"
                            placeholder="Criticality Level.."
                            value={inputs.Criticality_Level}
                            options={["1","2","3"]}
                            renderInput={(params, key) => <MDInput {...params} />}
                            onChange={(event, value) => { setInputs({ ...inputs, Criticality_Level: value })}}
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Criticality Level 
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Brand</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Asset_Brand"
                            value={inputs.Asset_Brand}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Asset Brand
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Manufacturer</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Asset_Manufacturer"
                            value={inputs.Asset_Manufacturer}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Asset Manufacturer
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Model Number</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Asset_Model_Number"
                            value={inputs.Asset_Model_Number}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Asset Model Number
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Asset Serial Number</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Asset_Serial_Number"
                            value={inputs.Asset_Serial_Number}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                        />
                        <FormHelperText>
                            Asset Serial Number
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel required>
                            <strong>Warranty Expiry Date</strong>
                        </InputLabel>
                        <br />
                        <DatePicker
                            name="Warranty_Expires_On"
                            value={inputs.Warranty_Expires_On}
                            onChange={
                                (newValue) => { handleDateTimeChange("Warranty_Expires_On", newValue) }
                            }
                            renderInput={(params) => <MDInput {...params} fullWidth />}
                            disabled={viewMode}
                            minDate={new Date()}
                        />
                        <FormHelperText>
                            Warranty Expiry Date
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel required>
                            <strong>Installation Date</strong>
                        </InputLabel>
                        <br />
                        <DatePicker
                            name="Installation_Date"
                            value={inputs.Installation_Date}
                            onChange={
                                (newValue) => { handleDateTimeChange("Installation_Date", newValue) }
                            }
                            renderInput={(params) => <MDInput {...params} fullWidth />}
                            disabled={viewMode}
                            // maxDate={new Date()}
                        />
                        <FormHelperText>
                            Installation Date
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Remarks</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Remarks"
                            value={inputs.Remarks}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            multiline
                            rows={5}
                        />
                        <FormHelperText>
                            Remarks
                        </FormHelperText>
                    </Grid>
                </Grid>
                {/* <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={5}
                >
                    <Grid item xs={12} md={2}>
                        {editButton.show ?
                            <MDButton
                                fullWidth
                                color={editButton.color}
                                onClick={editButton.handler}
                            >
                                {editButton.text || "Submit"}
                            </MDButton>
                            : null}
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <MDButton
                            fullWidth
                            color={submitButton.color}
                            onClick={submitButton.handler}
                        >
                            {submitButton.text || "Submit"}
                        </MDButton>
                    </Grid>
                </Grid> */}
            </MDBox>
        </PlusCollapse>
    )
}

export default AssetBasicInformationSection


AssetBasicInformationSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    title: "Building",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        handler: ()=>{}
    },
    showIdField: false

};