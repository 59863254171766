/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "./components/IdCell";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import CustomerCell from "./components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";

const requestorArray = ["Adam Shah", "Joey Cheng", "Aloysius Wong"]

const statusArray = [ "approved", "rejected", "in-progress"]

const operationArray = ["Create", "Read", "Update", "Delete"]

const objectTypeArray = ["Estate", "Property Group", "Building", "Space", "Asset"]

const departmentArray = ["N1", "N2", "N3", "N4", "S1", "S2", "S3", "S4", "E1", "E2", "E3", "E4", "W1", "W2", "W3", "W4", "W5", "W6", "W7"]

const dateArray = [
  new Date().toLocaleDateString(),
  new Date(2023, 0, 1).toLocaleDateString(),
  new Date(2023,0,2).toLocaleDateString(),
  new Date(2023,0,3).toLocaleDateString(),
  new Date(2023,0,4).toLocaleDateString(),
  new Date(2023,0,5).toLocaleDateString(),
  new Date(2023,0,6).toLocaleDateString(),
  new Date(2023,0,7).toLocaleDateString(),
  new Date(2023,0,8).toLocaleDateString(),
  new Date(2023,0,9).toLocaleDateString(),
  new Date(2023,0,10).toLocaleDateString(),
  new Date(2023,0,11).toLocaleDateString(),
  new Date(2023,0,12).toLocaleDateString(),
  new Date(2023,0,13).toLocaleDateString(),
  new Date(2023,0,14).toLocaleDateString(),
  new Date(2023,0,15).toLocaleDateString(),
  new Date(2023,0,16).toLocaleDateString(),
  ]


const dataTableData = {
  columns: [
    { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "operation",
      accessor: "operation",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "object type",
      accessor: "objectType",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Department",
      accessor: "department",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "date",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Requestor",
      accessor: "requestor",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => {
        let status;

        if (value === "approved") {
          status = <StatusCell icon="done" color="success" status="Approved" />;
        } else if (value === "in-progress") {
          status = <StatusCell icon="replay" color="warning" status="In Progress" />;
        } else {
          status = <StatusCell icon="rejected" color="error" status="Rejected" />;
        }

        return status;
      },
    },
  ],

  rows: [
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
    {
      id: "#" + (Math.floor(Math.random() * (999999 - 0 + 1)) + 0),
      date: dateArray[Math.floor(Math.random() * (13 - 0 + 1)) + 0],
      status: statusArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0],
      operation: operationArray[Math.floor(Math.random() * (3 - 0 + 1)) + 0],
      objectType: objectTypeArray[Math.floor(Math.random() * (4 - 0 + 1)) + 0],
      department: departmentArray[Math.floor(Math.random() * (18 - 0 + 1)) + 0],
      requestor: requestorArray[Math.floor(Math.random() * (2 - 0 + 1)) + 0]
    },
   
  ],
};

export default dataTableData;
