import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React context
import {
    useMaterialUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
    } from "context";
    
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,

} from "redux/features/menuOptions/menuOptionsSlice";
import { prepareAllMenuOptions } from "utils/menuOptionsUtils";
import useCookies from "react-cookie/cjs/useCookies";
import { globalPublicData } from "globalPublicData";
import axios from "axios";
import { ticketColumns } from "page-sections/home/utils";
import { prepareTicketRows } from "page-sections/home/utils";
import BSDView from "page-sections/home/sections/BSDView";
import BSDHODView from "page-sections/home/sections/BSDHODView";
import FMOpsView from "page-sections/home/sections/FMOpsView";
import FMCView from "page-sections/home/sections/FMCView";

const HomePage = () => {

    const [controller, dispatch] = useMaterialUIController();
    const [cookies, setCookie, removeCookie] = useCookies()
    const user = useSelector(state => state.user)
    const userInterface = useSelector(state => state.userInterface)
    const [userType, setUserType] = useState("fmc")
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    useEffect( ()=>{
        setMiniSidenav(dispatch, true)
    },[])


    useEffect(()=>{
        const getUserType = async () => {
            const validationEndpoint = globalPublicData.baseEndpoint + "/systemaccess/validate_token"
            const userObject = await axios.get(validationEndpoint, axiosConfig)
            var _roleClaims = []
            userObject.data.roles.map( item => {
                item.role_claims.map(claim => {
                    _roleClaims.push(claim.claim_value)
                })
            })
            if (_roleClaims.includes("amm.hod")) {
                setUserType("hod")
            } else if (_roleClaims.includes("amm.bsd")) {
                setUserType("bsd")
            } else if (_roleClaims.includes("amm.fmops")) {
                setUserType("fmops")
            } else {
                setUserType("fmc")
            }
        }

        getUserType()
    },[])



    const dispatchRedux = useDispatch()

    const dispatchReduxStates = (data) => {
        const { Estate, Property_Group, Building, Space } = prepareAllMenuOptions(data)

        console.log('[HomePage][dispatchReduxStates] Estate is: ', Estate)
        dispatchRedux(updateEstateMenuOptions(Estate))
        dispatchRedux(updateFilteredEstateMenuOptions(Estate))
        dispatchRedux(updatePropertyGroupMenuOptions(Property_Group))
        dispatchRedux(updateFilteredPropertyGroupOptions(Property_Group))
        dispatchRedux(updateBuildingMenuOptions(Building))
        dispatchRedux(updateFilteredBuildingMenuOptions(Building))
        dispatchRedux(updateSpaceMenuOptions(Space))
        dispatchRedux(updateFilteredSpaceMenuOptions(Space))
    }

    useEffect(() => {

        async function populateData() {
            const url = globalPublicData.baseEndpoint + "/common/getmenuoptions"
            const response = await axios.get(url, axiosConfig)
            dispatchReduxStates(response.data)
        }
        populateData()
    }, [])

    

    const [ticketData, setTicketData] = useState({
        approvedTicketsData: { rows: [], columns: ticketColumns },
        pendingHodTicketsData: { rows: [], columns: ticketColumns },
        pendingBSDTicketsData: { rows: [], columns: ticketColumns },
        pendingFMOpsTicketsData: { rows: [], columns: ticketColumns },
        pendingDraftTicketsData: { rows: [], columns: ticketColumns },
        rejectedTicketsData: { rows: [], columns: ticketColumns },
        cancelledTicketsData: { rows: [], columns: ticketColumns }
    })
    const [ticketLoadingStatus, setTicketLoadingStatus] = useState(true)
    const [dashboardSection, setDashboardSection] = useState(
        <BSDHODView
            ticketLoadingStatus={true}
            approvedTicketsData={ticketData.approvedTicketsData}
            pendingHodTicketsData={ticketData.pendingHodTicketsData}
            pendingBSDTicketsData={ticketData.pendingBSDTicketsData}
            pendingFMOpsTicketsData={ticketData.pendingFMOpsTicketsData}
            pendingDraftTicketsData={ticketData.pendingDraftTicketsData}
            rejectedTicketsData={ticketData.rejectedTicketsData}
            cancelledTicketsData={ticketData.cancelledTicketsData}
        />
    )
    const [approvedTicketsData, setApprovedTicketsData] = useState({ rows: [], columns: ticketColumns })
    const [pendingHodTicketsData, setPendingHodTicketsData] = useState({ rows: [], columns: ticketColumns })
    const [pendingBSDTicketsData, setPendingBSDTicketsData] = useState({ rows: [], columns: ticketColumns })
    const [pendingFMOpsTicketsData, setPendingFMOpsTicketsData] = useState({ rows: [], columns: ticketColumns })
    const [pendingDraftTicketsData, setPendingDraftTicketsData] = useState({ rows: [], columns: ticketColumns })
    const [rejectedTicketsData, setRejectedTicketsData] = useState({ rows: [], columns: ticketColumns })
    const [cancelledTicketsData, setCancelledTicketsData] = useState({ rows: [], columns: ticketColumns })

    
    useEffect(() => {
        console.log('fetchTickets in useEffect ran')

        const fetchTickets = async () => {
            const url = globalPublicData.baseEndpoint + "/ammticket/getticketlist"
            const res = await axios.get(url, axiosConfig)
            console.log("[TicketList] res.data is: ", res.data)
            const _approvedTicketRows = prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "Approved"}))
            const _pendingHodTicketsData = prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "PendingHODApproval"}))
            const _pendingBSDTicketsData = prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "PendingBSDApproval"}))
            const _pendingFMOpsTicketsData = prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "PendingFmOpsVerification"}))
            const _draftTicketsData = prepareTicketRows(res.data.filter(ticket => {return (ticket.Status === "Draft" && ticket.Requester_Email === user.user_name)}))
            const _rejectedTicketsData = prepareTicketRows(res.data.filter(ticket => {return (ticket.Status === "Rejected")}))
            const _cancelledTicketsData = prepareTicketRows(res.data.filter(ticket => {return (ticket.Status === "Cancelled")}))
            
            setTicketData({
                approvedTicketsData: {rows: _approvedTicketRows, columns: ticketColumns},
                pendingHodTicketsData: {rows: _pendingHodTicketsData, columns: ticketColumns},
                pendingBSDTicketsData: {rows: _pendingBSDTicketsData, columns: ticketColumns},
                pendingFMOpsTicketsData: {rows: _pendingFMOpsTicketsData, columns: ticketColumns},
                pendingDraftTicketsData: {rows: _draftTicketsData, columns: ticketColumns},
                rejectedTicketsData: {rows: _rejectedTicketsData, columns: ticketColumns},
                cancelledTicketsData: {rows: _cancelledTicketsData, columns: ticketColumns},
                
            })
            
            // setApprovedTicketsData({columns: ticketColumns, rows: prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "Approved"}))})
            // setPendingHodTicketsData({columns: ticketColumns, rows: prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "PendingHODApproval"}))})
            // setPendingBSDTicketsData({columns: ticketColumns, rows: prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "PendingBSDApproval"}))})
            // setPendingFMOpsTicketsData({columns: ticketColumns, rows: prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "PendingFmOpsVerification"}))})
            // setPendingDraftTicketsData({columns: ticketColumns, rows: prepareTicketRows(res.data.filter(ticket => {return (ticket.Status === "Draft" && ticket.Requester_Email === user.user_name)}))})
            // setRejectedTicketsData({columns: ticketColumns, rows: prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "Rejected"}))})
            // setCancelledTicketsData({columns: ticketColumns, rows: prepareTicketRows(res.data.filter(ticket => {return ticket.Status === "Cancelled"}))})
            setTicketLoadingStatus(false)
        }
        
        fetchTickets()
    }, [])

    return(
        <>        
         <DashboardLayout>
            <DashboardNavbar />
                {/* <BSDView
                    ticketLoadingStatus={ticketLoadingStatus}
                    approvedTicketsData={ticketData.approvedTicketsData}
                    pendingHodTicketsData={ticketData.pendingHodTicketsData}
                    pendingBSDTicketsData={ticketData.pendingBSDTicketsData}
                    pendingFMOpsTicketsData={ticketData.pendingFMOpsTicketsData}
                    pendingDraftTicketsData={ticketData.pendingDraftTicketsData}
                    rejectedTicketsData={ticketData.rejectedTicketsData}
                    cancelledTicketsData={ticketData.cancelledTicketsData}
                /> */}
            {(userType === "hod")? 
                <BSDHODView 
                    ticketLoadingStatus={ticketLoadingStatus}
                    approvedTicketsData={ticketData.approvedTicketsData}
                    pendingHodTicketsData={ticketData.pendingHodTicketsData}
                    pendingBSDTicketsData={ticketData.pendingBSDTicketsData}
                    pendingFMOpsTicketsData={ticketData.pendingFMOpsTicketsData}
                    pendingDraftTicketsData={ticketData.pendingDraftTicketsData}
                    rejectedTicketsData={ticketData.rejectedTicketsData}
                    cancelledTicketsData={ticketData.cancelledTicketsData}
                />
            : null}
            {(userType === "bsd") ? 
                <BSDView 
                    ticketLoadingStatus={ticketLoadingStatus}
                    approvedTicketsData={ticketData.approvedTicketsData}
                    pendingHodTicketsData={ticketData.pendingHodTicketsData}
                    pendingBSDTicketsData={ticketData.pendingBSDTicketsData}
                    pendingFMOpsTicketsData={ticketData.pendingFMOpsTicketsData}
                    pendingDraftTicketsData={ticketData.pendingDraftTicketsData}
                    rejectedTicketsData={ticketData.rejectedTicketsData}
                    cancelledTicketsData={ticketData.cancelledTicketsData}
                />
            : null}
            {(userType === "fmops") ? 
                <FMOpsView 
                    ticketLoadingStatus={ticketLoadingStatus}
                    approvedTicketsData={ticketData.approvedTicketsData}
                    pendingHodTicketsData={ticketData.pendingHodTicketsData}
                    pendingBSDTicketsData={ticketData.pendingBSDTicketsData}
                    pendingFMOpsTicketsData={ticketData.pendingFMOpsTicketsData}
                    pendingDraftTicketsData={ticketData.pendingDraftTicketsData}
                    rejectedTicketsData={ticketData.rejectedTicketsData}
                    cancelledTicketsData={ticketData.cancelledTicketsData}
                />
            : null}
            {(userType === "fmc") ? 
                <FMCView 
                    ticketLoadingStatus={ticketLoadingStatus}
                    approvedTicketsData={ticketData.approvedTicketsData}
                    pendingHodTicketsData={ticketData.pendingHodTicketsData}
                    pendingBSDTicketsData={ticketData.pendingBSDTicketsData}
                    pendingFMOpsTicketsData={ticketData.pendingFMOpsTicketsData}
                    pendingDraftTicketsData={ticketData.pendingDraftTicketsData}
                    rejectedTicketsData={ticketData.rejectedTicketsData}
                    cancelledTicketsData={ticketData.cancelledTicketsData}
                />
            : null}
            <Footer />
        </DashboardLayout>
        </>
    )
}

export default HomePage