import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "./utils";
import validateEmptyString from "datavalidation/validateEmptyString";
import AMMDialog from "components/AMMDialog";
import SuccessDialog from "components/SuccessDialog";
import ErrorDialog from "components/ErrorDialog";


const PropertyGroupSubmitArea = (props) => {

    const navigate = useNavigate()
    // const { inputs, createButton, submitButton, editButton, deleteButton, ticket, submitCreateRequestButton, approveButton } = props

    const { 
        inputs, 
        createButton, 
        submitButton, 
        editButton, 
        deleteButton, 
        submitCreateRequestButton, 
        approveButton, 
        assignBackButton,
        rejectButton,
        cancelButton, 
        updateTicketButton,
        setInputErrorState,
        ticket } = props
    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }
    const [openDialog, setOpenDialog] = useState(false)
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)

    const [dialogParams, setDialogParams] = useState({
        title: "Confirmation",
        messageLine1: "Are you sure you would like to proceed?",
        messageLine2: "Note: Please check all inputs before proceeding",
        handleSubmit: () => { }
    })

    const [successDialogParams, setSuccessDialogParams] = useState({ message: "" })
    const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: "" })

    const handleConfirmation = ({ title, messageLine1, messageLine2, handleSubmit }) => {
        setDialogParams({
            // open: true,
            title: title,
            messageLine1: messageLine1,
            messageLine2: messageLine2,
            handleSubmit: handleSubmit
        })

        setOpenDialog(true)
    }

    const handleSubmitCreateRequest = async () => {

        var dataToSend = {
            ...inputs,
        }


        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(inputs[item])) {
                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }
            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }
            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleSubmit] dataToSend are: ', dataToSend)
        console.log('[handleSubmit] validationState is: ', validationState)
        if (validationState) {

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.post(endPoint + "/propertygroup/create", dataToSend, axiosConfig)
                console.log('post response is: ', response)
                if (response.status === 200) {
                    setSuccessDialogParams({
                        messageLine1: "Create Request Ticket has been submitted for verification/approval",
                        handleSubmit: () => { navigate("/fb/app/home") }
                    })
                    setOpenDialog(false)
                    setOpenSuccessDialog(true)
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    setErrorDialogParams({
                        messageLine1: "Apologies, an error has occured.",
                        messageLine2: JSON.stringify(err.response.data)
                    })
                    setOpenDialog(false)
                    setOpenErrorDialog(true)
                }
            }
        } else {
            setErrorDialogParams({
                messageLine1: "Error: Please check your inputs and try again"
            })
            setOpenDialog(false)
            setOpenErrorDialog(true)
        }
    }

    const handleHODApproval = async () => {

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.put(endPoint + "/ammticket/hod/approve", dataToSend, axiosConfig)
            console.log('[handleHODApproval] response is: ', response)
            if (response.status === 200) {
                setSuccessDialogParams({
                    messageLine1: "Ticket has been successfully approved",
                    handleSubmit: () => { navigate("/fb/app/home") }
                })
                setOpenDialog(false)
                setOpenSuccessDialog(true)
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                setErrorDialogParams({
                    messageLine1: "Apologies, an error has occured.",
                    messageLine2: JSON.stringify(err.response.data)
                })
                setOpenDialog(false)
                setOpenErrorDialog(true)
            } else {
                setErrorDialogParams({
                    messageLine1: "Apologies, an error has occured.",
                    messageLine2: ""
                })
                setOpenDialog(false)
                setOpenErrorDialog(true)

            }
        }
    }

    const handleUpdateTicket = async () => {

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(inputs[item])) {
                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }
            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }
            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleUpdateTicket] dataToSend are: ', dataToSend)
        if (validationState) {

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.put(endPoint + "/ammticket/update", dataToSend, axiosConfig)
                console.log('[handleUpdateTicket] response is: ', response)
                if (response.status === 200) {
                    alert("Request Re-Submitted")
                    navigate("/fb/app/home")
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    alert(JSON.stringify(err.response.data))
                }
            }
        } else {
            alert("Error: Please check your inputs and try again")
        }
    }

    const handleCancelTicket = async () => {

        if (user.email !== ticket.Requester_Email){
            return alert("Insufficient Account Priviledges: You can only reject tickets that you have raised.")
        }

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/cancel"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleCancelTicket] response is: ', response)
            if (response.status === 200) {
                alert("Ticket Cancelled")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleRejectTicket = async () => {

        if (!user.roleClaims.includes("amm.hod")){
            return alert("Insufficient Account Priviledges: Only BSD HOD or BSD role(s) can reject tickets.")
        }
        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleRejectTicket] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/reject"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleRejectTicket] response is: ', response)
            if (response.status === 200) {
                alert("Ticket Rejected")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleAssignBackTicket = async () => {

        if (!user.roleClaims.includes("amm.hod")){
            return alert("Insufficient Account Priviledges: Only BSD HOD or BSD role(s) can route back tickets.")
        }
        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleAssignBackTicket] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/assignback"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleAssignBackTicket] response is: ', response)
            if (response.status === 200) {
                alert("Ticket has been assigned back to Requester")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    
    const handleEdit = async () => {

        var dataToSend = {
            ...inputs,
            // Name: inputs.Property_Group_Name
        }

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(inputs[item])) {
                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }
            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }
            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleEdit] dataToSend are: ', dataToSend)
        if (validationState) {

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.put(endPoint + "/propertygroup/update/", dataToSend, axiosConfig)
                console.log('put response is: ', response)
                if (response.status === 200) {
                    alert("Property Group Update Request Submitted")
                    navigate("/fb/app/home")
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    alert(JSON.stringify(err.response.data))
                }
            }
        } else {
            alert("Error: Please check your inputs and try again")
        }
    }

    const redirectToEdit = () => {
        navigate("/fb/app/home/edit-property-group", { state: inputs })
    }

    const handleCreate = async () => {

        var dataToSend = {
            ...inputs,
            // Name: inputs.Property_Group_Name
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.post(endPoint + "/propertygroup/create", dataToSend, axiosConfig)
            console.log('post response is: ', response)
            if (response.status === 200) {
                alert("Property Group Created")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        } 
    }

    const handleDelete = async (id) => {

        const endPoint = globalPublicData.baseEndpoint
            + "/propertygroup/delete"
            + "?id=" + id
            + "&modified_by=FrontendPlaceholder"

        try {
            console.log('[handleDelete] endpoint is: ', endPoint)
            const response = await axios.delete(endPoint, axiosConfig)

            console.log('delete response is: ', response)
            if (response.status === 200) {
                alert("Property Group Delete Request Submitted")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data){
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    

    return (
        <>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={5}
                >
                    {createButton.show ? 
                    <Grid item xs={12} md={2}>
                        <MDButton
                            fullWidth
                            color={createButton.color || "info"}
                            onClick={handleCreate}
                        >
                            {createButton.text}
                        </MDButton>   
                    </Grid>
                    : null}
                    {deleteButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={deleteButton.color || "error"}
                                // onClick={() => {handleDelete(inputs.id)}}
                                onClick={() => {
                                    handleConfirmation({
                                        title: "Delete Confirmation",
                                        messageLine1: "This action will proceed to submit the deletion ticket for verification/approval. Would you like to proceed?",
                                        messageLine2: "",
                                        handleSubmit: () => { handleDelete(inputs.id) }
                                    })
                                }}
                            >
                                {deleteButton.text}
                            </MDButton>
                        </Grid>
                        : null}
                    {editButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={editButton.color || "warning"}
                                onClick={editButton.redirectToEditPage ?
                                    redirectToEdit :
                                    // handleEdit
                                    () => {
                                        handleConfirmation({
                                            title: "Edit Confirmation",
                                            messageLine1: "This action will proceed to submit the edit ticket for verification/approval. Would you like to proceed?",
                                            messageLine2: "",
                                            handleSubmit: handleEdit
                                        })
                                    }
                                }
                            >
                                {editButton.text}
                            </MDButton>
                        </Grid>
                        : null}
                    {submitCreateRequestButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={submitCreateRequestButton.color || "info"}
                                // onClick={handleSubmitCreateRequest}
                                onClick={() => {
                                    handleConfirmation({
                                        title: "Create Ticket Confirmation",
                                        messageLine1: "This action will proceed to submit the creation ticket for verification/approval. Would you like to proceed?",
                                        messageLine2: "",
                                        handleSubmit: handleSubmitCreateRequest
                                    })
                                }}
                            >
                                {submitCreateRequestButton.text}
                            </MDButton>
                        </Grid>
                        : null}
                    {cancelButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={cancelButton.color || "info"}
                                // onClick={handleCancelTicket}
                                onClick={() => {
                                    handleConfirmation({
                                        title: "Cancellation Confirmation",
                                        messageLine1: "This action will proceed to revoke & cancel the ticket. Would you like to proceed?",
                                        messageLine2: "",
                                        handleSubmit: handleCancelTicket
                                    })
                                }}
                            >
                                {cancelButton.text}
                            </MDButton>
                        </Grid>

                        : null}
                    {rejectButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={rejectButton.color || "info"}
                                // onClick={handleRejectTicket}
                                onClick={() => {
                                    handleConfirmation({
                                        title: "Rejection Confirmation",
                                        messageLine1: "This action will proceed to reject the ticket. Would you like to proceed?",
                                        messageLine2: "",
                                        handleSubmit: handleRejectTicket
                                    })
                                }}
                            >
                                {rejectButton.text}
                            </MDButton>
                        </Grid>

                        : null}
                    {assignBackButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={assignBackButton.color || "info"}
                                // onClick={handleAssignBackTicket}
                                onClick={() => {
                                    handleConfirmation({
                                        title: "Reroute Confirmation",
                                        messageLine1: "Would you like to route this ticket back to " + ticket.Requester_Name + "?",
                                        messageLine2: "",
                                        handleSubmit: handleAssignBackTicket
                                    })
                                }}
                            >
                                {assignBackButton.text}
                            </MDButton>
                        </Grid>

                        : null}
                    {updateTicketButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={updateTicketButton.color || "info"}
                                // onClick={handleUpdateTicket}
                                onClick={() => {
                                    handleConfirmation({
                                        title: "Approval Confirmation",
                                        messageLine1: "This action will resubmit the ticket for approval/verification. Would you like to proceed?",
                                        messageLine2: "",
                                        handleSubmit: handleUpdateTicket
                                    })
                                }}
                            >
                                {updateTicketButton.text}
                            </MDButton>
                        </Grid>
                        : null}
                    {approveButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={approveButton.color || "info"}
                                // onClick={handleHODApproval}
                                onClick={() => {
                                    handleConfirmation({
                                        title: "Approval Confirmation",
                                        messageLine1: "This action will proceed to create the Property Group. Would you like to proceed?",
                                        messageLine2: "",
                                        handleSubmit: handleHODApproval
                                    })
                                }}
                            >
                                {approveButton.text}
                            </MDButton>
                        </Grid>
                        : null}
                </Grid>
            </MDBox>
            <AMMDialog
                open={openDialog}
                title={dialogParams.title}
                messageLine1={dialogParams.messageLine1}
                messageLine2={dialogParams.messageLine2}
                handleSubmit={dialogParams.handleSubmit}    
                setOpenDialog={setOpenDialog}                
            />
            <SuccessDialog
                open={openSuccessDialog}
                messageLine1={successDialogParams.messageLine1}
                handleSubmit={successDialogParams.handleSubmit}
                setOpenDialog={setOpenSuccessDialog}
            />
            <ErrorDialog
                open={openErrorDialog}
                messageLine1={errorDialogParams.messageLine1}
                messageLine2={errorDialogParams.messageLine2}
                setOpenDialog={setOpenErrorDialog}
            />
            </>
    )
}

export default PropertyGroupSubmitArea


PropertyGroupSubmitArea.defaultProps = {
    viewMode: false,
    disableIdField: false,
    title: "Property Group",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        redirectToEditPage: false
    },
    createButton: {
        show: false,
        text: "Create",
        color: "info"
    },
    deleteButton: {
        show: false,
        text: "Delete",
        color: "error"
    },
    submitCreateRequestButton: {
        show: false,
        text: "Submit Create Request",
        color: "info"
    },
    cancelButton: {
        show:false,
        text: "Cancel Request",
        color: "error"
    },
    assignBackButton: {
        show: false,
        text: "Route Back",
        color: "warning"
    },
    rejectButton: {
        show: false,
        text: "Reject Request",
        color: "error"
    },
    approveButton: {
        show: false,
        text: "Approve Request",
        color: "info"
    },
    updateTicketButton: {
        show: false,
        text: "Update Request",
        color: "info"
    },

};