import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import EstateSection from "page-sections/estates/EstateSection"
import PropertyGroupSection from "page-sections/property-groups/PropertyGroupSection";
import PropertyGroupSubmitArea from "page-sections/property-groups/PropertyGroupSubmitArea";
import HierarchyFormInformation from "../../page-sections/HierarchyFormInformation/HierarchyFormInformation";
import { useCookies } from "react-cookie";
import { defaultInputState, defaultInputErrorState } from "page-sections/property-groups/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";


const EditPropertyGroupPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const viewMode = false
    const disableIdField = true

    const user = useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    
    useEffect(()=>{
        if (!user.roleClaims.includes("amm.managepropertygroup")){ navigate("/fb/app/403")}
    },[])

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)


    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const handleEdit = async (event) => {
        event.preventDefault()
        var dataToSend = {
            ...inputs,
            Id: inputs.Property_Group_Id,
            Name: inputs.Property_Group_Name
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.put(endPoint + "/propertygroup/update/", dataToSend)
            console.log('put response is: ', response)
            if (response.status === 200) {
                alert("Property Group Updated")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        var dataToSend = {
            ...inputs,
            id: inputs.Property_Group_Id,
            Id: inputs.Property_Group_Id,
            Name: inputs.Property_group_Name
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.post(endPoint + "/propertygroup/create", dataToSend)
            console.log('post response is: ', response)
            if (response.status === 200) {
                alert("Property Group Created")
                navigate("/fb/app/home/home")
            }

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <PropertyGroupSection
                        title="Edit Property Group" 
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={false}
                        disableIdField={true}
                    />
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={true}
                    />
                    <MDBox p={2} mt={7}>
                    <EstatePGBuildingSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Property Group",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Property Group",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Property Group",
                            color: "error"
                        }}
                        updateTicketButton={defaultNoShowButtonAttributes}
                        updateBatchTicketButton={defaultNoShowButtonAttributes}
                        createEndpoint="/propertygroup/create"
                        updateEndpoint="/propertygroup/update"
                        deleteEndpoint="/propertygroup/delete"
                        editRedirectUrl="/fb/app/home/edit-property-group"
                        defaultInputErrorState={defaultInputErrorState}
                    />
                    </MDBox>
                    {/* <PropertyGroupSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Property Group",
                            color: "info"
                        }}
                        editButton={{
                            show: true,
                            text: "Edit Property Group",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: true,
                            text: "Delete Property Group",
                            color: "error"
                        }}
                    />    */}
                                     
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default EditPropertyGroupPage


EditPropertyGroupPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};