import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import QueryResultsHeader from "../../page-sections/queryResults/components/QueryResultsHeader";
import DataTable from "examples/Tables/DataTable";


import { tableColumnNames } from "page-sections/queryResults/utils";
import MDTypography from "components/MDTypography";



const QueryResultsPage = () => {

    const tableData = useSelector(state => state.query.tableData)
    const userInterface = useSelector(state => state.query.userInterface)

    const [resultsTable, setResultsTable] = useState()
    const [tabValue, setTabValue] = useState(userInterface.tabValue)


    useEffect( ()=>{
        if (tableData.resultsFetched && !tableData.resultsEmpty){
            console.log("[QueryResults] true code ran")
            console.log("[QueryResults] redux tableData is: ", tableData)
            console.log("[QueryResults] userInterface is: ", userInterface)
            console.log("[QueryResults] tableColumnNames is: ", tableColumnNames)

            var _tableData = {
                columns: tableColumnNames[userInterface.tabValue],
                rows: tableData[userInterface.tabValue]
            }

            console.log("[QueryResults] initial _tableData is: ", _tableData)

            setResultsTable(<DataTable canSearch table={_tableData} />)
            

        } else{
            console.log("[QueryPage] false code ran")
            console.log("[QueryResults] redux tableData is: ", tableData)
        }
        
    }, [tableData])

    const handleTableViewChange = (viewType) => {
        console.log('[handleTableViewChange] tableData is: ', tableData)
        console.log('[handleTableViewChange] tableData viewType is: ', tableData[viewType])
        var _tableData = {
            columns: tableColumnNames[viewType],
            rows: tableData[viewType]
        }
        
        setResultsTable(<DataTable canSearch table={_tableData} />)
    }
    

    return(
        <DashboardLayout>
            <DashboardNavbar />
            <QueryResultsHeader 
                my={3} 
                handleTableViewChange={handleTableViewChange}
                tabValue={tabValue}
                setTabValue={setTabValue}
            >
                <MDBox my={3}>
                    {resultsTable}
                </MDBox>        
            </QueryResultsHeader>
            <Footer />
        </DashboardLayout>
    )
}


export default QueryResultsPage