import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import EstateSection from "page-sections/estates/EstateSection"
import PropertyGroupSection from "page-sections/property-groups/PropertyGroupSection";
import PropertyGroupSubmitArea from "page-sections/property-groups/PropertyGroupSubmitArea";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import HierarchyFormInformation from "../../page-sections/HierarchyFormInformation/HierarchyFormInformation";
import { useCookies } from "react-cookie";
import { defaultInputState, defaultInputErrorState } from "page-sections/property-groups/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";


const ViewPropertyGroupPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const viewMode = true
    const disableIdField = true
    const queryParams = new URLSearchParams(location.search)

    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      } 

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [auditData, setAuditData] = useState([])

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const [editButton, setEditButton] = useState({
        show: false,
        text: "Edit",
        color: "info",
        redirectToEditPage: true
    })

    const [deleteButton, setDeleteButton] = useState({
        show: false,
        text: "delete",
        color: "error"
    })

    useEffect( ()=>{
        if (user.roleClaims.includes("amm.managepropertygroup")){
            setEditButton({
                show: true,
                text: "Edit",
                color: "info",
                redirectToEditPage: true
            })

            setDeleteButton({
                show: true,
                text: "Delete",
                color: "error"
            })
        }

    },[])

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewPropertyGroupPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/propertygroup/getpropertygroupbyuuid//"
                + idToQuery + "/true"

            console.log('[ViewPropertyGroupPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            setInputs(res.data[0])
            setAuditData(res.data)
            console.log('[ViewPropertyGroupPage][fetchData] res.data is: ', res.data)
        }

        fetchData()

    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            View Property Group
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={true}
                        inputErrorState={inputErrorState}
                    />
                    <PropertyGroupSection 
                        inputs={inputs} 
                        setInputs={setInputs}
                        handleInputChange={handleInputChange}
                        viewMode
                        disableIdField
                        inputErrorState={inputErrorState}
                    />
                    <MDBox p={2} mt={7}>     
                        <EstatePGBuildingSubmitArea
                            inputs={inputs}
                            setInputErrorState={setInputErrorState}
                            createButton={{
                                show: false,
                                text: "Create Property Group",
                                color: "info"
                            }}
                            editButton={editButton}
                            deleteButton={deleteButton}
                            updateTicketButton={defaultNoShowButtonAttributes}
                            updateBatchTicketButton={defaultNoShowButtonAttributes}
                            createEndpoint="/propertygroup/create"
                            updateEndpoint="/propertygroup/update"
                            deleteEndpoint="/propertygroup/delete"
                            editRedirectUrl="/fb/app/home/edit-property-group"
                            defaultInputErrorState={defaultInputErrorState}

                        />
                    </MDBox>
                </Card>
                <AuditTrailSection
                    auditData={auditData}
                    auditPath="/fb/app/home/view-property-group-audit"
                />     
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewPropertyGroupPage


ViewPropertyGroupPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};