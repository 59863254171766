import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";


import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";



import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import BuildingBasicInformationSection from "page-sections/building/BuildingBasicInformationSection";
import BuildingDetailedInformationSection from "page-sections/building/BuildingDetailedInformationSection";
import BuildingSubmitArea from "page-sections/building/BuildingSubmitArea";
import FMCContactInformationSection from "page-sections/contactInformationForms/FMCContactInformationSection";
import JTCContactInformationSection from "page-sections/contactInformationForms/JTCContactInformationSection";

import { defaultInputState } from "page-sections/building/utils";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "page-sections/building/utils";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import defaultNoShowButtonAttributes from "page-sections/utils/defaultNoShowButtonAttributes";

const ViewBuildingPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const viewMode = true
    const disableIdField = true

    const [inputs, setInputs] = useState(location.state || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    const [auditData, setAuditData] = useState([])

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const [editButton, setEditButton] = useState({
        show: false,
        text: "Edit",
        color: "info",
        redirectToEditPage: true
    })

    const [deleteButton, setDeleteButton] = useState({
        show: false,
        text: "delete",
        color: "error"
    })

    useEffect( ()=>{
        if (user.roleClaims.includes("amm.managebuilding")){
            setEditButton({
                show: true,
                text: "Edit",
                color: "info",
                redirectToEditPage: true
            })

            setDeleteButton({
                show: true,
                text: "Delete",
                color: "error"
            })
        }

    },[])

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewBuildingPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/building/getbuildingbyuuid//"
                + idToQuery + "/true"

            console.log('[ViewBuildingPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            setInputs(res.data[0])
            setAuditData(res.data)
            console.log('[ViewBuildingPage][fetchData] res.data is: ', res.data)
        }
        
        fetchData()
        
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            View Building
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        openCollapse={false}
                    />
                    <BuildingBasicInformationSection
                        inputs={inputs} 
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    <BuildingDetailedInformationSection
                        inputs={inputs}
                        inputErrorState={inputErrorState}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={false}
                    />
                    {/* <FMCContactInformationSection 
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    />
                    <JTCContactInformationSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    /> */}
                    <MDBox p={2} mt={7}>
                        <EstatePGBuildingSubmitArea
                            inputs={inputs}
                            setInputErrorState={setInputErrorState}
                            createButton={{
                                show: false,
                                text: "Create Building",
                                color: "info"
                            }}
                            editButton={editButton}
                            deleteButton={deleteButton}
                            updateTicketButton={defaultNoShowButtonAttributes}
                            updateBatchTicketButton={defaultNoShowButtonAttributes}
                            createEndpoint="/building/create"
                            updateEndpoint="/building/update"
                            deleteEndpoint="/building/delete"
                            editRedirectUrl="/fb/app/home/edit-building"
                            defaultInputErrorState={defaultInputErrorState}
                        />
                    </MDBox>
                    {/* <BuildingSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        createButton={{
                            show: false,
                            text: "Create Building",
                            color: "info"
                        }}
                        editButton={editButton}
                        deleteButton={deleteButton}
                    />                  */}
                </Card>  
            </MDBox>
            <AuditTrailSection
                auditData={auditData}
                auditPath="/fb/app/home/view-building-audit"
            />
            <Footer />
        </DashboardLayout>
    )
}

export default ViewBuildingPage


ViewBuildingPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};