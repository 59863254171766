/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoneAllIcon from '@mui/icons-material/DoneAll';


function OrdersOverview() {
  return (
    <>
      <MDTypography variant="h6" fontWeight="medium">
        Track Booking
      </MDTypography>
      <MDBox mt={2}>
      <TimelineItem
          color="secondary"
          icon={<EditCalendarIcon />}
          title="Booking Requested"
          dateTime="6 June 7:20 PM"
        />
        <TimelineItem
          color="secondary"
          icon={<TableRestaurantIcon />}
          title="Room Preparation Started"
          dateTime="7 June 10:21 AM"
        />
        <TimelineItem
          color="secondary"
          icon={<AssignmentTurnedInIcon />}
          title="Room Preparation Completed"
          dateTime="7 June 11:01 AM"
        />
        <TimelineItem
          color="success"
          icon={<DoneAllIcon />}
          title="Booking Completed"
          dateTime="8 JUN 4:30 PM"
          lastItem
        />
      </MDBox>
    </>
  );
}

export default OrdersOverview;
