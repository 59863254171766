// @mui material components
import Card from "@mui/material/Card";
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';

import MDBox from "components/MDBox";

import TimelineItem from "examples/Timeline/TimelineItem";
import PlusCollapse from "components/PlusCollapse";
import { useEffect, useState } from "react";
import MDAvatar from "components/MDAvatar";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import TicketComment from "components/TicketComment";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

function stringToColor(string) {
    let hash = 0;
    let i;
    
    let color = '#';
    try {
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    } catch(err){
        color = "#89CFF0"
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

function stringAvatar(name) {
    console.log("name is: ", name)

return {
    sx: {
    bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}`,
    // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
};
}


const TicketConversationSection = (props) => {

    const { ticket, setTicket, disableCommentInput } = props
    const [comment, setComment] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies()
    const user =  useSelector( state => state.user)


    useEffect(()=>{
        console.log('[TicketConversationSection] ticket received are: ', ticket)
        console.log('[TicketConversationSection] disableCommentInput: ', disableCommentInput)

    },[ticket, disableCommentInput])

    const handleCommentChange = e => {
        e.persist()
        setComment(e.target.value)
    }

    const handleAddComment = ()=>{
        setComment("") 
        // var _Comments = ticket.Comments.concat(newComments)    
        setTicket({
            ...ticket,
             Comments: [...ticket.Comments, 
                            {
                                name: user.name,
                                comment: comment,
                                timestamp: new Date().toISOString()
                            }
        ]})

    }

    return (
        <PlusCollapse title="Comments" open={true}>
            <MDBox p={2}>
                <Grid container>
                    {/* {originalCommentsSection} */}
                    {ticket.Comments.map( (comment, key) => {
                        return(
                            <TicketComment
                                key={comment.timestamp}
                                name={comment.name}
                                comment={comment.comment}
                                timestamp={comment.timestamp}
                            />
                        )
                    })}
                </Grid>
                { !disableCommentInput ? 
                <Grid container>
                    <Grid item xs={12}>
                    <MDBox component="li" display="flex" alignItems="center" py={1} mb={1}>
                        <MDBox mr={2}>
                            <Avatar {...stringAvatar(user.name)} />    
                        </MDBox>
                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" sx={{width: "100%"}} mr={2}>
                            <MDInput
                                name="newComment"
                                sx={{width: "100%"}}
                                multiline
                                variant="outlined"
                                rows={5}
                                label="Type comment here.."
                                value={comment}
                                onChange={handleCommentChange}
                            />
                        </MDBox>
                        <MDBox>
                            <MDButton onClick={handleAddComment} color="info">Enter</MDButton>
                        </MDBox>
                    </MDBox>

                    </Grid>
                </Grid>
                : null }
            </MDBox>
        </PlusCollapse>


        
    )
}

export default TicketConversationSection


TicketConversationSection.defaultProps = {
    auditData: [],
    auditPath: "/dashboard"
};

