/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import Notifications from "layouts/pages/notifications";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
// import { HomePage } from "layouts/home/Home";
import HomePage from "pages/HomePage";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import HomeIcon from '@mui/icons-material/Home';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';


// Images
import profilePicture from "assets/images/team-3.jpg";

// AMM Pages
import QueryBuilderPage from "pages/query/QueryBuilderPage";
import QueryResultsPage from "pages/query/QueryResultsPage";

//Estate Pages
import BatchImportBuildingsPage from "pages/buildings/BatchImportBuildingsPage";
import CreateBuildingPageFMOps from "pages/buildings/CreateBuildingPageFMOps";
import BatchImportEstatesPage from "pages/estates/BatchImportEstatesPage";
import BatchImportPropertyGroupsPage from "pages/property-groups/BatchImportPropertyGroupsPage";
import BatchImportSpacesPage from "pages/spaces/BatchImportSpacesPage";
import BatchImportAssetsPage from "pages/assets/BatchImportAssetsPage";

import CreateEstateTicketPage from "pages/estates/CreateEstateTicketPage";
import CreatePropertyGroupTicketPage from "pages/property-groups/CreatePropertyGroupTicketPage";
import CreateBuildingTicketPage from "pages/buildings/CreateBuildingTicketPage";
import CreateSpaceTicketPage from "pages/spaces/CreateSpaceTicketPage";
import CreateAssetTicketPage from "pages/assets/CreateAssetTicketPage";
import CreateBatchTicketPage from "pages/batchCreateTicket/CreateBatchTicketPage";
import BSDHomePage from "pages/BSDHomePage";


const routes = [
  // {
  //   type: "collapse",
  //   name: "Brooklyn Alice",
  //   key: "brooklyn-alice",
  //   icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  //   collapse: [
      // {
      //   name: "My Profile",
      //   key: "my-profile",
      //   route: "/fb/app/pages/profile/profile-overview",
      //   component: <ProfileOverview />,
      // },
  //     {
  //       name: "Change Password",
  //       key: "change-password",
  //       route: "/fb/app/pages/account/settings",
  //       component: <Settings />,
  //     },
  //     {
  //       name: "Logout",
  //       key: "logout",
  //       route: "/fb/app/authentication/sign-in/basic",
  //       component: <SignInBasic />,
  //     },
  //   ],
  // },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Home",
    key: "dashboards",
    icon: <HomeIcon />,
    route: "/fb/app/home",
    component: <HomePage />,
    noCollapse: true,
    
  },
  {
    type: "collapse",
    name: "Search",
    key: "search",
    icon: <ManageSearchIcon />,
    collapse: [
      {
        name: "Search",
        key: "search",
        route: "/fb/app/home/search",
        component: <QueryBuilderPage />
      },
      {
        name: "Search Results",
        key: "results",
        route: "/fb/app/home/search/results",
        component: <QueryResultsPage />
      }
    ]
  },
  {
    type: "collapse",
    name: "Create",
    key: "create-request",
    icon: <NoteAddIcon />,
    collapse: [
      {
        name: "Estate",
        key: "estate",
        route: "/fb/app/home/create-request/estate",
        component: <CreateEstateTicketPage />,
      },
      {
        name: "Property Group",
        key: "property-group",
        route: "/fb/app/home/create-request/property-group",
        component: <CreatePropertyGroupTicketPage />,
      },
      {
        name: "Building",
        key: "building",
        route: "/fb/app/home/create-request/building",
        component: <CreateBuildingTicketPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Batch Create",
    key: "batch-create",
    icon: <FileUploadIcon />,
    collapse: [
      {
        name: "Estates",
        key: "estates",
        route: "/fb/app/home/batch-create/estate",
        component: <BatchImportEstatesPage />,
      },
      {
        name: "Property Groups",
        key: "property-groups",
        route: "/fb/app/home/batch-create/property-group",
        component: <BatchImportPropertyGroupsPage />,
      },
      {
        name: "Buildings",
        key: "buildings",
        route: "/fb/app/home/batch-create/buildings",
        component: <BatchImportBuildingsPage />,
      },
      {
        name: "Spaces",
        key: "spaces",
        route: "/fb/app/home/batch-create/spaces",
        component: <BatchImportSpacesPage />,
      },
      {
        name: "Assets",
        key: "assets",
        route: "/fb/app/home/batch-create/assets",
        component: <BatchImportAssetsPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Batch Update",
    key: "batch-update",
    icon: <DynamicFeedIcon />,
    collapse: [
      {
        name: "Estates",
        key: "estates",
        route: "/fb/app/home/batch-update/estate",
        component: <CreateBatchTicketPage title="Batch Update Estate" endpoint="/estate/batchimport/update" />,
      },
      {
        name: "Property Groups",
        key: "property-groups",
        route: "/fb/app/home/batch-update/property-group",
        component: <BatchImportPropertyGroupsPage />,
      },
      {
        name: "Buildings",
        key: "buildings",
        route: "/fb/app/home/batch-update/buildings",
        component: <BatchImportBuildingsPage />,
      },
      {
        name: "Spaces",
        key: "spaces",
        route: "/fb/app/home/batch-update/spaces",
        component: <BatchImportSpacesPage />,
      },
      {
        name: "Assets",
        key: "assets",
        route: "/fb/app/home/batch-update/assets",
        component: <BatchImportAssetsPage />,
      },
    ],
  },
];

export default routes;
