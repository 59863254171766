// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';


import DataTable from "examples/Tables/DataTable";

import {ticketColumns } from "./utils";
import { Skeleton } from "@mui/material";

const TicketTable = (props) => {
    
    const {title, ticketData, defaultValue, loading} = props

    return(
        <MDBox my={5}>
            <Card sx={{ width: "100%"}}>
                <MDBox display="flex">
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        variant="gradient"
                        bgColor="dark"
                        color="white"
                        shadow="md"
                        borderRadius="xl"
                        ml={3}
                        mt={-2}
                    >
                        <PlaylistAddCheckOutlinedIcon color="inherit" fontSize="medium" />
                    </MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                        {title}
                    </MDTypography>
                </MDBox>
                <MDBox p={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            {loading ? 
                            <div>
                                <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
                                <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
                                <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
                                <Skeleton variant="text" sx={{fontSize: '1rem'}}/>
                                <Skeleton variant="text" sx={{fontSize: '1rem'}}/>        
                            </div>
                            :
                            <DataTable 
                                table={ticketData} 
                                canSearch 
                                entriesPerPage={{ defaultValue: defaultValue }}
                            />
                            }
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>
        </MDBox>
    )


}

export default TicketTable

TicketTable.defaultProps = {
    title: "Tickets",
    ticketData: {rows: [], columns: ticketColumns},
    defaultValue: 5,
    loading: true
}