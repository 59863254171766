// import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "./utils";
import validateEmptyString from "datavalidation/validateEmptyString";


const BuildingSubmitArea = (props) => {

    const navigate = useNavigate()
    const { 
        inputs, 
        createButton, 
        submitButton, 
        editButton, 
        deleteButton, 
        submitCreateRequestButton, 
        approveButton, 
        assignBackButton,
        rejectButton,
        cancelButton,
        updateTicketButton, 
        setInputErrorState,
        ticket } = props
    
    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const handleSubmitCreateRequest = async () => {

        var dataToSend = {
            ...inputs,
            Name: inputs.External_Facing_Name
        }

        if (dataToSend.Estate_uuid){delete dataToSend.Estate_uuid}

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(dataToSend[item])) {
                console.log("item failed validation: ", item)
                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }
            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }
            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
                console.log("_inputErrorState is: ", _inputErrorState)
            } else {
                validationState = true
            }
        })


        console.log('[handleSubmit] dataToSend are: ', dataToSend)
        console.log('[handleSubmit] validationState is: ', validationState)
        if (validationState) {

            console.log('[handleSubmit] dataToSend are: ', dataToSend)

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.post(endPoint + "/building/create", dataToSend, axiosConfig)
                console.log('post response is: ', response)
                if (response.status === 200) {
                    alert("Building Create Request Submitted")
                    navigate("/fb/app/home")
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    alert(JSON.stringify(err.response.data))
                }
            }
        } else {
                alert("Error: Please check your inputs and try again")
        }
    }

    const handleHODApproval = async () => {

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.put(endPoint + "/ammticket/hod/approve", dataToSend, axiosConfig)
            console.log('[handleHODApproval] response is: ', response)
            if (response.status === 200) {
                alert("Building Request Approved")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleUpdateTicket = async () => {

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(dataToSend[item])) {
                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }
            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }
            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleUpdateTicket] dataToSend are: ', dataToSend)
        console.log('[handleUpdateTicket] validationState is: ', validationState)
        if (validationState){

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.put(endPoint + "/ammticket/update", dataToSend, axiosConfig)
                console.log('[handleUpdateTicket] response is: ', response)
                if (response.status === 200) {
                    alert("Request Re-Submitted")
                    navigate("/fb/app/home")
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    alert(JSON.stringify(err.response.data))
                }
            }
        } else {
                alert("Error: Please check your inputs and try again")
        }
    }

    const handleCancelTicket = async () => {

        if (user.email !== ticket.Requester_Email){
            return alert("Insufficient Account Priviledges: You can only reject tickets that you have raised.")
        }

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/cancel"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleCancelTicket] response is: ', response)
            if (response.status === 200) {
                alert("Building Request Cancelled")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleRejectTicket = async () => {

        if (!user.roleClaims.includes("amm.hod")){
            return alert("Insufficient Account Priviledges: Only BSD HOD or BSD role(s) can reject tickets.")
        }
        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleRejectTicket] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/reject"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleRejectTicket] response is: ', response)
            if (response.status === 200) {
                alert("Ticket Rejected")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleAssignBackTicket = async () => {

        if (!user.roleClaims.includes("amm.hod")){
            return alert("Insufficient Account Priviledges: Only BSD HOD or BSD role(s) can route back tickets.")
        }
        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleAssignBackTicket] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/assignback"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleAssignBackTicket] response is: ', response)
            if (response.status === 200) {
                alert("Ticket has been assigned back to Requester")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleEdit = async () => {

        var dataToSend = {
            ...inputs,
            // Id: inputs.Building_Id,
            Name: inputs.FM_Internal_Name
        }
        if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(dataToSend[item])) {
                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }
            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }
            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleEdit] dataToSend are: ', dataToSend)
        console.log('[handleEdit] validationState is: ', validationState)
        if (validationState) {

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.put(endPoint + "/building/update/", dataToSend, axiosConfig)
                console.log('put response is: ', response)
                if (response.status === 200) {
                    alert("Building Update Request Submitted")
                    navigate("/fb/app/home")
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    alert(JSON.stringify(err.response.data))
                }
            }
        } else {
                alert("Error: Please check your inputs and try again")
        }
    }

    const redirectToEdit = () => {
        navigate("/fb/app/home/edit-building", { state: inputs })
    }

    const handleDelete = async (id) => {

        const endPoint = globalPublicData.baseEndpoint
            + "/building/delete"
            + "?id=" + id
            + "&modified_by=FrontendPlaceholder"

        try {
            console.log('[handleDelete] endpoint is: ', endPoint)
            const response = await axios.delete(endPoint, axiosConfig)

            console.log('delete response is: ', response)
            if (response.status === 200) {
                alert("Building Delete Request Submitted")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    

    return (
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={5}
                >
                    
                    {deleteButton.show ?
                    <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={deleteButton.color || "error"}
                                onClick={() => {handleDelete(inputs.id)}}
                            >
                                {deleteButton.text}
                            </MDButton>
                        </Grid>
                    : null}
                    {editButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={editButton.color || "warning"}
                            onClick={editButton.redirectToEditPage ? redirectToEdit : handleEdit}
                            >
                                {editButton.text}
                            </MDButton>    
                        </Grid>
                    : null}
                    {/* {createButton.show ? 
                    <Grid item xs={12} md={2}>
                        <MDButton
                            fullWidth
                            color={createButton.color || "info"}
                            onClick={handleCreate}
                        >
                            {createButton.text}
                        </MDButton>   
                    </Grid>
                    : null}
                    {returnToFMOpsCreate.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={returnToFMOpsCreate.color || "info"}
                                onClick={handleSubmitCreateRequest}
                            >
                                {returnToFMOpsCreate.text}
                            </MDButton>
                        </Grid>
                    : null} */}
                    {submitCreateRequestButton.show ? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={submitCreateRequestButton.color || "info"}
                                onClick={handleSubmitCreateRequest}
                            >
                                {submitCreateRequestButton.text}
                            </MDButton>
                        </Grid>
                    : null}
                    {cancelButton.show? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={cancelButton.color || "info"}
                                onClick={handleCancelTicket}
                            >
                                {cancelButton.text}
                            </MDButton>
                        </Grid>
                
                    : null}
                    {rejectButton.show? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={rejectButton.color || "info"}
                                onClick={handleRejectTicket}
                            >
                                {rejectButton.text}
                            </MDButton>
                        </Grid>
                
                    : null}
                    {assignBackButton.show? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={assignBackButton.color || "info"}
                                onClick={handleAssignBackTicket}
                            >
                                {assignBackButton.text}
                            </MDButton>
                        </Grid>
                
                    : null}
                    {updateTicketButton.show ?
                    <Grid item xs={12} md={2}>
                        <MDButton
                            fullWidth
                            color={updateTicketButton.color || "info"}
                            onClick={handleUpdateTicket}
                        >
                            {updateTicketButton.text}
                        </MDButton>
                    </Grid>
                    : null}
                    {approveButton.show ? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={approveButton.color || "info"}
                                onClick={handleHODApproval}
                            >
                                {approveButton.text}
                            </MDButton>
                        </Grid>
                    : null}


                </Grid>
            </MDBox>
    )
}

export default BuildingSubmitArea


BuildingSubmitArea.defaultProps = {
    viewMode: false,
    disableIdField: false,
    title: "Building",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        redirectToEditPage: false
    },
    createButton: {
        show: false,
        text: "Create",
        color: "info"
    },
    deleteButton: {
        show: false,
        text: "Delete",
        color: "error"
    },
    submitCreateRequestButton: {
        show: false,
        text: "Submit Create Request",
        color: "info"
    },
    cancelButton: {
        show:false,
        text: "Cancel Request",
        color: "error"
    },
    assignBackButton: {
        show: false,
        text: "Route Back",
        color: "warning"
    },
    rejectButton: {
        show: false,
        text: "Reject Request",
        color: "error"
    },
    approveButton: {
        show: false,
        text: "Approve Request",
        color: "info"
    },
    updateTicketButton: {
        show: false,
        text: "Update Request",
        color: "info"
    }
};