import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  query: {
    name: "",
    is_exact_match: false,
    estate_ids: [],
    property_group_ids: [],
    building_ids: [],
    space_ids: [],
    estates: [],
    property_groups: [],
    buildings: [],
    spaces: [],
    is_deleted: false,
    searchTabValue: "is_fuzzy_match"
  },
  results: {
    resultsFetched: false,
    resultsEmpty: true,
    queriedEstates: [],
    queriedProperties: [],
    queriedBuildings: [],
    queriedBuildingSpaces: [],
    queriedAssets: []
  },
  tableData: {
    estates: {},
    propertyGroups: {},
    buildings: {},
    spaces: {},
    assets: {},
    resultsFetched: false,
    resultsEmpty: true
  },
  userInterface: {
    tabValue: "estates",
    tabOptions: [
      { label: "Estates", value: "estates" },
      { label: "Property Groups", value: "propertyGroups" },
      { label: "Buildings", value: "buildings" },
      { label: "Spaces", value: "spaces" },
      { label: "Assets", value: "assets" },
    ]
  }
};


const querySlice = createSlice({
    name: 'query',
    initialState,
    reducers: {
        updateQuery(state, action){
          state.query = action.payload
        },
        updateQueryResults(state, action){
          state.results = action.payload
        },
        updateTableData(state, action) {
          state.tableData = action.payload
        },

        updateUITabOptions(state, action){
          state.userInterface.tabOptions = action.payload
        },

        updateUITabValue(state, action) {
          state.userInterface.tabValue = action.payload
        },
        
        resetQueryState( state ){
          state.query = {
            name: "",
            is_exact_match: true,
            estate_ids: [],
            property_group_ids: [],
            building_ids: [],
            space_ids: [],
            estates: [],
            property_groups: [],
            buildings: [],
            spaces: [],
            is_deleted: false,
            searchTabValue: "is_exact_match"
          }
          state.results = {
            resultsFetched: false,
            resultsEmpty: true,
            queriedEstates: [],
            queriedProperties: [],
            queriedBuildings: [],
            queriedBuildingSpaces: [],
            queriedAssets: []
          }
          state.tableData = {
            estates: { },
            propertyGroups: { },
            buildings: { },
            spaces: { },
            assets: { },
            resultsFetched: false,
            resultsEmpty: true
          }
          state.userInterface = {
            tabValue: "estates",
            tabOptions: [
              { label: "Estates", value: "estates" },
              { label: "Property Groups", value: "propertyGroups" },
              { label: "Buildings", value: "buildings" },
              { label: "Spaces", value: "spaces" },
              { label: "Assets", value: "assets" },
            ]
          }
        },
    }
})

export const { 
  updateQuery,
  updateQueryResults,
  updateTableData,
  updateUITabOptions,
  updateUITabValue,
  resetQueryState
  } = querySlice.actions

export default querySlice.reducer