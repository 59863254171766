// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

import BatchInstructionsSection from "page-sections/batchupload/BatchInstructionsSection";
import BatchUploadSection from "page-sections/batchupload/BatchUploadSection";
import { globalPublicData } from "globalPublicData";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ProgressSectionBSD from "page-sections/approvalSections/ProgressSectionBSD";
import ProgressSectionSpaceAsset from "page-sections/approvalSections/ProgressSectionSpaceAsset";



const CreateBatchTicketPage = (props) => {

    const navigate = useNavigate()    
    const user = useSelector(state => state.user)
    const [file, setFile] = useState()
    const {title, endpoint, type} = props

    useEffect(()=>{
        if (
            (endpoint === "/estate/batchimport/update" && !user.roleClaims.includes("amm.batchimportestate")) ||
            (endpoint === "/propertygroup/batchimport/update" && !user.roleClaims.includes("amm.batchimportpropertygroup")) ||
            (endpoint === "/building/batchimport/update" && !user.roleClaims.includes("amm.batchimportbuilding")) ||
            (endpoint === "/space/batchimport/update" && !user.roleClaims.includes("amm.batchimportspace")) ||
            (endpoint === "/asset/batchimport/update" && !user.roleClaims.includes("amm.batchimportasset")) 
        ){
            navigate("/fb/app/403")
        } 
    },[])

    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <Card sx={{ width: "100%" }}>
                        {(type === "BSD") ? <ProgressSectionBSD activeStep={0} /> : <ProgressSectionSpaceAsset activeStep={0} />}
                        <MDBox display="flex">
                            <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                                {title}
                            </MDTypography>
                        </MDBox>
                        <BatchInstructionsSection />
                        <BatchUploadSection
                            file={file}
                            setFile={setFile}
                            uploadEndpoint={globalPublicData.baseEndpoint + endpoint}
                        />
                    </Card>  
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreateBatchTicketPage


CreateBatchTicketPage.defaultProps = {
    title: "Batch Create Estates",
    endpoint: "/estate/batchimport/create",
    type: "BSD"
    // createMode: true,
};