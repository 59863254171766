// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import BatchApprovalSection from "page-sections/batchupload/BatchApprovalSection";



const ViewBatchCreateTicketPage = (props) => {

    const { title } = props
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user = useSelector(state => state.user)
    var ticket_uuid = queryParams.get("id")

    useEffect(()=>{
        if (!user.roleClaims.includes("amm.viewticket")){ navigate("/fb/app/403")}
    },[])

    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <Card sx={{ width: "100%" }}>
                        <MDBox display="flex">
                            <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                                {title}    
                            </MDTypography>
                        </MDBox>
                        <BatchApprovalSection
                            ticket_uuid={ticket_uuid}
                        />
                    </Card>  
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewBatchCreateTicketPage


ViewBatchCreateTicketPage.defaultProps = {
    title: "Batch Import Ticket",
    viewMode: false,
    disableIdField: false
};