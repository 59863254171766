/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// Data
import BasicLayout from "layouts/BasicLayout";
import DefaultPageHeader from "page-sections/DefaultPageHeader";
import meetingRoom1 from "assets/images/meeting-room-1.jpg"
import meetingRoom2 from "assets/images/meeting-room-2.jpg"
import meetingRoom3 from "assets/images/meeting-room-3.jpg"
import meetingRoom4 from "assets/images/meeting-room-4.jpg"
import meetingRoom5 from "assets/images/meeting-room-5.jpg"
import { useNavigate } from "react-router-dom";
import SearchResultCard from "./components/SearchResultCard";

import sampleFacilityData from "./data/sampleFacilityData";

function SearchResults() {
  const navigate = useNavigate()
  const [menu, setMenu] = useState(null);


  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const filterByStatus = (status) => {

    closeMenu()
  }

  const cardStyles = {
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  }

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={()=>{filterByStatus("completed")}}>Status: Completed</MenuItem>
      <MenuItem onClick={()=>{filterByStatus("refunded")}}>Status: Refunded</MenuItem>
      <MenuItem onClick={()=>{filterByStatus("in-progress")}}>Status: In-Progress</MenuItem>
      <MenuItem onClick={()=>{filterByStatus("requested")}}>Status: Requested</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={()=>{}}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );
  

  return (
    <BasicLayout user>
      <DefaultPageHeader title="Search Results" />
      <MDBox my={3} mx={3}>
        <MDBox display="flex" justifyContent="space-between" sx={{width: "100%"}} alignItems="flex-end" mb={2}>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "contained"} color="info" onClick={openMenu}>
              filters&nbsp;
              <ArrowDropDownIcon fontSize="large" />
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton color="info" onClick={()=>{navigate("/fb/app/home/view-facility")}}>
                export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {sampleFacilityData.map( (item, key) => {
          return(
          <SearchResultCard
            key={key}
            title={item.title}
            description1={item.description1}
            description2={item.description2}
            img={item.img}
            data={item}
          />
          )
        })}
      </MDBox>
    </BasicLayout>
  );
}

export default SearchResults;
