export const defaultInputState = {
    // Name: "",
    Id: "",
    Estate_Id: "",
    Estate_Name: "",
    Property_Group_Id: "",
    Property_Group_Name: "",
    Building_Id: "",
    FM_Internal_Name: "",
    External_Facing_Name: "",
    Gross_Floor_Area: null,
    JTC_Address: "",
    Postal_Code: null,
    Longtitude: 0,
    Latitude: 0,
    Department_Id: "",
    FMC_Id: "",
    FEMS_Building_System_Code: null,
    Building_Short_Name: "",
    Number_Of_Floors: null,
    Remarks: "",
    Periodic_Structural_Inspection_Date: new Date().toISOString(),
    Green_Mark_Award: "",
    Green_Mark_Certification_Expiry_Date: new Date().toISOString(),
    Building_Type: "",
    // FMC_Company: "",
    // FMC_Contact_Person_Name: "",
    // FMC_Contact_Person_Email: "",
    // FMC_Contact_Person_Mobile: "",
    // FMC_Covering_Person_Name: "",
    // FMC_Covering_Person_Email: "",
    // FMC_Covering_Person_Mobile: "",
    // JTCPE_Contact_Person_Name: "",
    // JTCPE_Contact_Person_Email: "",
    // JTCPE_Contact_Person_Mobile: "",
    // JTCPE_Covering_Person_Name: "",
    // JTCPE_Covering_Person_Email: "",
    // JTCPE_Covering_Person_Mobile: "",
    // JTCTL_Contact_Person_Name: "",
    // JTCTL_Contact_Person_Email: "",
    // JTCTL_Contact_Person_Mobile: "",
    // JTCTL_Covering_Person_Name: "",
    // JTCTL_Covering_Person_Email: "",
    // JTCTL_Covering_Person_Mobile: "",
    // JTCHOD_Contact_Person_Name: "",
    // JTCHOD_Contact_Person_Email: "",
    // JTCHOD_Contact_Person_Mobile: "",
    // JTCHOD_Covering_Person_Name: "",
    // JTCHOD_Covering_Person_Email: "",
    // JTCHOD_Covering_Person_Mobile: "",

}

export const defaultInputErrorState = {
    Id: { state: false, message: "" },
    Name: { state: false, message: "" },
    FM_Internal_Name: { state: false, message: "" },
    External_Facing_Name: { state: false, message: "" },
    Building_Short_Name: { state: false, message: "" },
    JTC_Address: { state: false, message: "" },
    Postal_Code: { state: false, message: "" },
    Department_Id: { state: false, message: "" },
    FMC_Id: { state: false, message: "" },
    Estate_Id: { state: false, message: "" },
    Estate_Name: { state: false, message: "" },
    Property_Group_Id: { state: false, message: "" },
    Property_Group_Name: { state: false, message: "" },

}

export const convertTextToObject = (text) => {
    console.log('text is: ', text)
    const newText = text.toString()
    console.log('newText is: ', newText)
    var lines = []
    var _linesBefCleanup = text.split("\n")
    _linesBefCleanup.map( str => {
        var cleanedStr = str.replace("\\r", "")
        lines.push(cleanedStr)
    })
    console.log('linesBefCleanup are: ', _linesBefCleanup)
    console.log('lines are: ', lines)
    var headers = _linesBefCleanup[0].split(",")
    console.log('headers are: ', headers)

}