const propertyGroupExcelHeaders = [
    "Property Group Id",
    "FMC Id",
    "Department Id",
    "Estate Id",
    "Name",
    "Property Type",
    "Gross Floor Area",
]

export default propertyGroupExcelHeaders