import { useCallback, useEffect, useState } from "react";
import axios from "axios"
import validator from "validator";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card"
// @mui icons
import SyncIcon from '@mui/icons-material/Sync';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Label } from "@mui/icons-material";
import { FormHelperText, FormLabel, Grid, Switch } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { globalPublicData } from "globalPublicData";
import useCookies from "react-cookie/cjs/useCookies";
import { useDispatch } from "react-redux";
import { 
  updateToken,
  clearUser,
  updateUserDetails,
  updateRolesAndRoleClaims,
} from "redux/features/user/userSlice";
import ErrorDialog from "components/ErrorDialog";
import CircularProgress from '@mui/material/CircularProgress';
import { updateUserType } from "redux/features/userInterface/userInterfaceSlice";
// import { updateRolesAndRoleClaims } from "redux/features/user/userSlice";

function SignInPage() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [inputs, setInputs] = useState({
    user_name: "",
    password: ""
  })

  const [userNameError, setUserNameError] = useState({
    state: false,
    label: ""
  })

  const [passwordError, setPasswordError] = useState({
    state: false,
    label: ""
  })

  const [buttonClicked, setButtonClicked] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'fruit'])

  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: ""})

  const [signInButtonColor, setSignInButtonColor] = useState("info")
  // const [rememberMe, setRememberMe] = useState(false);

  
  const handleInputChange = (event) => {
    event.persist()
    setInputs({ ...inputs, [event.target.name]: event.target.value })
  }
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('verify');
    const endpoint = globalPublicData.baseEndpoint 
    + "/common/getgrecaptchaverification?token=" 
    + token

    const response = await axios.get(endpoint)
    console.log('grecaptcha response is: ', response)
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const generalInputsErrorHandler = (messageLine1, messageLine2) => {
    setErrorDialogParams({
        messageLine1: messageLine1,
        messageLine2: messageLine2
    })
    // setOpenDialog(false)
    setOpenErrorDialog(true)
}

const handleSetRememberMe = ()=>{

}

  const invalidInputsErrorHandler = () => {
    setErrorDialogParams({
        messageLine1: "Error: Please check your inputs and try again"
    })
    // setOpenDialog(false)
    setOpenErrorDialog(true)
}

const identifiedErrorHandler = (errData)=>{
        setErrorDialogParams({
            messageLine1: "Apologies, an error has occured.",
            messageLine2: JSON.stringify(errData)
        })
        // setOpenDialog(false)
        setOpenErrorDialog(true)
    }

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSignIn = async () => {

    setButtonClicked(true)
    console.log('validator for email is: ', validator.isEmail(inputs.user_name))
    
    var validationState = false
    var _userNameError = {
      state: false,
      label: ""
    }

    var _passwordError = {
      state: false,
      label: ""
    }

    var messageLine1 = ""
    var messageLine2 = ""
    
    if (validator.isEmpty(inputs.user_name)){
      messageLine1 = "Please input your email address"
      _userNameError = {state: true, label: messageLine1}
      setUserNameError(_userNameError)
    } else if (!validator.isEmail(inputs.user_name)){
      messageLine1 = "Please enter a valid email address"
      _userNameError = {state: true, label: messageLine1}
      setUserNameError(_userNameError)
    } else {
      setUserNameError({state: false, label: ""})
    }

    if (validator.isEmpty(inputs.password)){
      messageLine2 = "Please input your password"
      _passwordError = {state: true, label: messageLine2}
      setPasswordError(_passwordError) 
    } else {
      setPasswordError({state: false, label: ""})
    }
    
    if (_userNameError.state === false && _passwordError.state === false){

      validationState = true

    } else {
      invalidInputsErrorHandler()
      setButtonClicked(false)
      validationState = false
    }
    
    if (validationState === true){
      const endpoint = globalPublicData.baseEndpoint + "/systemaccess/login"
      const validationEndpoint = globalPublicData.baseEndpoint + "/systemaccess/validate_token"
      navigate("/fb/app/home")
      // try {
      //   const response = await axios.post(endpoint, inputs)
      //   console.log('response is: ', response)
      //   if (response.status !== 200){
      //     if (response.status === 401 || response.status === 403){
      //       generalInputsErrorHandler("Signin Failure. Please check your inputs and try again")
      //     } else {
      //       generalInputsErrorHandler("Please check your inputs and try again")
      //     }
      //     setButtonClicked(false)
      //     throw "Log in Failure"
      //   } else {
      //       const bearer_token = `Bearer ${response.data.token}`;
      //       const axiosGetConfig = {
      //         headers: {
      //           Authorization: bearer_token
      //         }
      //       }
      //       dispatch(updateToken(response.data.token))

      //       if (response.data["require_change_password"]){
      //         setSignInButtonColor("success")
      //         navigate("/fb/app/home/change-password")
      //       } else {
      //         const userObject = await axios.get(validationEndpoint, axiosGetConfig)
      //         setSignInButtonColor("success")
      //         console.log("userObject is: ", userObject)
      //         dispatch(updateUserDetails(userObject.data))
      //         dispatch(updateRolesAndRoleClaims(userObject.data.roles))
      //         dispatch(updateUserType(userObject.data.roles))
      //         navigate("/fb/app/home")
      //       }
      //       setButtonClicked(false)
        // }
      // } catch (err){
      //   console.log('[handleSignIn] error: ', err)
      //   if (err.response.data.errors[0].error_code){
      //     if (err.response.data.errors[0].error_code === "1010107"){
      //       generalInputsErrorHandler("Signin Failure. Please check your inputs and try again")
      //     } 
      //   } else {
      //     identifiedErrorHandler(err.response.data.errors)
      //   }
      //   setButtonClicked(false)
      // }
    } else {
      generalInputsErrorHandler("Please check your inputs and try again")
      setButtonClicked(false)
    }
  }



  return (
    <BasicLayout image={bgImage}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={9} md={8} xl={6}>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={1}>
              <FormLabel sx={{marginBottom: "5px", paddingBottom: "5px", lineHeight: "2px"}}>
                <MDTypography fontWeight="medium" variant="subtitle2">Please enter your work email address*</MDTypography>
              </FormLabel>
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                type="email" 
                label="Email" 
                name="user_name" 
                onChange={handleInputChange} 
                fullWidth
                error={userNameError.state}
              />
              <FormHelperText error={userNameError.state}>
                <strong>{userNameError.label}</strong>
              </FormHelperText>
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                type="password" 
                label="Password" 
                name="password" 
                onChange={handleInputChange} 
                fullWidth 
                error={passwordError.state}
              />
              <FormHelperText error={passwordError.state}>
                <strong>{passwordError.label}</strong>
              </FormHelperText>
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}> 
              <MDButton 
                onClick={handleSignIn} 
                variant="gradient" 
                color={signInButtonColor} 
                fullWidth
                disabled={buttonClicked}
              >
                {buttonClicked ? 
                  <CircularProgress color={"white"} sx={{ maxHeight: "20px", maxWidth: "20px"}}/>
                  : "Sign In"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} ml={-3} textAlign="left">
              <MDButton 
                variant="text" 
                color="primary"
                onClick={()=>{navigate("/fb/app/forget-password")}}
              >
                Forget Password?
              </MDButton>
              {/* <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forget Password?
                </MDTypography>
              </MDTypography> */}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      </Grid>
      </Grid>
      <ErrorDialog
          open={openErrorDialog}
          messageLine1={errorDialogParams.messageLine1}
          messageLine2={errorDialogParams.messageLine2}
          setOpenDialog={setOpenErrorDialog}
      />
    </BasicLayout>
  );
}

export default SignInPage;
