// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MDInput from "components/MDInput";

import MDButton from "components/MDButton";

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const AssetCustomFieldRead = (props) => {

    const { inputs, handleInputChange, setInputs, viewMode, fieldData, allowDelete, filteredDataKeys, setFilteredDataKeys, setCustomAttributes } = props


    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    const handleDeleteCustomField = () => {
        setFilteredDataKeys( current => current.filter( item => item !== fieldData.key))
        const _inputs = inputs
        delete _inputs[fieldData.key]
        setInputs(_inputs)
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={3}
            mb={3}
        >
            <Grid
                item
                xs={12}
                md={viewMode ? 7 : 8}
            >
                <InputLabel sx={inputLabelStyles}>
                    <strong>{fieldData.label}</strong>
                </InputLabel>
                <MDInput
                    variant="outlined"
                    name={fieldData.label}
                    value={fieldData.value}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={viewMode}
                />
            </Grid>
            {allowDelete ? 
            <Grid item alignItems="center"
                justifyContent="center"
                xs={12} md={2}
            >
                <MDButton
                    color="error"
                    variant="gradient"
                    size="large"
                    sx={{ marginTop: "20px" }}
                    onClick={handleDeleteCustomField}
                >
                    <DeleteIcon />
                </MDButton>
            </Grid>
            : null
            }
        </Grid>
    )
}

export default AssetCustomFieldRead

AssetCustomFieldRead.defaultProps = {
    viewMode: true,
    fieldData: {
        key: "test",
        label: "label",
        value: "value"
    }
};