/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav,setOpenConfigurator, setLayout } from "context";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Sidenav from "examples/Sidenav";
import { globalPublicData } from "globalPublicData";
import routes from "routes";
import routes_base from "routes_base"
import routes_fmc from "routes_fmc"
import routes_fmops from "routes_fmops"
import routes_bsd from "routes_bsd"
import routes_bsdhod from "routes_bsdhod"

import SettingsIcon from '@mui/icons-material/Settings';
import MenuOpen from "@mui/icons-material/MenuOpen";
import MenuIcon from '@mui/icons-material/Menu';

import brandWhite from "assets/images/jtc_logo_white.png";
import brandDark from "assets/images/jtc_logo_white.png";
import Configurator from "examples/Configurator";
import { useCookies } from "react-cookie";
// import { updateUserDetails } from "redux/features/user/userSlice";
import {updateUserDetails, updateRolesAndRoleClaims } from "redux/features/user/userSlice";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const location = useLocation()

  // const user = useSelector(state => state.user)
  const [cookies, setCookie, removeCookie] = useCookies()
  const [dashboardRoutes, setDashboardRoutes] = useState(routes)

  const reduxDispatch = useDispatch()

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);


  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <SettingsIcon color="inherit"/>
    </MDBox>
  );

  const sidebarButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleMiniSidenav}
    >
      {miniSidenav ?  <MenuIcon color="inherit" fontSize="medium" /> : <MenuOpen color="inherit" fontSize="medium" />}
    </MDBox>
  );
  

  const user = useSelector(state => state.user)

  // useEffect( ()=>{
  //   if (user.roleClaims.includes("amm.fmc")){
  //     setDashboardRoutes(routes_fmc)
  //   } else if (user.roleClaims.includes("amm.fmops")){
  //     setDashboardRoutes(routes_fmops)
  //   } else if (user.roleClaims.includes("amm.bsd")){
  //     setDashboardRoutes(routes_bsd)
  //   } else if (user.roleClaims.includes("amm.hod")){
  //     setDashboardRoutes(routes_bsdhod)
  //   } else {
  //     setDashboardRoutes(routes_base)
  //   }

  // },[user])
  
  // const validateToken = async (token) => {
  //   const validationEndpoint = globalPublicData.baseEndpoint + "/systemaccess/validate_token"
  //   const bearer_token = `Bearer ${token}`;
  //   const axiosGetConfig = {
  //     headers: {
  //       Authorization: bearer_token
  //     }
  //   }

  //   try{
  //     const res = await axios.get(validationEndpoint, axiosGetConfig)
  //     if (res.status !== 200){
  //       navigate("/fb/app/403")
  //     }
  //     console.log("[DashboardLayout] token validation response: ",res.data)
  //     if (location.pathname !== "/fb/app/home/change-password"){
  //       reduxDispatch(updateUserDetails(res.data))
  //       reduxDispatch(updateRolesAndRoleClaims(res.data.roles))
  //     }
      
  //   } catch(err){
  //     navigate("/fb/app/403")
  //     console.log(err)
  //   }
  // }

  // useEffect( ()=>{
  //   if (user.token === "" || user.token === undefined){
  //     navigate("/fb/app/403")
  //   } else{
  //       // if (location.pathname !== "/fb/app/home/change-password"){
  //         validateToken(user.token)
  //         console.log('typeof user.name: ', typeof user.name)
  //       // }
  //   }
  // },[])

  useEffect( ()=>{
    console.log("[DashboardLayout] Current path is: ", location.pathname)
  },[])

  return (
    <>
    <Sidenav
      color={sidenavColor}
      brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
      brandName={miniSidenav ? "" : "Facility Booking"}
      routes={dashboardRoutes}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    />
    <Configurator />
    {/* {configsButton} */}
    {sidebarButton}
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </MDBox>
    </>


  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
