export const defaultInputState = {
    Estate_Id: "",
    Estate_Name: "",
    Property_Group_Id: "",
    Property_Group_Name: "",
    Building_Id: "",
    Building_Name: "",
    Space_Ids: [],
    Space_Names: [],
    Asset_Name: "",
    L1_Trade: "",
    L2_Asset_Category: "",
    L3_Asset: "",
    Asset_Group: "",
    Asset_Category: "",
    Asset_Abbreviation: "",
    Asset_System_Type: "",
    Asset_Uniclass_PR_Code: "",
    Asset_Uniclass_SS_Code: "",
    Criticality_Level: "",
    Asset_Brand: "",
    Asset_Manufacturer: "",
    Asset_Model_Number: "",
    Asset_Serial_Number: "",
    FEMS_Date_Created: "",
    FEMS_Date_Modified: "",
    Warranty_Expires_On: new Date().toISOString(),
    AIR_Template: "",
    Installation_Date: new Date().toISOString(), // can be blank
    System_Asset_Id: "",
    FEMS_Asset_System_Code: 99999999,
    BIM_GUID: "",
    Remarks: "",
    Floor_No: "NA",
    // Type: "asset",
    // FM_Internal_Name: "",
    // External_Facing_Name: "",
    // Space_Id: [],
    // Space_Name: [],
    // Asset_Id: "",
    // Created_By: "Test Frontend User",
    // Modified_By: "Test Frontend User",
    // Date_Created: new Date().toISOString(),
    // Date_Modified: new Date().toISOString(),
}

export const defaultInputErrorState = {
    Floor_No: { state: false, message: "" },
    Estate_Id: { state: false, message: "" },
    Estate_Name: { state: false, message: "" },
    Property_Group_Id: { state: false, message: "" },
    Property_Group_Name: { state: false, message: "" },
    Building_Id: { state: false, message: "" },
    Building_Name: { state: false, message: "" },
    Asset_Group: { state: false, message: "" },
    Asset_Abbreviation: { state: false, message: "" },
    Asset_System_Type: { state: false, message: "" },
    L1_Trade: { state: false, message: "" },
    L2_Asset_Category: { state: false, message: "" },
    L3_Asset: { state: false, message: "" },
    Asset_Uniclass_PR_Code: { state: false, message: "" }, 
    Asset_Uniclass_SS_Code: { state: false, message: "" }, 
}

export const provisionedFields = [
    "Type",
    "Estate_Id",
    "Estate_Name",
    "Property_Group_Id",
    "Property_Group_Name",
    "Building_Id",
    "Building_Name",
    "FM_Internal_Name",
    "External_Facing_Name",
    "Space_Ids",
    "Space_Names",
    "Space_Id",
    "Space_Name",
    "Asset_Id",
    "Asset_Name",
    "L1_Trade",
    "L2_Asset_Category",
    "L3_Asset",
    "Asset_Group",
    "Asset_Category",
    "Asset_Abbreviation",
    "Asset_System_Type",
    "Asset_Uniclass_PR_Code",
    "Asset_Uniclass_SS_Code",
    "Criticality_Level",
    "Remark",
    "Asset_Brand",
    "Asset_Manufacturer",
    "Asset_Model_Number",
    "Asset_Serial_Number",
    "FEMS_Date_Created",
    "FEMS_Date_Modified",
    "Warranty_Expires_On",
    "AIR_Template",
    "Installation_Date",
    "System_Asset_Id",
    "FEMS_Asset_System_Code",
    "BIM_GUID",
    "Remarks",
    "Created_By",
    "Modified_By",
    "Date_Created",
    "Date_Modified",
    "Floor_No",

    //CosmosDB Fields

    "_rid",
    "_self", 
    "_etag", 
    "_attachments", 
    "_ts", 
    "id", 
    "Is_Deleted", 
    "Created_DateTime", 
    "Modified_DateTime", 
    "version",
    "_lsn",
    "Space_uuids",
    "Building_uuid",
    "Property_Group_uuid",
    "Estate_uuid",
    "Asset_Group_uuid",
    "Asset_System_Type_uuid",

    //Additional Fields to hide
    "Id",
    "Name",
    "Oid",
    "Space_Hierarchy_Infos",
    "Asset_Group_Id",
    "Asset_System_Type_Id"
]