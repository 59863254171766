import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";

import { globalPublicData } from "globalPublicData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BatchApprovalSection from "page-sections/batchupload/BatchApprovalSection";
import EstatePGBuildingSubmitArea from "page-sections/EstatePGBuildingSubmitArea/EstatePGBuildingSubmitArea";
import TicketConversationSection from "page-sections/ticketConversation/TicketConversationSection";
import ProgressSectionBSD from "page-sections/approvalSections/ProgressSectionBSD";
import BatchInstructionsSection from "page-sections/batchupload/BatchInstructionsSection";
import BatchUploadSection from "page-sections/batchupload/BatchUploadSection";




const ViewBatchEstateTicketPage = (props) => {

    const { title } = props
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user = useSelector(state => state.user)
    const [ticket, setTicket] = useState(location.state || {})
    const [activeStep, setActiveStep] = useState(1)
    const [disableCommentInput, setDisableCommentInput] = useState(false)
    const [file, setFile] = useState()
    var ticket_uuid = queryParams.get("id")

    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    useEffect(()=>{
        if (!user.roleClaims.includes("amm.viewticket")){ navigate("/fb/app/403")}
    },[])

    const [approveButton, setApproveButton] = useState({
        show: false,
        text: "Approve Request",
        color: "info"
    })

    const [cancelButton, setCancelButton] = useState({
        show: false,
        text: "Cancel Request",
        color: "error"
    })

    const [assignBackButton, setAssignBackButton] = useState({
        show: false,
        text: "Route Back Request",
        color: "info"
    })

    const [rejectButton, setRejectButton] = useState({
        show: false,
        text: "Reject Request",
        color: "error"
    })

    const [updateTicketButton, setUpdateTicketButton] = useState({
        show: false,
        text: "Update Ticket",
        color: "info"
    })

    useEffect(()=>{
        if (user.roleClaims.includes("amm.hod") && user.roleClaims.includes("amm.approvebatchimportestate") && ticket.Status === "PendingHODApproval"){
            setApproveButton({
                show: true,
                text: "Approve Request",
                color: "info"
            })

            setRejectButton({
                show: true,
                text: "Reject Request",
                color: "error"
            })

            setAssignBackButton({
                show: true,
                text: "Route Back",
                color: "warning"
            })
        }

        if (
            user.roleClaims.includes("amm.bsd") && 
            user.roleClaims.includes("amm.manageestate") &&
            user.email === ticket.Requester_Email &&
            ticket.Status !== "Cancelled" &&
            ticket.Status !== "Approved" &&
            ticket.Status !== "Rejected"
        ){
            setCancelButton({
                show: true,
                text: "Cancel Request",
                color: "error"
            })

            if (ticket.Status === "Draft"){
                
                setUpdateTicketButton({
                    show: true,
                    text: "Update Request",
                    color: "info"
                })
            }

        }

    },[])

    useEffect(() => {

        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewBatchEstaeTicketPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/ammticket/getticketbyuuid/"
                + idToQuery + "/false"

            console.log('[ViewBatchEstaeTicketPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            console.log("[ViewBatchEstaeTicketPage] res.data is: ", res.data)
            console.log("[ViewBatchEstaeTicketPage] res.data[0].Object_Data is: ", res.data[0].Object_Data)

            setTicket(res.data[0])
            
            if (res.data[0].Status === "PendingHODApproval"){
                if (!user.roleClaims.includes("amm.hod")){ setDisableCommentInput(true)}
                setActiveStep(1)
            } else if (res.data[0].Status === "Approved"){
                setActiveStep(2)
                setDisableCommentInput(true)
            } else if (res.data[0].Status === "Cancelled" || res.data[0].Status === "Rejected"){
                setDisableCommentInput(true)
            } else {

            }
            // setAuditData(res.data)
        }
        
        fetchData()
        
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
                <MDBox my={5}>
                    <Card sx={{ width: "100%" }}>
                        <ProgressSectionBSD activeStep={activeStep} />
                        <MDBox display="flex">
                            <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                                Batch Estate Ticket
                            </MDTypography>
                        </MDBox>
                    {(ticket.Status === "Draft" && user.email === ticket.Requester_Email) ?
                        <>
                            <BatchInstructionsSection /> 
                            <BatchUploadSection
                                file={file}
                                setFile={setFile}
                                uploadEndpoint={globalPublicData.baseEndpoint + "/estate/batchimport/create"}
                                showUploadButton={false}
                            />
                        </>
                        : <BatchApprovalSection ticket_uuid={ticket_uuid}/>
                        }
                        <MDBox mt={7}>
                            <TicketConversationSection
                                ticket={ticket}
                                setTicket={setTicket}
                                disableCommentInput={disableCommentInput}
                            />
                            <MDBox p={2} mt={1}>
                                <EstatePGBuildingSubmitArea
                                // inputs={inputs}
                                submitRequestButton={{
                                    show: false,
                                    text: "Submit Request",
                                    color: "info"
                                }}
                                // setInputErrorState={setInputErrorState}
                                approveButton={approveButton}
                                assignBackButton={assignBackButton}
                                rejectButton={rejectButton}
                                cancelButton={cancelButton}
                                file={file}
                                updateBatchTicketButton={updateTicketButton} // using updateBatch instead of update
                                ticket={ticket}
                                createEndpoint="/estate/create"
                                updateEndpoint="/estate/update"
                                deleteEndpoint="/estate/delete"
                                editRedirectUrl="/fb/app/home/edit-estate"
                                // defaultInputErrorState={defaultInputErrorState}
                            />
                        </MDBox>
                    </MDBox>
                    
                        
                    </Card>  
                </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewBatchEstateTicketPage


ViewBatchEstateTicketPage.defaultProps = {
    title: "Batch Estate Ticket",
    viewMode: false,
    disableIdField: false
};