/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "../components/IdCell";
import DefaultCell from "../components/DefaultCell";
import StatusCell from "../components/StatusCell";
import CustomerCell from "../components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";
import IdLinkCell from "../components/IdLinkCell";

const dataTableData = {
  columns: [
    { 
      Header: "id", 
      accessor: "id", 
      Cell: ({ value }) => <IdLinkCell id={value} /> ,
      width: "10%"
    },
    {
      Header: "Building Name",
      accessor: "buildingName",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Facility",
      accessor: "facility",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "From",
      accessor: "from",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: "10%"
    },
    {
      Header: "To",
      accessor: "to",
      Cell: ({ value }) => <DefaultCell value={value} />,
      width: "10%"
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => {
        let status;

        if (value === "completed") {
          status = <StatusCell icon="done" color="success" status="Completed" />;
        } else if (value === "refunded") {
          status = <StatusCell icon="replay" color="info" status="Refunded" />;
        } else if (value === "requested") {
          status = <StatusCell icon="close" color="warning" status="Requested" />;
        } else {
          status = <StatusCell icon="close" color="info" status="In-Progress" />;
        }

        return status;
      },
    },
    // { Header: "revenue", accessor: "revenue", Cell: ({ value }) => <DefaultCell value={value} /> },
  ],

  rows: [
    {
      id: "10421",
      buildingName: "JTC Summit",
      facility: "Meeting Room 10",
      date: new Date(2023, 5,8,14,0,0).toDateString(),
      from: new Date(2023, 5,8,14,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,8,16,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "in-progress",
      revenue: "$140,20",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 5",
      date: new Date(2023, 5,7,14,0,0).toDateString(),
      from: new Date(2023, 5,9,9,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,12,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "completed",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 6",
      date: new Date(2023, 5,6,10,0,0).toDateString(),
      from: new Date(2023, 5,9,11,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,12,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "in-progress",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 11",
      date: new Date(2023, 5,7,14,0,0).toDateString(),
      from: new Date(2023, 5,9,10,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,11,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "completed",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 7",
      date: new Date(2023, 5,15,14,0,0).toDateString(),
      from: new Date(2023, 5,9,14,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,16,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "requested",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 3",
      date: new Date(2023, 5,9,14,0,0).toDateString(),
      from: new Date(2023, 5,9,14,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,15,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "in-progress",
      revenue: "$42,00",
    },
    //
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 5",
      date: new Date(2023, 5,7,14,0,0).toDateString(),
      from: new Date(2023, 5,9,9,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,12,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "completed",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 6",
      date: new Date(2023, 5,16,10,0,0).toDateString(),
      from: new Date(2023, 5,9,11,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,12,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "requested",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 11",
      date: new Date(2023, 5,8,14,0,0).toDateString(),
      from: new Date(2023, 5,9,10,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,11,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "in-progress",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 7",
      date: new Date(2023, 5,5,14,0,0).toDateString(),
      from: new Date(2023, 5,9,14,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,16,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "completed",
      revenue: "$42,00",
    },
    {
      id: "10422",
      buildingName: "JTC Summit",
      facility: "Meeting Room 3",
      date: new Date(2023, 5,8,14,0,0).toDateString(),
      from: new Date(2023, 5,9,14,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      to: new Date(2023, 5,6,15,0,0).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
      status: "in-progress",
      revenue: "$42,00",
    },
    // {
    //   id: "10432",
    //   date: "2 Nov, 5:12 PM",
    //   status: "in-progress",
    //   customer: ["Oana Kilas", { image: "O", color: "info" }],
    //   product: "Office Papers",
    //   revenue: "$23,90",
    // },
  ],
};

export default dataTableData;
