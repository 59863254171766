import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import Randomstring from "randomstring";

import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import PlusCollapse from "components/PlusCollapse";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { prepareAllMenuOptions } from "utils/menuOptionsUtils";

import { handlebuildingChange, handleBuildingChangeMultiple, handleEstateChange, handleEstateChangeMultiple, handlePropertyGroupChange, handlePropertyGroupChangeMultiple, handleSpaceChange, handleSpaceChangeMultiple } from "./utils";
import { useCookies } from "react-cookie";


const QueryHierarchySection = (props) => {

    const { inputs, handleInputChange, setInputs, viewMode, showPropertyGroupFields, showBuildingFields, showSpaceFields, showMultiSpaceFields, openCollapse } = props

    const navigate = useNavigate()
    const user = useSelector(state => state.user)

    // const {
    //     data = [],
    //     isFetching,
    //     isUninitialized,
    //     isLoading,
    //     isSuccess,
    //     isError,
    //     refetch,
    //     fulfilledTimeStamp,
    //     currentData,

    // } = useFetchMenuOptionsQuery();

    const dispatch = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }
    
    const dispatchReduxStates = (data) => {
        const { Estate, Property_Group, Building, Space } = prepareAllMenuOptions(data)

        console.log('[QueryHierarchySection][dispatchReduxStates] Estate is: ', Estate)
        dispatch(updateEstateMenuOptions(Estate))
        dispatch(updateFilteredEstateMenuOptions(Estate))
        dispatch(updatePropertyGroupMenuOptions(Property_Group))
        dispatch(updateFilteredPropertyGroupOptions(Property_Group))
        dispatch(updateBuildingMenuOptions(Building))
        dispatch(updateFilteredBuildingMenuOptions(Building))
        dispatch(updateSpaceMenuOptions(Space))
        dispatch(updateFilteredSpaceMenuOptions(Space))
    }

    useEffect(() => {

        async function populateData() {
            const url = globalPublicData.baseEndpoint + "/common/getmenuoptions"
            const response = await axios.get(url, axiosConfig)
            dispatchReduxStates(response.data)
        }
        populateData()
    }, [])

    

    // useEffect(() => {

    //     async function populateData() {
    //         if (fulfilledTimeStamp && !isLoading) {
    //             console.log('[useEffect dispatch] refetchMenuOptions ran')
    //             const response = await refetch()
    //             const optionData = prepareAllMenuOptions(response.data)
    //             console.log('[populateData] optionData is: ', optionData)
    //             dispatchReduxStates(response.data)
    //         } else {
    //             dispatchReduxStates(data)
    //         }
    //     }
    //     populateData()
    // }, [isLoading, isSuccess])

    const fullOptionsData = useSelector(state => state.menuOptions)

    useEffect(()=>{
        console.log('[QueryHierarchySection] fullOptionsData is: ', fullOptionsData)
    },[])
    
    const initialMenuOptionsData = {
        estates: {
            name: fullOptionsData.fullEstateOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullEstateOptions.map(item => { return item.Id })
        },
        propertyGroups: {
            name: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Id })
        },
        buildings: {
            name: fullOptionsData.fullBuildingOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullBuildingOptions.map(item => { return item.Id })
        },
        spaces: {
            name: fullOptionsData.fullSpaceOptions.map(item => { return item.Name }),
            id: fullOptionsData.fullSpaceOptions.map(item => { return item.Id })
        }
    }

    const [menuOptions, setMenuOptions] = useState(initialMenuOptionsData)

    useEffect( ()=>{
        const _menuOptions = {
            estates: {
                name: fullOptionsData.fullEstateOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullEstateOptions.map(item => { return item.Id })
            },
            propertyGroups: {
                name: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullPropertyGroupOptions.map(item => { return item.Id })
            },
            buildings: {
                name: fullOptionsData.fullBuildingOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullBuildingOptions.map(item => { return item.Id })
            },
            spaces: {
                name: fullOptionsData.fullSpaceOptions.map(item => { return item.Name }),
                id: fullOptionsData.fullSpaceOptions.map(item => { return item.Id })
            }
        }
        setMenuOptions(_menuOptions)

    },[fullOptionsData])

    const handleEstateNameChange = (event, value) => {
        console.log('[handleEstateNameChange] value is: ', value)
        var fullItems = []
        value.map( item => {
            var retrievedItem = fullOptionsData.fullEstateOptions.find(option => { return option.Name === item })
            fullItems.push(retrievedItem)
        })
        // handleEstateChange(fullItems, inputs, setInputs, fullOptionsData, setMenuOptions, showPropertyGroupFields, showBuildingFields, showSpaceFields, showMultiSpaceFields)
        handleEstateChangeMultiple(
            fullItems, 
            inputs, 
            setInputs, 
            fullOptionsData, 
            menuOptions, 
            setMenuOptions)
    }

    const handlePropertyGroupNameChange = (event, value) => {
        console.log('[handlePropertyGroupNameChange] value is: ', value)
        var fullItems = []
        value.map(item => {
            var retrievedItem = fullOptionsData.fullPropertyGroupOptions.find(option => { return option.Name === item })
            fullItems.push(retrievedItem)
        })
        handlePropertyGroupChangeMultiple(
            fullItems,
            inputs,
            setInputs,
            fullOptionsData,
            menuOptions,
            setMenuOptions)
    }

    const handleBuildingNameChange = (event, value) => {
        console.log('[handleBuildingNameChange] value is: ', value)
        var fullItems = []
        value.map(item => {
            var retrievedItem = fullOptionsData.fullBuildingOptions.find(option => { return option.Name === item })
            fullItems.push(retrievedItem)
        })
        handleBuildingChangeMultiple(
            fullItems,
            inputs,
            setInputs,
            fullOptionsData,
            menuOptions,
            setMenuOptions)
    }

    const handleSpaceNameChange = (event, value) => {
        var fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Name === value })
        console.log('[handleSpaceNameChange] value is: ', value)
        var fullItems = []
        value.map(item => {
            var retrievedItem = fullOptionsData.fullSpaceOptions.find(option => { return option.Name === item })
            fullItems.push(retrievedItem)
        })
        handleSpaceChangeMultiple(
            fullItems,
            inputs,
            setInputs,
            fullOptionsData,
            menuOptions,
            setMenuOptions)
    }

    const handleSpaceIdChangeMultiple = (event, value) => {
        var fullItems = []
        value.map((spaceId) => {
            var _fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Id === spaceId })
            fullItems.push(_fullItem)
        })
        console.log(fullItems)
        handleSpaceChangeMultiple(fullItems, inputs, setInputs, fullOptionsData, menuOptions, setMenuOptions)
    }

    const handleSpaceNameChangeMultiple = (event, value) => {
        var fullItems = []
        value.map( (spaceName) => {
            var _fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Name === spaceName })
            fullItems.push(_fullItem)
        })
        console.log(fullItems)
        // var fullItem = fullOptionsData.fullSpaceOptions.find(item => { return item.Name === value })
        handleSpaceChangeMultiple(fullItems, inputs, setInputs, fullOptionsData, menuOptions, setMenuOptions)
    }

    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    useEffect( ()=>{
        console.log('[QueryHierarchySection] menuOptions are: ', menuOptions)
    },[menuOptions])


    return (
        <>
            {/* <MDBox p={2} mt={3}> */}
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={3}
                    >
                        <MDTypography sx={{ fontSize: "1rem" }} display="block" fontWeight="bold" color="dark" mb={1}>
                            Estate(s)
                        </MDTypography>
                        <Autocomplete
                            multiple
                            name="Estate_Name"
                            placeholder="Select Estate by Name"
                            value={inputs.Estate_Name}
                            options={menuOptions.estates.name}
                            renderInput={(params, key) => <MDInput {...params} />}
                            onChange={handleEstateNameChange}
                            disabled={viewMode}
                        />
                    </Grid>
                { showPropertyGroupFields ? 
                <Grid
                    item
                    xs={12}
                    md={3}
                >
                    <MDTypography sx={{ fontSize: "1rem" }} display="block" fontWeight="bold" color="dark" mb={1}>
                        Property Group(s)
                    </MDTypography>
                    <Autocomplete
                        multiple
                        name="Property_Group_Name"
                        placeholder="Select Property Group by Name"
                        value={inputs.Property_Group_Name}
                        options={menuOptions.propertyGroups.name}
                        renderInput={(params, key) => <MDInput {...params} />}
                        onChange={handlePropertyGroupNameChange}
                        disabled={viewMode}
                    />
                </Grid>

                : null}
                { showBuildingFields ? 
                <Grid
                    item
                    xs={12}
                    md={3}
                >
                    <MDTypography sx={{ fontSize: "1rem" }} display="block" fontWeight="bold" color="dark" mb={1}>
                        Building(s)
                    </MDTypography>
                    <Autocomplete
                        multiple
                        name="Building_Name"
                        placeholder="Select Building by Name"
                        value={inputs.Building_Name}
                        options={menuOptions.buildings.name}
                        renderInput={(params, key) => <MDInput {...params} />}
                        onChange={handleBuildingNameChange}
                        disabled={viewMode}
                    />
                </Grid>
                : null }
                {/* {showSpaceFields ? 
                <Grid
                    item
                    xs={12}
                    md={3}
                >
                    <InputLabel sx={inputLabelStyles}>
                        <strong>Space Name</strong>
                    </InputLabel>
                    <Autocomplete
                        multiple
                        name="Space_Name"
                        placeholder="Select Space by Name"
                        value={inputs.Space_Name}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option + Randomstring.generate()}>
                                    {option}
                                </li>
                            );
                        }}
                        options={menuOptions.spaces.name}
                        renderInput={(params, key) => <MDInput {...params} />}
                        onChange={handleSpaceNameChange}
                        disabled={viewMode}
                    />
                </Grid>
                : null } */}
                {showMultiSpaceFields ?
                            <Grid
                                item
                                xs={12}
                                md={3}
                            >
                                <MDTypography sx={{ fontSize: "1rem" }} display="block" fontWeight="bold" color="dark" mb={1}>
                                    Space(s)
                                </MDTypography>
                                <Autocomplete
                                    multiple
                                    name="Space_Names"
                                    placeholder="Select Spaces by Name"
                                    value={inputs.Space_Names}
                                    options={menuOptions.spaces.name}
                                    renderInput={(params, key) => <MDInput {...params} />}
                                    onChange={handleSpaceNameChangeMultiple}
                                    disabled={viewMode}
                                />
                            </Grid>

                        : null}
            </Grid>
            {/* </MDBox> */}
        </>
    )
}

export default QueryHierarchySection


QueryHierarchySection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    showPropertyGroupFields: true,
    showBuildingFields: true,
    showSpaceFields: false,
    showMultiSpaceFields: true,
    openCollapse: true,

};