import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import TicketTable from "page-sections/home/TicketTable";
import { ticketColumns } from "page-sections/home/utils";

import VerifiedIcon from '@mui/icons-material/Verified';
import ApprovalIcon from '@mui/icons-material/Approval';
import { Alert, AlertTitle, Grid } from "@mui/material";
import StatisticsCard from "components/StatisticsCard";

const BSDHODView = (props) => {

    const {
        ticketLoadingStatus,
        approvedTicketsData,
        pendingHodTicketsData,
        // pendingBSDTicketsData,
        // pendingFMOpsTicketsData,
        // pendingDraftTicketsData,
        rejectedTicketsData,
        cancelledTicketsData
    } = props

    const user = useSelector(state => state.user)    

    const [rejectedCancelledTicketsData, setRejectedCancelledTicketsData] = useState(
        {
            columns: ticketColumns,
            rows: [...rejectedTicketsData.rows, ...cancelledTicketsData.rows]
        }
    )

    
    useEffect(()=>{
        setRejectedCancelledTicketsData({
            columns: ticketColumns,
            rows: [...rejectedTicketsData.rows, ...cancelledTicketsData.rows]
        })

    },[rejectedTicketsData, cancelledTicketsData])


    return(
        <>        
            <Grid container my={5} spacing={5}>
                <Grid item xs={12} lg={6}>
                    <StatisticsCard
                        color="warning"
                        icon={<VerifiedIcon fontSize="large" />}
                        title={"Pending Actions"}
                        count={pendingHodTicketsData.rows.length}
                        percentage={{ color: "success", amount: "-25%", label: "from last week" }}
                        loading={ticketLoadingStatus}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <StatisticsCard
                        color="success"
                        icon={<ApprovalIcon fontSize="large"/>}
                        title={"Approved Tickets"}
                        count={approvedTicketsData.rows.length}
                        percentage={{color: "success", amount: "+55%", label: "from last week"}}
                        loading={ticketLoadingStatus}
                    />
                </Grid>
            </Grid>
            <TicketTable 
                title="Pending Actions" 
                ticketData={pendingHodTicketsData} 
                defaultValue={5} 
                loading={ticketLoadingStatus}
            />
            <TicketTable 
                title="Approved Tickets" 
                ticketData={approvedTicketsData} 
                defaultValue={5}
                loading={ticketLoadingStatus} 
            />
            <TicketTable 
                title="Rejected" 
                ticketData={rejectedTicketsData} 
                defaultValue={5} 
                loading={ticketLoadingStatus}
            />
        </>
    )
}

export default BSDHODView