const buildingExcelHeaders = [
    "Building Id",
    "FMC Id",
    "Department Id",
    "Property Group Id",
    "Name",
    "FM Internal Name",
    "External Facing Name",
    "Gross Floor Area",
    "JTC Address",
    "Postal Code",
    "Longtitude",
    "Latitude",
    "FEMS Building System Code",
    "Building Short Name",
    "Number of Floors",
    "Number of Units",
    "Remarks",
    "Periodic Structural Inspection Date",
    "Green Mark Award",
    "Green Mark Certification Expiry Date",
    "Building Type",
    "Building Type Id",
    "FMC Company Name"
]

export default buildingExcelHeaders