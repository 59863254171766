
//////////////////////////////////////////////////////////////////////////////////
// PLACEHOLDER CODE FOR FUTURE SHARED API QUERY FUNCTIONS
//////////////////////////////////////////////////////////////////////////////////
// Currently not used anywhere
//////////////////////////////////////////////////////////////////////////////////

import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { globalPublicData } from "../../../globalPublicData"


export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: globalPublicData.baseEndpoint,
        mode: "cors",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user.token
            if (token){
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
        // prepareHeaders(headers){
        //     headers.set('x-api-key', "INSERT_KEY_HERE")
        //     return headers;
        // }
    }),
    endpoints: (builder) => ({
        fetchMenuOptions: builder.query({
            // query: () => `Common/getmenunamelist`,
            query: () => `Common/getmenuoptions`,
            // keepUnusedDataFor: 0,
        }),
        fetchDepartments: builder.query({
            query: () => `department/getdepartmentlist`
        }),
        fetchFMC: builder.query({
            query: () => `facilitymanagement/getfacilityvendorlist`
        })
    })
})

export const { useFetchMenuOptionsQuery, useFetchDepartmentsQuery, useFetchFMCQuery } = apiSlice

