import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';


import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";
import { useCookies } from "react-cookie";
import { defaultInputErrorState } from "./utils";
import validateEmptyString from "datavalidation/validateEmptyString";


const AssetSubmitArea = (props) => {

    const navigate = useNavigate()
    // const { inputs, createButton, submitButton, editButton, deleteButton } = props
    
    const { 
        inputs, 
        createButton, 
        submitButton, 
        editButton, 
        deleteButton, 
        submitCreateRequestButton, 
        approveButton, 
        assignBackButton,
        rejectButton,
        cancelButton, 
        verifyButton,
        updateTicketButton,
        setInputErrorState,
        ticket } = props

    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const handleEdit = async () => {

        var dataToSend = {
            ...inputs,
            // Name: inputs.Asset_Name
        }

        if (dataToSend.Space_uuids) {
            console.log("[EditAsset][handleEdit] space uuid array found: ", dataToSend.Space_uuids)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            if (dataToSend.Property_Group_uuid) { delete dataToSend.Property_Group_uuid }
        } else if (dataToSend.Building_uuid){
            console.log("[EditAsset][handleEdit] building uuid found: ", dataToSend.Building_uuid)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            if (dataToSend.Property_Group_uuid) { delete dataToSend.Property_Group_uuid }
            dataToSend.Space_uuids = []
        } else if (dataToSend.Property_Group_uuid){
            console.log("[EditAsset][handleEdit] propertygroup uuid found: ", dataToSend.Property_Group_uuid)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            dataToSend.Space_uuids = []
        }

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(inputs[item])) {
                console.log('validation item is ', item)

                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }

            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }

            //Fields that are optional in Assets
            _inputErrorState.Property_Group_Id = { state: false, message: "" }
            _inputErrorState.Property_Group_Name = { state: false, message: "" }
            _inputErrorState.Building_Id = { state: false, message: "" }
            _inputErrorState.Building_Name = { state: false, message: "" }

            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleSubmit] dataToSend are: ', dataToSend)
        console.log('[handleSubmit] validationState is: ', validationState)
        if (validationState) {

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.put(endPoint + "/asset/update/", dataToSend, axiosConfig)
                console.log('put response is: ', response)
                if (response.status === 200) {
                    alert("Asset Update Request Submited")
                    navigate("/fb/app/home")
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    alert(JSON.stringify(err.response.data))
                }
            }
        } else {
                alert("Error: Please check your inputs and try again")
        }
    }

    const handleSubmitCreateRequest = async () => {

        var dataToSend = {
            ...inputs,
            // Name: inputs.External_Facing_Name
        }

        if (dataToSend.Space_uuids) {
            console.log("[EditAssetPage][handleCreate] space uuid array found: ", dataToSend.Space_uuids)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            if (dataToSend.Property_Group_uuid) { delete dataToSend.Property_Group_uuid }
        } else if (dataToSend.Building_uuid){
            console.log("[EditAssetPage][handleCreate] building uuid found: ", dataToSend.Building_uuid)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            if (dataToSend.Property_Group_uuid) { delete dataToSend.Property_Group_uuid }
            dataToSend.Space_uuids = []
        } else if (dataToSend.Property_Group_uuid){
            console.log("[EditAssetPage][handleCreate] propertygroup uuid found: ", dataToSend.Property_Group_uuid)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            dataToSend.Space_uuids = []
        }

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(inputs[item])) {
                console.log('validation item is ', item)
                
                    _inputErrorState[item] = {
                        state: true,
                        message: "Input must not be empty"
                    }
                
            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }

            //Fields that are optional in Assets
            _inputErrorState.Property_Group_Id = { state: false, message: "" }
            _inputErrorState.Property_Group_Name = { state: false, message: "" }
            _inputErrorState.Building_Id = { state: false, message: "" }
            _inputErrorState.Building_Name = { state: false, message: "" }

            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleSubmit] dataToSend are: ', dataToSend)
        console.log('[handleSubmit] validationState is: ', validationState)
        if (validationState) {

            const endPoint = globalPublicData.baseEndpoint

            try {
                const response = await axios.post(endPoint + "/asset/create", dataToSend, axiosConfig)
                console.log('post response is: ', response)
                if (response.status === 200) {
                    alert("Asset Create Request Submitted")
                    navigate("/fb/app/home")
                }

            } catch (err) {
                console.log(err)
                if (err.response.data) {
                    alert(JSON.stringify(err.response.data))
                }
            }
        } else {
            alert("Error: Please check your inputs and try again")
        }
    }


    const handleBSDApproval = async () => {

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.put(endPoint + "/ammticket/bsd/approve", dataToSend, axiosConfig)
            console.log('[handleBSDApproval] response is: ', response)
            if (response.status === 200) {
                alert("Request Approved")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleFMVerification = async () => {

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.put(endPoint + "/ammticket/fmops/verify", dataToSend, axiosConfig)
            console.log('[handleFMVerification] response is: ', response)
            if (response.status === 200) {
                alert("Request Verified")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleUpdateTicket = async () => {

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        if (dataToSend.Space_uuids) {
            console.log("[EditAssetPage][handleCreate] space uuid array found: ", dataToSend.Space_uuids)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            if (dataToSend.Property_Group_uuid) { delete dataToSend.Property_Group_uuid }
        } else if (dataToSend.Building_uuid){
            console.log("[EditAssetPage][handleCreate] building uuid found: ", dataToSend.Building_uuid)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            if (dataToSend.Property_Group_uuid) { delete dataToSend.Property_Group_uuid }
            dataToSend.Space_uuids = []
        } else if (dataToSend.Property_Group_uuid){
            console.log("[EditAssetPage][handleCreate] propertygroup uuid found: ", dataToSend.Property_Group_uuid)
            if (dataToSend.Estate_uuid) { delete dataToSend.Estate_uuid }
            dataToSend.Space_uuids = []
        }

        var validationState = true
        var _inputErrorState = defaultInputErrorState
        Object.keys(defaultInputErrorState).map(item => {
            if (!validateEmptyString(inputs[item])) {
                console.log('validation item is ', item)

                _inputErrorState[item] = {
                    state: true,
                    message: "Input must not be empty"
                }

            } else {
                _inputErrorState[item] = {
                    state: false,
                    message: ""
                }
            }

            //Fields that are optional in Assets
            _inputErrorState.Property_Group_Id = { state: false, message: "" }
            _inputErrorState.Property_Group_Name = { state: false, message: "" }
            _inputErrorState.Building_Id = { state: false, message: "" }
            _inputErrorState.Building_Name = { state: false, message: "" }

            setInputErrorState({ ...defaultInputErrorState, _inputErrorState })
            var failedValidationArray = Object.entries(_inputErrorState).map(item => item[1].state)
            console.log("failedValidationArray array is: ", failedValidationArray)
            if (failedValidationArray.includes(true)) {
                validationState = false
            } else {
                validationState = true
            }
        })


        console.log('[handleSubmit] dataToSend are: ', dataToSend)
        console.log('[handleSubmit] validationState is: ', validationState)
        if (validationState) {

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.put(endPoint + "/ammticket/update", dataToSend, axiosConfig)
            console.log('[handleUpdateTicket] response is: ', response)
            if (response.status === 200) {
                alert("Request Re-Submitted")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    } else {
            alert("Error: Please check your inputs and try again")
    }
    }

    const handleCancelTicket = async () => {

        if (user.email !== ticket.Requester_Email){
            return alert("Insufficient Account Priviledges: You can only reject tickets that you have raised.")
        }

        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/cancel"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleCancelTicket] response is: ', response)
            if (response.status === 200) {
                alert("Building Request Cancelled")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleRejectTicket = async () => {

        if (!user.roleClaims.includes("amm.bsd")){
            return alert("Insufficient Account Priviledges: Only BSD role(s) can reject tickets.")
        }
        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleRejectTicket] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/reject"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleRejectTicket] response is: ', response)
            if (response.status === 200) {
                alert("Ticket Rejected")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleAssignBackTicket = async () => {

        if (!user.roleClaims.includes("amm.bsd")){
            return alert("Insufficient Account Priviledges: Only BSD role(s) can route back tickets.")
        }
        var dataToSend = {
            ...ticket,
            Object_Data: inputs
        }

        console.log('[handleAssignBackTicket] dataToSend are: ', dataToSend)

        const url = globalPublicData.baseEndpoint + "/ammticket/assignback"

        try {
            const response = await axios.put(url, dataToSend, axiosConfig)
            console.log('[handleAssignBackTicket] response is: ', response)
            if (response.status === 200) {
                alert("Ticket has been assigned back to Requester")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const redirectToEdit = () => {
        navigate("/fb/app/home/edit-asset", { state: inputs })
    }

    const handleCreate = async () => {

        var dataToSend = {
            ...inputs,
            Name: inputs.Asset_Name
        }

        console.log('[handleSubmit] dataToSend are: ', dataToSend)

        const endPoint = globalPublicData.baseEndpoint

        try {
            const response = await axios.post(endPoint + "/asset/create", dataToSend, axiosConfig)
            console.log('post response is: ', response)
            if (response.status === 200) {
                alert("Asset Created")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    const handleDelete = async (id) => {

        const endPoint = globalPublicData.baseEndpoint
            + "/asset/delete"
            + "?id=" + id
            + "&modified_by=FrontendPlaceholder"

        try {
            console.log('[handleDelete] endpoint is: ', endPoint)
            const response = await axios.delete(endPoint, axiosConfig)

            console.log('delete response is: ', response)
            if (response.status === 200) {
                alert("Asset Deleted")
                navigate("/fb/app/home")
            }

        } catch (err) {
            console.log(err)
            if (err.response.data) {
                alert(JSON.stringify(err.response.data))
            }
        }
    }

    

    return (
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={5}
                >
                    {createButton.show ? 
                    <Grid item xs={12} md={2}>
                        <MDButton
                            fullWidth
                            color={createButton.color || "info"}
                            onClick={handleSubmitCreateRequest}
                        >
                            {createButton.text}
                        </MDButton>   
                    </Grid>
                    : null}
                    {deleteButton.show ?
                    <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={deleteButton.color || "error"}
                                onClick={() => {handleDelete(inputs.id)}}
                            >
                                {deleteButton.text}
                            </MDButton>
                        </Grid>
                    : null}
                    {editButton.show ?
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={editButton.color || "warning"}
                            onClick={editButton.redirectToEditPage ? redirectToEdit : handleEdit}
                            >
                                {editButton.text}
                            </MDButton>    
                        </Grid>
                    : null}
                    {submitCreateRequestButton.show ? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={submitCreateRequestButton.color || "info"}
                                onClick={handleSubmitCreateRequest}
                            >
                                {submitCreateRequestButton.text}
                            </MDButton>
                        </Grid>
                    : null}
                    {cancelButton.show? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={cancelButton.color || "info"}
                                onClick={handleCancelTicket}
                            >
                                {cancelButton.text}
                            </MDButton>
                        </Grid>
                
                    : null}
                    {rejectButton.show? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={rejectButton.color || "info"}
                                onClick={handleRejectTicket}
                            >
                                {rejectButton.text}
                            </MDButton>
                        </Grid>
                
                    : null}
                    {assignBackButton.show? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={assignBackButton.color || "info"}
                                onClick={handleAssignBackTicket}
                            >
                                {assignBackButton.text}
                            </MDButton>
                        </Grid>
                
                    : null}
                    {verifyButton.show ? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={verifyButton.color || "info"}
                                onClick={handleFMVerification}
                            >
                                {verifyButton.text}
                            </MDButton>
                        </Grid>
                    : null}
                    {updateTicketButton.show ? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={updateTicketButton.color || "info"}
                                onClick={handleUpdateTicket}
                            >
                                {updateTicketButton.text}
                            </MDButton>
                        </Grid>
                    : null}
                    {approveButton.show ? 
                        <Grid item xs={12} md={2}>
                            <MDButton
                                fullWidth
                                color={approveButton.color || "info"}
                                onClick={handleBSDApproval}
                            >
                                {approveButton.text}
                            </MDButton>
                        </Grid>
                    : null}
                </Grid>
            </MDBox>
    )
}

export default AssetSubmitArea


AssetSubmitArea.defaultProps = {
    viewMode: false,
    disableIdField: false,
    title: "Building",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        redirectToEditPage: false
    },
    createButton: {
        show: false,
        text: "Create",
        color: "info"
    },
    deleteButton: {
        show: false,
        text: "Delete",
        color: "error"
    },
    submitCreateRequestButton: {
        show: false,
        text: "Submit Create Request",
        color: "info"
    },
    cancelButton: {
        show:false,
        text: "Cancel Request",
        color: "error"
    },
    assignBackButton: {
        show: false,
        text: "Route Back",
        color: "warning"
    },
    rejectButton: {
        show: false,
        text: "Reject Request",
        color: "error"
    },
    verifyButton: {
        show: false,
        text: "Verify Request",
        color: "info"
    },
    approveButton: {
        show: false,
        text: "Approve Request",
        color: "info"
    },
    updateTicketButton: {
        show: false,
        text: "Update Request",
        color: "info"
    },

};