import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import MDTypography from "components/MDTypography";
import { useLocation, useNavigate } from "react-router-dom";
import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import AssetBasicInformationSection from "page-sections/assets/AssetBasicInformationSection";
import { defaultInputState, provisionedFields } from "page-sections/assets/utils";
import AssetOtherInformationSection from "page-sections/assets/AssetOtherInformationSection";
import AssetCustomSection from "page-sections/assets/AssetCustomSection";
import AuditTrailSection from "page-sections/auditTrail/AuditTrailSection";
import TicketConversationSection from "page-sections/ticketConversation/TicketConversationSection";
import { useCookies } from "react-cookie";
import ProgressSectionSpaceAsset from "page-sections/approvalSections/ProgressSectionSpaceAsset";
import { defaultInputErrorState } from "page-sections/assets/utils";
import SpaceAssetSubmitArea from "page-sections/SpaceAssetSubmitArea/SpaceAssetSubmitArea";

const ViewAssetTicketPage = (props) => {

    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const user =  useSelector(state => state.user)
    const [cookies, setCookie, removeCookie] = useCookies()
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }

    const [viewMode, setViewMode] = useState(true)
    const disableIdField = true

    const [filteredDataKeys, setFilteredDataKeys] = useState({})
    const [inputs, setInputs] = useState(location.state.Object_Data || defaultInputState)
    const [inputErrorState, setInputErrorState] = useState(defaultInputErrorState)
    // const [inputs, setInputs] = useState(defaultInputState)
    const [ticket, setTicket] = useState(location.state || {})
    const [activeStep, setActiveStep] = useState(1)
    const [disableCommentInput, setDisableCommentInput] = useState(false)
    const [auditData, setAuditData] = useState([])

    const defaultNoShowState = {
        show: false,
        text: "NA",
        color: "info"
    }

    const [approveButton, setApproveButton] = useState({
        show: false,
        text: "Approve Request",
        color: "info"
    })

    const [verifyButton, setVerifyButton] = useState({
        show: false,
        text: "Verify Request",
        color: "info"
    })

    const [cancelButton, setCancelButton] = useState({
        show: false,
        text: "Cancel Request",
        color: "error"
    })

    const [assignBackButton, setAssignBackButton] = useState({
        show: false,
        text: "Route Back Request",
        color: "info"
    })

    const [updateTicketButton, setUpdateTicketButton] = useState({
        show: false,
        text: "Update Ticket",
        color: "info"
    })

    const [rejectButton, setRejectButton] = useState({
        show: false,
        text: "Reject Request",
        color: "error"
    })

    useEffect(()=>{
        if (
            user.roleClaims.includes("amm.bsd") && 
            user.roleClaims.includes("amm.approvemanageasset") &&
            ticket.Status === "PendingBSDApproval"
        ){
            setApproveButton({
                show: true,
                text: "Approve Request",
                color: "info"
            })

            setRejectButton({
                show: true,
                text: "Reject Request",
                color: "error"
            })

            setAssignBackButton({
                show: true,
                text: "Route Back",
                color: "warning"
            })
        }

        if (
            user.roleClaims.includes("amm.fmops") && 
            user.roleClaims.includes("amm.verifymanageasset") &&
            ticket.Status === "PendingFmOpsVerification"
        
            ){
            setVerifyButton({
                show: true,
                text: "Verify Request",
                color: "info"
            })
            setAssignBackButton({
                show: true,
                text: "Route Back",
                color: "warning"
            })
        }

        if ( 
            user.roleClaims.includes("amm.manageasset") &&
            user.email === ticket.Requester_Email &&
            ticket.Status !== "Cancelled" &&
            ticket.Status !== "Rejected" &&
            ticket.Status !== "Approved"
        ){
            setCancelButton({
                show: true,
                text: "Cancel Request",
                color: "error"
            })

            if (ticket.Status === "Draft") {
                setViewMode(false)
                setUpdateTicketButton({
                    show: true,
                    text: "Update Request",
                    color: "info"
                })
            }

        }

    },[])

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
        console.log('[handleInputChange] inputs are: ', inputs)
    }

    const handleCriticalityLevelChange = (event, value) => {
        setInputs({ ...inputs, Criticality_Level: value})
    }

    const handleDateTimeChange = (name, value) => {
        setInputs({ ...inputs, [name]: new Date(value["$d"]).toISOString() })
    }

    useEffect( ()=>{
        console.log('[ViewAssetPage] location.state is: ', location.state)
        const recievedDataKeys = Object.keys(location.state || inputs)
        const duplicateFields = new Set(provisionedFields)
        const _filteredDataKeys = recievedDataKeys.filter(item => { return !duplicateFields.has(item) })
        setFilteredDataKeys(_filteredDataKeys)
        console.log('_filteredDataKeys are: ', filteredDataKeys)
    },[inputs])

    useEffect(() => {
        const fetchData = async () => {
            var cosmosId = queryParams.get("id")
            console.log('[ViewAssetTicketPage][fetchData] cosmosId is: ', cosmosId)
            var idToQuery = cosmosId || location.state.id
            const url =
                globalPublicData.baseEndpoint
                + "/ammticket/getticketbyuuid/"
                + idToQuery + "/false"

            console.log('[ViewAssetTicketPage][fetchData] url is: ', url)
            const res = await axios.get(url, axiosConfig)
            console.log("[ViewSpaceTicketPage] res.data is: ", res.data)
            console.log("[ViewAssetTicketPage] res.data[0].Object_Data is: ", res.data[0].Object_Data)
            
            if (res.data[0].Ticket_Action_Type === "Delete"){
                var getObjectByIdUrl = globalPublicData.baseEndpoint + "/asset/getassetbyuuid/" + res.data[0].Object_Data.id + "/false"
                const objRes = await axios.get(getObjectByIdUrl, axiosConfig)
                console.log('[fetcData] delete objRes.data[0] is: ', objRes.data[0])
                setInputs(objRes.data[0])
            } else {
                setInputs(res.data[0].Object_Data)
            }

            setTicket(res.data[0])

            if (res.data[0].Status === "PendingBSDApproval"){
                // if (!user.roleClaims.includes("amm.bsd")){ setDisableCommentInput(true)}
                setActiveStep(2)
            } else if (res.data[0].Status === "Approved"){
                setActiveStep(3)
                setDisableCommentInput(true)
            } else if (res.data[0].Status === "PendingFmOpsVerification"){
                setActiveStep(1)
            }
            // setAuditData(res.data)
        }
        
        fetchData()
        
    }, [])


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                <ProgressSectionSpaceAsset activeStep={activeStep} />
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                        {"Asset Ticket (" + ticket.Ticket_Action_Type + ")"} 
                        </MDTypography>
                    </MDBox>
                    <HierarchyFormInformation
                        inputs={inputs}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        showBuildingFields
                        showMultiSpaceFields
                        openCollapse={true}
                        inputErrorState={inputErrorState}
                    />
                    <AssetBasicInformationSection
                        inputs={inputs} 
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                        showIdField={false}
                        inputErrorState={inputErrorState}
                    />
                    <AssetOtherInformationSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        handleCriticalityLevelChange={handleCriticalityLevelChange}
                        handleDateTimeChange={handleDateTimeChange}
                        inputErrorState={inputErrorState}
                    />
                    <AssetCustomSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        disableLabel={false}
                        createMode={false}
                        allowDelete={false}
                    />
                    <MDBox p={2} mt={7}>
                    <TicketConversationSection
                        ticket={ticket}
                        setTicket={setTicket}
                        disableCommentInput={disableCommentInput}
                    />
                    <MDBox mt={1}>
                    <SpaceAssetSubmitArea
                        inputs={inputs}
                        setInputErrorState={setInputErrorState}
                        bsdApproveButton={approveButton}
                        verifyButton={verifyButton}
                        assignBackButton={assignBackButton}
                        rejectButton={rejectButton}
                        cancelButton={cancelButton}
                        updateTicketButton={updateTicketButton}
                        updateBatchTicketButton={defaultNoShowState}
                        ticket={ticket}
                        createEndpoint="/asset/create"
                        updateEndpoint="/asset/update"
                        deleteEndpoint="/asset/delete"
                        editRedirectUrl="/fb/app/home/edit-asset"
                        defaultInputErrorState={defaultInputErrorState}
                    />
                    </MDBox>
                    </MDBox>
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default ViewAssetTicketPage


ViewAssetTicketPage.defaultProps = {
    viewMode: false,
    disableIdField: false

};