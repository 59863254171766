import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";

const SpaceAutocomplete = (props) => {

    const {
        name,
        fetchingData,
        inputs,
        setInputs,
        variant,
        hideAllOption,
        hideNAOption
    } = props

    const filteredOptions = useSelector( state => state.menuOptions.filteredSpaceOptions)

    const hideAllOptionFilter = options => options.filter( opt => opt.Name !== "All")

    const hideNAOptionFilter = options => options.filter( opt => opt.Name !== "NA")

    let filterFunction

    if (hideAllOption){
        filterFunction = hideAllOptionFilter
    } else if (hideNAOption) {
        filterFunction = hideNAOptionFilter
    } else {
        filterFunction = options => options.filter( opt => opt)
    }

    return(
        <>
            <Autocomplete
                multiple
                name={name}
                disabled={fetchingData}
                value={inputs.spaces}
                options={filteredOptions}
                filterOptions={filterFunction}
                getOptionLabel={option => {
                    if (option.Space_Name === "" || option.Space_Id === "") {
                        return ""
                    } else {
                        return option.Space_Name + " (" + option.Space_Id + ")"
                    }
                }}
                renderInput={(params) => <MDInput {...params} key={params.id} variant={variant}/>}
                onChange={(event, value) => { 
                    console.log('[SpaceAutocomplete] value is: ', value)
                    var tempIds = []
                    value.map(item => {
                        tempIds.push(item.Id)
                    })

                    setInputs({
                        ...inputs,
                        ["space_ids"]: tempIds,
                        ["spaces"]: value
                    })

                //     var dataToAppend = {
                //         ["space"]: { Name: value.Name, Id: value.Id },
                //         ["spaceId"]: value.Id,
                //         ["Space_Name"]: value.Name,
                //         ["Space_Id"]: value.Id
                //     }

                //     var additionalDataCriteria = true
                //     if (hideAllOption){
                //         additionalDataCriteria = (value.Id !== "NA" && value.Name !== "NA") ? true : false

                //     } else if (hideNAOption){
                //         additionalDataCriteria = (value.Id !== "All" && value.Name !== "All") ? true : false
                //     }

                //     if (additionalDataCriteria){
                //         dataToAppend = {
                //             ...dataToAppend,
                //             ["estate"]: { Name: value.Estate_Name, Id: value.Estate_Id },
                //             ["estateId"]: value.Estate_Id,
                //             ["Estate_Name"]: value.Estate_Name,
                //             ["Estate_Id"]: value.Estate_Id,
                //             ["propertyGroup"]: { Name: value.Property_Group_Name, Id: value.Property_Group_Id },
                //             ["propertyGroupId"]: value.Property_Group_Id,
                //             ["Property_Group_Name"]: value.Property_Group_Name,
                //             ["Property_Group_Id"]: value.Property_Group_Id,
                //             //property field to be restored to value.Property_Name instead of Building_Name after DB is updated with Property_Name field for Spaces
                //             ["property"]: { Name: value.Building_Name, Id: value.Property_Id },
                //             ["propertyId"]: value.Property_Id,
                //             ["Property_Name"]: value.Property_Name || value.Building_Name,
                //             ["Property_Id"]: value.Property_Id, 
                //         }
                //         console.log('[SpaceAutocomplete] dataToAppend post: ', dataToAppend)
                //     }

                //     console.log('[SpaceAutocomplete] dataToAppend final: ', dataToAppend)
                //     setInputs({
                //         ...inputs,
                //         ...dataToAppend
                //     })
                // }}
                }}
            />
        </>
    )
}

export default SpaceAutocomplete

SpaceAutocomplete.defaultProps = {
    fetchingData: false,
    name: "space",
    variant: "standard",
    hideAllOption: false,
    hideNAOption: true
  };