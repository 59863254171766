import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
import {
    updateEstateMenuOptions,
    updatePropertyGroupMenuOptions,
    updateBuildingMenuOptions,
    updateSpaceMenuOptions,
    updateFilteredEstateMenuOptions,
    updateFilteredPropertyGroupOptions,
    updateFilteredBuildingMenuOptions,
    updateFilteredSpaceMenuOptions,
    resetFilteredMenuOptions

} from "redux/features/menuOptions/menuOptionsSlice";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CreateIcon from '@mui/icons-material/Create';

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";


import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import HierarchyFormInformation from "page-sections/HierarchyFormInformation/HierarchyFormInformation";
import { prepareAllMenuOptions } from "page-sections/queryBuilder/utils";
import BuildingBasicInformationSection from "page-sections/building/BuildingBasicInformationSection";
import BuildingDetailedInformationSection from "page-sections/building/BuildingDetailedInformationSection";
import BuildingSubmitArea from "page-sections/building/BuildingSubmitArea";
import FMCContactInformationSection from "page-sections/contactInformationForms/FMCContactInformationSection";
import JTCContactInformationSection from "page-sections/contactInformationForms/JTCContactInformationSection";
import { defaultInputState } from "page-sections/building/utils";

const CreateBuildingPageFMOps = (props) => {

    const navigate = useNavigate()

    const viewMode = false
    const disableIdField = false

    const [inputs, setInputs] = useState(defaultInputState)

    const handleInputChange = (event) => {
        event.persist()
        setInputs({ ...inputs, [event.target.name]: event.target.value })
    }

    const {
        data = [],
        isFetching,
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        refetch,
        fulfilledTimeStamp,
        currentData,

    } = useFetchMenuOptionsQuery();

    const dispatch = useDispatch()

    useEffect(() => {
        console.log('[QueryPage][TBD] data is: ', data)
        console.log('[QueryPage][TBD] isFetching is: ', isFetching)
        console.log('[QueryPage][TBD] isSuccess is: ', isSuccess)
        console.log('[QueryPage][TBD] fulfilledTimeStamp is: ', fulfilledTimeStamp)

    }, [isFetching])
    const dispatchReduxStates = (data) => {
        const { estate, property_group, building, space } = prepareAllMenuOptions(data)

        dispatch(updateEstateMenuOptions(estate))
        dispatch(updateFilteredEstateMenuOptions(estate))
        dispatch(updatePropertyGroupMenuOptions(property_group))
        dispatch(updateFilteredPropertyGroupOptions(property_group))
        dispatch(updateBuildingMenuOptions(building))
        dispatch(updateFilteredBuildingMenuOptions(building))
        dispatch(updateSpaceMenuOptions(space))
        dispatch(updateFilteredSpaceMenuOptions(space))
    }

    useEffect(() => {

        async function populateData() {
            if (fulfilledTimeStamp && !isLoading) {
                console.log('[useEffect dispatch] refetchMenuOptions ran')
                const response = await refetch()
                dispatchReduxStates(response.data)
            } else {
                dispatchReduxStates(data)
            }
        }
        populateData()
    }, [isLoading, isSuccess])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={5}>
                <Card sx={{ width: "100%" }}>
                    <MDBox display="flex">
                        <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                            Create Building
                        </MDTypography>
                    </MDBox>
                    <BuildingBasicInformationSection
                        inputs={inputs} 
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                    />
                    <HierarchyFormInformation
                        inputs={inputs}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        showPropertyGroupFields
                        openCollapse={false}
                    />
                    <BuildingDetailedInformationSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        setInputs={setInputs}
                        viewMode={viewMode}
                        disableIdField={disableIdField}
                        openCollapse={false}
                    />
                    {/* <FMCContactInformationSection 
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    />
                    <JTCContactInformationSection
                        inputs={inputs}
                        handleInputChange={handleInputChange}
                        viewMode={viewMode}
                        openCollapse={false}
                    /> */}
                    <BuildingSubmitArea
                        inputs={inputs}
                        createButton={{
                            show: false,
                            text: "Create Building",
                            color: "info"
                        }}
                        editButton={{
                            show: false,
                            text: "Edit Building",
                            color: "warning",
                            redirectToEditPage: false
                        }}
                        deleteButton={{
                            show: false,
                            text: "Delete Building",
                            color: "error"
                        }}
                        submitRequestButton={{
                            show: true,
                            text: "Submit Request",
                            color: "info"
                        }}
                    />             
                </Card>  
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}

export default CreateBuildingPageFMOps


CreateBuildingPageFMOps.defaultProps = {
    viewMode: false,
    disableIdField: false

};