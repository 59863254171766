/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// OrderDetails page components
import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import TrackOrder from "./components/TrackOrder";
import PaymentDetails from "./components/PaymentDetails";
import BillingInformation from "./components/BillingInformation";
import OrderSummary from "./components/OrderSummary";
import BasicLayout from "layouts/BasicLayout";
import DefaultPageHeader from "page-sections/DefaultPageHeader";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

function BookingDetails() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  useEffect(()=>{
    console.log("queryParams.get(): ", queryParams.get("id") )
  },[])

  return (
    <BasicLayout user>
      <DefaultPageHeader title="Booking Details"/>
      <MDBox my={3} mx={3}>
            <Card>
              <MDBox pt={2} px={2}>
                <Header 
                  id={queryParams.get("id")}
                />
              </MDBox>
              <Divider />
              <MDBox pt={1} pb={3} px={2}>
                <MDBox mb={3}>
                  <OrderInfo />
                </MDBox>
                <Divider />
                <MDBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                      <TrackOrder />
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                      <PaymentDetails />
                      <MDBox mt={3}>
                        <BillingInformation />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                      <OrderSummary />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
      </MDBox>
    </BasicLayout>
  );
}

export default BookingDetails;
