import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";

import {
    updateFullDepartmentData,
    updateFMC,
} from "redux/features/dropdownOptions/dropdownOptionsSlice"
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchDepartmentsQuery, useFetchFMCQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { updateDepartment } from "redux/features/dropdownOptions/dropdownOptionsSlice";
import { useCookies } from "react-cookie";
import { defaultInputErrorState, defaultInputState } from "./utils";


const EstateSection = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const dropdownOptions = useSelector(state => state.dropdownOptions)
    const { title, inputs, inputErrorState, handleInputChange, setInputs, viewMode, disableIdField, showIdField } = props

    const [cookies, setCookie, removeCookie] = useCookies()
    const user = useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }


    const inputLabelStyles = {
        fontWeight: "700px",
        color: "black",
        marginBottom: "10px"
    }

    const handleDepartmentChange = (event, value) => {
        var _Department = dropdownOptions.fullDepartmentData.find( item => {
            return item.Department_Id === value
        })
        setInputs({ 
            ...inputs, 
            Department_uuid: _Department.id,
            Department_Id: value 
        
        })
    }

    const handleFMCIdChange = (event, value) => {
        var _FMC = dropdownOptions.FMC.find( item => {
            return item.Id === value
        })
        setInputs({ 
            ...inputs, 
            FMC_uuid: _FMC.id,
            FMC_Id: value
        })
    }

    useEffect( ()=>{
        const fetchFMCDeptData = async () => {
            console.log('[fetchFMCDeptData] function called')
            const fmcEndpoint = globalPublicData.baseEndpoint + "/facilitymanagement/getfacilityvendorlist"
            const deptEndpoint = globalPublicData.baseEndpoint + "/department/getdepartmentlist"

            const fmcRes = await axios.get(fmcEndpoint, axiosConfig)
            const deptRes = await axios.get(deptEndpoint, axiosConfig)

            dispatch(updateFMC(fmcRes.data))
            dispatch(updateFullDepartmentData(deptRes.data))
        }

        fetchFMCDeptData()

    },[])
    
    useEffect(()=>{
        console.log("inputErrorState is: ", inputErrorState)
    },[inputErrorState])

    return (
        <>
            <MDBox display="flex">
                <MDTypography variant="h1" sx={{ mt: 2, mb: 1, ml: 3, fontSize: "2rem" }}>
                    {title}
                </MDTypography>
            </MDBox>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    {showIdField ? 
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Estate Id</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Id"
                            value={inputs.Id}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={disableIdField}
                        />
                        <FormHelperText>
                            Estate Identifier Code
                        </FormHelperText>
                    </Grid> : null}
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Estate Name</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Name"
                            value={inputs.Name}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            error={inputErrorState.Name.state}
                        />
                        {inputErrorState.Name.state ? 
                        <FormHelperText error>
                            <strong>{inputErrorState.Name.message}</strong>
                        </FormHelperText>
                        : 
                        <FormHelperText>
                            Name of Estate
                        </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Estate Department</strong>
                        </InputLabel>
                        <Autocomplete
                            name="Department_Id"
                            forcePopupIcon={!inputErrorState.Department_Id.state}
                            placeholder="Select Department.."
                            value={inputs.Department_Id}
                            options={dropdownOptions.department}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.Department_Id.state} />}
                            onChange={handleDepartmentChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.Department_Id.state ? 
                        <FormHelperText error>
                            <strong>{inputErrorState.Department_Id.message}</strong>
                        </FormHelperText>
                        :
                        <FormHelperText>
                            Estate Department Categorisation
                        </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <InputLabel sx={inputLabelStyles} required>
                            <strong>Estate FMC ID</strong>
                        </InputLabel>
                        <Autocomplete
                            name="FMC_Id"
                            forcePopupIcon={!inputErrorState.FMC_Id.state}
                            placeholder="Select FMC Id.."
                            value={inputs.FMC_Id}
                            options={dropdownOptions.FMC_Id}
                            renderInput={(params, key) => <MDInput {...params} error={inputErrorState.FMC_Id.state} />}
                            onChange={handleFMCIdChange}
                            disabled={viewMode}
                        />
                        {inputErrorState.FMC_Id.state ?
                        <FormHelperText error>
                            <strong>{inputErrorState.FMC_Id.message}</strong>
                        </FormHelperText>
                        :
                        <FormHelperText>
                            ID of FMC Managing the Estate
                        </FormHelperText>
                        }
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <InputLabel sx={inputLabelStyles}>
                            <strong>Estate Geometry</strong>
                        </InputLabel>
                        <MDInput
                            variant="outlined"
                            name="Geometry"
                            value={inputs.Geometry}
                            onChange={handleInputChange}
                            fullWidth
                            disabled={viewMode}
                            // error={inputErrorState.Geometry.state}
                        />
                        <FormHelperText>
                            Estate Geometry/Polygon string
                        </FormHelperText>
                        {/* {inputErrorState.Geometry.state ? 
                        <FormHelperText error>
                            <strong>{inputErrorState.Geometry.message}</strong>
                        </FormHelperText>
                        :
                        <FormHelperText>
                            Estate Geometry/Polygon string
                        </FormHelperText>
                        } */}
                    </Grid>
                </Grid> 
            </MDBox>
        </>
    )
}

export default EstateSection


EstateSection.defaultProps = {
    viewMode: false,
    disableIdField: false,
    showIdField: false,
    title: "Estate",
    editButton: {
        show: false,
        text: "Edit",
        color: "warning",
        handler: ()=>{}
    },
    inputs: defaultInputState,
    inputErrorState: defaultInputErrorState

};