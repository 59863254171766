import { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { globalPublicData } from "globalPublicData";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { useFetchMenuOptionsQuery } from "redux/features/api/apiSlice";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PlusCollapse from "components/PlusCollapse";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Menu, MenuItem } from "@mui/material";
import ErrorDialog from "components/ErrorDialog";


const BatchApprovalSection = (props) => {

    const navigate = useNavigate()
    const user =  useSelector(state => state.user)
    const axiosConfig = {
        headers: {
          Authorization: `Bearer ${user.token}`
        },
        responseType: 'blob'
      }
    
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [errorDialogParams, setErrorDialogParams] = useState({ messageLine1: "", messageLine2: ""})
      
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const handleClick = (event) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu)
    };
    const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false)
    };

    const { templateEndpoint, ticket_uuid} = props

    const ticketAttachmentDownloadEndpoint = globalPublicData.baseEndpoint + "/ammticket/downloadattachmentbyticketuuid/"

    const handleDownloadTicketAttachment = async ()=>{
        try {
            var path = ticketAttachmentDownloadEndpoint + ticket_uuid
            const response = await axios.get(path, axiosConfig)
            if (response.status === 200){
                console.log('[handleDownloadTicketAttachment] response is: ', response)
                const fileName =  response.headers["content-disposition"] || new Date().toUTCString() + ".xlsx"
                const url = URL.createObjectURL(new Blob([response.data]));
                console.log(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            } else {
                setErrorDialogParams({
                    messageLine1: "Apologies, there was an error"
                })
                setOpenErrorDialog(true)
            }

        } catch (err){
            console.log("[handleDownloadTicketAttachment] error: ", err)
            setErrorDialogParams({
                messageLine1: "Apologies, there was an error",
                messageLine2: JSON.stringify(err.response.data)
            })
            setOpenErrorDialog(true)
        }
    } 

    return (
        <PlusCollapse title="Instructions" open={true}>
            <MDBox p={2} mt={3}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                    >
                        <MDTypography variant="body" color="dark">
                            Step 1: Download the batch file at the link below
                        </MDTypography>
                        <br />
                        <br />
                        <MDButton 
                            mt={3} 
                            color="info"
                            onClick={handleDownloadTicketAttachment}
                        >
                            Download File  <FileDownloadIcon/>
                        </MDButton>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        Step 2: Review the data for import in the file
                    </Grid>
                    <Grid item xs={12} md={7}>
                        Step 3: Approve, Reroute, or Reject the ticket accordingly
                    </Grid>
                </Grid>
            </MDBox>
            <ErrorDialog
                open={openErrorDialog}
                messageLine1={errorDialogParams.messageLine1}
                messageLine2={errorDialogParams.messageLine2}
                setOpenDialog={setOpenErrorDialog}
            />
        </PlusCollapse>
    )
}

export default BatchApprovalSection


BatchApprovalSection.defaultProps = {
    

};