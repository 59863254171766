/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import React, { useState, useEffect } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Collapse from "@mui/material/Collapse";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


// Material Kit 2 PRO React components
import borders from "assets/theme/base/borders";

function PlusCollapse({ title, open, children, ...rest }) {
    const { borderWidth, borderColor } = borders;
    const [openCollapse, setOpenCollapse] = React.useState(open)

    const handleToggle = () => {
        setOpenCollapse(!openCollapse)
    }

    return (
        <MDBox mx={1} mb={2}>
            <MDBox
                {...rest}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={2}
                px={2}
                borderBottom={`${borderWidth[1]} solid ${borderColor}`}
                sx={{ cursor: "pointer" }}
                onClick={handleToggle}
            >
                <MDTypography variant="h5" color={openCollapse ? "dark" : "text"} sx={{ userSelect: "none" }}>
                    {title}
                </MDTypography>
                <MDBox color={openCollapse ? "dark" : "text"}>
                    {openCollapse ?
                        <RemoveIcon
                            sx={{ fontWeight: "bold" }}
                            onClick={handleToggle}
                        />
                        : <AddIcon
                            sx={{ fontWeight: "bold" }}
                            onClick={handleToggle}

                        />}
                </MDBox>
            </MDBox>
            <Collapse timeout={400} in={openCollapse}>
                <MDBox py={1} lineHeight={1}>
                    <MDTypography variant="button" color="text" opacity={0.8} fontWeight="regular">
                        {children}
                    </MDTypography>
                </MDBox>
            </Collapse>
        </MDBox>
    );
}

// Typechecking props for the FaqCollapse
PlusCollapse.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export default PlusCollapse;
